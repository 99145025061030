import {
  CellClassParams,
  CellStyle,
  ColDef,
  ICellRendererParams,
  ITooltipParams,
  ValueGetterParams,
} from 'ag-grid-community';
import Link from 'components/atoms/Link/Link';
import { IHeaderCellProps } from 'components/molecules/HeaderCell/HeaderCell';
import { DEFAULT_ALTERNATING_ROW_STYLE } from 'components/molecules/RowStylingConfigurator/constants';
import { yesNoFromBool } from 'components/molecules/ToEntityDataTable/helpers';
import { IETagCopyPayload } from 'components/molecules/ToEntityETagCopy/interfaces';
import {
  BooleanCell,
  DateCell,
  DateTimeCell,
  HourEndingCell,
  IntegerCell,
  StringCell,
} from 'components/organisms/Cell/Cell';
import { HOUR_ENDING_KEY_PREFIX } from 'components/organisms/ToEntityMonitor/constants';
import { TAgGridSortState } from 'components/organisms/ToEntityMonitor/types';
import { DEFAULT_ON_SCREEN_TIME_IN_SECONDS } from 'constants/Alert';
import { ETAG_BOOLEAN_OPTIONS, ETAG_ENUM_OPTIONS_MAP } from 'constants/ETag';
import { NOT_FOUND_STATUS_CODE } from 'constants/misc';
import {
  ATTRIBUTE_ROW_STYLE_KEY,
  COLUMN_DATA_MAP,
  COLUMN_DATA_MAP_AG_GRID,
  DRAFT_ROW_STYLE_KEY,
  HOUR_ENDING_STYLE_KEY,
  PROFILES_ROW_STYLE_KEY,
} from 'constants/Summary';
import { DATE_FORMAT } from 'constants/time';
import { EAdHocFilter } from 'enums/AdHocFilter';
import { EAlertEffect } from 'enums/Alert';
import {
  EColumnPosition,
  EColumnType,
  ECompositeState,
  EProfileSegment,
  ETransactionType,
} from 'enums/ETag';
import { EPageMode } from 'enums/Page';
import { ESortDirection } from 'enums/Sort';
import { ETheme } from 'enums/Style';
import { EDateTimeRangeDST } from 'enums/Time';
import {
  IAlert,
  IAlertConfiguration,
  IAlertRule,
  IConfiguredAlert,
} from 'interfaces/Alert';
import { IOption } from 'interfaces/Component';
import {
  IETagAdHocFilters,
  IETagColumnData,
  IETagColumnDataAgGrid,
  IETagColumnExpand,
  IETagData,
  IETagDataSet,
  IETagIdentifier,
  IETagSummaryAttributeResponse,
  IETagSummaryProfilesResponse,
  IHeData,
} from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import {
  IColumnConfiguration,
  IColumnSort,
  ISummaryStyleCoding,
  ISummaryStyles,
  ISummaryThemedStyles,
  ITableConfiguration,
} from 'interfaces/Summary';
import { MutableRefObject } from 'react';
import {
  retrieveETagDistributedSummaryAttribute,
  retrieveETagDistributedSummaryProfiles,
} from 'services/agent/tags/distributed';
import {
  retrieveETagDraftSummaryAttribute,
  retrieveETagDraftSummaryProfiles,
} from 'services/agent/tags/drafts';
import { TAlertRulesMap } from 'types/Alert';
import { TTimeZone } from 'types/DateTime';
import { TDetailPageLocationIdParameter } from 'types/Detail';
import {
  TETagDataArrayDirectionalSorter,
  TETagDataArraySorter,
  TETagDataSetFilter,
} from 'types/ETag';
import { TFilterId } from 'types/Filter';
import { TArraySorter } from 'types/Sort';
import { TToEntityId } from 'types/ToEntity';
import { detailPageLocationString } from 'utils/detail';
import {
  eTagSort,
  getKeyForETagDataSet,
  getRecordKeyForETagIdentifier,
} from 'utils/eTag';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import {
  andThen,
  booleanSortWithEmpties,
  dateTimeStringSortWithEmpties,
  hourEndingSortWithEmpties,
  numberSortWithEmpties,
  stringSortWithEmpties,
} from 'utils/sort';
import { backgroundColour } from 'utils/styles';
import {
  getDateTimeForTimeZone,
  getDateTimeRangeDstType,
  toFormattedDateStringHandleNull,
  toFormattedDateTimeStringHandleNull,
} from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { EFields } from '../../../enums/Fields';
import { SummaryTotal } from '../DataGrid/aggFuncs';
import {
  SUMMARY_TOTALS_ROW_DARK_COLOR,
  SUMMARY_TOTALS_ROW_LIGHT_COLOR,
} from '../../../constants/Component';

export const getInitialSummaryThemedStyles = (): ISummaryThemedStyles => ({
  dark: {},
  light: {},
});

const determineThemedStylesAgGrid = (
  styleCoding: (ISummaryStyleCoding & IIndexable) | undefined,
  columnData: IETagColumnDataAgGrid,
  value: any,
  record: IETagDataSet & IIndexable,
  currentTheme: string | undefined = ETheme.Light,
): CellStyle => {
  let summaryStyles: ISummaryStyles = {
    'background-color': backgroundColour({ currentTheme }),
    'text-align': 'center',
    'padding-left': '4px',
    'padding-right': '4px',
  };

  const isTotalsCell = record && record.id && record.id === '_totals';

  if (styleCoding !== undefined) {
    if (columnData.isProfileData) {
      // Summary Profiles Row Conditions
      const rowStyleStates: Record<
        string,
        Record<string, ISummaryThemedStyles>
      > = styleCoding[PROFILES_ROW_STYLE_KEY] as Record<
        string,
        Record<string, ISummaryThemedStyles>
      >;
      Object.keys(rowStyleStates).forEach((key: string) => {
        const styleStates: Record<string, ISummaryThemedStyles> =
          rowStyleStates[key];
        const state: string = record[key];
        const stateSummaryThemedStyles:
          | (ISummaryThemedStyles & IIndexable)
          | undefined = styleStates[state];

        if (stateSummaryThemedStyles !== undefined) {
          summaryStyles = {
            ...summaryStyles,
            ...stateSummaryThemedStyles[currentTheme],
          };
        }
      });
    } else {
      // Summary Attribute Row Conditions
      const rowStyleStates: Record<
        string,
        Record<string, ISummaryThemedStyles>
      > = styleCoding[ATTRIBUTE_ROW_STYLE_KEY] as Record<
        string,
        Record<string, ISummaryThemedStyles>
      >;
      Object.keys(rowStyleStates).forEach((key: string) => {
        const styleStates: Record<string, ISummaryThemedStyles> =
          rowStyleStates[key];
        const state: string = record[key];
        const stateSummaryThemedStyles:
          | (ISummaryThemedStyles & IIndexable)
          | undefined = styleStates[state];

        if (stateSummaryThemedStyles !== undefined) {
          summaryStyles = {
            ...summaryStyles,
            ...stateSummaryThemedStyles[currentTheme],
          };
        }
      });
    }

    if (
      columnData.isProfileData &&
      columnData.field.startsWith(HOUR_ENDING_KEY_PREFIX)
    ) {
      // Summary Profile Conditions
      const he_StyleStates: Record<
        string,
        Record<string, ISummaryThemedStyles>
      > = styleCoding[HOUR_ENDING_STYLE_KEY] as Record<
        string,
        Record<string, ISummaryThemedStyles>
      >;

      Object.keys(he_StyleStates).forEach((key: string) => {
        const styleStates: Record<string, ISummaryThemedStyles> =
          he_StyleStates[key];
        const heData: IHeData | undefined = record[columnData.field];

        if (heData !== undefined && !isEmptyValue(heData.energy)) {
          const state: string = (heData.energy! as IIndexable)[key];
          const stateSummaryThemedStyles:
            | (ISummaryThemedStyles & IIndexable)
            | undefined = styleStates[state];

          if (stateSummaryThemedStyles !== undefined) {
            summaryStyles = {
              ...summaryStyles,
              ...stateSummaryThemedStyles[currentTheme],
            };
          }
        }
      });
    } else {
      // Summary Attribute Cell Conditions
      const styleStates: Record<string, ISummaryThemedStyles> = styleCoding[
        columnData.styleIndex === undefined
          ? columnData.field
          : columnData.styleIndex
      ] as Record<string, ISummaryThemedStyles>;

      const state: string =
        columnData.styleIndex === undefined
          ? value
          : record[columnData.styleIndex];

      if (styleStates !== undefined) {
        const stateSummaryThemedStyles:
          | (ISummaryThemedStyles & IIndexable)
          | undefined = styleStates[state];

        if (stateSummaryThemedStyles !== undefined) {
          summaryStyles = {
            ...summaryStyles,
            ...stateSummaryThemedStyles[currentTheme],
          };
        }
      }
    }

    // Draft Row Conditions
    const draftRowStyleStates: Record<
      string,
      Record<string, ISummaryThemedStyles>
    > = styleCoding[DRAFT_ROW_STYLE_KEY] as Record<
      string,
      Record<string, ISummaryThemedStyles>
    >;
    Object.keys(draftRowStyleStates).forEach((key: string) => {
      const styleStates: Record<string, ISummaryThemedStyles> =
        draftRowStyleStates[key];
      const state: string = record[key];
      const stateSummaryThemedStyles:
        | (ISummaryThemedStyles & IIndexable)
        | undefined = styleStates[state];

      if (stateSummaryThemedStyles !== undefined) {
        summaryStyles = {
          ...summaryStyles,
          ...stateSummaryThemedStyles[currentTheme],
        };
      }
    });
  }

  if (record.rowIndex && record.rowIndex % 2 === 1 && !isTotalsCell) {
    summaryStyles = {
      ...summaryStyles,
      ...DEFAULT_ALTERNATING_ROW_STYLE[currentTheme],
    };
  }

  if (isTotalsCell) {
    summaryStyles['background-color'] =
      currentTheme === ETheme.Light
        ? SUMMARY_TOTALS_ROW_LIGHT_COLOR
        : SUMMARY_TOTALS_ROW_DARK_COLOR;
    summaryStyles['border-right'] = '0';
    summaryStyles.display = 'flex';
    summaryStyles['justify-content'] =
      columnData.type === EColumnType.Integer ||
      columnData.type === EColumnType.HourEndings
        ? 'center'
        : 'left';
  }

  const summaryThemedStyles: ISummaryStyles = summaryStyles;

  return summaryThemedStyles as CellStyle & IIndexable;
};

const determineThemedStyles = (
  styleCoding: (ISummaryStyleCoding & IIndexable) | undefined,
  columnData: IETagColumnData,
  value: any,
  record: IETagDataSet & IIndexable,
): ISummaryThemedStyles => {
  const summaryThemedStyles: Record<string, ISummaryStyles> = {};

  Object.values(ETheme).forEach((currentTheme: string) => {
    let summaryStyles: ISummaryStyles = {
      'background-color': backgroundColour({ currentTheme }),
    };

    if (styleCoding !== undefined) {
      if (columnData.isProfileData) {
        // Summary Profiles Row Conditions
        const rowStyleStates: Record<
          string,
          Record<string, ISummaryThemedStyles>
        > = styleCoding[PROFILES_ROW_STYLE_KEY] as Record<
          string,
          Record<string, ISummaryThemedStyles>
        >;
        Object.keys(rowStyleStates).forEach((key: string) => {
          const styleStates: Record<string, ISummaryThemedStyles> =
            rowStyleStates[key];
          const state: string = record[key];
          const stateSummaryThemedStyles:
            | (ISummaryThemedStyles & IIndexable)
            | undefined = styleStates[state];

          if (stateSummaryThemedStyles !== undefined) {
            summaryStyles = {
              ...summaryStyles,
              ...stateSummaryThemedStyles[currentTheme],
            };
          }
        });
      } else {
        // Summary Attribute Row Conditions
        const rowStyleStates: Record<
          string,
          Record<string, ISummaryThemedStyles>
        > = styleCoding[ATTRIBUTE_ROW_STYLE_KEY] as Record<
          string,
          Record<string, ISummaryThemedStyles>
        >;
        Object.keys(rowStyleStates).forEach((key: string) => {
          const styleStates: Record<string, ISummaryThemedStyles> =
            rowStyleStates[key];
          const state: string = record[key];
          const stateSummaryThemedStyles:
            | (ISummaryThemedStyles & IIndexable)
            | undefined = styleStates[state];

          if (stateSummaryThemedStyles !== undefined) {
            summaryStyles = {
              ...summaryStyles,
              ...stateSummaryThemedStyles[currentTheme],
            };
          }
        });
      }

      if (
        columnData.isProfileData &&
        columnData.dataIndex.startsWith(HOUR_ENDING_KEY_PREFIX)
      ) {
        // Summary Profile Conditions
        const he_StyleStates: Record<
          string,
          Record<string, ISummaryThemedStyles>
        > = styleCoding[HOUR_ENDING_STYLE_KEY] as Record<
          string,
          Record<string, ISummaryThemedStyles>
        >;

        Object.keys(he_StyleStates).forEach((key: string) => {
          const styleStates: Record<string, ISummaryThemedStyles> =
            he_StyleStates[key];
          const heData: IHeData | undefined = record[columnData.dataIndex];

          if (heData !== undefined && !isEmptyValue(heData.energy)) {
            const state: string = (heData.energy! as IIndexable)[key];
            const stateSummaryThemedStyles:
              | (ISummaryThemedStyles & IIndexable)
              | undefined = styleStates[state];

            if (stateSummaryThemedStyles !== undefined) {
              summaryStyles = {
                ...summaryStyles,
                ...stateSummaryThemedStyles[currentTheme],
              };
            }
          }
        });
      } else {
        // Summary Attribute Cell Conditions
        const styleStates: Record<string, ISummaryThemedStyles> = styleCoding[
          columnData.styleIndex === undefined
            ? columnData.dataIndex
            : columnData.styleIndex
        ] as Record<string, ISummaryThemedStyles>;

        const state: string =
          columnData.styleIndex === undefined
            ? value
            : record[columnData.styleIndex];

        if (styleStates !== undefined) {
          const stateSummaryThemedStyles:
            | (ISummaryThemedStyles & IIndexable)
            | undefined = styleStates[state];

          if (stateSummaryThemedStyles !== undefined) {
            summaryStyles = {
              ...summaryStyles,
              ...stateSummaryThemedStyles[currentTheme],
            };
          }
        }
      }

      // Draft Row Conditions
      const draftRowStyleStates: Record<
        string,
        Record<string, ISummaryThemedStyles>
      > = styleCoding[DRAFT_ROW_STYLE_KEY] as Record<
        string,
        Record<string, ISummaryThemedStyles>
      >;
      Object.keys(draftRowStyleStates).forEach((key: string) => {
        const styleStates: Record<string, ISummaryThemedStyles> =
          draftRowStyleStates[key];
        const state: string = record[key];
        const stateSummaryThemedStyles:
          | (ISummaryThemedStyles & IIndexable)
          | undefined = styleStates[state];

        if (stateSummaryThemedStyles !== undefined) {
          summaryStyles = {
            ...summaryStyles,
            ...stateSummaryThemedStyles[currentTheme],
          };
        }
      });
    }

    summaryThemedStyles[currentTheme] = summaryStyles;
  });

  return summaryThemedStyles as ISummaryThemedStyles & IIndexable;
};

const columnSorter = (a: IETagColumnData, b: IETagColumnData): number => {
  if (a.isProfileData === true && b.isProfileData === undefined) {
    return 1;
  } else if (a.isProfileData === undefined && b.isProfileData === true) {
    return -1;
  }

  return 0;
};

const columnSorterAgGrid = (
  a: IETagColumnDataAgGrid,
  b: IETagColumnDataAgGrid,
): number => {
  if (a.isProfileData === true && b.isProfileData === undefined) {
    return 1;
  } else if (a.isProfileData === undefined && b.isProfileData === true) {
    return -1;
  }

  return 0;
};

export const getColumnsForTableConfigurationAgGrid = (
  selectedTableConfiguration: ITableConfiguration | undefined,
  fixedProfile: boolean,
  startDateTime: ZonedDateTime,
  endDateTime: ZonedDateTime,
  showLosses?: boolean,
): IETagColumnDataAgGrid[] => {
  let columns: IETagColumnDataAgGrid[] = [];
  let expandColumns: IETagColumnDataAgGrid[] | undefined = [];

  if (selectedTableConfiguration !== undefined) {
    const dateTimeRangeType: EDateTimeRangeDST = getDateTimeRangeDstType(
      startDateTime,
      endDateTime,
    );
    selectedTableConfiguration.columns.forEach(
      (columnConfiguration: IColumnConfiguration) => {
        if (columnConfiguration.dataIndex === 'hour_endings') {
          if (
            COLUMN_DATA_MAP_AG_GRID[columnConfiguration.dataIndex]?.expandTo !==
            undefined
          ) {
            expandColumns =
              COLUMN_DATA_MAP_AG_GRID[columnConfiguration.dataIndex]?.expandTo;
            if (expandColumns !== undefined) {
              const expandExtraColumns: IETagColumnDataAgGrid[] | undefined =
                COLUMN_DATA_MAP_AG_GRID[columnConfiguration.dataIndex]
                  ?.expandToExtra;
              if (
                dateTimeRangeType === EDateTimeRangeDST.Longer &&
                expandExtraColumns
              ) {
                const insertIndex = expandColumns.findIndex(
                  (i: IETagColumnDataAgGrid) => i.field === 'he_02',
                );
                // Create a new array to avoid mutating the original one and prevent duplicates
                const expandColumnsWithLongDaySupport = expandColumns
                  .slice(0, insertIndex + 1)
                  .concat(
                    expandExtraColumns,
                    expandColumns.slice(insertIndex + 1),
                  );
                columns.push(...expandColumnsWithLongDaySupport);
              } else {
                if (dateTimeRangeType === EDateTimeRangeDST.Shorter) {
                  // Remove the extra hour for short date
                  const extraIndex = expandColumns.findIndex(
                    (i: IETagColumnDataAgGrid) => i.field === 'he_03',
                  );
                  const expandColumnsWithShortDaySupport = expandColumns
                    .slice(0, extraIndex)
                    .concat(expandColumns.slice(extraIndex + 1));
                  columns.push(...expandColumnsWithShortDaySupport);
                } else {
                  columns.push(...expandColumns);
                }
              }
            }
          }
        } else {
          const columnData: IETagColumnDataAgGrid | undefined =
            COLUMN_DATA_MAP_AG_GRID[columnConfiguration.dataIndex];

          if (columnData !== undefined) {
            const column: IETagColumnDataAgGrid = {
              ...columnData,
              ...columnConfiguration,
            };

            if (fixedProfile && columnData.isProfileData === true) {
              column.fixed = EColumnPosition.Right;
            }

            columns.push(column);
          }
        }
      },
    );
  }

  if (!showLosses) {
    const isLossesTagColumnIndex = columns.findIndex(
      (column) => column.field === 'is_losses_tag',
    );
    if (isLossesTagColumnIndex) {
      columns.splice(isLossesTagColumnIndex, 1);
    }
  }

  if (fixedProfile) {
    columns.sort(columnSorterAgGrid);
  }

  return columns;
};

export const getColumnsForTableConfiguration = (
  selectedTableConfiguration: ITableConfiguration | undefined,
  fixedProfile: boolean,
): IETagColumnData[] => {
  let columns: IETagColumnData[] = [];

  if (selectedTableConfiguration !== undefined) {
    selectedTableConfiguration.columns.forEach(
      (columnConfiguration: IColumnConfiguration) => {
        const columnData: IETagColumnData | undefined =
          COLUMN_DATA_MAP[columnConfiguration.dataIndex];

        if (columnData !== undefined) {
          const column: IETagColumnData = {
            ...columnData,
            ...columnConfiguration,
          };

          if (fixedProfile && columnData.isProfileData === true) {
            column.fixed = EColumnPosition.Right;
          }

          columns.push(column);
        }
      },
    );
  }

  if (fixedProfile) {
    columns.sort(columnSorter);
  }

  return columns;
};

const hasStyleDataChanged = (
  stylingRecord:
    | Record<string, Record<string, ISummaryThemedStyles>>
    | undefined,
  previousRecord: IETagDataSet & IIndexable,
  nextRecord: IETagDataSet & IIndexable,
  hourEndingKey?: string,
): boolean => {
  if (stylingRecord !== undefined) {
    if (hourEndingKey === undefined) {
      for (const styleKey in stylingRecord) {
        if (previousRecord[styleKey] !== nextRecord[styleKey]) {
          return true;
        }
      }
    } else {
      for (const styleKey in stylingRecord) {
        if (
          ((previousRecord[hourEndingKey] as IHeData)?.energy as IIndexable)?.[
            styleKey
          ] !==
          ((nextRecord[hourEndingKey] as IHeData)?.energy as IIndexable)?.[
            styleKey
          ]
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const hourEndingRenderer =
  (
    styleCoding: ISummaryStyleCoding | undefined,
    columnData: IETagColumnData,
    toEntityId: TToEntityId,
  ) =>
  (value: IHeData, record: IETagDataSet): JSX.Element =>
    (
      <HourEndingCell
        eTagRecordKey={getRecordKeyForETagIdentifier(record)}
        isProfileData={columnData.isProfileData}
        minWidth={columnData.minColumnWidth}
        summaryThemedStyles={determineThemedStyles(
          styleCoding,
          columnData,
          value,
          record,
        )}
        toEntityId={toEntityId}
        value={value}
      />
    );

const isNowWithinEditableTimeFrame = (
  record: IETagDataSet,
  timeZone: TTimeZone,
): boolean => {
  if (!record.end_date) {
    return false;
  }
  const now: ZonedDateTime = ZonedDateTime.now(timeZone);
  const tagStop: ZonedDateTime = ZonedDateTime.parseIso(
    record.end_date,
    timeZone,
  );
  if (
    record.transaction_type === ETransactionType.Dynamic ||
    record.transaction_type === ETransactionType.PseudoTie
  ) {
    return now.isBefore(tagStop.add(168, 'hours'));
  }
  if (record.transaction_type === ETransactionType.Normal) {
    return now.isBefore(tagStop.add(1, 'hour'));
  }
  return now.isBefore(tagStop);
};

const STRING_FILTERS = [
  EAdHocFilter.Contains,
  EAdHocFilter.DoesNotContain,
  EAdHocFilter.StartsWith,
  EAdHocFilter.DoesNotStartWith,
  EAdHocFilter.EndsWith,
  EAdHocFilter.DoesNotEndWith,
  EAdHocFilter.Equals,
  EAdHocFilter.DoesNotEqual,
  EAdHocFilter.CaseInsensitiveEquals,
  EAdHocFilter.CaseInsensitiveDoesNotEqual,
  EAdHocFilter.IsBlank,
  EAdHocFilter.IsNotBlank,
];
export const transformColumnInformation = (
  columnData: IETagColumnData[],
  selectedTableConfiguration: ITableConfiguration | undefined,
  timeZone: TTimeZone,
  toEntityId: TToEntityId,
  styleCoding: ISummaryStyleCoding | undefined,
): IETagColumnData[] => {
  const defaultSort: Record<string, IColumnSort> =
    selectedTableConfiguration === undefined ||
    selectedTableConfiguration.defaultSort === undefined
      ? {}
      : selectedTableConfiguration.defaultSort;

  const transformed: IETagColumnData[] = [];

  columnData.forEach((column: IETagColumnData, index: number) => {
    const columnCopy: IETagColumnData = { ...column };

    const adHocFilters: IETagAdHocFilters = {
      filters: [],
    };
    let sort: TETagDataArrayDirectionalSorter | undefined = undefined;

    switch (columnCopy.type) {
      case EColumnType.Boolean: {
        columnCopy.render = (
          value: boolean,
          record: IETagDataSet,
        ): JSX.Element => (
          <BooleanCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.enumOptions = ETAG_BOOLEAN_OPTIONS;
        adHocFilters.filters = [
          EAdHocFilter.BooleanIsOneOf,
          EAdHocFilter.BooleanIsNotOneOf,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagSort<boolean>(booleanSortWithEmpties)(columnCopy.dataIndex);
        break;
      }
      case EColumnType.Date: {
        columnCopy.render = (
          value: string,
          record: IETagDataSet,
        ): JSX.Element => (
          <DateCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            timeZone={timeZone}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.filters = [
          EAdHocFilter.IsSame,
          EAdHocFilter.IsBefore,
          EAdHocFilter.IsSameOrBefore,
          EAdHocFilter.IsAfter,
          EAdHocFilter.IsSameOrAfter,
          EAdHocFilter.IsWithin,
          EAdHocFilter.InclusiveIsWithin,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagSort<string>(dateTimeStringSortWithEmpties(timeZone, false))(
          columnCopy.dataIndex,
        );
        break;
      }
      case EColumnType.DateTime: {
        columnCopy.render = (
          value: string,
          record: IETagDataSet,
        ): JSX.Element => (
          <DateTimeCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            timeZone={timeZone}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.filters = [
          EAdHocFilter.IsBefore,
          EAdHocFilter.IsSameOrBefore,
          EAdHocFilter.IsAfter,
          EAdHocFilter.IsSameOrAfter,
          EAdHocFilter.IsWithin,
          EAdHocFilter.InclusiveIsWithin,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagSort<string>(dateTimeStringSortWithEmpties(timeZone, false))(
          columnCopy.dataIndex,
        );
        break;
      }
      case EColumnType.DetailLink: {
        columnCopy.render = (
          value: string,
          record: IETagDataSet,
        ): JSX.Element => {
          let idparams: TDetailPageLocationIdParameter | undefined;

          if (record.draft_id !== null) {
            // `${DETAIL_PAGE_DRAFT_ID_QUERY_PARAMETER_NAME}=${record.draft_id}`;
            idparams = { draftId: record.draft_id };
          } else if (record.tag_primary_key !== null) {
            //`${DETAIL_PAGE_TAG_PRIMARY_KEY_QUERY_PARAMETER_NAME}=${record.tag_primary_key}`;
            idparams = { tagPrimaryKey: record.tag_primary_key };
          } else {
            idparams = undefined;
          }

          const stringCell = (
            <StringCell
              eTagRecordKey={getRecordKeyForETagIdentifier(record)}
              isProfileData={columnCopy.isProfileData}
              minWidth={columnCopy.minColumnWidth}
              summaryThemedStyles={determineThemedStyles(
                styleCoding,
                columnCopy,
                value,
                record,
              )}
              toEntityId={toEntityId}
              value={value}
            />
          );

          if (idparams === undefined) {
            return stringCell;
          } else {
            const detailMode: EPageMode =
              record.composite_state === ECompositeState.Draft
                ? EPageMode.Edit
                : record.composite_state === ECompositeState.Denied ||
                  record.composite_state === ECompositeState.Terminated ||
                  record.composite_state === ECompositeState.Expired ||
                  record.composite_state === ECompositeState.Withdrawn ||
                  record.composite_state === ECompositeState.Cancelled ||
                  !isNowWithinEditableTimeFrame(record, timeZone)
                ? EPageMode.Review
                : EPageMode.Edit;
            return (
              <Link
                to={detailPageLocationString({
                  ...idparams,
                  defaultTimeZone: timeZone,
                  mode: detailMode,
                  toEntity: toEntityId,
                })}
                target='_blank'
              >
                {stringCell}
              </Link>
            );
          }
        };

        adHocFilters.filters = STRING_FILTERS;

        sort = eTagSort<string>(stringSortWithEmpties)(columnCopy.dataIndex);
        break;
      }
      case EColumnType.Enumeration: {
        columnCopy.render = (
          value: string,
          record: IETagDataSet,
        ): JSX.Element => (
          <StringCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.enumOptions = ETAG_ENUM_OPTIONS_MAP[columnCopy.dataIndex];
        adHocFilters.filters = [
          EAdHocFilter.IsOneOf,
          EAdHocFilter.IsNotOneOf,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagSort<string>(stringSortWithEmpties)(columnCopy.dataIndex);
        break;
      }
      case EColumnType.HourEndings: {
        adHocFilters.filters = [
          EAdHocFilter.IsLessThan,
          EAdHocFilter.IsLessThanOrEqual,
          EAdHocFilter.IsGreaterThan,
          EAdHocFilter.IsGreaterThanOrEqual,
          EAdHocFilter.IsBetween,
          EAdHocFilter.InclusiveIsBetween,
          EAdHocFilter.NumberEquals,
          EAdHocFilter.NumberDoesNotEqual,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];
        break;
      }
      case EColumnType.Integer: {
        columnCopy.render = (
          value: number,
          record: IETagDataSet,
        ): JSX.Element => (
          <IntegerCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.filters = [
          EAdHocFilter.IsLessThan,
          EAdHocFilter.IsLessThanOrEqual,
          EAdHocFilter.IsGreaterThan,
          EAdHocFilter.IsGreaterThanOrEqual,
          EAdHocFilter.IsBetween,
          EAdHocFilter.InclusiveIsBetween,
          EAdHocFilter.NumberEquals,
          EAdHocFilter.NumberDoesNotEqual,
          EAdHocFilter.IsBlank,
          EAdHocFilter.IsNotBlank,
        ];

        sort = eTagSort<number>(numberSortWithEmpties)(columnCopy.dataIndex);
        break;
      }
      case EColumnType.String: {
        columnCopy.render = (
          value: string,
          record: IETagDataSet,
        ): JSX.Element => (
          <StringCell
            eTagRecordKey={getRecordKeyForETagIdentifier(record)}
            isProfileData={columnCopy.isProfileData}
            minWidth={columnCopy.minColumnWidth}
            summaryThemedStyles={determineThemedStyles(
              styleCoding,
              columnCopy,
              value,
              record,
            )}
            toEntityId={toEntityId}
            value={value}
          />
        );

        adHocFilters.filters = STRING_FILTERS;

        sort = eTagSort<string>(stringSortWithEmpties)(columnCopy.dataIndex);
        break;
      }
      default: {
        throw new Error(`Invalid column type: ${columnCopy.type}`);
      }
    }

    columnCopy.shouldCellUpdate = (
      prevRecord: IETagDataSet & IIndexable,
      nextRecord: IETagDataSet & IIndexable,
    ): boolean =>
      prevRecord[column.dataIndex] !== nextRecord[column.dataIndex] ||
      prevRecord.isFixedMap[column.dataIndex] !==
        nextRecord.isFixedMap[column.dataIndex] ||
      nextRecord.isFixedMap[column.dataIndex] ||
      (column.styleIndex !== undefined &&
        prevRecord[column.styleIndex] !== nextRecord[column.styleIndex]) ||
      hasStyleDataChanged(
        styleCoding?.[
          columnCopy.isProfileData
            ? PROFILES_ROW_STYLE_KEY
            : ATTRIBUTE_ROW_STYLE_KEY
        ],
        prevRecord,
        nextRecord,
      ) ||
      hasStyleDataChanged(
        styleCoding?.[DRAFT_ROW_STYLE_KEY],
        prevRecord,
        nextRecord,
      );

    const headerCellProps: IHeaderCellProps = {
      ...columnCopy,
      adHocFilters,
      // We use a doubled index in order to allow for a gap between each
      // column's z-index value which allows us to later insert the
      // extra dst column in between existing z-indexes and thus retain
      // the correct descending z-index values needed to ensure that we
      // always render input expansions from left to right and on top.
      columnIndex: index * 2,
      defaultSort: defaultSort[columnCopy.dataIndex],
      isPrimaryColumn: false,
      sort,
      timeZone,
    };

    // Forward column data to the header cell component
    columnCopy.onHeaderCell = (): IHeaderCellProps => headerCellProps;

    transformed.push(columnCopy);
  });

  const firstColumn: IETagColumnData | undefined = transformed[0];

  if (firstColumn !== undefined && firstColumn.onHeaderCell !== undefined) {
    const headerCellProps: IHeaderCellProps = {
      ...firstColumn.onHeaderCell(firstColumn),
      isPrimaryColumn: true,
    };

    firstColumn.onHeaderCell = (): IHeaderCellProps => headerCellProps;
  }

  return transformed;
};

const boolFromYesNo = (yesOrNo: string): boolean | null =>
  yesOrNo === 'Yes' ? true : yesOrNo === 'No' ? false : null;

const totalTextSelector = (params: any) => {
  const isLastRow = params.data.id === '_totals';
  if (isLastRow || params.node.rowPinned === 'bottom') {
    let component;
    switch (params.colDef?.colId) {
      case EFields.UITagId:
        component = 'tagCountTextRenderer';
        break;
      case EFields.Day:
        component = 'mwTotalsTextRenderer';
        break;
      default:
        component = undefined;
        break;
    }

    return {
      component,
    };
  } else {
    return undefined;
  }
};

const totalsHoursEndingCellTextSelector = (params: any) => {
  const isLastRow = params.data.id === '_totals';
  if (isLastRow || params.node.rowPinned === 'bottom') {
    const component = 'totalsRowRenderer';
    return {
      component,
    };
  } else {
    return undefined;
  }
};

const totalsIntegerCellTextSelector = (params: any) => {
  const isLastRow = params.data.id === '_totals';
  if (isLastRow || params.node.rowPinned === 'bottom') {
    let component;
    switch (params.colDef?.colId) {
      case EFields.TotalMW:
      case EFields.OnPeakTotalMW:
      case EFields.OffPeakTotalMW:
        component = 'totalsRowRenderer';
        break;
      default:
        component = undefined;
        break;
    }
    return {
      component,
    };
  } else {
    return undefined;
  }
};

const caseInsensitiveCompare = (
  a: string | undefined,
  b: string | undefined,
) => {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  return (a ?? '').toLowerCase().localeCompare((b ?? '').toLowerCase());
};

const dateCompare = (a: string | undefined, b: string | undefined) => {
  if (a === b) {
    return 0;
  }
  if (!a) {
    return 1;
  }
  if (!b) {
    return -1;
  }

  return new Date(a).getTime() - new Date(b).getTime();
};

export const transformGridColumnInformation = (
  columnData: IETagColumnDataAgGrid[],
  timeZone: TTimeZone,
  toEntityId: TToEntityId,
  styleCoding: ISummaryStyleCoding | undefined,
  currentTheme: string | undefined,
): ColDef[] => {
  const transformed: ColDef[] = [];

  columnData.forEach((column: IETagColumnDataAgGrid) => {
    const columnDefinition: ColDef = {
      cellStyle: (params: CellClassParams) =>
        determineThemedStylesAgGrid(
          styleCoding,
          column,
          params.value,
          params.data,
          currentTheme,
        ),
      colId: column.field,
      comparator: caseInsensitiveCompare,
      filter: true,
      floatingFilter: true,
      resizable: true,
      sortable: true,
      menuTabs: [],
      tooltipValueGetter: (params: ITooltipParams) =>
        params.valueFormatted ?? params.value,
      field: column.field,
      filterParams: {
        newRowsAction: 'keep',
      },
      // TODO: Apply AG Grid recommended fix, if any
      // Disabled this to adress the column filter not being clickable in certain browsers
      // floatingFilterComponentParams: { suppressFilterButton: true },
      headerName: column.headerName,
      maxWidth: column.maxWidth,
      minWidth: column.minWidth,
      pinned: column.fixed ?? false,
      cellRendererSelector: totalTextSelector,
      aggFunc: SummaryTotal,
    };

    switch (column.type) {
      case EColumnType.Boolean: {
        columnDefinition.field = undefined;
        columnDefinition.valueGetter = (params: ValueGetterParams) =>
          yesNoFromBool(params.data[column.field]);
        columnDefinition.cellStyle = (params: CellClassParams) =>
          determineThemedStylesAgGrid(
            styleCoding,
            column,
            boolFromYesNo(params.value),
            params.data,
            currentTheme,
          );
        break;
      }
      case EColumnType.Date:
      case EColumnType.DateTime: {
        columnDefinition.field = undefined;
        if (column.type === EColumnType.Date) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        if (column.type === EColumnType.DateTime) {
          columnDefinition.valueGetter = (params: ValueGetterParams) =>
            toFormattedDateTimeStringHandleNull(
              params.data[column.field],
              timeZone,
            );
        }
        columnDefinition.chartDataType = 'time';
        columnDefinition.filter = 'agMultiColumnFilter';
        columnDefinition.filterParams = {
          ...columnDefinition.filterParams,
          filters: [
            {
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: function (filterDate: Date, cellValue: string) {
                  if (cellValue === null) {
                    return -1;
                  }
                  return (
                    new Date(
                      ZonedDateTime.parse(cellValue, 'UTC', DATE_FORMAT).format(
                        'M/D/YYYY',
                      ),
                    ).getTime() -
                    new Date(
                      ZonedDateTime.fromDate(filterDate, 'UTC').format(
                        'M/D/YYYY',
                      ),
                    ).getTime()
                  );
                },
                newRowsAction: 'keep',
              },
            },
            {
              filter: 'agSetColumnFilter',
            },
          ],
        };
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        columnDefinition.comparator = dateCompare;
        break;
      }
      case EColumnType.DetailLink: {
        columnDefinition.cellRenderer = (params: ICellRendererParams) => {
          let idparams: TDetailPageLocationIdParameter | undefined;

          if (params.data.draft_id !== null) {
            // `${DETAIL_PAGE_DRAFT_ID_QUERY_PARAMETER_NAME}=${record.draft_id}`;
            idparams = { draftId: params.data.draft_id };
          } else if (params.data.tag_primary_key !== null) {
            //`${DETAIL_PAGE_TAG_PRIMARY_KEY_QUERY_PARAMETER_NAME}=${record.tag_primary_key}`;
            idparams = { tagPrimaryKey: params.data.tag_primary_key };
          } else {
            idparams = undefined;
          }

          if (idparams === undefined) {
            return params.value;
          } else {
            const detailMode: EPageMode =
              params.data.composite_state === ECompositeState.Draft
                ? EPageMode.Edit
                : params.data.composite_state === ECompositeState.Denied ||
                  params.data.composite_state === ECompositeState.Terminated ||
                  params.data.composite_state === ECompositeState.Expired ||
                  params.data.composite_state === ECompositeState.Withdrawn ||
                  params.data.composite_state === ECompositeState.Cancelled ||
                  !isNowWithinEditableTimeFrame(
                    params.data as IETagDataSet,
                    timeZone,
                  )
                ? EPageMode.Review
                : EPageMode.Edit;
            return (
              // eslint-disable-next-line react/jsx-no-target-blank
              <a
                href={
                  (process.env.REACT_APP_BASEDIR ?? '') +
                  detailPageLocationString({
                    ...idparams,
                    defaultTimeZone: timeZone,
                    mode: detailMode,
                    toEntity: toEntityId,
                  })
                }
                target='_blank'
              >
                {params.value}
              </a>
            );
          }
        };
        break;
      }
      case EColumnType.HourEndings: {
        columnDefinition.filter = 'agNumberColumnFilter';
        columnDefinition.field = undefined;
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        columnDefinition.valueGetter = (params: ValueGetterParams) =>
          params.data[column.field]?.energy?.mw;
        columnDefinition.cellRendererSelector =
          totalsHoursEndingCellTextSelector;
        break;
      }
      case EColumnType.Integer: {
        columnDefinition.filter = 'agNumberColumnFilter';
        columnDefinition.floatingFilterComponentParams = {
          suppressFilterButton: false,
        };
        columnDefinition.cellRendererSelector = totalsIntegerCellTextSelector;
        break;
      }
      case EColumnType.Enumeration:
      case EColumnType.String: {
        break;
      }
      default: {
        throw new Error(`Invalid column type: ${columnDefinition.type}`);
      }
    }
    transformed.push(columnDefinition);
  });

  return transformed;
};

export const expandColumns = (
  columnData: IETagColumnData[],
  toEntityId: TToEntityId,
  styleCoding: ISummaryStyleCoding | undefined,
  startDateTime: ZonedDateTime,
  endDateTime: ZonedDateTime,
): IETagColumnData[] => {
  const expanded: IETagColumnData[] = [];
  const dateTimeRangeType: EDateTimeRangeDST = getDateTimeRangeDstType(
    startDateTime,
    endDateTime,
  );

  columnData.forEach((column: IETagColumnData) => {
    if (column.expandTo === undefined) {
      expanded.push(column);
    } else {
      column.expandTo.forEach((value: IETagColumnExpand, index: number) => {
        if (
          value.dataIndex === 'he_03' &&
          dateTimeRangeType === EDateTimeRangeDST.Shorter
        ) {
          return;
        }

        const headerCellProps: IHeaderCellProps = column.onHeaderCell!(column);

        const hourEndingColumnData: IETagColumnData = {
          ...column,
          dataIndex: value.dataIndex,
          displayName: value.displayName,
        };

        hourEndingColumnData.shouldCellUpdate = (
          prevRecord: IETagDataSet & IIndexable,
          nextRecord: IETagDataSet & IIndexable,
        ): boolean =>
          (prevRecord[value.dataIndex] as IHeData)?.energy?.mw !==
            (nextRecord[value.dataIndex] as IHeData)?.energy?.mw ||
          prevRecord.isFixedMap[value.dataIndex] !==
            nextRecord.isFixedMap[value.dataIndex] ||
          hasStyleDataChanged(
            styleCoding?.[
              column.isProfileData
                ? PROFILES_ROW_STYLE_KEY
                : ATTRIBUTE_ROW_STYLE_KEY
            ],
            prevRecord,
            nextRecord,
          ) ||
          hasStyleDataChanged(
            styleCoding?.[DRAFT_ROW_STYLE_KEY],
            prevRecord,
            nextRecord,
          ) ||
          hasStyleDataChanged(
            styleCoding?.[HOUR_ENDING_STYLE_KEY],
            prevRecord,
            nextRecord,
            value.dataIndex,
          );

        const hourEndingColumnHeaderCellProps: IHeaderCellProps = {
          ...hourEndingColumnData,
          adHocFilters: headerCellProps.adHocFilters,
          // We must ensure we continue to use doubled indexes in order to
          // retain the column z-index gap.
          columnIndex: headerCellProps.columnIndex! + index * 2,
          defaultSort: headerCellProps.defaultSort,
          isPrimaryColumn: false,
          sort: eTagSort<IHeData>(hourEndingSortWithEmpties)(
            hourEndingColumnData.dataIndex,
          ),
          timeZone: headerCellProps.timeZone,
        };

        hourEndingColumnData.onHeaderCell = (): IHeaderCellProps =>
          hourEndingColumnHeaderCellProps;

        hourEndingColumnData.render = hourEndingRenderer(
          styleCoding,
          hourEndingColumnData,
          toEntityId,
        );

        expanded.push(hourEndingColumnData);

        if (
          hourEndingColumnData.dataIndex === 'he_02' &&
          dateTimeRangeType === EDateTimeRangeDST.Longer
        ) {
          const extraHourEndingColumnData: IETagColumnData = {
            ...hourEndingColumnData,
            dataIndex: 'he_02_extra',
            displayName: 'HE\n02*',
          };

          const extraHourEndingColumnHeaderCellProps: IHeaderCellProps = {
            ...extraHourEndingColumnData,
            adHocFilters: headerCellProps.adHocFilters,
            // This allows us to correctly set the z-index value between
            // existing column z-index values.
            columnIndex: headerCellProps.columnIndex! + index * 2 + 1,
            defaultSort: headerCellProps.defaultSort,
            isPrimaryColumn: false,
            sort: eTagSort<IHeData>(hourEndingSortWithEmpties)(
              extraHourEndingColumnData.dataIndex,
            ),
            timeZone: headerCellProps.timeZone,
          };

          extraHourEndingColumnData.onHeaderCell = (): IHeaderCellProps =>
            extraHourEndingColumnHeaderCellProps;

          extraHourEndingColumnData.render = hourEndingRenderer(
            styleCoding,
            extraHourEndingColumnData,
            toEntityId,
          );

          expanded.push(extraHourEndingColumnData);
        }
      });
    }
  });

  return expanded;
};

export const retrieveETagData = async (
  toEntityId: TToEntityId,
  eTagIdentifier: IETagIdentifier,
  start: ZonedDateTime,
  end: ZonedDateTime,
  timeZone: string,
  profileSegment: EProfileSegment,
  filterId?: TFilterId,
): Promise<IETagData> => {
  const { draft_id, tag_primary_key } = eTagIdentifier;
  const [retrieveAttributeResponse, retrieveProfilesResponse] =
    await Promise.allSettled(
      draft_id
        ? [
            retrieveETagDraftSummaryAttribute(
              toEntityId,
              draft_id,
              start,
              end,
              filterId,
            ),
            retrieveETagDraftSummaryProfiles(
              toEntityId,
              draft_id,
              timeZone,
              start,
              end,
              profileSegment,
            ),
          ]
        : [
            retrieveETagDistributedSummaryAttribute(
              toEntityId,
              tag_primary_key,
              start,
              end,
              filterId,
            ),
            retrieveETagDistributedSummaryProfiles(
              toEntityId,
              tag_primary_key,
              timeZone,
              start,
              end,
              profileSegment,
            ),
          ],
    );

  if (retrieveAttributeResponse.status === 'rejected') {
    throw new Error(retrieveAttributeResponse.reason);
  }

  if (retrieveProfilesResponse.status === 'rejected') {
    throw new Error(retrieveProfilesResponse.reason);
  }

  const eTagSummaryAttributeResponse: IETagSummaryAttributeResponse =
    retrieveAttributeResponse.value.data;

  if (retrieveAttributeResponse.value.status === NOT_FOUND_STATUS_CODE) {
    return {
      recordKey: getRecordKeyForETagIdentifier(eTagIdentifier),
      summaryAttribute: undefined,
      summaryProfiles: [],
    };
  } else if (!isSuccessStatus(retrieveAttributeResponse.value.status)) {
    throw new Error(eTagSummaryAttributeResponse.errorMessage!);
  }

  const eTagSummaryProfilesResponse: IETagSummaryProfilesResponse =
    retrieveProfilesResponse.value.data;

  if (!isSuccessStatus(retrieveProfilesResponse.value.status)) {
    return {
      recordKey: getRecordKeyForETagIdentifier(eTagIdentifier),
      summaryAttribute: eTagSummaryAttributeResponse.response,
      summaryProfiles: [],
    };
  }

  return {
    recordKey: getRecordKeyForETagIdentifier(eTagIdentifier),
    summaryAttribute: eTagSummaryAttributeResponse.response,
    summaryProfiles: eTagSummaryProfilesResponse.response,
  };
};

export const getEtagCopyPayload = (
  selectedEtagDataset: IETagDataSet | undefined,
): IETagCopyPayload | undefined =>
  selectedEtagDataset === undefined
    ? undefined
    : {
        composite_state: selectedEtagDataset.composite_state,
        start_date: selectedEtagDataset.start_date,
        tag_id: selectedEtagDataset.tag_id,
        tag_primary_key: selectedEtagDataset.tag_primary_key,
      };

export const getETagDataSetForKey = (
  key: string | undefined,
  eTagDataSets: IETagDataSet[],
  timeZone: TTimeZone | undefined,
): IETagDataSet | undefined =>
  key === undefined || timeZone === undefined
    ? undefined
    : eTagDataSets.find(
        (eTagDataSet: IETagDataSet): boolean =>
          key === getKeyForETagDataSet(eTagDataSet, timeZone),
      );

export const manageAlert = (
  alert: IAlert,
  allowAlerts: boolean,
  onAlertRef: MutableRefObject<
    ((configuredAlert: IConfiguredAlert) => void) | undefined
  >,
  currentTheme: string,
  alertRule: IAlertRule | undefined,
  alertConfiguration?: IAlertConfiguration,
) => {
  if (
    allowAlerts &&
    (alertConfiguration === undefined ||
      alertConfiguration.subscribe_to_alerts) &&
    onAlertRef.current
  ) {
    const configuredAlert: IConfiguredAlert = {
      ...alert,
      visible_time:
        alertConfiguration === undefined
          ? DEFAULT_ON_SCREEN_TIME_IN_SECONDS
          : alertConfiguration.visible_time,
    };

    if (alertRule !== undefined && alertConfiguration !== undefined) {
      switch (alertConfiguration.alert_effect) {
        case EAlertEffect.All: {
          configuredAlert.color_effect = (alertRule.color_effect as IIndexable)[
            currentTheme
          ];
          configuredAlert.sound_effect = alertRule.sound_effect;
          configuredAlert.sound_play_repetitions =
            alertConfiguration.sound_play_repetitions;
          break;
        }
        case EAlertEffect.Message: {
          configuredAlert.color_effect = (alertRule.color_effect as IIndexable)[
            currentTheme
          ];
          break;
        }
        case EAlertEffect.Sound: {
          configuredAlert.sound_effect = alertRule.sound_effect;
          configuredAlert.sound_play_repetitions =
            alertConfiguration.sound_play_repetitions;
          break;
        }
        default:
          break;
      }

      configuredAlert.visible_time = alertConfiguration.visible_time;
    }

    onAlertRef.current(configuredAlert);
  }
};

const connectByRecordKey = (eTagDataSet: IETagDataSet[]) => {
  let index: number = 0;
  let rowIndex: number = -1;

  while (index < eTagDataSet.length) {
    const record = eTagDataSet[index];
    const recordKey = getRecordKeyForETagIdentifier(record);

    rowIndex += 1;
    record.rowIndex = rowIndex;

    index += 1;

    if (index === eTagDataSet.length) {
      break;
    }

    let nextRecord = eTagDataSet[index];
    while (recordKey === getRecordKeyForETagIdentifier(nextRecord)) {
      nextRecord.rowIndex = rowIndex;
      index += 1;

      if (index === eTagDataSet.length) {
        break;
      }

      nextRecord = eTagDataSet[index];
    }
  }
};

const filterReducer =
  (
    previous: TETagDataSetFilter,
    current: TETagDataSetFilter,
  ): TETagDataSetFilter =>
  (eTagDataSet: IETagDataSet): boolean =>
    previous(eTagDataSet) && current(eTagDataSet);

const filterReduction = (_: IETagDataSet): boolean => true;

export const applyFiltersAndSorters = (
  data: IETagDataSet[],
  filters: Record<string, TETagDataSetFilter>,
  sorters: TArraySorter<IETagDataSet>[],
): IETagDataSet[] => {
  const processedData: IETagDataSet[] = data.filter(
    Object.values(filters).reduce(filterReducer, filterReduction),
  );

  if (sorters.length > 0) {
    const sort: TETagDataArraySorter = sorters.reduceRight(
      (
        previousValue: TETagDataArraySorter,
        currentValue: TETagDataArraySorter,
      ) => andThen(currentValue)(previousValue),
    );

    processedData.sort(sort);
  }

  connectByRecordKey(processedData);

  return processedData;
};

export const alertConfigurationSorterFor =
  (alertRulesMap: TAlertRulesMap) =>
  (a: IAlertConfiguration, b: IAlertConfiguration): number => {
    const alertRuleA: IAlertRule | undefined = alertRulesMap[a.alert_rule_id];
    const alertRuleB: IAlertRule | undefined = alertRulesMap[b.alert_rule_id];

    if (alertRuleA === undefined && alertRuleB === undefined) {
      return 0;
    } else if (alertRuleA === undefined && alertRuleB !== undefined) {
      return -1;
    } else if (alertRuleA !== undefined && alertRuleB === undefined) {
      return 1;
    } else {
      return alertRuleA!.name.toLocaleLowerCase() <
        alertRuleB!.name.toLocaleLowerCase()
        ? -1
        : alertRuleA!.name.toLocaleLowerCase() >
          alertRuleB!.name.toLocaleLowerCase()
        ? 1
        : 0;
    }
  };

export const customFilterToCustomFilterOption = (
  customFilter: ICustomFilter,
): IOption<ICustomFilter> => ({
  label: customFilter.filter_name === null ? '' : customFilter.filter_name,
  value: customFilter,
});

export const updateDateTimeForTimeZone =
  (timeZone: TTimeZone) =>
  (dateTime: ZonedDateTime | null): ZonedDateTime | null =>
    dateTime === null ? null : getDateTimeForTimeZone(dateTime, timeZone);

export const createGridSorters = (
  sorters: Record<string, IColumnSort>,
): TAgGridSortState[] => {
  const agSorters: TAgGridSortState[] = [];
  Object.keys(sorters).forEach((key: string) => {
    const sorter: IColumnSort = sorters[key];
    const sortDirection: 'asc' | 'desc' | null | undefined =
      sorter.sortDirection === ESortDirection.Ascending
        ? 'asc'
        : sorter.sortDirection === ESortDirection.Descending
        ? 'desc'
        : undefined;

    if (sortDirection && sorter.sortOrder) {
      agSorters.push({
        colId: key,
        sort: sortDirection,
        sortIndex: sorter.sortOrder - 1,
      });
    }
  });
  return agSorters;
};

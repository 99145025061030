import { TEXT_DARK } from 'constants/styles';
import { ETheme } from 'enums/Style';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { toFormattedDateString } from 'utils/time';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';

interface ISeveritySpanProps {
  currentTheme?: string;
  severity: string;
}

const StyledSeveritySpan = styled.span<ISeveritySpanProps>`
  color: ${(props) => severityColor(props.severity, props.currentTheme)};
`;

interface IProps {
  body: string;
  currentTheme?: string;
  expirationTime: string;
  link: string;
  publishedDate: string;
  severity: string;
  timeZone: TTimeZone;
}

const severityColor = (severity: string, currentTheme?: string): string => {
  let color = currentTheme
    ? currentTheme === ETheme.Dark
      ? TEXT_DARK
      : 'black'
    : '';
  switch (severity) {
    case 'high':
      color = 'red';
      break;
    case 'medium':
      color = 'orange';
      break;
  }

  return color;
};

const NoticeBody = (props: IProps): JSX.Element => {
  const {
    body,
    currentTheme,
    expirationTime,
    link,
    publishedDate,
    severity,
    timeZone,
  } = props;

  return (
    <div>
      <SeparatedRowLayout>
        <span>Published: {toFormattedDateString(publishedDate, timeZone)}</span>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <span>Message: {body}</span>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <span>
          URL: <a href={link}>{link}</a>
        </span>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <span>Expires: {toFormattedDateString(expirationTime, timeZone)}</span>
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <div>
          <span>Severity: </span>
          <StyledSeveritySpan currentTheme={currentTheme} severity={severity}>
            {severity.toUpperCase()}
          </StyledSeveritySpan>
        </div>
      </SeparatedRowLayout>
    </div>
  );
};

export default NoticeBody;

import { IIndexable } from 'interfaces/General';
import { ISummaryStyles, ISummaryThemedStyles } from 'interfaces/Summary';

const summaryStylesDifferent = (
  a: ISummaryStyles & IIndexable,
  b: ISummaryStyles & IIndexable,
): boolean => {
  const aKeys: string[] = Object.keys(a);

  if (aKeys.length !== Object.keys(b).length) {
    return true;
  }

  for (let i: number = 0; i < aKeys.length; i += 1) {
    const key: string = aKeys[i];
    if (a[key] !== b[key]) {
      return true;
    }
  }

  return false;
};

export const summaryThemedStylesDifferent = (
  a: ISummaryThemedStyles,
  b: ISummaryThemedStyles,
): boolean =>
  summaryStylesDifferent(a.dark, b.dark) ||
  summaryStylesDifferent(a.light, b.light);

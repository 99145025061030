export enum EMenuActions {
  ShowConfigurationItemClicked = 'SHOW_CONFIGURATION_ITEM_CLICKED',
  ShowReportsItemClicked = 'SHOW_SEARCH_TAG_ITEM_CLICKED',
  ShowQueryEtagAuthorityItemClicked = 'SHOW_QUERY_ETAG_AUTHORITY_ITEM_CLICKED',
}

interface IShowConfigurationItemClicked {
  payload: any;
  type: EMenuActions.ShowConfigurationItemClicked;
}

interface IShowReportsItemClicked {
  payload: any;
  type: EMenuActions.ShowReportsItemClicked;
}

interface IShowQueryETagAuthorityItemClicked {
  payload: any;
  type: EMenuActions.ShowQueryEtagAuthorityItemClicked;
}

export type TMenuAction =
  | IShowConfigurationItemClicked
  | IShowReportsItemClicked
  | IShowQueryETagAuthorityItemClicked;

import { permissionsForEncodedPermissionsId } from 'hooks/usePermissions';
import { IPermissions } from 'interfaces/Permissions';
import { IToEntity } from 'interfaces/ToEntity';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';

export const TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID =
  'toEntitySummaryUiConfiguration';

export const saveButtonExecutePermissions = (
  toEntities: IToEntity[],
): ((toEntity: IToEntity) => boolean) => {
  const toEntitySaveButtonPermissionsRecord: Record<TToEntityId, boolean> = {};

  toEntities.forEach((userToEntity: IToEntity) => {
    const permissions: IPermissions = permissionsForEncodedPermissionsId(
      encodeIds(
        [TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID, 'save'],
        userToEntity.to_entity,
      ),
      [userToEntity],
    );
    toEntitySaveButtonPermissionsRecord[userToEntity.to_entity] =
      permissions.isExecutable;
  });

  return (toEntity: IToEntity): boolean => {
    return toEntitySaveButtonPermissionsRecord[toEntity.to_entity];
  };
};

import {
  CURRENT_REQUEST_KEY,
  ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
} from 'constants/Detail';
import { useCallback } from 'react';
import { HeaderRendererProps } from 'react-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { detailSetETagDetailSelectedRequestKey } from 'reduxes/Detail/actions';
import styled from 'styled-components';
import { TProfileDataGridRow, TProfileDataGridSummaryRow } from 'types/Detail';
import { TRootState } from 'types/Redux';

const Container = styled.div`
  cursor: pointer;
  padding: 3px 4px;
`;

const retrieveEnergyProfileSnapshotsRequestColumnHeaderState = (
  state: TRootState,
) => ({
  selectedRequestKey: state.detail.present.selectedRequestKey,
});

const EnergyProfileSnapshotsRequestColumnHeader = (
  props: HeaderRendererProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedRequestKey } = useSelector(
    retrieveEnergyProfileSnapshotsRequestColumnHeaderState,
  );
  const {
    column: { key, name },
  } = props;
  const requestKey: string = key.replace(
    ENERGY_PROFILE_SNAPSHOTS_KEY_PREFIX,
    '',
  );
  const isColumnSelected: boolean = requestKey === selectedRequestKey;

  const setSelectedRequestKey = useCallback(
    (selectedRequestKey: string) =>
      dispatch(detailSetETagDetailSelectedRequestKey(selectedRequestKey)),
    [dispatch],
  );

  const handleRequestHeaderClick = () => {
    if (setSelectedRequestKey) {
      setSelectedRequestKey(
        isColumnSelected ? CURRENT_REQUEST_KEY : requestKey,
      );
    }
  };

  return <Container onClick={handleRequestHeaderClick}>{name}</Container>;
};

export default EnergyProfileSnapshotsRequestColumnHeader;

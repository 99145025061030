export enum EDateTimeRangeDST {
  Longer = 'longer',
  Normal = 'normal',
  Shorter = 'shorter',
}

/**
 * When the backend computes datetimes embedded in other strings,
 */
export enum EDatetimeFormat {
  // e.g. 01/28/2021 15:30
  GeneralDateTime24Hr = 'GENERAL_DATETIME_24HR',
}

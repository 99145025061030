import ProfileDataGridColumnHeader from 'components/molecules/ProfileDataGridColumnHeader/ProfileDataGridColumnHeader';
import {
  CELL_DISABLED_CLASS,
  CELL_TOTALS_CLASS,
  EDITOR_OPTIONS,
  TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP,
} from 'components/organisms/ProfileInformationView/constants';
import {
  EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL,
  INITIAL_RECORD_ID,
  PROFILE_PATH_START_KEY,
  TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX,
  TRANSMISSION_LOSS_KEY_SUFFIX,
  TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY,
  TRANSMISSION_POD_KEY_SUFFIX,
  TRANSMISSION_POR_KEY_SUFFIX,
} from 'constants/Detail';
import { ID_KEY, LEFT_SEPARATOR_CLASS } from 'constants/General';
import { DATA_GRID_DATA_COLUMN_WIDTH_VALUE } from 'constants/styles';
import { EProfileDataGridCellType, ETableConfiguration } from 'enums/Detail';
import { ESeverity } from 'enums/General';
import {
  IDataGridSelectionContext,
  IValidationMessage,
} from 'interfaces/Component';
import { IProfileDataGridCell } from 'interfaces/Detail';
import {
  IETagPhysicalSegmentsProfile,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
} from 'interfaces/ETag';
import { Context } from 'react';
import {
  EditorProps,
  FormatterProps,
  SummaryFormatterProps,
} from 'react-data-grid';
import {
  TDetailValidations,
  TProfileDataGridColumn,
  TProfileDataGridRow,
  TProfileDataGridSummaryRow,
} from 'types/Detail';
import {
  getAdjustedContractNumber,
  getEditInfoKey,
  getKeyForProfileTransmission,
  getSplitEditInfoKey,
  getTransmissionName,
  stopDisabledDate,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const checkRowId = (row: TProfileDataGridRow) => {
  if (
    row.id === undefined ||
    row.id === null ||
    row.id.type !== EProfileDataGridCellType.String
  ) {
    throw new Error(`Invalid row id for row: ${row}`);
  }
};

const displayAdjustedContractNumber = (
  adjustedContractNumber: string,
  multipleContracts = false,
  contracts: (string | null)[] = [],
): string => {
  if (!multipleContracts) {
    return adjustedContractNumber.match(
      TRANSMISSION_CONTRACT_NUMBER_KEY_REG_EXP,
    ) === null
      ? adjustedContractNumber
      : '';
  } else {
    let joinedContracts = '';
    let blankSpaces = 0;
    contracts.forEach((contract, index) => {
      blankSpaces =
        contract && contract.length > 60
          ? 100
          : contract && contract.length > 20
          ? 80
          : 20;
      joinedContracts =
        index < contracts.length - 1
          ? `${
              joinedContracts +
              contract +
              '\xa0'.repeat(blankSpaces - (contract ? contract.length : 0))
            }`
          : `${joinedContracts + contract}`;
    });
    return joinedContracts;
  }
};

interface ISegment {
  segment: number | null;
  count: number;
  contracts: (string | null)[];
}

export const getTransmissionColumns = (
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  sortedTransmissionAllocations: IETagTransmissionAllocation[],
  selectedTableConfiguration: ETableConfiguration | undefined,
  isEditingUniqueProfiles: boolean,
  getCurrentFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getFormatter?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: FormatterProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  getEditor?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => (
    props: EditorProps<TProfileDataGridRow, TProfileDataGridSummaryRow>,
  ) => JSX.Element,
  summaryFormatter?: (
    props: SummaryFormatterProps<
      TProfileDataGridSummaryRow,
      TProfileDataGridRow
    >,
  ) => JSX.Element,
  groupColumnHeaders?: boolean,
): TProfileDataGridColumn[] => {
  const transmissionColumns: TProfileDataGridColumn[] = [];
  let previousPid: number | null = null;
  let nextPid: number | null | undefined = undefined;
  let transmissionName: string = '';
  let shouldIncludeSeparator: boolean = false;

  const getColSpan = (props: any, colSpan: number) => {
    return props.type === 'HEADER' ? colSpan : 1;
  };

  const uniquePhysicalSegments = sortedTransmissionAllocations
    .map((item) => item.physical_segment_ref)
    .filter((value, index, self) => self.indexOf(value) === index);

  const segmentArray: ISegment[] = [];
  uniquePhysicalSegments.forEach((segment) => {
    const count = sortedTransmissionAllocations.filter(
      (item) => item.physical_segment_ref === segment,
    ).length;
    const contracts = sortedTransmissionAllocations
      .filter((item) => item.physical_segment_ref === segment)
      .map((item) => item.contract_number);
    segmentArray.push({
      segment,
      count,
      contracts,
    });
  });

  let previousSegment: ISegment | undefined;

  sortedTransmissionAllocations.forEach(
    (
      eTagTransmissionAllocation: IETagTransmissionAllocation,
      index: number,
    ) => {
      let transmissionCode: string | undefined = '';
      const contractNumber = eTagTransmissionAllocation.contract_number;
      const physicalSegment = eTagTransmissionAllocation.physical_segment_ref;
      let transmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined;

      if (eTagTransmissionAllocation.contract_number) {
        transmissionPhysicalSegment = transmissionPhysicalSegments?.find(
          (segment) => segment.oasis_info?.includes(contractNumber!),
        );
      }

      if (
        !transmissionPhysicalSegment &&
        eTagTransmissionAllocation.physical_segment_ref
      ) {
        transmissionPhysicalSegment = transmissionPhysicalSegments?.find(
          (segment) =>
            segment.physical_segment_id ===
            eTagTransmissionAllocation.physical_segment_ref,
        );
      }
      transmissionCode = transmissionPhysicalSegment?.tp_code?.entity_code;

      const currentSegment = segmentArray.find(
        (segment) => segment.segment === physicalSegment,
      );
      const { physical_segment_ref, trans_alloc_id, trans_product_ref } =
        eTagTransmissionAllocation;
      const adjustedContractNumber: string = getAdjustedContractNumber(
        eTagTransmissionAllocation,
      );
      const physicalSegmentRefString: string =
        physical_segment_ref === null
          ? TRANSMISSION_PHYSICAL_SEGMENT_ID_SPECIAL_KEY
          : physical_segment_ref.toString();
      const nextTransmissionAllocation:
        | IETagTransmissionAllocation
        | undefined = sortedTransmissionAllocations[index + 1];
      const hasChangedPid: boolean = physical_segment_ref !== previousPid;

      nextPid =
        nextTransmissionAllocation === undefined
          ? undefined
          : nextTransmissionAllocation.physical_segment_ref;

      const productName: string =
        trans_product_ref === null ? '' : `\n${trans_product_ref.product_name}`;

      if (hasChangedPid) {
        previousPid = physical_segment_ref;
        transmissionName = getTransmissionName(
          eTagTransmissionAllocation,
          transmissionPhysicalSegments,
        );
        shouldIncludeSeparator = index > 0;

        const key: string = getKeyForProfileTransmission({
          adjustedContractNumber,
          physicalSegmentRef: physical_segment_ref,
          transAllocId: trans_alloc_id,
          transmissionName,
        });

        if (selectedTableConfiguration === ETableConfiguration.All) {
          transmissionColumns.push({
            cellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            getFormatter: getCurrentFormatter,
            headerCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_POR_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nPOR-Current for T${physicalSegmentRefString}`,
            resizable: true,
            summaryCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }

        shouldIncludeSeparator =
          index > 0 &&
          (selectedTableConfiguration === ETableConfiguration.POD ||
            isEditingUniqueProfiles);

        if (
          selectedTableConfiguration === ETableConfiguration.POD ||
          selectedTableConfiguration === ETableConfiguration.All ||
          isEditingUniqueProfiles
        ) {
          transmissionColumns.push({
            cellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            editorOptions: isEditingUniqueProfiles ? EDITOR_OPTIONS : undefined,
            getEditor:
              physical_segment_ref === null || !isEditingUniqueProfiles
                ? undefined
                : getEditor,
            getFormatter: getCurrentFormatter,
            headerCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_POD_KEY_SUFFIX}`,
            name: `${transmissionCode || ''}\n${transmissionName}\n${
              isEditingUniqueProfiles ? 'POD Profile' : 'POD-Current'
            } for T${physicalSegmentRefString}`,
            resizable: true,
            summaryCellClass: shouldIncludeSeparator
              ? LEFT_SEPARATOR_CLASS
              : undefined,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }

        if (
          selectedTableConfiguration === ETableConfiguration.All ||
          selectedTableConfiguration === ETableConfiguration.NewTagRequest
        ) {
          transmissionColumns.push({
            getFormatter,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_LOSS_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nLOSS-Current for T${physicalSegmentRefString}`,
            resizable: true,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }
      }

      shouldIncludeSeparator =
        index > 0 &&
        selectedTableConfiguration === ETableConfiguration.ProfileTotals &&
        hasChangedPid;

      const key: string = getKeyForProfileTransmission({
        adjustedContractNumber,
        physicalSegmentRef: physical_segment_ref,
        transAllocId: trans_alloc_id,
        transmissionName,
      });

      const shouldIncludeLeftAltSeparator =
        index > -1 && currentSegment !== previousSegment;
      if (previousSegment !== currentSegment) {
        previousSegment = currentSegment;
      }

      let cellClass: string | undefined = shouldIncludeLeftAltSeparator
        ? LEFT_SEPARATOR_CLASS
        : undefined;

      if (physical_segment_ref === null) {
        cellClass = `${CELL_DISABLED_CLASS} ${cellClass}`;
      }

      transmissionColumns.push({
        cellClass,
        colSpan:
          groupColumnHeaders && currentSegment
            ? (props: any) => getColSpan(props, currentSegment.count)
            : () => 1,
        editorOptions: EDITOR_OPTIONS,
        getEditor: physical_segment_ref === null ? undefined : getEditor,
        getFormatter,
        headerCellClass: shouldIncludeLeftAltSeparator
          ? LEFT_SEPARATOR_CLASS
          : undefined,
        headerRenderer: ProfileDataGridColumnHeader,
        key,
        name: `${transmissionCode || ''}\n\n${displayAdjustedContractNumber(
          adjustedContractNumber,
          currentSegment && currentSegment.count > 1,
          currentSegment ? currentSegment.contracts : [],
        )}${productName}`,
        resizable: true,
        summaryCellClass: shouldIncludeLeftAltSeparator
          ? LEFT_SEPARATOR_CLASS
          : undefined,
        summaryFormatter,
        width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
      });

      if (physical_segment_ref !== nextPid) {
        if (
          selectedTableConfiguration === ETableConfiguration.ProfileTotals ||
          selectedTableConfiguration === ETableConfiguration.All
        ) {
          transmissionColumns.push({
            cellClass: CELL_TOTALS_CLASS,
            getFormatter,
            headerRenderer: ProfileDataGridColumnHeader,
            key: `${key}:${TRANSMISSION_ALLOC_TOTALS_KEY_SUFFIX}`,
            name: `${
              transmissionCode || ''
            }\n${transmissionName}\nAlloc-Totals for T${physicalSegmentRefString}`,
            resizable: true,
            summaryFormatter,
            width: DATA_GRID_DATA_COLUMN_WIDTH_VALUE,
          });
        }
      }
    },
  );

  return transmissionColumns;
};

export const getInitialRowForId = (
  id: string,
  initialProfileInformationDataSet?: TProfileDataGridRow[],
): TProfileDataGridRow | undefined => {
  if (initialProfileInformationDataSet !== undefined) {
    return initialProfileInformationDataSet.find(
      (initialRow: TProfileDataGridRow): boolean => {
        const initialRowId: IProfileDataGridCell | null | undefined =
          initialRow[ID_KEY];

        if (initialRowId === undefined) {
          throw new Error(
            `Missing key: id for initialRow: ${JSON.stringify(initialRow)}`,
          );
        }

        return initialRowId !== null && id === initialRowId.value;
      },
    );
  }

  return undefined;
};

export const stopDisabledDateForRow =
  (row: TProfileDataGridRow) =>
  (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean => {
    if (dateTime !== null) {
      const startCell: IProfileDataGridCell | null | undefined =
        row[PROFILE_PATH_START_KEY];

      if (startCell === undefined) {
        throw new Error(
          `Missing key: ${PROFILE_PATH_START_KEY} for row: ${JSON.stringify(
            row,
          )}`,
        );
      }

      if (startCell !== null) {
        if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
          throw new Error('Invalid start cell type');
        }

        if (startCell !== null) {
          if (startCell.type !== EProfileDataGridCellType.DateTimeString) {
            throw new Error('Invalid start cell type');
          }

          return stopDisabledDate(
            startCell.value as string,
            dateTime,
            isSelected,
          );
        }
      }
    }

    return false;
  };

export const isInitialProfileDataGridRow = (
  row: TProfileDataGridRow,
): boolean => {
  const rowId: IProfileDataGridCell | null | undefined = row[ID_KEY];

  if (rowId === undefined) {
    throw new Error(`Missing key: id for row: ${JSON.stringify(row)}`);
  }

  if (rowId === null || rowId.type !== EProfileDataGridCellType.String) {
    throw new Error(`Invalid rowId for row: ${JSON.stringify(row)}`);
  }

  const { primaryId } = getSplitEditInfoKey(rowId.value as string);

  return primaryId === INITIAL_RECORD_ID;
};

export const isInitialProfileDataGrid = (
  rows: TProfileDataGridRow[],
): boolean =>
  rows.length === 1 ? isInitialProfileDataGridRow(rows[0]) : false;

export const validateProfileInformation = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
) => {
  const detailValidations: TDetailValidations = {};
  const validationMessages: IValidationMessage[] = [];

  if (physicalSegmentsProfiles !== null) {
    let isPhysicalSegmentsProfilesValid: boolean = true;

    physicalSegmentsProfiles.forEach(
      (
        eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile,
        index: number,
      ) => {
        if (isEmptyValue(eTagPhysicalSegmentsProfile.start)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Start for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }

        if (isEmptyValue(eTagPhysicalSegmentsProfile.stop)) {
          isPhysicalSegmentsProfilesValid = false;

          validationMessages.push({
            message: `Missing Stop for row ${index + 1}`,
            severity: ESeverity.Error,
          });
        }
      },
    );

    detailValidations[
      getEditInfoKey(EDIT_PHYSICAL_SEGMENTS_PROFILES_LABEL, 0, 0)
    ] = isPhysicalSegmentsProfilesValid;
  }

  return {
    detailValidations,
    validationMessages,
  };
};

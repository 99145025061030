import { AxiosResponse } from 'axios';
import { EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL } from 'constants/Detail';
import {
  ICON_BUTTON_SIZE_VALUE,
  VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_PERCENTAGES_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { EPointTypeName } from 'enums/Point';
import { EViewMode } from 'enums/View';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEditPhysicalPathInformation } from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import { IETagTransmissionPhysicalSegment } from 'interfaces/ETag';
import { IMiscInfo } from 'interfaces/General';
import { IPointInfo, IRegistryPoint } from 'interfaces/Point';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { retrieveRegistryPoints } from 'services/naesb-registry/registry';
import { TToEntityId } from 'types/ToEntity';
import { getEditInfoKey, getSplitEditInfoKey } from 'utils/detail';
import {
  entityInfoToString,
  entityInfoToUid,
  moEntityInfoEqual,
  pseEntityInfoEqual,
  tpEntityInfoEqual,
} from 'utils/entity';
import { captureError } from 'utils/error';
import {
  eventToStringOrNull,
  isEmptyValue,
  isSuccessStatus,
  listEqual,
  miscInfosEqual,
  selectOptionLabelFilter,
  simpleEqualityChecker,
} from 'utils/general';
import {
  pointInfoEqual,
  pointInfoToString,
  pointInfoToUid,
  registryPointToPointInfoOption,
} from 'utils/point';
import {
  getColumnEntityInfoRender,
  getColumnLossesEditRender,
  getColumnMiscInfoEditRender,
  getColumnOasisInfoEditRender,
  getColumnRender,
  getColumnSelectRender,
  getLossesPercentageRender,
  midSelectRender,
} from 'utils/views';
import LossMethodsEdit from './LossMethodsEdit/LossMethodsEdit';
import { ECompositeState } from '../../enums/ETag';

const getTransmissionMiscInfoEditId = (
  record: IETagTransmissionPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const getTransmissionMiscInfoInitialEditKey = (
  record: IETagTransmissionPhysicalSegment,
): string =>
  getEditInfoKey(
    EDIT_TRANSMISSION_PHYSICAL_SEGMENT_MISC_INFO_LABEL,
    record.physical_segment_id,
    0,
  );

const schedulingEntitiesListEqual = listEqual(pseEntityInfoEqual);

const transmissionMidSelectRender =
  midSelectRender<IETagTransmissionPhysicalSegment>();

const transmissionTpSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IETagTransmissionPhysicalSegment
>('100%');

const transmissionMoSelectRender = getColumnSelectRender<
  IEntityInfo | null,
  IETagTransmissionPhysicalSegment
>('100%');

const porSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IETagTransmissionPhysicalSegment
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const podSelectRender = getColumnSelectRender<
  IPointInfo | null,
  IETagTransmissionPhysicalSegment
>(
  '100%',
  undefined,
  (value: IPointInfo | (IPointInfo | null)[] | null | undefined) =>
    pointInfoToString(value as IPointInfo),
);

const schedulingEntitiesSelectRender = getColumnSelectRender<
  IEntityInfo,
  IETagTransmissionPhysicalSegment
>('100%', undefined, (value: IEntityInfo[] | IEntityInfo | undefined): string =>
  isEmptyValue(value)
    ? ''
    : (value as IEntityInfo[])
        .map((entityInfo: IEntityInfo): string => `${entityInfo.entity_code}`)
        .join(', '),
);

export const getColumnTransmissionPhysicalSegmentSchedulingEntitiesRender = (
  isUnconstrained: boolean,
) =>
  getColumnRender(isUnconstrained, (value: IEntityInfo[]): string =>
    value.map(entityInfoToString).join(', '),
  );

export const useTransmissionEditColumns = (
  baOptions: IOption<IEntityInfo>[],
  composite_state: ECompositeState | null,
  focusKey: string | null,
  initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | undefined,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isUnconstrained: boolean,
  midOptions: IOption<number>[],
  moOptions: IOption<IEntityInfo>[],
  onChange: (editPhysicalPathInformation: IEditPhysicalPathInformation) => void,
  previousBaOptions: IOption<IEntityInfo>[] | undefined,
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  previousMidOptions: IOption<number>[] | undefined,
  previousMoOptions: IOption<IEntityInfo>[] | undefined,
  previousTpOptions: IOption<IEntityInfo>[] | undefined,
  toEntityId: TToEntityId,
  tpOptions: IOption<IEntityInfo>[],
  viewMode: EViewMode,
  isDisabled?: boolean,
  showLosses?: boolean,
) => {
  const hasDetailChanged: boolean =
    isDetailDeleted !== previousIsDetailDeleted ||
    isDetailUpdating !== previousIsDetailUpdating;
  const isDetailDisabled: boolean = isDetailDeleted || isDetailUpdating;

  const getInitialTransmissionForPhysicalSegmentId = useCallback(
    (physical_segment_id: number) => {
      if (initialTransmissionPhysicalSegments !== undefined) {
        return initialTransmissionPhysicalSegments.find(
          (
            transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
          ): boolean =>
            transmissionPhysicalSegment.physical_segment_id ===
            physical_segment_id,
        );
      }

      return undefined;
    },
    [initialTransmissionPhysicalSegments],
  );

  const getInitialTransmissionForEditInfoKey = useCallback(
    (editInfoKey: string): IETagTransmissionPhysicalSegment | undefined => {
      if (initialTransmissionPhysicalSegments !== undefined) {
        const { primaryId: physical_segment_id } =
          getSplitEditInfoKey(editInfoKey);

        return initialTransmissionPhysicalSegments.find(
          (
            transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
          ): boolean =>
            transmissionPhysicalSegment.physical_segment_id ===
            physical_segment_id,
        );
      }

      return undefined;
    },
    [initialTransmissionPhysicalSegments],
  );

  const getMidOptions = useCallback(async () => midOptions, [midOptions]);

  const getInitialTransmissionMidSelectValue = useCallback(
    (record: IETagTransmissionPhysicalSegment): number | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.market_segment_id;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionMidOnChange = useCallback(
    (
      value: number | null | undefined,
      record: IETagTransmissionPhysicalSegment,
    ) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              market_segment_id: value === undefined ? null : value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getTpOptions = useCallback(async () => tpOptions, [tpOptions]);

  const getInitialTransmissionTpSelectValue = useCallback(
    (record: IETagTransmissionPhysicalSegment): IEntityInfo | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.tp_code;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionTpOnChange = useCallback(
    (
      value: IEntityInfo | null | undefined,
      record: IETagTransmissionPhysicalSegment,
    ) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              tp_code: value === undefined ? null : value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getMoOptions = useCallback(async () => moOptions, [moOptions]);

  const getInitialTransmissionMoSelectValue = useCallback(
    (record: IETagTransmissionPhysicalSegment): IEntityInfo | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.mo_code;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionMoOnChange = useCallback(
    (
      value: IEntityInfo | null | undefined,
      record: IETagTransmissionPhysicalSegment,
    ) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              mo_code: value === undefined ? null : value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const shouldGetPodPorOptions = useCallback(
    (
      record: IETagTransmissionPhysicalSegment,
      previousRecord: IETagTransmissionPhysicalSegment | undefined,
    ): boolean =>
      previousRecord === undefined ||
      !tpEntityInfoEqual(record.tp_code, previousRecord.tp_code),
    [],
  );

  const getPorOptions = useCallback(
    async (record: IETagTransmissionPhysicalSegment) => {
      if (record.tp_code !== null) {
        try {
          const response: AxiosResponse<IRegistryPoint[]> =
            await retrieveRegistryPoints(
              toEntityId,
              EPointTypeName.POR,
              undefined,
              undefined,
              record.tp_code.tagging_entity_id,
            );

          if (!isSuccessStatus(response.status)) {
            throw new Error(response.statusText);
          }

          const registryPoints: IRegistryPoint[] = response.data;

          return registryPoints.map(registryPointToPointInfoOption);
        } catch (error: any) {
          captureError(error);
        }
      }

      return [];
    },
    [toEntityId],
  );

  const previousGetPorOptions = usePrevious(getPorOptions);

  const getInitialTransmissionPorSelectValue = useCallback(
    (record: IETagTransmissionPhysicalSegment): IPointInfo | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.por;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionPorOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IETagTransmissionPhysicalSegment,
    ) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              por: value === undefined ? null : value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getPodOptions = useCallback(
    async (record: IETagTransmissionPhysicalSegment) => {
      if (record.tp_code !== null) {
        try {
          const response: AxiosResponse<IRegistryPoint[]> =
            await retrieveRegistryPoints(
              toEntityId,
              EPointTypeName.POD,
              undefined,
              undefined,
              record.tp_code.tagging_entity_id,
            );

          if (!isSuccessStatus(response.status)) {
            throw new Error(response.statusText);
          }

          const registryPoints: IRegistryPoint[] = response.data;

          return registryPoints.map(registryPointToPointInfoOption);
        } catch (error: any) {
          captureError(error);
        }
      }

      return [];
    },
    [toEntityId],
  );

  const previousGetPodOptions = usePrevious(getPodOptions);

  const getInitialTransmissionPodSelectValue = useCallback(
    (record: IETagTransmissionPhysicalSegment): IPointInfo | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.pod;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionPodOnChange = useCallback(
    (
      value: IPointInfo | null | undefined,
      record: IETagTransmissionPhysicalSegment,
    ) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              pod: value === undefined ? null : value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const getBaOptions = useCallback(async () => baOptions, [baOptions]);

  const getInitialTransmissionSchedulingEntitiesSelectValues = useCallback(
    (record: IETagTransmissionPhysicalSegment): IEntityInfo[] | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.scheduling_entities;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionSchedulingEntitiesOnChangeMultiple = useCallback(
    (value: IEntityInfo[], record: IETagTransmissionPhysicalSegment) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              scheduling_entities: value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const miscInfoEditRender =
    getColumnMiscInfoEditRender<IETagTransmissionPhysicalSegment>(
      isDisabled,
      hasDetailChanged,
    );

  const lossesPercentageRender = getLossesPercentageRender();

  const getInitialTransmissionMiscInfoData = useCallback(
    (record: IETagTransmissionPhysicalSegment): IMiscInfo[] | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForPhysicalSegmentId(
        record.physical_segment_id,
      );

      return initialTransmissionPhysicalSegment === undefined
        ? null
        : initialTransmissionPhysicalSegment.misc_infos;
    },
    [getInitialTransmissionForPhysicalSegmentId],
  );

  const transmissionMiscInfoOnAdd = useCallback(
    (value: unknown, miscInfoRecord: IMiscInfo) => {
      const { primaryId: physical_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [physical_segment_id]: {
              editMiscInfos: {
                addAfterMiscInfo: miscInfoRecord.key,
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const transmissionMiscInfoOnRemove = useCallback(
    (miscInfoRecord: IMiscInfo) => {
      const { primaryId: physical_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [physical_segment_id]: {
              editMiscInfos: {
                removeMiscInfo: miscInfoRecord.key,
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialTransmissionTokenInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForEditInfoKey(miscInfoRecord.key);

      if (
        initialTransmissionPhysicalSegment === undefined ||
        initialTransmissionPhysicalSegment.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialTransmissionPhysicalSegment.misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.token;
    },
    [getInitialTransmissionForEditInfoKey],
  );

  const transmissionTokenInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      const { primaryId: physical_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [physical_segment_id]: {
              editMiscInfos: {
                editMiscInfoMap: {
                  [miscInfoRecord.key]: { token: eventToStringOrNull(event) },
                },
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialTransmissionValueInputValue = useCallback(
    (miscInfoRecord: IMiscInfo): string | null => {
      const initialTransmissionPhysicalSegment:
        | IETagTransmissionPhysicalSegment
        | undefined = getInitialTransmissionForEditInfoKey(miscInfoRecord.key);

      if (
        initialTransmissionPhysicalSegment === undefined ||
        initialTransmissionPhysicalSegment.misc_infos === null
      ) {
        return null;
      }

      const initialMiscInfo: IMiscInfo | undefined =
        initialTransmissionPhysicalSegment.misc_infos.find(
          (miscInfo: IMiscInfo): boolean => miscInfo.key === miscInfoRecord.key,
        );

      return initialMiscInfo === undefined ? null : initialMiscInfo.value;
    },
    [getInitialTransmissionForEditInfoKey],
  );

  const transmissionValueInputOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, miscInfoRecord: IMiscInfo) => {
      const { primaryId: physical_segment_id } = getSplitEditInfoKey(
        miscInfoRecord.key,
      );

      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [physical_segment_id]: {
              editMiscInfos: {
                editMiscInfoMap: {
                  [miscInfoRecord.key]: { value: eventToStringOrNull(event) },
                },
              },
            },
          },
        },
      });
    },
    [onChange],
  );

  const getInitialTransmissionOasisInfoData = useCallback(
    (record: IETagTransmissionPhysicalSegment): number | null =>
      record.physical_segment_id,
    [],
  );

  const getInitialLossesData = useCallback(
    (record: IETagTransmissionPhysicalSegment): any | null => {
      return {
        lossMethods: record.loss_methods,
        physicalSegmentId: record.physical_segment_id,
      };
    },
    [],
  );

  const handleLossesPercentageInputChange = useCallback(
    (value: string, record: any) => {
      onChange({
        editTransmissionPhysicalSegments: {
          transmissionPhysicalSegmentMap: {
            [record.physical_segment_id]: {
              physical_segment_loss_percentage: value,
            },
          },
        },
      });
    },
    [onChange],
  );

  const oasisInfoEditRender =
    getColumnOasisInfoEditRender<IETagTransmissionPhysicalSegment>(isDisabled);

  const lossesEditRender =
    getColumnLossesEditRender<IETagTransmissionPhysicalSegment>(isDisabled);

  return useMemo(() => {
    const baOptionsChanged: boolean = baOptions !== previousBaOptions;
    const midOptionsChanged: boolean = midOptions !== previousMidOptions;
    const moOptionsChanged: boolean = moOptions !== previousMoOptions;
    const podOptionsChanged: boolean = getPodOptions !== previousGetPodOptions;
    const porOptionsChanged: boolean = getPorOptions !== previousGetPorOptions;
    const tpOptionsChanged: boolean = tpOptions !== previousTpOptions;

    const columns = [
      {
        dataIndex: 'physical_segment_id',
        render: getColumnRender(isUnconstrained),
        title: 'PID',
        width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
      },
      {
        dataIndex: 'market_segment_id',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnRender(isUnconstrained)
            : transmissionMidSelectRender({
                allowClear: true,
                equalityChecker: simpleEqualityChecker,
                getInitialValue: getInitialTransmissionMidSelectValue,
                getOptions: getMidOptions,
                isDisabled: isDetailDisabled,
                onChange: transmissionMidOnChange,
                valueToUid: (value: number | null | undefined): string =>
                  isEmptyValue(value) ? '' : value!.toString(),
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IETagTransmissionPhysicalSegment,
                previousRecord: IETagTransmissionPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                midOptionsChanged ||
                record.market_segment_id !== previousRecord.market_segment_id,
        title: 'MID',
        width: VIEW_DATA_TABLE_COLUMN_MID_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'tp_code',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnEntityInfoRender(isUnconstrained)
            : transmissionTpSelectRender({
                allowClear: true,
                equalityChecker: tpEntityInfoEqual,
                filter: selectOptionLabelFilter,
                getInitialValue: getInitialTransmissionTpSelectValue,
                getOptions: getTpOptions,
                isDisabled: isDetailDisabled,
                onChange: transmissionTpOnChange,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IETagTransmissionPhysicalSegment,
                previousRecord: IETagTransmissionPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                tpOptionsChanged ||
                (record.tp_code === null
                  ? previousRecord.tp_code !== null
                  : previousRecord.tp_code === null
                  ? true
                  : !(
                      record.tp_code.tagging_entity_id ===
                        previousRecord.tp_code.tagging_entity_id &&
                      record.tp_code.entity_type ===
                        previousRecord.tp_code.entity_type
                    )),
        title: 'TP',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'mo_code',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnEntityInfoRender(isUnconstrained)
            : transmissionMoSelectRender({
                allowClear: true,
                equalityChecker: moEntityInfoEqual,
                filter: selectOptionLabelFilter,
                getInitialValue: getInitialTransmissionMoSelectValue,
                getOptions: getMoOptions,
                isDisabled: isDetailDisabled,
                onChange: transmissionMoOnChange,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IETagTransmissionPhysicalSegment,
                previousRecord: IETagTransmissionPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                moOptionsChanged ||
                (record.mo_code === null
                  ? previousRecord.mo_code !== null
                  : previousRecord.mo_code === null
                  ? true
                  : !(
                      record.mo_code.tagging_entity_id ===
                        previousRecord.mo_code.tagging_entity_id &&
                      record.mo_code.entity_type ===
                        previousRecord.mo_code.entity_type
                    )),
        title: 'MO',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'pse',
        render: getColumnRender(isUnconstrained, entityInfoToString),
        title: 'PSE',
        width: VIEW_DATA_TABLE_COLUMN_ENTITY_COLUMN_WIDTH,
      },
      {
        dataIndex: 'por',
        render: porSelectRender({
          allowClear: true,
          filter: selectOptionLabelFilter,
          equalityChecker: pointInfoEqual,
          equalityCheckerForOptions: pointInfoEqual,
          getInitialValue: getInitialTransmissionPorSelectValue,
          getOptions: getPorOptions,
          isDisabled: isDetailDisabled,
          onChange: transmissionPorOnChange,
          showSearch: true,
          shouldGetOptions: shouldGetPodPorOptions,
          valueToUid: pointInfoToUid,
        }),
        shouldCellUpdate: (
          record: IETagTransmissionPhysicalSegment,
          previousRecord: IETagTransmissionPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          porOptionsChanged ||
          (record.tp_code === null
            ? previousRecord.tp_code !== null
            : previousRecord.tp_code === null
            ? true
            : !tpEntityInfoEqual(record.tp_code, previousRecord.tp_code)) ||
          (record.por === null
            ? previousRecord.por !== null
            : previousRecord.por === null
            ? true
            : record.por.point_id !== previousRecord.por.point_id),
        title: 'POR',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'pod',
        render: podSelectRender({
          allowClear: true,
          filter: selectOptionLabelFilter,
          equalityChecker: pointInfoEqual,
          equalityCheckerForOptions: pointInfoEqual,
          getInitialValue: getInitialTransmissionPodSelectValue,
          getOptions: getPodOptions,
          isDisabled: isDetailDisabled,
          onChange: transmissionPodOnChange,
          showSearch: true,
          shouldGetOptions: shouldGetPodPorOptions,
          valueToUid: pointInfoToUid,
        }),
        shouldCellUpdate: (
          record: IETagTransmissionPhysicalSegment,
          previousRecord: IETagTransmissionPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          podOptionsChanged ||
          (record.tp_code === null
            ? previousRecord.tp_code !== null
            : previousRecord.tp_code === null
            ? true
            : !tpEntityInfoEqual(record.tp_code, previousRecord.tp_code)) ||
          (record.pod === null
            ? previousRecord.pod !== null
            : previousRecord.pod === null
            ? true
            : record.pod.point_id !== previousRecord.pod.point_id),
        title: 'POD',
        width: VIEW_DATA_TABLE_COLUMN_POINT_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'scheduling_entities',
        render:
          viewMode === EViewMode.EditETagCorrection
            ? getColumnTransmissionPhysicalSegmentSchedulingEntitiesRender(
                isUnconstrained,
              )
            : schedulingEntitiesSelectRender({
                allowClear: true,
                allowMultiple: true,
                equalityCheckerMultiple: schedulingEntitiesListEqual,
                filter: selectOptionLabelFilter,
                getInitialValues:
                  getInitialTransmissionSchedulingEntitiesSelectValues,
                getOptions: getBaOptions,
                isDisabled: isDetailDisabled,
                onChangeMultiple:
                  transmissionSchedulingEntitiesOnChangeMultiple,
                showSearch: true,
                valueToUid: entityInfoToUid,
              }),
        shouldCellUpdate:
          viewMode === EViewMode.EditETagCorrection
            ? undefined
            : (
                record: IETagTransmissionPhysicalSegment,
                previousRecord: IETagTransmissionPhysicalSegment,
              ): boolean =>
                hasDetailChanged ||
                baOptionsChanged ||
                !schedulingEntitiesListEqual(
                  record.scheduling_entities,
                  previousRecord.scheduling_entities,
                ),
        title: 'SE(s)',
        width: VIEW_DATA_TABLE_COLUMN_SE_SELECT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'oasis_info',
        render: oasisInfoEditRender({
          getInitialData: getInitialTransmissionOasisInfoData,
        }),
        title: 'OASIS Info',
        width: VIEW_DATA_TABLE_COLUMN_OASIS_INFO_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'losses',
        render: lossesEditRender({
          getInitialData: getInitialLossesData,
        }),
        title: <LossMethodsEdit isDisabled={isDisabled} />,
        width: VIEW_DATA_TABLE_COLUMN_LOSS_METHODS_EDIT_COLUMN_WIDTH,
      },
      {
        dataIndex: 'misc_infos',
        render: miscInfoEditRender({
          editButtonWidth:
            viewMode === EViewMode.EditETagCorrection
              ? undefined
              : `${
                  VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH_VALUE -
                  ICON_BUTTON_SIZE_VALUE
                }px`,
          equalityChecker: miscInfosEqual,
          getId: getTransmissionMiscInfoEditId,
          getInitialData: getInitialTransmissionMiscInfoData,
          getInitialEditKey: getTransmissionMiscInfoInitialEditKey,
          getTableTitle: (record: IETagTransmissionPhysicalSegment): string =>
            `Edit Transmission Physical Segment Misc Info`,
          onAdd: transmissionMiscInfoOnAdd,
          onRemove: transmissionMiscInfoOnRemove,
          tokenInputColumnConfig: {
            getInitialValue: getInitialTransmissionTokenInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: transmissionTokenInputOnChange,
            shouldFocusOn: (miscInfoRecord: IMiscInfo): boolean =>
              miscInfoRecord.key === focusKey,
          },
          valueInputColumnConfig: {
            getInitialValue: getInitialTransmissionValueInputValue,
            getKey: (miscInfoRecord: IMiscInfo): string => miscInfoRecord.key,
            isDisabled: isDetailDisabled,
            onChange: transmissionValueInputOnChange,
          },
        }),
        shouldCellUpdate: (
          record: IETagTransmissionPhysicalSegment,
          previousRecord: IETagTransmissionPhysicalSegment,
        ): boolean =>
          hasDetailChanged ||
          (record.misc_infos === null
            ? previousRecord.misc_infos !== null
            : previousRecord.misc_infos === null
            ? true
            : !miscInfosEqual(record.misc_infos, previousRecord.misc_infos)),
        title: 'Misc Info',
        width: VIEW_DATA_TABLE_COLUMN_MISC_INFO_WIDTH,
      },
    ];

    if (showLosses && composite_state === ECompositeState.Draft) {
      const showLossesColumn = {
        dataIndex: 'physical_segment_loss_percentage',
        render: lossesPercentageRender({
          onChange: handleLossesPercentageInputChange,
        }),
        title: 'Losses %',
        width: VIEW_DATA_TABLE_COLUMN_LOSS_PERCENTAGES_WIDTH,
      };
      const lossesColumnIndex = columns.findIndex(
        (column) => column.dataIndex === 'losses',
      );
      columns.splice(lossesColumnIndex + 1, 0, showLossesColumn);
    }

    return columns;
  }, [
    baOptions,
    previousBaOptions,
    midOptions,
    previousMidOptions,
    moOptions,
    previousMoOptions,
    getPodOptions,
    previousGetPodOptions,
    getPorOptions,
    previousGetPorOptions,
    tpOptions,
    previousTpOptions,
    isUnconstrained,
    viewMode,
    getInitialTransmissionMidSelectValue,
    getMidOptions,
    isDetailDisabled,
    transmissionMidOnChange,
    getInitialTransmissionTpSelectValue,
    getTpOptions,
    transmissionTpOnChange,
    getInitialTransmissionMoSelectValue,
    getMoOptions,
    transmissionMoOnChange,
    getInitialTransmissionPorSelectValue,
    transmissionPorOnChange,
    shouldGetPodPorOptions,
    getInitialTransmissionPodSelectValue,
    transmissionPodOnChange,
    getInitialTransmissionSchedulingEntitiesSelectValues,
    getBaOptions,
    transmissionSchedulingEntitiesOnChangeMultiple,
    oasisInfoEditRender,
    getInitialTransmissionOasisInfoData,
    // isDisabled,
    miscInfoEditRender,
    getInitialTransmissionMiscInfoData,
    transmissionMiscInfoOnAdd,
    transmissionMiscInfoOnRemove,
    getInitialTransmissionTokenInputValue,
    transmissionTokenInputOnChange,
    getInitialTransmissionValueInputValue,
    transmissionValueInputOnChange,
    hasDetailChanged,
    focusKey,
    getInitialLossesData,
    lossesEditRender,
    isDisabled,
    lossesPercentageRender,
    handleLossesPercentageInputChange,
    showLosses,
    composite_state,
  ]);
};

export default useTransmissionEditColumns;

import { TAG_CODE_REGEX } from 'constants/ETag';
import { ICON_BUTTON_SIZE, STANDARD_SPACING } from 'constants/styles';
import { ERegistryType } from 'enums/General';
import usePrevious from 'hooks/usePrevious';
import { IOption } from 'interfaces/Component';
import { IEntityInfo, IRegistryEntity } from 'interfaces/Entity';
import { IETagTagId } from 'interfaces/ETag';
import { IToEntity } from 'interfaces/ToEntity';
import { ChangeEvent, useCallback, useMemo } from 'react';
import {
  caEntityInfoEqual,
  isWritablePseEntity,
  pseEntityInfoEqual,
  registryEntityToEntityInfoOption,
  toEntityToEntityInfo,
  typeIgnoredEntityInfoToUid,
} from 'utils/entity';
import { isEmptyValue, selectOptionLabelFilter } from 'utils/general';
import { getColumnInputRender, getColumnSelectRender } from 'utils/views';

const entityToOption = registryEntityToEntityInfoOption(false, false);

const useETagTagIdsEditColumns = (
  hasRemoveHandler: boolean,
  initialETagTagIds: IETagTagId[] | undefined,
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  onCpseChange: (
    value: IEntityInfo | null | undefined,
    record: IETagTagId,
  ) => void,
  onGcaChange: (
    value: IEntityInfo | null | undefined,
    record: IETagTagId,
  ) => void,
  onLcaChange: (
    value: IEntityInfo | null | undefined,
    record: IETagTagId,
  ) => void,
  registryEntities: IRegistryEntity[],
  onTagCodeChange?: (tagCode: string, record: IETagTagId) => void,
  toEntities?: IToEntity[],
) => {
  const gcaSelectRender = getColumnSelectRender<IEntityInfo | null, IETagTagId>(
    '100%',
  );
  const pseSelectRender = getColumnSelectRender<IEntityInfo | null, IETagTagId>(
    '100%',
  );
  const tagCodeInputRender = getColumnInputRender<IETagTagId>('100%');
  const lcaSelectRenderWithRemoveHandler = getColumnSelectRender<
    IEntityInfo | null,
    IETagTagId
  >(`calc(100% + ${STANDARD_SPACING} - ${ICON_BUTTON_SIZE})`);
  const lcaSelectRender = getColumnSelectRender<IEntityInfo | null, IETagTagId>(
    '100%',
  );

  const previousIsDetailDeleted: boolean | undefined =
    usePrevious(isDetailDeleted);
  const previousIsDetailUpdating: boolean | undefined =
    usePrevious(isDetailUpdating);

  const getInitialCpseSelectValue = useCallback(
    (record: IETagTagId): IEntityInfo | null => {
      if (initialETagTagIds === null) {
        return null;
      }

      const initialETagTagId: IETagTagId | undefined = initialETagTagIds?.find(
        (eTagTagId: IETagTagId): boolean => eTagTagId.key === record.key,
      );

      return initialETagTagId === undefined ? null : initialETagTagId.pse;
    },
    [initialETagTagIds],
  );

  const getInitialGcaSelectValue = useCallback(
    (record: IETagTagId): IEntityInfo | null => {
      if (initialETagTagIds === null) {
        return null;
      }

      const initialETagTagId: IETagTagId | undefined = initialETagTagIds?.find(
        (eTagTagId: IETagTagId): boolean => eTagTagId.key === record.key,
      );

      return initialETagTagId === undefined ? null : initialETagTagId.gca;
    },
    [initialETagTagIds],
  );

  const getInitialLcaSelectValue = useCallback(
    (record: IETagTagId): IEntityInfo | null => {
      if (initialETagTagIds === null) {
        return null;
      }

      const initialETagTagId: IETagTagId | undefined = initialETagTagIds?.find(
        (eTagTagId: IETagTagId): boolean => eTagTagId.key === record.key,
      );

      return initialETagTagId === undefined ? null : initialETagTagId.lca;
    },
    [initialETagTagIds],
  );

  const adjustedLcaSelectRender = useMemo(
    () =>
      hasRemoveHandler ? lcaSelectRenderWithRemoveHandler : lcaSelectRender,
    [hasRemoveHandler, lcaSelectRender, lcaSelectRenderWithRemoveHandler],
  );

  const getInitialTagCodeInputValue = useCallback(
    (record: IETagTagId): string | null => {
      if (initialETagTagIds === null) {
        return null;
      }

      const initialETagTagId: IETagTagId | undefined = initialETagTagIds?.find(
        (eTagTagId: IETagTagId): boolean => eTagTagId.key === record.key,
      );

      return initialETagTagId === undefined ? null : initialETagTagId.tag_code;
    },
    [initialETagTagIds],
  );

  const handleTagCodeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, record: IETagTagId) => {
      if (onTagCodeChange === undefined) {
        throw new Error('Missing onTagCodeChange');
      }

      // Only empty string, letters and numbers are allowed!
      if (
        isEmptyValue(event.target.value) ||
        TAG_CODE_REGEX.test(event.target.value)
      ) {
        onTagCodeChange(event.target.value.toLocaleUpperCase(), record);
      }
    },
    [onTagCodeChange],
  );

  const getCpseOptions = useCallback(async () => {
    const cpseOptions: IOption<IEntityInfo>[] = [];

    if (toEntities === undefined) {
      registryEntities.forEach((registryEntity: IRegistryEntity) => {
        if (registryEntity['Registry Type'] === ERegistryType.PSE) {
          cpseOptions.push(entityToOption(registryEntity));
        }
      });
    } else {
      toEntities.forEach((toEntity: IToEntity) => {
        if (isWritablePseEntity(toEntity)) {
          cpseOptions.push({
            label: toEntity.entity_code,
            value: toEntityToEntityInfo(toEntity),
          });
        }
      });
    }

    return cpseOptions;
  }, [registryEntities, toEntities]);

  const previousGetCpseOptions = usePrevious(getCpseOptions);

  const getBaOptions = useCallback(
    async () =>
      registryEntities
        .filter(
          (registryEntity: IRegistryEntity): boolean =>
            registryEntity['Registry Type'] === ERegistryType.BA,
        )
        .map(entityToOption),
    [registryEntities],
  );

  const previousGetBaOptions = usePrevious(getBaOptions);

  const eTagTagIdColumns = useMemo(() => {
    const isDetailDeletedChanged: boolean =
      isDetailDeleted !== previousIsDetailDeleted;
    const isDetailUpdatingChanged: boolean =
      isDetailUpdating !== previousIsDetailUpdating;
    const baOptionsChanged: boolean = getBaOptions !== previousGetBaOptions;
    const cpseOptionsChanged: boolean =
      getCpseOptions !== previousGetCpseOptions;
    const includeTagCode: boolean = onTagCodeChange !== undefined;
    const columnWidth: string = includeTagCode ? '25%' : '33%';

    const eTagTagIdsEditColumns = [
      {
        dataIndex: 'gca',
        render: gcaSelectRender({
          allowClear: true,
          equalityChecker: caEntityInfoEqual,
          filter: selectOptionLabelFilter,
          getInitialValue: getInitialGcaSelectValue,
          getOptions: getBaOptions,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: onGcaChange,
          showSearch: true,
          valueToUid: typeIgnoredEntityInfoToUid,
        }),
        shouldCellUpdate: (
          record: IETagTagId,
          previousRecord: IETagTagId,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          baOptionsChanged ||
          (record.gca === null
            ? previousRecord.gca !== null
            : previousRecord.gca === null
            ? true
            : record.gca.tagging_entity_id !==
              previousRecord.gca.tagging_entity_id),
        title: 'GCA',
        width: columnWidth,
      },
      {
        dataIndex: 'pse',
        render: pseSelectRender({
          allowClear: true,
          equalityChecker: pseEntityInfoEqual,
          filter: selectOptionLabelFilter,
          getInitialValue: getInitialCpseSelectValue,
          getOptions: getCpseOptions,
          isDisabled: isDetailDeleted || isDetailUpdating,
          onChange: onCpseChange,
          showSearch: true,
          valueToUid: typeIgnoredEntityInfoToUid,
        }),
        shouldCellUpdate: (
          record: IETagTagId,
          previousRecord: IETagTagId,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          cpseOptionsChanged ||
          (record.pse === null
            ? previousRecord.pse !== null
            : previousRecord.pse === null
            ? true
            : !(
                record.pse.tagging_entity_id ===
                  previousRecord.pse.tagging_entity_id &&
                record.pse.entity_type === previousRecord.pse.entity_type
              )),
        title: 'CPSE',
        width: columnWidth,
      },
    ];

    if (includeTagCode) {
      eTagTagIdsEditColumns.push({
        dataIndex: 'tag_code',
        render: tagCodeInputRender({
          getInitialValue: getInitialTagCodeInputValue,
          getKey: (record: IETagTagId): string => record.key,
          isDisabled: isDetailDeleted || isDetailUpdating,
          maxLength: 7,
          onChange: handleTagCodeChange,
        }),
        shouldCellUpdate: (
          record: IETagTagId,
          previousRecord: IETagTagId,
        ): boolean =>
          isDetailDeletedChanged ||
          isDetailUpdatingChanged ||
          record.tag_code !== previousRecord.tag_code,
        title: 'Tag Code',
        width: columnWidth,
      });
    }

    eTagTagIdsEditColumns.push({
      dataIndex: 'lca',
      render: adjustedLcaSelectRender({
        allowClear: true,
        equalityChecker: caEntityInfoEqual,
        filter: selectOptionLabelFilter,
        getInitialValue: getInitialLcaSelectValue,
        getOptions: getBaOptions,
        isDisabled: isDetailDeleted || isDetailUpdating,
        onChange: onLcaChange,
        showSearch: true,
        valueToUid: typeIgnoredEntityInfoToUid,
      }),
      shouldCellUpdate: (
        record: IETagTagId,
        previousRecord: IETagTagId,
      ): boolean =>
        isDetailDeletedChanged ||
        isDetailUpdatingChanged ||
        baOptionsChanged ||
        (record.lca === null
          ? previousRecord.lca !== null
          : previousRecord.lca === null
          ? true
          : record.lca.tagging_entity_id !==
            previousRecord.lca.tagging_entity_id),
      title: 'LCA',
      width: columnWidth,
    });

    return eTagTagIdsEditColumns;
  }, [
    adjustedLcaSelectRender,
    gcaSelectRender,
    getBaOptions,
    getCpseOptions,
    getInitialCpseSelectValue,
    getInitialGcaSelectValue,
    getInitialLcaSelectValue,
    getInitialTagCodeInputValue,
    handleTagCodeChange,
    isDetailDeleted,
    isDetailUpdating,
    onCpseChange,
    onGcaChange,
    onLcaChange,
    onTagCodeChange,
    pseSelectRender,
    previousGetBaOptions,
    previousGetCpseOptions,
    previousIsDetailDeleted,
    previousIsDetailUpdating,
    tagCodeInputRender,
  ]);

  return eTagTagIdColumns;
};

export default useETagTagIdsEditColumns;

import {
  CELL_NAVIGATION_KEY_DOWN_DELAY_IN_MILLISECONDS,
  COPY_FRAME_DELAY,
  DOUBLE_CLICK_INTERVAL_IN_MILLISECONDS,
  PASTE_FRAME_DELAY,
  REACT_DATA_GRID_DARK_MODE_CLASS,
  REACT_DATA_GRID_LIGHT_MODE_CLASS,
} from 'components/organisms/DataGrid/constants';
import {
  getSelectedCellsMap,
  isFocusedOnDataGrid,
  isPositionInSelection,
  transformDataGridMatrix,
  transformSelectedCellsMap,
  transformTabSeparatedList,
  updateRowsWithDataGridMatrix,
} from 'components/organisms/DataGrid/helpers';
import PasteModal from 'components/organisms/DataGrid/PasteModal';
import {
  ESelectedCellsDirection,
  IPasteData,
  TPositionHandler,
} from 'components/organisms/DataGrid/types';
import { EMPTY_CELLS_SELECTION } from 'constants/Component';
import { ARROW_DOWN_KEY, ARROW_UP_KEY, TAB_KEY } from 'constants/General';
import { DATA_GRID_HEADER_ROW_Z_INDEX } from 'constants/styles';
import { ETheme } from 'enums/Style';
import usePrevious from 'hooks/usePrevious';
import {
  IDataGridCellProps,
  IDataGridColumn,
  IDataGridHandle,
  IDataGridPosition,
  IDataGridSelectionContext,
  ISelectedCellsMapDimensions,
} from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import {
  ChangeEvent,
  ComponentType,
  Context,
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import ReactDataGrid, {
  DataGridHandle,
  FormatterProps,
  PasteEvent,
} from 'react-data-grid';
import styled from 'styled-components';
import {
  TDataGridRowRenderer,
  TSelectedCellsMap,
  TSelectedCellsRow,
} from 'types/Component';
import { TDataGridMatrix } from 'types/General';
import { getSelectedCellsMapDimensions } from 'utils/component';
import {
  hasShiftKeyDown,
  hasShiftKeyUp,
  isControlShortcut,
  isEmptyValue,
  isShiftShortcut,
} from 'utils/general';

// Redecalare forwardRef
// See https://fettblog.eu/typescript-react-generic-forward-refs/
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

const Wrapper = styled.div`
  .rdg-header-row {
    z-index: ${DATA_GRID_HEADER_ROW_Z_INDEX};
  }
`;

export interface IWrappedDataGridProps<
  C extends IDataGridColumn<R, S>,
  R extends IIndexable,
  S extends IIndexable,
  T,
> {
  cellToString?: (
    cell: T | null | undefined,
    useNullCellValue?: boolean,
  ) => string;
  className?: string;
  columns: C[];
  copyDataGridCellTo?: (
    sourceRow: R,
    sourceColumnKey: string,
    targetRow: R,
    targetColumnKey: string,
  ) => R;
  dataGridRef: RefObject<DataGridHandle>;
  DataGridSelectionContext: Context<IDataGridSelectionContext>;
  emptyRowsRenderer?: () => JSX.Element;
  getRowRenderer?: (
    DataGridSelectionContext: Context<IDataGridSelectionContext>,
  ) => TDataGridRowRenderer<R, S> | undefined;
  headerRowHeight?: number;
  isDisabled?: boolean;
  isVirtualised?: boolean;
  keyboardNavigationEnabled?: boolean;
  onRowsChange?: (updatedRows: R[], extraRows?: R[]) => void;
  rowHeight?: number;
  rows: R[];
  summaryRows?: S[];
  updateExtraRows?: (
    updatedRowsLength: number,
    startPosition: IDataGridPosition,
    endPosition: IDataGridPosition,
    extraRowsEndIdx: number,
    columns: C[],
    matrix: TDataGridMatrix,
  ) => R[];
  updateRowWithValue?: (
    value: string,
    targetColumnKey: string,
    targetRow: R,
  ) => R;
}

const WrappedDataGrid = <
  C extends IDataGridColumn<R, S>,
  R extends IIndexable,
  S extends IIndexable,
  T,
>(
  {
    cellToString,
    className,
    columns,
    copyDataGridCellTo,
    dataGridRef,
    DataGridSelectionContext,
    emptyRowsRenderer,
    getRowRenderer,
    headerRowHeight,
    isDisabled,
    isVirtualised,
    keyboardNavigationEnabled,
    onRowsChange,
    rowHeight,
    rows,
    summaryRows,
    updateExtraRows,
    updateRowWithValue,
  }: IWrappedDataGridProps<C, R, S, T>,
  ref: ForwardedRef<IDataGridHandle>,
): JSX.Element => {
  const {
    dataGridHasFocus,
    selectedCells,
    selectedPrimaryCell,
    setDataGridHasFocus,
    setOnExitEditCallback,
    setSelectedCells,
    setSelectedPrimaryCell,
    setSelectedSourceCells,
  } = useContext<IDataGridSelectionContext>(DataGridSelectionContext);
  const { currentTheme } = useThemeSwitcher();

  const [controlDToggle, setControlDToggle] = useState<boolean>(false);
  const [controlRToggle, setControlRToggle] = useState<boolean>(false);
  const [selectedCellsDirection, setSelectedCellsDirection] =
    useState<ESelectedCellsDirection>(ESelectedCellsDirection.None);
  const [hiddenCopyTextAreaValue, setHiddenCopyTextAreaValue] =
    useState<string>('');
  const [sourceValue, setSourceValue] = useState<string>('');
  const [isCopying, setIsCopying] = useState<boolean>(false);
  const [pasteValue, setPasteValue] = useState<string | undefined>(undefined);
  const [copyToggle, setCopyToggle] = useState<boolean>(false);
  const [pasteToggle, setPasteToggle] = useState<boolean>(false);
  const [pasteData, setPasteData] = useState<IPasteData | undefined>(undefined);

  const isShiftKeyDownRef = useRef<boolean>(false);
  const cellSelectedTimeRef = useRef<number>(0);
  const hiddenCopyTextAreaRef =
    useRef<HTMLTextAreaElement>() as MutableRefObject<HTMLTextAreaElement>;
  const hiddenPasteTextAreaRef =
    useRef<HTMLTextAreaElement>() as MutableRefObject<HTMLTextAreaElement>;
  const requestAnimationFrameIdCopyRef = useRef<number | null>(null);
  const requestAnimationFrameIdPasteRef = useRef<number | null>(null);
  const isDragSelectingRef = useRef<boolean>(false);
  const mouseDownButtonRef = useRef<number | undefined>(undefined);

  const previousControlDToggle: boolean | undefined =
    usePrevious(controlDToggle);
  const previousControlRToggle: boolean | undefined =
    usePrevious(controlRToggle);
  const previousCopyToggle: boolean | undefined = usePrevious(copyToggle);
  const previousPasteToggle: boolean | undefined = usePrevious(pasteToggle);

  const resetCopying = useCallback(() => {}, []);

  const resetSelection = useCallback(() => {
    if (selectedPrimaryCell !== undefined) {
      setSelectedCells(
        getSelectedCellsMap(selectedPrimaryCell, selectedPrimaryCell),
      );
    }
  }, [selectedPrimaryCell, setSelectedCells]);

  const resetSelectionToFirstRow = useCallback(() => {
    const updatedSelectedPrimaryCell: IDataGridPosition = { idx: 0, rowIdx: 0 };

    dataGridRef.current?.selectCell(updatedSelectedPrimaryCell);

    setSelectedPrimaryCell(updatedSelectedPrimaryCell);

    setSelectedCells(
      getSelectedCellsMap(
        updatedSelectedPrimaryCell,
        updatedSelectedPrimaryCell,
      ),
    );
  }, [dataGridRef, setSelectedCells, setSelectedPrimaryCell]);

  useImperativeHandle(
    ref,
    () => ({
      resetCopying,
      resetSelection,
      resetSelectionToFirstRow,
    }),
    [resetCopying, resetSelection, resetSelectionToFirstRow],
  );

  useEffect(() => {
    setOnExitEditCallback({
      callback: (key?: string) => {
        if (
          dataGridRef.current &&
          (key === ARROW_DOWN_KEY || key === ARROW_UP_KEY)
        ) {
          const rdgFocusSinkElements: HTMLCollectionOf<Element> | undefined =
            dataGridRef.current.element?.getElementsByClassName(
              'rdg-focus-sink',
            );

          if (
            rdgFocusSinkElements === undefined ||
            rdgFocusSinkElements.length !== 1
          ) {
            throw new Error('Invalid number of rdg-focus-sink elements found');
          } else {
            const rdgFocusSinkElement: HTMLElement =
              rdgFocusSinkElements[0] as HTMLElement;

            window.setTimeout(() => {
              rdgFocusSinkElement.dispatchEvent(
                new KeyboardEvent('keydown', {
                  bubbles: true,
                  cancelable: true,
                  code: key,
                  key,
                }),
              );
            }, CELL_NAVIGATION_KEY_DOWN_DELAY_IN_MILLISECONDS);
          }
        }
      },
    });
  }, [dataGridRef, setOnExitEditCallback]);

  useEffect(() => {
    let dataGridElement: HTMLDivElement | null = null;

    if (
      !isEmptyValue(dataGridRef?.current) &&
      !isEmptyValue(dataGridRef?.current!.element)
    ) {
      dataGridElement = dataGridRef!.current!.element!;
    }

    const handleKeyUp = (keyboardEvent: KeyboardEvent) => {
      if (isFocusedOnDataGrid(dataGridElement)) {
        if (hasShiftKeyUp(keyboardEvent)) {
          isShiftKeyDownRef.current = false;
        }
      }
    };

    window.addEventListener('keyup', handleKeyUp);

    const handleMouseDown = (event: MouseEvent) => {
      if (!isFocusedOnDataGrid(dataGridElement, event.target)) {
        setDataGridHasFocus(false);
      }

      mouseDownButtonRef.current = event.button;
    };

    window.addEventListener('mousedown', handleMouseDown, true);

    const handleFocus = (event: FocusEvent) => {
      const hasFocus: boolean = isFocusedOnDataGrid(
        dataGridElement,
        event.target,
      );

      setDataGridHasFocus(hasFocus);
    };

    document.addEventListener('focus', handleFocus, true);

    const handleMouseUp = (event: MouseEvent) => {
      isShiftKeyDownRef.current = false;
      isDragSelectingRef.current = false;
    };

    window.addEventListener('mouseup', handleMouseUp, false);

    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('focus', handleFocus, true);
      window.removeEventListener('mousedown', handleMouseDown, true);
      window.removeEventListener('keyup', handleKeyUp);

      if (requestAnimationFrameIdCopyRef.current !== null) {
        window.cancelAnimationFrame(requestAnimationFrameIdCopyRef.current);
      }

      if (requestAnimationFrameIdPasteRef.current !== null) {
        window.cancelAnimationFrame(requestAnimationFrameIdPasteRef.current);
      }
    };
  }, [dataGridRef, setDataGridHasFocus]);

  useEffect(() => {
    const handleKeyDown = async (keyboardEvent: KeyboardEvent) => {
      if (dataGridHasFocus) {
        if (isControlShortcut(keyboardEvent, 'c')) {
          if (hiddenCopyTextAreaRef.current) {
            hiddenCopyTextAreaRef.current.select();
            hiddenCopyTextAreaRef.current.focus();
          }

          setIsCopying((): boolean => true);
          setCopyToggle(
            (previousCopyToggle: boolean): boolean => !previousCopyToggle,
          );
        }

        if (keyboardEvent.key === 'Escape') {
          setIsCopying((): boolean => false);
        }

        if (!isDisabled) {
          isShiftKeyDownRef.current = hasShiftKeyDown(keyboardEvent);

          if (isControlShortcut(keyboardEvent, 'd')) {
            setControlDToggle(
              (previousControlDToggle: boolean): boolean =>
                !previousControlDToggle,
            );
          }

          if (isControlShortcut(keyboardEvent, 'r')) {
            setControlRToggle(
              (previousControlRToggle: boolean): boolean =>
                !previousControlRToggle,
            );
          }

          if (isControlShortcut(keyboardEvent, 'v')) {
            if (hiddenPasteTextAreaRef.current) {
              hiddenPasteTextAreaRef.current.select();
              hiddenPasteTextAreaRef.current.focus();
            }

            setPasteToggle(
              (previousPasteToggle: boolean): boolean => !previousPasteToggle,
            );
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [dataGridHasFocus, isDisabled]);

  useEffect(() => {
    if (
      selectedPrimaryCell !== undefined &&
      selectedPrimaryCell.rowIdx >= rows.length
    ) {
      setSelectedCells(EMPTY_CELLS_SELECTION);
      setSelectedPrimaryCell(undefined);
      setSelectedSourceCells(EMPTY_CELLS_SELECTION);
    }
  }, [
    rows,
    selectedPrimaryCell,
    setSelectedCells,
    setSelectedPrimaryCell,
    setSelectedSourceCells,
  ]);

  const handleKeyDownCapture = useCallback(
    (keyboardEvent: React.KeyboardEvent) => {
      if (isShiftShortcut(keyboardEvent.nativeEvent, TAB_KEY)) {
        isShiftKeyDownRef.current = false;
      }

      if (
        isControlShortcut(keyboardEvent.nativeEvent, 'r') ||
        isControlShortcut(keyboardEvent.nativeEvent, 'd')
      ) {
        keyboardEvent.preventDefault();
        keyboardEvent.stopPropagation();

        window.dispatchEvent(
          new KeyboardEvent(keyboardEvent.type, {
            bubbles: true,
            cancelable: true,
            code: keyboardEvent.code,
            ctrlKey: true,
            key: keyboardEvent.key,
          }),
        );
      }
    },
    [],
  );

  const handleSelectedCellChange = useCallback(
    (position: IDataGridPosition) => {
      const timeNow: number = Date.now();
      const isSameCell: boolean =
        selectedPrimaryCell !== undefined &&
        selectedPrimaryCell.idx === position.idx &&
        selectedPrimaryCell.rowIdx === position.rowIdx;

      if (
        !isSameCell ||
        (isSameCell &&
          timeNow - cellSelectedTimeRef.current >
            DOUBLE_CLICK_INTERVAL_IN_MILLISECONDS)
      ) {
        let updatedSelectedCells: TSelectedCellsMap = EMPTY_CELLS_SELECTION;

        if (isShiftKeyDownRef.current && selectedPrimaryCell !== undefined) {
          updatedSelectedCells = getSelectedCellsMap(
            selectedPrimaryCell,
            position,
          );
        } else {
          updatedSelectedCells = getSelectedCellsMap(position, position);

          setSelectedPrimaryCell(position);
        }

        // 0 is primary mouse button
        if (
          mouseDownButtonRef.current === 0 ||
          !isPositionInSelection(position, selectedCells)
        ) {
          setSelectedCells(updatedSelectedCells);
        }
      }

      cellSelectedTimeRef.current = timeNow;
    },
    [
      selectedCells,
      selectedPrimaryCell,
      setSelectedCells,
      setSelectedPrimaryCell,
    ],
  );

  const handlePaste = useCallback(
    (pasteEvent: PasteEvent<R>): R => pasteEvent.targetRow,
    [],
  );

  const handleMouseDownRef = useRef<TPositionHandler>(
    (position: IDataGridPosition) => {
      setSelectedPrimaryCell(position);

      if (!isEmptyValue(dataGridRef?.current)) {
        dataGridRef!.current!.selectCell(position);
      }

      isDragSelectingRef.current = true;
      isShiftKeyDownRef.current = true;
    },
  );

  const handleMouseOverRef = useRef<TPositionHandler>(
    (position: IDataGridPosition) => {
      if (isDragSelectingRef.current === true) {
        if (!isEmptyValue(dataGridRef?.current)) {
          dataGridRef!.current!.selectCell(position);
        }
      }
    },
  );

  const handleMouseUpRef = useRef<TPositionHandler>(() => {
    isShiftKeyDownRef.current = false;
    isDragSelectingRef.current = false;
  });

  const handledColumns: C[] = useMemo(
    () =>
      columns.map((column: C): C => {
        if (column.getFormatter === undefined) {
          return column;
        }

        const Formatter: ComponentType<IDataGridCellProps<R, S>> =
          column.getFormatter(DataGridSelectionContext);

        return {
          ...column,
          formatter: (props: FormatterProps<R, S>): JSX.Element => (
            <Formatter
              {...props}
              onMouseDown={handleMouseDownRef.current}
              onMouseOver={handleMouseOverRef.current}
              onMouseUp={handleMouseUpRef.current}
            />
          ),
        };
      }),
    [columns, DataGridSelectionContext],
  );

  const adjustedColumns: C[] = useMemo(
    () =>
      handledColumns.map(
        (handledColumn: C): C => ({
          ...handledColumn,
          editor: isDisabled
            ? undefined
            : handledColumn.getEditor?.(DataGridSelectionContext),
        }),
      ),
    [DataGridSelectionContext, handledColumns, isDisabled],
  );

  useEffect(() => {
    const isControlR: boolean =
      previousControlRToggle !== undefined &&
      previousControlRToggle !== controlRToggle;
    const isControlD: boolean =
      previousControlDToggle !== undefined &&
      previousControlDToggle !== controlDToggle;

    if (copyDataGridCellTo !== undefined && (isControlD || isControlR)) {
      const updatedRows: R[] = [...rows];
      let sourceColumnKey: string | undefined = undefined;
      let sourceRow: R | undefined = undefined;

      Object.keys(selectedCells).forEach((columnIndexString: string) => {
        const columnIndex: number = parseInt(columnIndexString, 10);
        const columnKey: string = adjustedColumns[columnIndex].key;
        const selectedCellsRow: TSelectedCellsRow = selectedCells[columnIndex];

        Object.keys(selectedCellsRow).forEach((rowIndexString: string) => {
          const rowIndex: number = parseInt(rowIndexString, 10);
          const row: R = updatedRows[rowIndex];

          if (sourceColumnKey === undefined) {
            sourceColumnKey = columnKey;
          }

          if (sourceRow === undefined) {
            sourceRow = row;
          }

          updatedRows[rowIndex] = copyDataGridCellTo(
            sourceRow,
            sourceColumnKey,
            row,
            columnKey,
          );

          if (isControlR) {
            sourceRow = undefined;
          }
        });

        if (isControlD) {
          sourceColumnKey = undefined;
        }
      });

      if (onRowsChange !== undefined) {
        onRowsChange(updatedRows);
      }
    }
  }, [
    adjustedColumns,
    controlDToggle,

    controlRToggle,
    copyDataGridCellTo,
    onRowsChange,
    previousControlDToggle,
    previousControlRToggle,
    rows,
    selectedCells,
    selectedCellsDirection,
  ]);

  useEffect(() => {
    if (previousCopyToggle !== undefined && previousCopyToggle !== copyToggle) {
      const requestAnimationFrame = (count: number) => {
        if (count === 0) {
          if (
            !isEmptyValue(dataGridRef?.current) &&
            selectedPrimaryCell !== undefined
          ) {
            dataGridRef!.current!.selectCell(selectedPrimaryCell);

            setSelectedCells(selectedCells);
          }

          requestAnimationFrameIdCopyRef.current = null;
        } else {
          requestAnimationFrameIdCopyRef.current = window.requestAnimationFrame(
            () => {
              requestAnimationFrame(count - 1);
            },
          );
        }
      };

      requestAnimationFrame(COPY_FRAME_DELAY);

      setSelectedSourceCells(selectedCells);

      setSourceValue(hiddenCopyTextAreaValue);
    }
  }, [
    adjustedColumns,
    copyToggle,
    dataGridRef,
    hiddenCopyTextAreaValue,
    previousCopyToggle,
    rows,
    selectedCells,
    selectedPrimaryCell,
    setSelectedCells,
    setSelectedPrimaryCell,
    setSelectedSourceCells,
  ]);

  useEffect(() => {
    if (cellToString !== undefined) {
      setHiddenCopyTextAreaValue(
        transformDataGridMatrix(
          transformSelectedCellsMap<C, R, S, T>(
            selectedCells,
            adjustedColumns,
            rows,
            cellToString,
          ),
        ),
      );
    }
  }, [adjustedColumns, cellToString, rows, selectedCells]);

  useEffect(() => {
    if (
      previousPasteToggle !== undefined &&
      previousPasteToggle !== pasteToggle
    ) {
      const requestAnimationFrame = (count: number) => {
        if (count === 0) {
          setPasteValue(hiddenPasteTextAreaRef.current.value);

          requestAnimationFrameIdPasteRef.current = null;
        } else {
          requestAnimationFrameIdPasteRef.current =
            window.requestAnimationFrame(() => {
              requestAnimationFrame(count - 1);
            });
        }
      };

      requestAnimationFrame(PASTE_FRAME_DELAY);
    }
  }, [pasteToggle, previousPasteToggle]);

  useEffect(() => {
    if (pasteValue !== undefined && selectedPrimaryCell !== undefined) {
      const pasteMatrix: TDataGridMatrix | undefined =
        transformTabSeparatedList(pasteValue);

      if (pasteMatrix !== undefined) {
        const {
          endPosition,
          extraRows,
          hasTooManyColumns,
          hasTooManyRows,
          startPosition,
          updatedRows,
        } = updateRowsWithDataGridMatrix<C, R, S>(
          pasteMatrix,
          selectedCells,
          adjustedColumns,
          rows,
          updateRowWithValue,
          updateExtraRows,
        );
        const updatedSelectedCells: TSelectedCellsMap = getSelectedCellsMap(
          startPosition,
          endPosition,
        );

        const commitPaste = (addRows?: boolean) => {
          const {
            numSelectedColumns,
            numSelectedRows,
          }: ISelectedCellsMapDimensions =
            getSelectedCellsMapDimensions(updatedSelectedCells);
          const isSingleSelectedCell: boolean =
            numSelectedColumns === 1 && numSelectedRows === 1;

          if (!isEmptyValue(dataGridRef?.current)) {
            dataGridRef!.current!.selectCell(startPosition);
          }

          setSelectedPrimaryCell(startPosition);

          setSelectedCells(updatedSelectedCells);

          if (onRowsChange !== undefined) {
            onRowsChange(updatedRows, addRows === true ? extraRows : undefined);
          }

          setSelectedCellsDirection(
            isSingleSelectedCell
              ? ESelectedCellsDirection.None
              : ESelectedCellsDirection.Grid,
          );

          setPasteData(undefined);
        };

        if (hasTooManyColumns || hasTooManyRows) {
          setPasteData({ commitPaste, hasTooManyColumns, hasTooManyRows });
        } else {
          commitPaste();
        }

        if (sourceValue !== pasteValue) {
          setSelectedSourceCells(EMPTY_CELLS_SELECTION);
          setSourceValue('');
        }

        setPasteValue(undefined);
      }
    }
  }, [
    adjustedColumns,
    dataGridRef,
    hiddenCopyTextAreaValue,
    onRowsChange,
    pasteValue,
    rows,
    selectedCells,
    selectedPrimaryCell,
    setSelectedCells,
    setSelectedPrimaryCell,
    setSelectedSourceCells,
    sourceValue,
    updateExtraRows,
    updateRowWithValue,
  ]);

  useEffect(() => {
    if (!isCopying) {
      setSelectedSourceCells(EMPTY_CELLS_SELECTION);
      setSourceValue('');
    }
  }, [
    isCopying,
    setSelectedCells,
    setSelectedPrimaryCell,
    setSelectedSourceCells,
  ]);

  const handleHiddenCopyTextAreaChange = useCallback(
    (changeEvent: ChangeEvent<HTMLTextAreaElement>) => {
      setHiddenCopyTextAreaValue(changeEvent.target.value);
    },
    [],
  );

  const handleRowsChange = useCallback(
    (updatedRows: R[]) => {
      if (onRowsChange !== undefined) {
        onRowsChange(updatedRows);
      }
    },
    [onRowsChange],
  );

  const handlePasteModalCancel = useCallback(() => {
    setPasteData(undefined);
  }, []);

  const rowRenderer: TDataGridRowRenderer<R, S> | undefined = useMemo(
    (): TDataGridRowRenderer<R, S> | undefined =>
      getRowRenderer?.(DataGridSelectionContext),
    [DataGridSelectionContext, getRowRenderer],
  );

  return (
    <Wrapper
      className={`wrapped-data-grid${
        className === undefined ? '' : ` ${className}`
      }`}
      onKeyDownCapture={handleKeyDownCapture}
    >
      <textarea
        className='hidden-text-area'
        onChange={handleHiddenCopyTextAreaChange}
        ref={hiddenCopyTextAreaRef}
        value={hiddenCopyTextAreaValue}
      />
      <textarea className='hidden-text-area' ref={hiddenPasteTextAreaRef} />
      <ReactDataGrid
        className={
          currentTheme === ETheme.Dark
            ? REACT_DATA_GRID_DARK_MODE_CLASS
            : REACT_DATA_GRID_LIGHT_MODE_CLASS
        }
        columns={adjustedColumns}
        cellNavigationMode={keyboardNavigationEnabled ? 'CHANGE_ROW' : null}
        emptyRowsRenderer={emptyRowsRenderer}
        enableVirtualization={isVirtualised}
        headerRowHeight={headerRowHeight}
        onPaste={handlePaste}
        onRowsChange={handleRowsChange}
        onSelectedCellChange={handleSelectedCellChange}
        ref={dataGridRef}
        rowHeight={rowHeight}
        rowRenderer={rowRenderer}
        rows={rows}
        summaryRows={summaryRows}
      />
      <PasteModal onCancel={handlePasteModalCancel} pasteData={pasteData} />
    </Wrapper>
  );
};

export default forwardRef(WrappedDataGrid);

import Connector from 'components/organisms/PathDiagramView/Connector';
import {
  GENERATION_BLOCK_WIDTH_VALUE,
  SHORT_CONNECTOR_WIDTH_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import Node from 'components/organisms/PathDiagramView/Node';
import { ENodePosition } from 'components/organisms/PathDiagramView/types';
import { IThemedProps } from 'interfaces/Component';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { textColour } from 'utils/styles';

const GENERATOR_ICON_SIZE_VALUE = 48;

const Layout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const GeneratorIcon = styled.div<IThemedProps>`
  border: 2px solid ${(props) => textColour(props)};
  border-radius: 50%;
  height: ${GENERATOR_ICON_SIZE_VALUE}px;
  position: relative;
  width: ${GENERATOR_ICON_SIZE_VALUE}px;

  div:first-child {
    border: 3px solid ${(props) => textColour(props)};
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    height: 21px;
    left: 2px;
    position: absolute;
    top: 12px;
    transform: rotate(-45deg);
    width: 21px;
  }

  span {
    position: absolute;
    right: 0;
    top: ${GENERATOR_ICON_SIZE_VALUE + 2}px;
    white-space: nowrap;
  }

  div:last-child {
    border: 3px solid ${(props) => textColour(props)};
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
    bottom: 12px;
    height: 21px;
    position: absolute;
    right: 3px;
    transform: rotate(135deg);
    width: 21px;
  }
`;

interface IProps {
  mw: number | null;
  nodeLabel?: string | null;
  nodeSize: number;
}

const GenerationDiagram = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { mw, nodeLabel, nodeSize } = props;

  return (
    <Layout>
      <GeneratorIcon currentTheme={currentTheme!}>
        <div />
        <span>{mw === null ? null : `${mw} MW`}</span>
        <div />
      </GeneratorIcon>
      <Connector
        connectorWidth={
          GENERATION_BLOCK_WIDTH_VALUE -
          GENERATOR_ICON_SIZE_VALUE -
          nodeSize -
          SHORT_CONNECTOR_WIDTH_VALUE
        }
      />
      <Node
        nodeLabel={nodeLabel}
        nodePosition={ENodePosition.Right}
        nodeSize={nodeSize}
      />
      <Connector connectorWidth={SHORT_CONNECTOR_WIDTH_VALUE} />
    </Layout>
  );
};

export default GenerationDiagram;

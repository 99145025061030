import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Card as AntDesignCard } from 'antd';
import IconButton, {
  IIconButtonProps,
} from 'components/atoms/IconButton/IconButton';
import { EFloatOverPlacement } from 'components/molecules/FloatOver/FloatOver';
import DataGrid, {
  IDataGridProps,
} from 'components/organisms/DataGrid/DataGrid';
import {
  CARD_BORDER_SIZE_VALUE,
  CARD_TITLE_HEIGHT_VALUE,
  EDIT_MODAL_Y_OFFSET_VALUE,
  PANEL_MAXIMUM_HEIGHT,
  REVIEW_MODAL_Y_OFFSET_VALUE,
} from 'components/organisms/MarketInformation/MarketInfoPricesDataGrid/constants';
import {
  copyMarketInfoPricesDataGridCellTo,
  isStringAMarket,
  marketInfoPricesDataGridCellToString,
  updateMarketInfoPricesDataGridRowWithValue,
} from 'components/organisms/MarketInformation/MarketInfoPricesDataGrid/helpers';
import {
  BUTTON_ICON_DIMENSIONS,
  MARKET_INFO_PRICES_DATA_GRID_HEADER_ROW_HEIGHT_VALUE,
  MARKET_INFO_PRICES_DATA_GRID_ROW_HEIGHT_VALUE,
  STANDARD_SPACING_VALUE,
} from 'constants/styles';
import {
  FloatOverContext,
  IFloatOverContext,
} from 'contexts/FloatOver/FloatOver';
import { EMarketInfoMarket } from 'enums/ETag';
import { IMarketInfoPricesDataGridCell } from 'interfaces/Detail';
import { IIndexable } from 'interfaces/General';
import {
  Context,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import styled from 'styled-components';
import {
  TMarketInfoPricesDataGridColumn,
  TMarketInfoPricesDataGridRow,
  TMarketInfoPricesDataGridSummaryRow,
} from 'types/Detail';
import { isEmptyValue } from 'utils/general';
import { IDataGridSelectionContext } from '../../../../interfaces/Component';
import { RowRendererProps } from 'react-data-grid';
import MarketInfoDataGridEditRow from './MarketInfoDataGridEditRow/MarketInfoDataGridEditRow';

const ReviewIcon = styled(EyeOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const EditIcon = styled(EditOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const PriceInfoButton = styled(IconButton)<IIconButtonProps>`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IStyledCardProps {
  height: string;
}

const StyledCard = styled(AntDesignCard)<IStyledCardProps>`
  width: 309px;

  .ant-card-body {
    height: calc(100% - 38px);
    width: 100%;
  }

  ${(props) => `height: ${props.height};`}
`;

interface IMarketInfoPricesDataGridProps
  extends IDataGridProps<
    TMarketInfoPricesDataGridColumn,
    TMarketInfoPricesDataGridRow,
    IIndexable,
    IMarketInfoPricesDataGridCell
  > {
  isDisabled?: boolean;
  isEditable: boolean;
  market: EMarketInfoMarket;
  title?: string;
}

const MarketInfoPricesDataGrid = ({
  columns,
  dataGridRef,
  isDisabled,
  isEditable,
  market,
  onRowsChange,
  rows,
  title,
}: IMarketInfoPricesDataGridProps): JSX.Element => {
  const {
    floatOverContent,
    floatOverId,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  } = useContext<IFloatOverContext>(FloatOverContext);
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;

  const getRowRenderer = useCallback(
    (DataGridSelectionContext: Context<IDataGridSelectionContext>) =>
      isEditable === false
        ? (
            props: RowRendererProps<
              TMarketInfoPricesDataGridRow,
              TMarketInfoPricesDataGridSummaryRow
            >,
          ): JSX.Element => (
            <MarketInfoDataGridEditRow
              {...props}
              DataGridSelectionContext={DataGridSelectionContext}
              initialDataSet={rows}
            />
          )
        : undefined,
    [rows, isEditable],
  );

  const cardHeight: string = useMemo(
    (): string =>
      `${
        CARD_BORDER_SIZE_VALUE +
        STANDARD_SPACING_VALUE +
        CARD_TITLE_HEIGHT_VALUE +
        MARKET_INFO_PRICES_DATA_GRID_HEADER_ROW_HEIGHT_VALUE +
        rows.length * MARKET_INFO_PRICES_DATA_GRID_ROW_HEIGHT_VALUE +
        STANDARD_SPACING_VALUE +
        CARD_BORDER_SIZE_VALUE
      }px`,
    [rows.length],
  );

  const FloatOverContent: JSX.Element = useMemo(() => {
    return (
      <StyledCard height={cardHeight} key={market as string} title={title}>
        <DataGrid
          cellToString={marketInfoPricesDataGridCellToString}
          columns={columns}
          copyDataGridCellTo={copyMarketInfoPricesDataGridCellTo}
          dataGridRef={dataGridRef}
          headerRowHeight={MARKET_INFO_PRICES_DATA_GRID_HEADER_ROW_HEIGHT_VALUE}
          isDisabled={isDisabled || !isEditable}
          getRowRenderer={getRowRenderer}
          key={market as string}
          keyboardNavigationEnabled={true}
          onRowsChange={onRowsChange}
          rows={rows}
          updateRowWithValue={updateMarketInfoPricesDataGridRowWithValue}
        />
      </StyledCard>
    );
  }, [
    cardHeight,
    columns,
    dataGridRef,
    getRowRenderer,
    market,
    isDisabled,
    isEditable,
    onRowsChange,
    rows,
    title,
  ]);

  useEffect(() => {
    if (!isEmptyValue(floatOverContent) && isStringAMarket(floatOverId)) {
      setFloatOverId(market as string);
      setFloatOverUseDragPanel(true);
      setFloatOverContent(FloatOverContent);
    }
  }, [
    floatOverContent,
    FloatOverContent,
    floatOverId,
    market,
    setFloatOverContent,
    setFloatOverId,
    setFloatOverUseDragPanel,
  ]);

  const handleClick = useCallback(() => {
    const isFloatOverIdAMarket: boolean = isStringAMarket(floatOverId);

    if (isEmptyValue(floatOverContent) || !isFloatOverIdAMarket) {
      const { width, x, y } = buttonRef.current.getBoundingClientRect();
      setFloatOverId(market as string);
      setFloatOverDefaultPosition({
        placement: EFloatOverPlacement.Right,
        x: x + width + STANDARD_SPACING_VALUE * 3,
        y:
          y -
          (isEditable
            ? EDIT_MODAL_Y_OFFSET_VALUE
            : REVIEW_MODAL_Y_OFFSET_VALUE),
      });
      setFloatOverMaximumHeight(PANEL_MAXIMUM_HEIGHT);
      setFloatOverUseDragPanel(false);
      setFloatOverContent(FloatOverContent);
    } else if (isFloatOverIdAMarket) {
      setFloatOverContent(null);
    }
  }, [
    FloatOverContent,
    floatOverContent,
    floatOverId,
    isEditable,
    market,
    setFloatOverContent,
    setFloatOverDefaultPosition,
    setFloatOverId,
    setFloatOverMaximumHeight,
    setFloatOverUseDragPanel,
  ]);

  return (
    <PriceInfoButton
      buttonRef={buttonRef}
      icon={isEditable === true ? <EditIcon /> : <ReviewIcon />}
      isContained={true}
      isDisabled={isDisabled}
      noBorder={true}
      onClick={handleClick}
      transparentBackground={true}
    />
  );
};

export default MarketInfoPricesDataGrid;

import { TErrorMessage } from 'types/Error';

export enum EWebSocketWorkerMessageType {
  Close = 'close',
  Closed = 'closed',
  Connect = 'connect',
  Connected = 'connected',
  Error = 'error',
  Message = 'message',
  Send = 'send',
  Sent = 'sent',
}

export interface IWebSocketWorkerMessage<T> {
  closeCode?: number;
  closeReason?: string;
  error?: TErrorMessage;
  message: T;
  mock?: string;
  type: EWebSocketWorkerMessageType;
  url?: string;
}

import { EProfileView } from 'components/organisms/ProfileInformationView/types';
import { EProfileFormat, ETableConfiguration } from 'enums/Detail';
import { EProfileSegment } from 'enums/ETag';
import { IOption } from 'interfaces/Component';

export const PROFILE_VIEW_OPTIONS: IOption<EProfileView>[] = [
  { label: 'All Profiles', value: EProfileView.Profiles },
  { label: 'Energy Snapshots', value: EProfileView.Snapshots },
];

export const ALL_PROFILES_FORMAT_OPTIONS: IOption<EProfileFormat>[] = [
  { label: 'Start/Stop', value: EProfileFormat.StartStop },
  { label: 'Daily Integrated', value: EProfileFormat.Daily },
  { label: 'Hourly Integrated', value: EProfileFormat.Hourly },
  { label: '15min Integrated', value: EProfileFormat.FifteenMinute },
  { label: '5min Integrated', value: EProfileFormat.FiveMinute },
];

export const REDUCED_ALL_PROFILES_FORMAT_OPTIONS: IOption<EProfileFormat>[] = [
  { label: 'Start/Stop', value: EProfileFormat.StartStop },
  { label: 'Hourly Integrated (Read Only)', value: EProfileFormat.Hourly },
];

export const PRINT_VIEW_PROFILES_FORMAT_OPTIONS: IOption<EProfileFormat>[] = [
  { label: 'Start/Stop', value: EProfileFormat.StartStop },
  { label: 'Hourly Integrated', value: EProfileFormat.Hourly },
];

export const ENERGY_SNAPSHOT_FORMAT_OPTIONS: IOption<EProfileFormat>[] = [
  { label: 'Start/Stop', value: EProfileFormat.StartStop },
];

export const REVIEW_TABLE_CONFIGURATION_OPTIONS: IOption<ETableConfiguration>[] =
  [
    {
      label: 'Show Allocation Profile Only',
      value: ETableConfiguration.ProfileOnly,
    },
    {
      label: 'Show Allocation Profile Totals',
      value: ETableConfiguration.ProfileTotals,
    },
    {
      label: 'Show POD and Allocation Profile',
      value: ETableConfiguration.POD,
    },
    { label: 'Show All Profiles', value: ETableConfiguration.All },
  ];

export const EDIT_ETAG_DRAFT_TABLE_CONFIGURATION_OPTIONS: IOption<ETableConfiguration>[] =
  [
    {
      label: 'New Tag Request',
      value: ETableConfiguration.NewTagRequest,
    },
  ];

export const PROFILE_SEGMENT_OPTIONS: IOption<EProfileSegment>[] = [
  { label: 'Gen', value: EProfileSegment.Gen },
];

export const PROFILE_FORMAT_SELECT_WIDTH = '136px';

export const REDUCED_PROFILE_FORMAT_SELECT_WIDTH = '206px';

// REPORTS_SUMMARY_CHECKOUT_REQUESTS
import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  IETagCheckoutReportCreateResponse,
  IETagCheckoutReportsReponse,
} from 'interfaces/ETag';
import {
  REPORTS_SUMMARY_CHECKOUT_REQUESTS,
  REPORTS_SUMMARY_CHECKOUT_REPORT,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';

export const createCheckoutReport = async (
  toEntityId: TToEntityId,
  start: string,
  end: string,
  timeZone: TTimeZone,
  filterIds: string[],
  attributeNames: string[],
): Promise<AxiosResponse<IETagCheckoutReportCreateResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    REPORTS_SUMMARY_CHECKOUT_REPORT(
      toEntityId,
      start,
      end,
      timeZone,
      filterIds,
      attributeNames,
    ),
    DEFAULT_OPTIONS,
  );
};

export const getCheckoutReports = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse<IETagCheckoutReportsReponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    REPORTS_SUMMARY_CHECKOUT_REQUESTS(toEntityId),
    DEFAULT_OPTIONS,
  );
};

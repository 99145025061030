import { ColDef, GetRowIdParams, RowClickedEvent } from 'ag-grid-community';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import AGDataTable from 'components/molecules/DataTable/AGDataTable';
import {
  generateNewTemplateFilter,
  generateTemplateIdsRequestBodyFiltersFromUiFilters,
} from 'components/molecules/TemplateFilterConfigurator/helpers';
import {
  ACTIVE_FILTER_OPTIONS as FILTER_ACTIVE_OPTIONS,
  DEFAULT_TIME_ZONE,
  ETAG_TEMPLATE_COLUMN_DATA,
  ETAG_TEMPLATE_COLUMN_DATA_AG_GRID,
} from 'components/pages/TemplatesPage/constants';
import {
  connectByRecordKey,
  transformColumnInformation,
  transformGridColumnInformation,
  transformRetrievedTemplates,
} from 'components/pages/TemplatesPage/helpers';
import TemplatesActionBar from 'components/pages/TemplatesPage/TemplatesActionBar';
import {
  EActiveOptions,
  IETagTemplateId,
} from 'components/pages/TemplatesPage/types';
import {
  ACTION_BAR_HEIGHT_VALUE,
  COLUMN_LAYOUT_SHARED_STYLES,
  DETAIL_HEADER,
  LAYOUT_PADDING_VALUE,
  TO_ENTITY_TITLE_HEIGHT_VALUE,
} from 'constants/styles';
import { TEMPLATES_SHOW_ALL_GROUPS } from 'constants/Template';
import {
  ETagTemplateFilteringContext,
  IETagTemplateFilteringContext,
} from 'contexts/ETagTemplateFiltering/ETagTemplateFiltering';
import {
  ETagTemplateSortingContext,
  IETagTemplateSortingContext,
} from 'contexts/ETagTemplateSorting/ETagTemplateSorting';
import { IOption } from 'interfaces/Component';
import {
  IETagTemplate,
  IETagTemplateColumnData,
  IETagTemplateDataSet,
  IETagTemplateGroup,
  IETagTemplateGroupsResponse,
  IETagTemplateName,
  IETagTemplateNamesResponse,
  IETagTemplatesResponse,
} from 'interfaces/ETag';
import { ITemplateFilter } from 'interfaces/Filter';
import { IToEntity } from 'interfaces/ToEntity';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useSelector } from 'react-redux';
import { IToEntityUserState } from 'reduxes/User/types';
import {
  retrieveBulkETagTemplates,
  retrieveTemplateGroups,
  retrieveTemplates,
} from 'services/agent/templates';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import {
  TETagTemplateDataArraySorter,
  TETagTemplateDataSetFilter,
  TETagTemplateId,
} from 'types/ETag';
import { TRequestBodyFilter } from 'types/Filter';
import useAsyncEffect from 'use-async-effect';
import { sortByOptionLabel } from 'utils/component';
import { captureError } from 'utils/error';
import { getKeyForETagTemplateDataSet } from 'utils/eTag';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { andThen } from 'utils/sort';
import { retrieveToEntityIdTimeZone } from 'utils/user';
import SeparatedRowLayout from '../../atoms/SeparatedRowLayout/SeparatedRowLayout';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';

const Layout = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}

  height: 100%;
  padding: ${LAYOUT_PADDING_VALUE}px;
  padding-bottom: 0;
  position: relative;

  > :not(:last-child) {
    margin-bottom: 4px;
  }
`;

const Title = styled.div`
  ${DETAIL_HEADER}

  height: ${TO_ENTITY_TITLE_HEIGHT_VALUE}px;

  > div {
    padding-top: 6px;
  }
`;

interface ITemplateMonitorProps {
  encodedPermissionsId: string;
  isEmbeddedTitle?: boolean;
  maxHeight: string;
  toEntity: IToEntity;
  toEntityUserState: IToEntityUserState | undefined;
}

const TEMPLATES_REQUEST_CHUNK_LENGTH: number = 40;

const TemplateMonitor = (props: ITemplateMonitorProps): JSX.Element => {
  const {
    encodedPermissionsId,
    isEmbeddedTitle,
    maxHeight,
    toEntity,
    toEntityUserState,
  } = props;

  const { currentTheme } = useThemeSwitcher();

  const { arrayFilters, clearArrayFilters } =
    useContext<IETagTemplateFilteringContext>(ETagTemplateFilteringContext);

  const { arraySorters, clearArraySorters } =
    useContext<IETagTemplateSortingContext>(ETagTemplateSortingContext);

  const [templateIds, setTemplateIds] = useState<
    IETagTemplateId[] | undefined
  >();

  const [data, setData] = useState<IETagTemplate[] | undefined>();

  const [transformedData, setTransformedData] = useState<
    IETagTemplateDataSet[]
  >([]);

  const [sortedData, setSortedData] = useState<IETagTemplateDataSet[]>([]);

  const [filteredData, setFilteredData] = useState<IETagTemplateDataSet[]>([]);

  const [internalFilter, setInternalFilter] = useState<ITemplateFilter>(
    generateNewTemplateFilter('templateFilter'),
  );

  const [filter, setFilter] = useState<ITemplateFilter>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [finishedInitialLoad, setFinishedInitialLoad] =
    useState<boolean>(false);

  const [refreshFlag, setRefreshFlag] = useState<boolean>(false);

  const [transformedColumns, setTransformedColumns] = useState<
    IETagTemplateColumnData[]
  >([]);

  const [templateGroupNames, setTemplateGroupNames] = useState<
    IETagTemplateGroup[]
  >([]);

  const [templateGroupOptions, setTemplateGroupOptions] = useState<
    IOption<string | null>[]
  >([]);

  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState<
    string | null | undefined
  >(TEMPLATES_SHOW_ALL_GROUPS);

  const [templateIdsRequestBodyFilters, setTemplateIdsRequestBodyFilters] =
    useState<TRequestBodyFilter | undefined>();

  const [selectedRowKey, setSelectedRowKey] = useState<string | undefined>();

  const [selectedTemplate, setSelectedTemplate] = useState<
    IETagTemplate | undefined
  >();

  const timeZone: TTimeZone = useSelector(
    retrieveToEntityIdTimeZone(toEntity.to_entity),
  );

  const timeZoneRef = useRef<string | undefined>();

  const [errorMessage] = useState<TErrorMessage>(null);

  // set to true to tell ag grid to clear filters, ag grid will set to false
  const [clearGridFilters, setClearGridFilters] = useState<boolean>(false);

  useEffect(() => {
    const newTemplateGroupOptions: IOption<string | null>[] = templateGroupNames
      .map((option: IETagTemplateGroup) => ({
        label: option.group_name,
        value: option.group_name,
      }))
      .sort(sortByOptionLabel);
    setTemplateGroupOptions(newTemplateGroupOptions);
  }, [templateGroupNames]);

  useEffect(() => {
    const selectedTemplate: IETagTemplate | undefined = data?.find(
      (template: IETagTemplate) => template.id === selectedRowKey,
    );
    setSelectedTemplate(selectedTemplate);
  }, [data, selectedRowKey]);

  const [selectedActiveFilter, setSelectedActiveFilter] = useState<
    EActiveOptions | undefined
  >(EActiveOptions.ShowAll);

  const [showActive, setShowActive] = useState<boolean | undefined>();

  useAsyncEffect(async () => {
    try {
      setIsLoading(true);
      const namesResponse: AxiosResponse = await retrieveTemplates(
        toEntity.to_entity,
        templateIdsRequestBodyFilters,
      );

      const eTagTemplateNamesResponse: IETagTemplateNamesResponse =
        namesResponse.data;

      if (!isSuccessStatus(namesResponse.status)) {
        setIsLoading(false);
        setFinishedInitialLoad(true);
        throw new Error(eTagTemplateNamesResponse.errorMessage!);
      }

      const templateIds: IETagTemplateName[] =
        eTagTemplateNamesResponse.response;
      setTemplateIds(templateIds);
    } catch (error: any) {
      captureError(error, 'Failed to load list of template IDs');
    }
  }, [toEntity.to_entity, templateIdsRequestBodyFilters, refreshFlag]);

  useAsyncEffect(async () => {
    try {
      const groupsResponse: AxiosResponse = await retrieveTemplateGroups(
        toEntity.to_entity,
      );

      const eTagTemplateGroupsResponse: IETagTemplateGroupsResponse =
        groupsResponse.data;

      if (!isSuccessStatus(groupsResponse.status)) {
        setIsLoading(false);
        setFinishedInitialLoad(true);
        throw new Error(eTagTemplateGroupsResponse.errorMessage!);
      }

      const templateGroups: IETagTemplateGroup[] =
        eTagTemplateGroupsResponse.response;
      setTemplateGroupNames(templateGroups);
    } catch (error: any) {
      captureError(error, 'Failed to load list of template groups');
    }
  }, [toEntity.to_entity]);

  useEffect(() => {
    switch (selectedActiveFilter) {
      case EActiveOptions.ShowAll:
        setShowActive(undefined);
        break;
      case EActiveOptions.ShowActive:
        setShowActive(true);
        break;
      case EActiveOptions.ShowInactive:
        setShowActive(false);
        break;
      default:
        break;
    }
  }, [selectedActiveFilter]);

  useEffect(() => {
    setTransformedColumns(
      transformColumnInformation(
        ETAG_TEMPLATE_COLUMN_DATA,
        encodedPermissionsId,
        toEntity,
        timeZone,
      ),
    );
  }, [encodedPermissionsId, timeZone, toEntity]);

  const transformedGridColumns: ColDef[] = useMemo(
    () =>
      transformGridColumnInformation(
        ETAG_TEMPLATE_COLUMN_DATA_AG_GRID,
        toEntity,
        timeZone,
        currentTheme,
      ),
    [currentTheme, timeZone, toEntity],
  );

  useEffect(() => {
    if (data !== undefined) {
      setTransformedData(transformRetrievedTemplates(data));
    }
  }, [data]);

  useEffect(() => {
    setFilteredData(
      transformedData.filter(
        Object.values(arrayFilters).reduce(
          (
              previous: TETagTemplateDataSetFilter,
              current: TETagTemplateDataSetFilter,
            ): TETagTemplateDataSetFilter =>
            (eTagTemplateDataSet: IETagTemplateDataSet): boolean =>
              previous(eTagTemplateDataSet) && current(eTagTemplateDataSet),
          (_: IETagTemplateDataSet): boolean => true,
        ),
      ),
    );
  }, [arrayFilters, transformedData]);

  useEffect(() => {
    if (arraySorters.length > 0) {
      const sort: TETagTemplateDataArraySorter = arraySorters.reduceRight(
        (
          previousValue: TETagTemplateDataArraySorter,
          currentValue: TETagTemplateDataArraySorter,
        ) => andThen(currentValue)(previousValue),
      );
      const newData: IETagTemplateDataSet[] = [...filteredData];
      const sortedData = newData.sort(sort);
      connectByRecordKey(sortedData);
      setSortedData(sortedData);
    } else {
      connectByRecordKey(filteredData);
      setSortedData(filteredData);
    }
  }, [arraySorters, filteredData]);

  const handleClearAllFilters = () => {
    clearArrayFilters();
    setFilter(undefined);
    setInternalFilter(generateNewTemplateFilter('templateFilter'));
    setClearGridFilters(true);
  };

  useAsyncEffect(async () => {
    if (templateIds !== undefined) {
      try {
        setIsLoading(true);
        const templateIdChunks: TETagTemplateId[][] = [];
        for (
          let beginIndex = 0;
          beginIndex < templateIds.length;
          beginIndex += TEMPLATES_REQUEST_CHUNK_LENGTH
        ) {
          const objectTemplateIdsChunk: IETagTemplateId[] = templateIds.slice(
            beginIndex,
            beginIndex + TEMPLATES_REQUEST_CHUNK_LENGTH,
          );
          const stringTemplateIdsChunk: TETagTemplateId[] =
            objectTemplateIdsChunk.map((id: IETagTemplateId) => id.id);
          templateIdChunks.push(stringTemplateIdsChunk);
        }
        const retrieveResults: PromiseSettledResult<
          AxiosResponse<IETagTemplatesResponse>
        >[] = await Promise.allSettled(
          templateIdChunks.map((chunk: TETagTemplateId[]) =>
            retrieveBulkETagTemplates(toEntity.to_entity, chunk),
          ),
        );

        const fulfilledResults = retrieveResults
          .filter(
            (
              result: PromiseSettledResult<
                AxiosResponse<IETagTemplatesResponse>
              >,
            ): boolean => result.status === 'fulfilled',
          )
          .map(
            (
              result: PromiseSettledResult<
                AxiosResponse<IETagTemplatesResponse>
              >,
            ) =>
              (
                result as PromiseFulfilledResult<
                  AxiosResponse<IETagTemplatesResponse>
                >
              ).value,
          );

        const rejectedResults = retrieveResults
          .filter(
            (
              result: PromiseSettledResult<
                AxiosResponse<IETagTemplatesResponse>
              >,
            ): boolean => result.status === 'rejected',
          )
          .map(
            (
              result: PromiseSettledResult<
                AxiosResponse<IETagTemplatesResponse>
              >,
            ) => (result as PromiseRejectedResult).reason,
          );
        if (rejectedResults.length > 0) {
          captureError(new Error('One or more results rejected'));
        }

        const newTemplates: IETagTemplate[] = [];
        fulfilledResults.map((result: AxiosResponse<IETagTemplatesResponse>) =>
          result.data.response.forEach((template: IETagTemplate) =>
            newTemplates.push(template),
          ),
        );
        // we have an array of the templates
        clearArrayFilters();
        clearArraySorters();
        timeZoneRef.current = DEFAULT_TIME_ZONE;
        // map that to the final array
        setData(newTemplates);
      } catch (error: any) {
        captureError(error);
      } finally {
        setFinishedInitialLoad(true);
        setIsLoading(false);
      }
    }
  }, [templateIds]);

  const handleActivateSuccess = () => {
    const newData: IETagTemplate[] = [];
    if (data !== undefined) {
      data.forEach((template: IETagTemplate) => {
        if (template.id === selectedRowKey) {
          switch (selectedActiveFilter) {
            case EActiveOptions.ShowAll:
              newData.push({ ...template, active: !template.active });
              break;
            case EActiveOptions.ShowActive:
              if (template.active === false) {
                newData.push({ ...template, active: !template.active });
              }
              break;
            case EActiveOptions.ShowInactive:
              if (template.active === true) {
                newData.push({ ...template, active: !template.active });
              }
              break;
            default:
              break;
          }
        } else {
          newData.push(template);
        }
      });
    }

    setData(newData);
  };

  const handleRefresh = () => {
    setRefreshFlag(!refreshFlag);
  };

  const handleDelete = () => {
    const newData: IETagTemplate[] = [];
    if (data !== undefined) {
      data.forEach((element: IETagTemplate) => {
        if (element.id !== selectedRowKey) {
          newData.push(element);
        }
      });
    }
    setData(newData);
  };

  useEffect(() => {
    const requestFilter: TRequestBodyFilter | undefined =
      generateTemplateIdsRequestBodyFiltersFromUiFilters(
        filter,
        showActive,
        selectedTemplateGroup,
      );
    setTemplateIdsRequestBodyFilters(requestFilter);
  }, [filter, selectedTemplateGroup, showActive]);

  const handleSaveFilter = (): void => {
    setFilter(internalFilter);
  };

  const handleRowClick = (event: RowClickedEvent) => {
    const key: string = getKeyForETagTemplateDataSet(event.data);
    setSelectedRowKey((previousSelectedRowKey: string | undefined) => {
      return previousSelectedRowKey === key ? undefined : key;
    });
    return undefined;
  };

  const rowKey = (params: GetRowIdParams): string =>
    getKeyForETagTemplateDataSet(params.data);

  return (
    <Layout>
      <SeparatedRowLayout>
        {toEntity && toEntity.entity_code ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            timeZone={timeZone}
            toEntity={toEntity}
          />
        ) : null}
        {isEmbeddedTitle ? null : (
          <Title>
            <div>{toEntity.entity_code}</div>
          </Title>
        )}
      </SeparatedRowLayout>
      <>
        <ErrorMessage maxWidth='100%' topMargin={0}>
          {errorMessage}
        </ErrorMessage>
        <TemplatesActionBar
          activeOptions={FILTER_ACTIVE_OPTIONS}
          contactInfo={toEntityUserState?.userUiConfig?.contactInfo}
          currentFilter={internalFilter}
          encodedPermissionsId={encodeIds([encodedPermissionsId, 'actionBar'])}
          isEmbeddedTitle={isEmbeddedTitle}
          isTableLoading={isLoading}
          onActivateSuccess={handleActivateSuccess}
          onClearAllFilters={handleClearAllFilters}
          onDelete={handleDelete}
          onRefresh={handleRefresh}
          onSaveFilter={handleSaveFilter}
          selectedActiveFilter={selectedActiveFilter}
          selectedGroupFilter={selectedTemplateGroup}
          selectedTemplate={selectedTemplate}
          setActiveFilter={setSelectedActiveFilter}
          setFilter={setInternalFilter}
          setSelectedTemplateGroup={setSelectedTemplateGroup}
          templateGroupOptions={templateGroupOptions}
          timeZone={timeZone}
          toEntity={toEntity}
        />
        {transformedColumns.length > 0 ? (
          <AGDataTable
            className={'template-data-table'}
            clearFilters={clearGridFilters}
            columns={transformedGridColumns}
            data={sortedData}
            isLoading={isLoading || !finishedInitialLoad}
            maxHeight={`calc(${maxHeight} - ${
              TO_ENTITY_TITLE_HEIGHT_VALUE +
              ACTION_BAR_HEIGHT_VALUE +
              LAYOUT_PADDING_VALUE +
              LAYOUT_PADDING_VALUE
            }px)`}
            onRowClick={handleRowClick}
            getRowId={rowKey}
            setClearFilters={setClearGridFilters}
            timeZone={timeZone}
          />
        ) : null}
      </>
    </Layout>
  );
};

export default withFloatOver(TemplateMonitor);

import { ERetreiveState } from 'enums/General';
import {
  ITenantToEntities,
  IToEntityUiConfiguration,
  IUserUiConfiguration,
} from 'interfaces/Config';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';

export enum EUserAction {
  RetrieveUserInfoStart = 'RETRIEVE_USER_INFO_START',
  RetrieveUserInfoSuccess = 'RETRIEVE_USER_INFO_SUCCESS',
  RetrieveUserInfoFailure = 'RETRIEVE_USER_INFO_FAILURE',
  RetrieveToEntityUiConfigStart = 'RETRIEVE_TO_ENTITY_UI_CONFIG_START',
  RetrieveToEntityUiConfigSuccess = 'RETRIEVE_TO_ENTITY_UI_CONFIG_SUCCESS',
  RetrieveToEntityUiConfigFailure = 'RETRIEVE_TO_ENTITY_UI_CONFIG_FAILURE',
  SetSelectedTimeZone = 'SET_SELECTED_TIME_ZONE',
  SetSelectedToEntity = 'SET_SELECTED_TO_ENTITY',
  SetContactInfo = 'SET_CONTACT_INFO',
}

export type TToEntityUserStates = Partial<
  Record<TToEntityId, IToEntityUserState>
>;

export interface IUserState {
  errorMessage: TErrorMessage;
  retrievingUserInfo: ERetreiveState;
  toEntities: IToEntity[];
  selectedToEntity: IToEntity | undefined;
  tenantToEntities: ITenantToEntities[];
  toEntityUserStates: TToEntityUserStates;
}

export interface IToEntityUserState {
  errorMessage?: TErrorMessage;
  retrieving: ERetreiveState;
  selectedTimeZone?: TTimeZone;
  toEntityUiConfig?: IToEntityUiConfiguration;
  userUiConfig?: IUserUiConfiguration;
}

export interface IUserActionPayload {}

export interface IUserInfoRequest extends IUserActionPayload {}

interface IRetrieveUserInfoStartAction {
  payload: IUserInfoRequest;
  type: EUserAction.RetrieveUserInfoStart;
}

export interface IUserInfoSuccessReply extends IUserActionPayload {
  toEntities: IToEntity[];
  tenantToEntities: ITenantToEntities[];
}

interface IRetrieveUserInfoSuccessAction {
  payload: IUserInfoSuccessReply;
  type: EUserAction.RetrieveUserInfoSuccess;
}

export interface IUserInfoFailureReply extends IUserActionPayload {
  errorMessage: string;
}

interface IRetrieveUserInfoFailureAction {
  payload: IUserInfoFailureReply;
  type: EUserAction.RetrieveUserInfoFailure;
}

export interface IUserToEntityUiActionPayload extends IUserActionPayload {
  toEntityId: TToEntityId;
}

export interface IToEntityUiConfigRequest
  extends IUserToEntityUiActionPayload {}

interface IRetrieveToEntityUiConfigStartAction {
  payload: IToEntityUiConfigRequest;
  type: EUserAction.RetrieveToEntityUiConfigStart;
}

export interface IToEntityUiConfigSuccessReply
  extends IUserToEntityUiActionPayload {
  toEntityUiConfiguration: IToEntityUiConfiguration;
  userUiConfiguration: IUserUiConfiguration;
}

interface IRetrieveToEntityUiConfigSuccessAction {
  payload: IToEntityUiConfigSuccessReply;
  type: EUserAction.RetrieveToEntityUiConfigSuccess;
}

export interface IToEntityUiConfigFailureReply
  extends IUserToEntityUiActionPayload {
  errorMessage: string;
}

interface IRetrieveToEntityUiConfigFailureAction {
  payload: IToEntityUiConfigFailureReply;
  type: EUserAction.RetrieveToEntityUiConfigFailure;
}

export interface IUserSelectedTimeZone extends IUserToEntityUiActionPayload {
  selectedTimeZone: TTimeZone;
}

interface ISetUserInfoSelectedTimeZoneAction {
  payload: IUserSelectedTimeZone;
  type: EUserAction.SetSelectedTimeZone;
}

export interface IUserSelectedToEntity extends IUserActionPayload {
  selectedToEntity: IToEntity | undefined;
}

interface ISetUserInfoSelectedToEntityAction {
  payload: IUserSelectedToEntity;
  type: EUserAction.SetSelectedToEntity;
}

export interface IUserContactInfo extends IUserActionPayload {
  contactInfo: IContactInfo;
}

export interface IToEntityLossesConfig {
  toEntity: string;
  showLosses: boolean;
}

interface ISetUserInfoContactInfoAction {
  payload: IUserContactInfo;
  type: EUserAction.SetContactInfo;
}

// Actions
export type TUserAction =
  | IRetrieveUserInfoStartAction
  | IRetrieveUserInfoSuccessAction
  | IRetrieveUserInfoFailureAction
  | IRetrieveToEntityUiConfigStartAction
  | IRetrieveToEntityUiConfigSuccessAction
  | IRetrieveToEntityUiConfigFailureAction
  | ISetUserInfoSelectedTimeZoneAction
  | ISetUserInfoSelectedToEntityAction
  | ISetUserInfoContactInfoAction;

export enum EAdHocFilter {
  BooleanIsNotOneOf = 'booleanIsNotOneOf',
  BooleanIsOneOf = 'booleanIsOneOf',
  CaseInsensitiveDoesNotEqual = 'caseInsensitiveDoesNotEqual',
  CaseInsensitiveEquals = 'caseInsensitiveEquals',
  Contains = 'contains',
  DoesNotContain = 'doesNotContain',
  DoesNotEndWith = 'doesNotEndWith',
  DoesNotEqual = 'doesNotEqual',
  DoesNotStartWith = 'doesNotStartWith',
  EndsWith = 'endsWith',
  Equals = 'equals',
  InclusiveIsBetween = 'inclusiveIsBetween',
  InclusiveIsWithin = 'inclusiveIsWithin',
  IsAfter = 'isAfter',
  IsBefore = 'isBefore',
  IsBetween = 'isBetween',
  IsBlank = 'isBlank',
  IsGreaterThan = 'isGreaterThan',
  IsGreaterThanOrEqual = 'isGreaterThanOrEqual',
  IsLessThan = 'isLessThan',
  IsLessThanOrEqual = 'isLessThanOrEqual',
  IsNotBlank = 'isNotBlank',
  IsNotOneOf = 'isNotOneOf',
  IsOneOf = 'isOneOf',
  IsSame = 'isSame',
  IsSameOrAfter = 'isSameOrAfter',
  IsSameOrBefore = 'isSameOrBefore',
  IsWithin = 'isWithin',
  NumberDoesNotEqual = 'numberDoesNotEqual',
  NumberEquals = 'numberEquals',
  StartsWith = 'startsWith',
}

export enum EAdHocFilterConfigurationType {
  Blank = 'blank',
  Boolean = 'boolean',
  Date = 'date',
  Dates = 'dates',
  Enumerations = 'enumerations',
  Number = 'number',
  Numbers = 'numbers',
  String = 'string',
}

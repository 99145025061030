export enum EViewResize {
  Down = 0,
  Full = 1,
  Initial = 2,
}

export enum EViewMode {
  EditETagAdjustment = 'EDIT_ETAG_ADJUSTMENT',
  EditETagAdjustmentWithATF = 'EDIT_ETAG_ADJUSTMENT_WITH_ATF',
  EditETagCorrection = 'EDIT_ETAG_CORRECTION',
  EditETagDraft = 'EDIT_ETAG_DRAFT',
  EditETagTemplate = 'EDIT_ETAG_TEMPLATE',
  None = 'NONE',
  ReviewETag = 'REVIEW_ETAG',
  ReviewETagDraft = 'REVIEW_ETAG_DRAFT',
  ReviewETagTemplate = 'REVIEW_ETAG_TEMPLATE',
}

export enum EDisplayView {
  Graph = 'graph',
  Grid = 'grid',
}

import { EAlertAcknowledged } from 'enums/Alert';
import { ESeverity } from 'enums/General';
import { TAlertRuleId } from 'types/Alert';
import { TNextToken } from 'types/Summary';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const ALERTS_RULES_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/alerts/rules`;

export const ALERTS_ACKNOWLEDGE_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/alerts/ack`;

export const ALERTS_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  alertSeverities: ESeverity[],
  acknowledgeStatus: EAlertAcknowledged,
  alertRuleIds: TAlertRuleId[],
  cutOffTimeInMinutes: number[],
  next_token: TNextToken | undefined,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/alerts?start=${encodeURIComponent(
    start.toIsoString(),
  )}&end=${encodeURIComponent(end.toIsoString())}${
    alertSeverities.length === 0 ? '' : `&severity=${alertSeverities.join(',')}`
  }&acknowledged=${encodeURIComponent(acknowledgeStatus)}${
    alertRuleIds.length === 0 ? '' : `&alertRuleId=${alertRuleIds.join(',')}`
  }${
    cutOffTimeInMinutes.length === 0
      ? ''
      : `&cutOffTimeInMinute=${cutOffTimeInMinutes.join(',')}`
  }${isEmptyValue(next_token) ? '' : `&nextToken=${next_token}`}`;

export const ALERTS_USER_CONFIG_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/alerts/userConfig`;

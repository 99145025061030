import { PLACEHOLDER_CUSTOM_FILTER_ID_PREFIX } from 'components/organisms/ToEntityCustomFilterConfigurator/constants';
import { IEditableOption, IOption } from 'interfaces/Component';
import { ICustomFilter } from 'interfaces/Filter';
import { IIndexable } from 'interfaces/General';
import { TFilterId } from 'types/Filter';
import { copyCustomFilter, generateCustomFilter } from 'utils/filter';

export const generateNewPlaceholderCustomFilterId = (value: number): string =>
  `${PLACEHOLDER_CUSTOM_FILTER_ID_PREFIX}${value}`;

export const isPlaceholderCustomFilterId = (
  filterId: TFilterId | null,
): boolean =>
  filterId === null
    ? false
    : filterId.startsWith(PLACEHOLDER_CUSTOM_FILTER_ID_PREFIX);

export const generateNewConfiguration = (filterName: string): ICustomFilter => {
  const newConfiguration: ICustomFilter = generateCustomFilter();

  newConfiguration.filter_name = filterName;

  return newConfiguration;
};

export const getNewConfiguration = (filterName: string) => (): ICustomFilter =>
  generateNewConfiguration(filterName);

export const generateEditableCustomFilterOption = (
  customFilterOption: IOption<ICustomFilter>,
): IEditableOption<ICustomFilter> => ({
  isUnsaved: false,
  label: customFilterOption.label,
  value: copyCustomFilter(customFilterOption.value),
});

export const copyEditableCustomFilterOption = (
  editableCustomFilterOption: IEditableOption<ICustomFilter>,
): IEditableOption<ICustomFilter> => {
  const { isUnsaved, label, value } = editableCustomFilterOption;
  const updatedValue: ICustomFilter = copyCustomFilter(value);

  updatedValue.filter_name = label;

  return {
    isUnsaved,
    label,
    value: updatedValue,
  };
};

export const isCustomFilterConfigured = (
  customFilter: ICustomFilter & IIndexable,
): boolean =>
  Object.keys(customFilter).find(
    (key: string): boolean =>
      key !== 'filter_id' &&
      key !== 'filter_name' &&
      customFilter[key] !== null,
  ) !== undefined;

const andReducer = (accumulator: boolean, currentValue: boolean): boolean =>
  accumulator && currentValue;

export const isCustomFilterEqual = (
  a: ICustomFilter,
  b: ICustomFilter,
): boolean =>
  a.attribute === b.attribute &&
  a.filter_id === b.filter_id &&
  a.filter_name === b.filter_name &&
  ((a.sub_filter === null && b.sub_filter === null) ||
    (a.sub_filter !== null &&
      b.sub_filter !== null &&
      isCustomFilterEqual(a.sub_filter, b.sub_filter))) &&
  ((a.sub_filter_list === null && b.sub_filter_list === null) ||
    (a.sub_filter_list !== null &&
      b.sub_filter_list !== null &&
      a.sub_filter_list.length === b.sub_filter_list.length &&
      (a.sub_filter_list.length === 0 ||
        a.sub_filter_list
          .map((customFilter: ICustomFilter, index: number): boolean =>
            isCustomFilterEqual(customFilter, b.sub_filter_list![index]),
          )
          .reduce(andReducer)))) &&
  a.type === b.type &&
  a.value === b.value;

import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IPageContentProps } from 'components/organisms/Page/Page';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import ETagCommonReasonNotesConfiguration from 'components/pages/ApprovalConfigsPage/ETagCommonReasonNotesConfiguration/ETagCommonReasonNotesConfiguration';
import ValidationReportsConfiguration from 'components/molecules/ValidationReportsConfiguration/ValidationReportsConfiguration';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING } from 'constants/styles';
import { IETagReasons } from 'interfaces/ETag';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { userSetSelectedToEntity } from 'reduxes/User/actions';
import { updateReasons } from 'services/approval/approval';
import styled from 'styled-components';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';
import { CONFIRM_MESSAGE_BEFORE_UNLOAD } from './constants';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';
import { TTimeZone } from '../../../types/DateTime';
import { getToEntityUserState } from '../../../utils/user';
import { ZonedDateTime } from '../../../utils/zonedDateTime';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  padding: ${STANDARD_SPACING};

  > :not(:first-child) {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntitySelectionBar = styled(SeparatedRowLayout)`
  flex-shrink: 0;
  height: 40px;
`;

interface IApprovalConfigsPageContentProps
  extends IPageContentProps<undefined> {}

const ApprovalConfigsPageContent = ({
  userInfo,
}: IApprovalConfigsPageContentProps): JSX.Element => {
  const dispatch = useDispatch();
  const { selectedToEntity, toEntities, toEntityUserStates } = userInfo;
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const history = useHistory();
  const timeZone: TTimeZone =
    getToEntityUserState(selectedToEntity, toEntityUserStates)
      ?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();

  useEffect(() => {
    if (selectedToEntity === undefined && toEntities[0]) {
      const firstToEntity = toEntities[0];
      dispatch(userSetSelectedToEntity({ selectedToEntity: firstToEntity }));
      setIsDirty(false);
    }
  }, [dispatch, toEntities, selectedToEntity]);

  const saveToAll = async (reasons: IETagReasons) => {
    return Promise.all(
      toEntities.map(({ to_entity }) =>
        updateReasons(to_entity as TToEntityId, reasons),
      ),
    );
  };

  const blockHandler = useCallback(() => {
    if (isDirty) {
      if (window.confirm(CONFIRM_MESSAGE_BEFORE_UNLOAD)) {
        // leave
        // Resets isDirty
        setIsDirty(false);

        if (toEntities[0] !== selectedToEntity) {
          dispatch(
            userSetSelectedToEntity({ selectedToEntity: selectedToEntity }),
          );
        }

        // Block handler is expected to return anything but false when we
        // want to allow navigation to proceed.
        return;
      }
      // retain state
      return false;
    }
  }, [setIsDirty, isDirty, selectedToEntity, dispatch, toEntities]);

  useEffect(() => {
    // React router needs to have an explicit handler for preventing navigation
    // since it by-passes the standard browser navigation and thus does NOT
    // trigger the beforeunload event.
    const unblock = history.block(blockHandler);

    const unloadHandler = (event: Event) => {
      event.preventDefault();
      // noinspection JSDeprecatedSymbols
      (event || window.event).returnValue = true;
      return '';
    };

    if (isDirty) {
      window.addEventListener('beforeunload', unloadHandler);
    }

    return () => {
      unblock();
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [blockHandler, history, isDirty, selectedToEntity]);

  const onConfirm = useCallback(
    () => (isDirty ? window.confirm(CONFIRM_MESSAGE_BEFORE_UNLOAD) : true),
    [isDirty],
  );

  const encodedPermissionsId = selectedToEntity
    ? encodeIds(['eTagApprovalConfigs'], selectedToEntity.to_entity)
    : undefined;

  return (
    <Layout>
      <SeparatedRowLayout>
        {selectedToEntity && selectedToEntity.to_entity ? (
          <TopBarMenu
            encodedPermissionsId={encodedPermissionsId}
            timeZone={timeZone}
            toEntity={selectedToEntity}
          />
        ) : null}
        <ToEntitySelectionBar>
          <UserToEntitySelection onConfirmBeforeChange={onConfirm} />
        </ToEntitySelectionBar>
      </SeparatedRowLayout>
      {selectedToEntity === undefined ? null : (
        <>
          <ETagCommonReasonNotesConfiguration
            {...{
              selectedToEntity,
              saveToAll,
              setIsDirty,
              isDirty,
              entityCount: toEntities.length,
              encodedPermissionsId: encodeIds(
                ['eTagApprovalConfigs'],
                `${selectedToEntity.to_entity}`,
              ),
              onConfirmBeforeReload: onConfirm,
            }}
          />
          <ValidationReportsConfiguration
            encodedPermissionsId={encodeIds(
              ['eTagFailedValidationsReportConfiguration'],
              selectedToEntity.to_entity,
            )}
            toEntity={selectedToEntity}
          />
        </>
      )}
    </Layout>
  );
};

export default ApprovalConfigsPageContent;

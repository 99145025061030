export const AGENT_SERVICE_API_NAME = 'agent';
export const ALERT_SERVICE_API_NAME = 'alert';
export const AUTH_SERVICE_API_NAME = 'auth';
export const CONFIG_CLIENT_SERVICE_API_NAME = 'configclient';
export const NAESB_REGISTRY_SERVICE_API_NAME = 'naesb-registry';
export const QUERY_SERVICE_API_NAME = 'query';
export const APPROVAL_SERVICE_API_NAME = 'approval';

export const DEFAULT_OPTIONS = {
  response: true,
};

import {
  AlertOutlined,
  CheckCircleOutlined,
  DashboardOutlined,
  FileDoneOutlined,
  FileSearchOutlined,
  FormatPainterOutlined,
  MessageOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  TableOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IndustryBackground from 'components/atoms/IndustryBackground/IndustryBackground';
import NavigationActions from 'components/atoms/NavigationActions/NavigationActions';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Spinner from 'components/atoms/Spinner/Spinner';
import ToEntityNoticesHistory from 'components/molecules/ToEntityNoticesHistory/ToEntityNoticesHistory';
import UserButton from 'components/molecules/UserButton/UserButton';
import CreateTagPopover from 'components/pages/LandingPage/CreateTagPopover';
import FutureLink from 'components/pages/LandingPage/FutureLink';
import { bgColorForTheme } from 'components/pages/LandingPage/helpers';
import LinkBox from 'components/pages/LandingPage/LinkBox';
import NameAndDescription from 'components/pages/LandingPage/NameAndDescription';
import PageLinkBox from 'components/pages/LandingPage/PageLinkBox';
import PciLogo from 'components/pages/LandingPage/PciLogo';
import SearchTagPopover from 'components/pages/LandingPage/SearchTagPopover';
import { EXTERNAL_LINKS_MAP } from 'constants/General';
import {
  ALERT_RULES_ROUTE,
  APPROVAL_CONFIGS_ROUTE,
  APPROVAL_ROUTE,
  AUDIT_ROUTE,
  SUMMARY_OPTIONS_ROUTE,
  SUMMARY_ROUTE,
  HELP_ROUTE,
  TEMPLATES_ROUTE,
  TRANSMISSION_CAPACITY_AVAILABILITY_ROUTE,
} from 'constants/routes';
import {
  NAVIGATION_ICON_SPACING,
  NAVIGATION_ICON_WIDTH_VALUE,
} from 'constants/styles';
import { EExternalLinks } from 'enums/General';
import useUserInfo from 'hooks/useUserInfo';
import { IRouteProps, IThemedProps } from 'interfaces/Component';
import { useEffect } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TThemeValue } from 'types/Style';
import { retrieveToEntityTimeZone } from 'utils/user';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Content = styled.div`
  align-items: center;
  align-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100vh;
  justify-content: center;
  padding: 100px calc(10vw);
  width: 100%;
`;

const Banner = styled.div<IThemedProps>`
  align-items: center;
  background-color: ${bgColorForTheme};
  display: flex;
  flex-direction: row;
  height: 40px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;

// User Guide (1) + Home Icon(1) + ThemeToggle(2) = 4
const PushRightValue = `${
  (NAVIGATION_ICON_WIDTH_VALUE + NAVIGATION_ICON_SPACING) * 4
}px`;

const LandingPage = ({
  appActions,
  setUserGuideExternalLink,
}: IRouteProps): JSX.Element => {
  const {
    errorMessage,
    isLoading,
    selectedToEntity,
    toEntities,
    toEntityUserStates,
    selectTenantToEntity,
  } = useUserInfo();

  const timeZone: TTimeZone = useSelector(
    retrieveToEntityTimeZone(selectedToEntity),
  );
  const themeSwitcher = useThemeSwitcher();
  const currentTheme: TThemeValue = themeSwitcher.currentTheme as TThemeValue;

  useEffect(() => {
    document.title = 'PCI e-Tag+';
  }, []);

  useEffect(() => {
    setUserGuideExternalLink(EXTERNAL_LINKS_MAP[EExternalLinks.UserGuide]);
  }, [setUserGuideExternalLink]);

  return selectTenantToEntity ? (
    <>{selectTenantToEntity()}</>
  ) : (
    <>
      <Layout>
        {errorMessage === null ? (
          <>
            {isLoading ? (
              <Spinner />
            ) : (
              <IndustryBackground>
                <Banner currentTheme={currentTheme}>
                  <PciLogo currentTheme={currentTheme} />
                </Banner>
                <NavigationActions right={PushRightValue}>
                  <SeparatedRowLayout>
                    <ToEntityNoticesHistory
                      permissionId='landingLink:displayNotices'
                      timeZone={timeZone}
                      currentTheme={currentTheme}
                      toEntities={toEntities}
                    />
                    <UserButton
                      currentTheme={currentTheme}
                      userState={toEntityUserStates}
                    />
                  </SeparatedRowLayout>
                </NavigationActions>
                <Content>
                  <CreateTagPopover
                    permissionId='landingLink:createTag'
                    selectedToEntity={selectedToEntity}
                    timeZone={timeZone}
                    toEntities={toEntities}
                  >
                    <LinkBox implemented={true} currentTheme={currentTheme}>
                      <PlusCircleOutlined />
                      <NameAndDescription
                        description={['Create tags from scratch']}
                        name='Create Tag'
                      />
                    </LinkBox>
                  </CreateTagPopover>
                  <SearchTagPopover
                    permissionId='landingLink:searchTag'
                    selectedToEntity={selectedToEntity}
                    timeZone={timeZone}
                    toEntities={toEntities}
                  >
                    <LinkBox implemented={true} currentTheme={currentTheme}>
                      <FileSearchOutlined />
                      <NameAndDescription
                        description={[
                          'Search tag by tag id',
                          'Search tag by tag code',
                        ]}
                        name='Search Tag'
                      />
                    </LinkBox>
                  </SearchTagPopover>
                  <PageLinkBox
                    description={[
                      'Review E-Tag summaries',
                      'Monitor E-Tag alerts',
                      'Search and find E-Tag details',
                      'Copy existing E-Tags',
                    ]}
                    icon={<TableOutlined />}
                    name='Tag Summaries'
                    permissionId='landingLink:summary'
                    target={SUMMARY_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <PageLinkBox
                    description={[
                      'Review all E-Tag templates',
                      'Create E-Tag from template',
                    ]}
                    icon={<FormatPainterOutlined />}
                    name='Tag Templates'
                    permissionId='landingLink:templates'
                    target={TEMPLATES_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <PageLinkBox
                    description={['Review available Transmission Capacities']}
                    icon={<DashboardOutlined />}
                    name='Transmission Availability Report'
                    permissionId='landingLink:transmissionAvailability'
                    target={TRANSMISSION_CAPACITY_AVAILABILITY_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <PageLinkBox
                    description={[
                      'Review and update summary screen configuration',
                      'Update default tag code scheme',
                    ]}
                    icon={<SettingOutlined />}
                    name='E-Tag Screen Configuration'
                    permissionId='landingLink:summaryOptions'
                    target={SUMMARY_OPTIONS_ROUTE}
                    toEntities={toEntities}
                    currentTheme={currentTheme}
                  />
                  <FutureLink
                    description={['Review E-Tag events audits']}
                    icon={<MessageOutlined />}
                    name='E-Tag Audit'
                    permissionId='landingLink:audit'
                    target={AUDIT_ROUTE}
                    toEntities={toEntities}
                    currentTheme={currentTheme}
                  />
                  <PageLinkBox
                    description={['Review and update E-Tag alert rules']}
                    icon={<AlertOutlined />}
                    name='E-Tag Alert Rule Configuration'
                    permissionId='landingLink:alertRules'
                    target={ALERT_RULES_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <FutureLink
                    description={[
                      'Monitor E-Tag requests',
                      'Approve/deny/study E-Tag requests',
                    ]}
                    icon={<CheckCircleOutlined />}
                    name='Tag Approval'
                    permissionId='landingLink:approval'
                    target={APPROVAL_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <PageLinkBox
                    description={[
                      'Review and update E-Tag auto denial/approval rules',
                    ]}
                    icon={<FileDoneOutlined />}
                    name='E-Tag Approval Configuration'
                    permissionId='landingLink:approvalRules'
                    target={APPROVAL_CONFIGS_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                  <PageLinkBox
                    description={['Tutorial videos']}
                    icon={<QuestionCircleOutlined />}
                    name='Help'
                    permissionId='landingLink:help'
                    target={HELP_ROUTE}
                    currentTheme={currentTheme}
                    toEntities={toEntities}
                  />
                </Content>
              </IndustryBackground>
            )}
          </>
        ) : (
          <ErrorMessage>{errorMessage}</ErrorMessage>
        )}
      </Layout>
      {appActions}
    </>
  );
};

export default LandingPage;

import { NO_OP_HANDLER } from 'constants/General';
import { Context, createContext, ReactNode, useState } from 'react';

export interface IAnimationContext {
  shouldAnimate: boolean;
  setShouldAnimate: (shouldAnimate: boolean) => void;
}

export const AnimationContext: Context<IAnimationContext> =
  createContext<IAnimationContext>({
    shouldAnimate: false,
    setShouldAnimate: NO_OP_HANDLER,
  });

interface IAnimationProviderProps {
  children: ReactNode;
}

const AnimationProvider = (props: IAnimationProviderProps): JSX.Element => {
  const { children } = props;
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(false);

  return (
    <AnimationContext.Provider value={{ shouldAnimate, setShouldAnimate }}>
      {children}
    </AnimationContext.Provider>
  );
};

export default AnimationProvider;

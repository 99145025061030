import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { IAlertConfiguration } from 'interfaces/Alert';
import { ALERTS_USER_CONFIG_ROUTE } from 'services/alert/constants';
import { ALERT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TToEntityId } from 'types/ToEntity';

export const retrieveAlertConfigurations = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse> => {
  return await API.get(
    ALERT_SERVICE_API_NAME,
    ALERTS_USER_CONFIG_ROUTE(toEntityId),
    DEFAULT_OPTIONS,
  );
};

export const updateAlertConfigurations = async (
  toEntityId: TToEntityId,
  alertConfigurations: IAlertConfiguration[],
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: alertConfigurations,
  };

  return await API.put(
    ALERT_SERVICE_API_NAME,
    ALERTS_USER_CONFIG_ROUTE(toEntityId),
    options,
  );
};

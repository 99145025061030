export const DEFAULT_NODE_SIZE_VALUE = 27;

export const GENERATION_BLOCK_WIDTH_VALUE = 110;

export const TRANSMISSION_BLOCK_WIDTH_VALUE = 167;

export const LOAD_BLOCK_WIDTH_VALUE = 99;

export const SHORT_CONNECTOR_WIDTH_VALUE = 10;

export const NODE_LABEL_MAX_WIDTH_VALUE = 73;

export const CONNECTOR_HEIGHT_VALUE = 7;

export const INDICATOR_RIGHT_OFFSET_VALUE = 6;

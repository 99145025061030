import { TTimeZoneOptions } from 'types/DateTime';

export const DAY_FORMAT = 'MM/DD';

export const DATE_FORMAT = 'MM/DD/YYYY';

export const TIME_FORMAT = 'HH:mm';

export const DETAILED_TIME_FORMAT = 'HH:mm:ss';

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export const DETAILED_DATE_TIME_FORMAT = `${DATE_FORMAT} ${DETAILED_TIME_FORMAT}`;

export const CSV_DATE_FORMAT = 'YYYY-MM-DD';

export const CSV_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const FILENAME_DATE_TIME_FORMAT = 'MMDDYYHHmmss';

export const FILENAME_SELECTED_DATE_TIME_FORMAT = 'MMDDYY_HH';

export const ALL_TIME_ZONES = [
  'America/Los_Angeles',
  'America/Phoenix',
  'America/Chicago',
  'America/Denver',
  'America/Detroit',
  'America/New_York',
  'UTC',
  // Note: This is a special case for allowing (GMT-05:00)
  'America/Jamaica',
] as const;

/**
 * Global list of all time zones supported in this application.
 * Typically user configs limit these time zones to a smaller list.
 */
export const ALL_TIME_ZONE_OPTIONS: TTimeZoneOptions = [
  {
    label: 'Chicago (CPT)',
    value: 'America/Chicago',
  },
  {
    label: 'Detroit (EPT)',
    value: 'America/Detroit',
  },
  {
    label: 'Denver (MPT)',
    value: 'America/Denver',
  },
  {
    label: 'Los Angeles (PPT)',
    value: 'America/Los_Angeles',
  },
  {
    label: 'New York (EPT)',
    value: 'America/New_York',
  },
  {
    label: 'Phoenix (MST)',
    value: 'America/Phoenix',
  },
];

export type TOptionalColumn = string;

export enum EHourlyTransmissionFields {
  HE_01 = 'he_01',
  HE_02 = 'he_02',
  HE_02_EXTRA = 'he_02_extra',
  HE_03 = 'he_03',
  HE_04 = 'he_04',
  HE_05 = 'he_05',
  HE_06 = 'he_06',
  HE_07 = 'he_07',
  HE_08 = 'he_08',
  HE_09 = 'he_09',
  HE_10 = 'he_10',
  HE_11 = 'he_11',
  HE_12 = 'he_12',
  HE_13 = 'he_13',
  HE_14 = 'he_14',
  HE_15 = 'he_15',
  HE_16 = 'he_16',
  HE_17 = 'he_17',
  HE_18 = 'he_18',
  HE_19 = 'he_19',
  HE_20 = 'he_20',
  HE_21 = 'he_21',
  HE_22 = 'he_22',
  HE_23 = 'he_23',
  HE_24 = 'he_24',
}

export enum ETransmissionCapacityDataViews {
  USED_AREF = 'used_aref',
  UNUSED_AREF = 'unused_aref',
  TSN = 'transmission_service_network',
}

export type TTransmissionCapacityDataView =
  | ETransmissionCapacityDataViews.USED_AREF
  | ETransmissionCapacityDataViews.UNUSED_AREF
  | ETransmissionCapacityDataViews.TSN;

import toEntityPermissions from 'data/toEntityPermissions.json';
import { IPermissions } from 'interfaces/Permissions';
import { IPermissionSpecification } from 'interfaces/Summary';
import { IToEntity } from 'interfaces/ToEntity';
import { useSelector } from 'react-redux';
import { TRootState } from 'types/Redux';
import { decodeId, isEmptyValue } from 'utils/general';

export interface IusePermissionsProps {
  encodedPermissionsId?: string;
}

/**
 * Currently supported permission values in file 'data/toEntityPermissions.json'
    "ba-power-write-approve",
    "ba-read-only",
    "ba-read-write-approve",
    "ba-read-write",
    "pcisupport",
    "pse-read-only",
    "pse-power-write",
    "pse-power-write-approve",
    "pse-read-write",
    "pse-read-write-approve",
    "tp-read-only"
 * @param permissionsId key in file 'data/toEntityPermissions.json'
 * @param toEntity entity to check permissions for, will check to_entity_type_role_abbreviation
 */
export const permissionsForIdAndToEntity = (
  permissionsId: string,
  toEntity: IToEntity,
): IPermissions => {
  const permissions: IPermissionSpecification | undefined = (
    toEntityPermissions as Record<string, IPermissionSpecification>
  )[permissionsId];
  const displayList: string[] = permissions?.display ?? [];
  const executeList: string[] = permissions?.execute ?? [];

  const { to_entity_type_role_abbreviation } = toEntity;

  return {
    isDisplayable:
      displayList.includes(to_entity_type_role_abbreviation) ||
      displayList.includes('*'),
    isExecutable:
      executeList.includes(to_entity_type_role_abbreviation) ||
      executeList.includes('*'),
  };
};

export const permissionsForEncodedPermissionsId = (
  encodedPermissionsId: string,
  toEntities: IToEntity[],
): IPermissions => {
  const [toEntityId, permissionId] = decodeId(encodedPermissionsId);

  if (isEmptyValue(toEntityId)) {
    throw new Error(`Invalid permissions id: ${encodedPermissionsId}`);
  }

  const toEntity: IToEntity | undefined = toEntities.find(
    (userToEntity: IToEntity): boolean => userToEntity.to_entity === toEntityId,
  );

  if (toEntity === undefined) {
    // We have an id but don't yet have a toEntity to determine permissions so
    // we'll be cautious and set no permissions.
    return { isDisplayable: false, isExecutable: false };
  } else {
    return permissionsForIdAndToEntity(permissionId, toEntity);
  }
};

export const getPermissions = (
  state: TRootState,
  encodedPermissionsId?: string,
): IPermissions => {
  if (encodedPermissionsId === undefined) {
    return {
      isDisplayable: true,
      isExecutable: true,
    };
  } else {
    const {
      user: { toEntities },
    } = state;
    return permissionsForEncodedPermissionsId(encodedPermissionsId, toEntities);
  }
};

const usePermissions = (encodedPermissionsId?: string): IPermissions =>
  useSelector((state: TRootState) =>
    getPermissions(state, encodedPermissionsId),
  );

export default usePermissions;

import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { ExpandableConfig } from 'antd/lib/table/interface';
import ContainedText from 'components/molecules/ContainedText/ContainedText';
import RequestStatusIcon from 'components/molecules/RequestStatusIcon/RequestStatusIcon';
import RequestTypeIcon from 'components/molecules/RequestTypeIcon/RequestTypeIcon';
import RequestAuditTable from 'components/organisms/RequestsInformationView/RequestAuditTable/RequestAuditTable';
import RequestTable from 'components/organisms/RequestsInformationView/RequestTable/RequestTable';
import { CURRENT_REQUEST_ID } from 'constants/Detail';
import {
  BUTTON_ICON_DIMENSIONS,
  VIEW_TABLE_EXPAND_Z_INDEX,
} from 'constants/styles';
import { ERequestStatus, ERequestType } from 'enums/ETag';
import {
  IApprovalHistoryRecord,
  IRequestAuditDataSet,
  IRequestsDataSet,
} from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import { IETagAuditStatuses } from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { TableComponents } from 'rc-table/lib/interface';
import { HTMLAttributes, MouseEvent } from 'react';
import { IDetailTransactionStatuses } from 'reduxes/Detail/types';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { getRequestKey } from 'utils/detail';
import { getEntityInfoLabel } from 'utils/entity';
import { isEmptyValue } from 'utils/general';
import { toFormattedDateTimeString } from 'utils/time';
import {
  getColumnContactInfoRender,
  getColumnNotesRender,
  getColumnRender,
} from 'utils/views';

const ExpandDownIcon = styled(CaretDownOutlined)`
  position: absolute;
  left: -7px;
  top: -6px;
  z-index: ${VIEW_TABLE_EXPAND_Z_INDEX};

  ${BUTTON_ICON_DIMENSIONS}
`;

const ShrinkDownIcon = styled(CaretUpOutlined)`
  position: absolute;
  left: -7px;
  top: -6px;
  z-index: ${VIEW_TABLE_EXPAND_Z_INDEX};

  ${BUTTON_ICON_DIMENSIONS}
`;

const ExpandIconContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

export const generateRequestsDataTableExpandable = (
  isUnconstrained: boolean,
  toEntityId?: string,
): ExpandableConfig<IRequestsDataSet> => ({
  expandIcon: ({ expanded, onExpand, record }) => {
    const handleClick = (event: MouseEvent<HTMLSpanElement>) => {
      event.stopPropagation();
      onExpand(record, event);
    };

    return (
      <ExpandIconContainer>
        {record.statuses.length > 0 || record.audit.length > 0 ? (
          expanded ? (
            <ShrinkDownIcon onClick={handleClick} />
          ) : (
            <ExpandDownIcon onClick={handleClick} />
          )
        ) : null}
      </ExpandIconContainer>
    );
  },
  expandedRowRender: (record: IRequestsDataSet): JSX.Element => (
    <>
      <RequestAuditTable
        data={record.audit}
        isUnconstrained={isUnconstrained}
        toEntityId={toEntityId}
      />
      <RequestTable data={record.statuses} isUnconstrained={isUnconstrained} />
    </>
  ),
});

interface IRowProps extends HTMLAttributes<HTMLElement> {
  'data-row-key': string;
}

export const getCustomComponents = (): TableComponents<IRequestsDataSet> => ({
  body: {
    row: (props: IRowProps): JSX.Element => {
      let className: string = isEmptyValue(props.className)
        ? ''
        : props.className!;
      // data-row-key is added by Ant Design Table component using the rowKey
      const dataRowKey: string = props['data-row-key'];
      const dataRow: number = parseInt(dataRowKey!, 10);

      if (dataRow % 2 === 1) {
        className += ' row-even';
      }

      return <tr {...props} className={className} />;
    },
  },
});

const getRequestsContactInfosTitle = (record: IRequestsDataSet): string =>
  `Request ${record.request_id} Contact Info`;

const getShowDataCount = (record: IRequestsDataSet): boolean =>
  record.request_id !== CURRENT_REQUEST_ID;

export const getRequestsColumns = (
  isUnconstrained: boolean,
): IViewDataTableColumn<IRequestsDataSet>[] => [
  {
    dataIndex: 'request_id',
    render: (_value: any, record: IRequestsDataSet): JSX.Element => (
      <div>{getRequestKey(record)}</div>
    ),
    title: 'RID',
    width: '48px',
  },
  {
    dataIndex: 'ui_transaction_message_type',
    render: (value: unknown): JSX.Element => (
      <RequestTypeIcon requestType={value as ERequestType | null} />
    ),
    title: 'Type',
    width: '38px',
  },
  {
    dataIndex: 'resolution_status',
    render: (value: unknown): JSX.Element => (
      <IconContainer>
        <RequestStatusIcon requestStatus={value as ERequestStatus | null} />
      </IconContainer>
    ),
    title: 'Status',
    width: '47px',
  },
  {
    dataIndex: 'requestor',
    render: (value: unknown): JSX.Element => (
      <ContainedText
        maxWidth='100%'
        text={value === null ? '' : getEntityInfoLabel(value as IEntityInfo)}
      />
    ),
    title: 'Requestor',
  },
  {
    dataIndex: 'notes',
    render: getColumnNotesRender(isUnconstrained),
    title: 'Notes',
    width: isUnconstrained ? undefined : '44px',
  },
  {
    dataIndex: 'actOnByTime',
    render: getColumnRender(isUnconstrained),
    title: 'Deadline',
    width: '127px',
  },
  {
    dataIndex: 'contact_info',
    render: getColumnContactInfoRender(
      isUnconstrained,
      getShowDataCount,
      getRequestsContactInfosTitle,
    ),
    title: 'Contact',
    width: isUnconstrained ? '33%' : '62px',
  },
];

export const getAuditForTransactionStatus = (
  transactionStatus: IDetailTransactionStatuses,
  auditData: IETagAuditStatuses[],
  approvalHistoryData: IApprovalHistoryRecord[],
  timeZone: TTimeZone,
): IRequestAuditDataSet => {
  const audit: IETagAuditStatuses | undefined = auditData.find(
    (auditRecord: IETagAuditStatuses) =>
      auditRecord.request_id === transactionStatus.request_id.toString() &&
      (auditRecord.correction_id ?? '0') ===
        (transactionStatus.correction_id ?? 0).toString(),
  );
  const approvalHistory: IApprovalHistoryRecord | undefined =
    approvalHistoryData
      .reverse()
      .find(
        (approvalHistoryRecord: IApprovalHistoryRecord) =>
          approvalHistoryRecord.request_id ===
            transactionStatus.request_id.toString() &&
          (approvalHistoryRecord.correction_id ?? '0') ===
            (transactionStatus.correction_id ?? 0).toString(),
      );

  return {
    submitted_by: audit ? audit.created_by : null,
    submitted_on: audit
      ? toFormattedDateTimeString(audit.creation_time, timeZone)
      : null,
    pci_approved_by: approvalHistory ? approvalHistory.created_by : null,
    pci_approved_on: approvalHistory
      ? toFormattedDateTimeString(approvalHistory.creation_time, timeZone)
      : null,
    request_timestamp: transactionStatus.request_timestamp
      ? toFormattedDateTimeString(transactionStatus.request_timestamp, timeZone)
      : null,
  };
};

import { Input as AntDesignInput, Select as AntDesignSelect } from 'antd';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { StyledLabel } from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import { IToEntity } from 'interfaces/ToEntity';
import { ChangeEvent, useState } from 'react';

const { Option } = AntDesignSelect;

export interface ITagCodeSchemeAtom {
  value: string;
  index: number;
}

const CONSTANT_TAG_CODE_SCHEME_PREFIX = '{constant:';
const TAG_CODE_SCHEME_SUFFIX = '}';

const isTagCodeSchemeAtomConstant = (
  schemeAtom: ITagCodeSchemeAtom | undefined,
) => {
  if (schemeAtom === undefined) {
    return false;
  } else {
    return (
      schemeAtom.value.startsWith(CONSTANT_TAG_CODE_SCHEME_PREFIX) &&
      schemeAtom.value.endsWith(TAG_CODE_SCHEME_SUFFIX)
    );
  }
};

const getTagCodeSchemeAtomConstantValue = (
  schemeAtom: ITagCodeSchemeAtom,
): string => {
  return schemeAtom.value.substring(
    CONSTANT_TAG_CODE_SCHEME_PREFIX.length,
    schemeAtom.value.length - 1,
  );
};

// fixed list of atom choices
// we put two different constants with valid defaults so
// user can have more than one constant atom
const tagCodeSchemeOptions = [
  '{seq:4}',
  '{seq:5}',
  '{seq:6}',
  '{seq:7}',
  '{date:1Y2M}',
  '{date:2Y}',
  '{constant:A}',
  '{constant:B}',
].map((value: string) => (
  <Option key={value} value={value}>
    {value}
  </Option>
));

interface IConstantAtomEditorProps {
  constantIndex: number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

const ConstAtomEditor = (props: IConstantAtomEditorProps): JSX.Element => {
  const { value, constantIndex, onChange } = props;

  return (
    <AntDesignInput
      addonBefore={CONSTANT_TAG_CODE_SCHEME_PREFIX}
      addonAfter={TAG_CODE_SCHEME_SUFFIX}
      key={`constant-${constantIndex}`}
      onChange={onChange}
      placeholder='ABC'
      style={{ width: '12em' }}
      value={value}
    />
  );
};

interface IProps {
  defaultTagCodeScheme?: string;
  tagCodeSchemeAtoms: ITagCodeSchemeAtom[];
  toEntity: IToEntity;
  setTagCodeSchemeAtoms: (atoms: ITagCodeSchemeAtom[]) => void;
}

const ETagCodeSchemeAtoms = (props: IProps): JSX.Element => {
  const { defaultTagCodeScheme, setTagCodeSchemeAtoms, tagCodeSchemeAtoms } =
    props;
  // selection errors such as invalid constants like {constant:a-%}
  // set by validation function wrapper around setter for tagCodeSchemeAtoms
  const [tagCodeSchemeErrors, setTagCodeSchemeErrors] = useState<string[]>([]);

  // wrapper around setTagCodeSchemeAtoms which validates constant atoms
  const validateAndSetTagCodeSchemeList = (
    schemeAtoms: ITagCodeSchemeAtom[],
  ) => {
    const schemeErrors = [];
    for (const schemeAtom of schemeAtoms) {
      if (isTagCodeSchemeAtomConstant(schemeAtom)) {
        const constantValue = getTagCodeSchemeAtomConstantValue(schemeAtom);
        if (!constantValue.match(/^[A-Z0-9]+$/)) {
          schemeErrors.push(
            `${constantValue} is not a valid constant value.
             Only uppercase characters A-Z and numbers 0-9 allowed`,
          );
        }
      }
    }
    setTagCodeSchemeErrors(schemeErrors);
    setTagCodeSchemeAtoms(schemeAtoms);
  };

  // update the state when user interacts with the multi-select element
  const handleTagCodeSchemeAtomSelectionChange = (value: string[]) => {
    let changed = value.length !== tagCodeSchemeAtoms.length;
    const newTagCodeSchemeAtoms = [];

    for (let index: number = 0; index < value.length; index += 1) {
      if (
        index >= tagCodeSchemeAtoms.length ||
        value[index] !== tagCodeSchemeAtoms[index].value
      ) {
        changed = true;
        newTagCodeSchemeAtoms[index] = {
          value: value[index],
          index: index,
        };
      } else {
        newTagCodeSchemeAtoms[index] = tagCodeSchemeAtoms[index];
      }
    }
    if (changed) {
      validateAndSetTagCodeSchemeList(newTagCodeSchemeAtoms);
    }
  };

  // update the constant atom at the specified index to the event value
  // this is triggered by edits to an input item separate from the multi-select item
  // this is because we only want the user editing the ABC part of {constant:ABC}
  // not the full text, since that leaves room for more error
  const handleTagCodeSchemeConstantChange =
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      validateAndSetTagCodeSchemeList(
        tagCodeSchemeAtoms.map((atom: ITagCodeSchemeAtom) => {
          if (atom.index === index && isTagCodeSchemeAtomConstant(atom)) {
            return {
              value: `{constant:${event.target.value}}`,
              index: atom.index,
            };
          }
          return atom;
        }),
      );
    };

  return (
    <>
      <StyledLabel>Select scheme atoms:</StyledLabel>
      <SeparatedRowLayout>
        <AntDesignInput
          addonBefore='Current'
          disabled={true}
          style={{ width: '21em' }}
          value={defaultTagCodeScheme ?? '...loading'}
        />
        <AntDesignSelect
          mode='multiple'
          children={tagCodeSchemeOptions}
          onChange={handleTagCodeSchemeAtomSelectionChange}
          style={{ width: '21em' }}
          defaultValue={['{seq:7}']}
          value={tagCodeSchemeAtoms.map(
            (schemAtom: ITagCodeSchemeAtom) => schemAtom.value,
          )}
          placeholder='Select tag code scheme atoms'
        />
        {tagCodeSchemeAtoms
          .filter(isTagCodeSchemeAtomConstant)
          .map(
            (tagCodeSchemeAtom: ITagCodeSchemeAtom, constantIndex: number) => (
              <ConstAtomEditor
                value={getTagCodeSchemeAtomConstantValue(tagCodeSchemeAtom)}
                constantIndex={constantIndex}
                onChange={handleTagCodeSchemeConstantChange(
                  tagCodeSchemeAtom.index,
                )}
              />
            ),
          )}
      </SeparatedRowLayout>
      {tagCodeSchemeErrors.map((errorMsg: string, index: number) => (
        <div key={`tag-code-scheme-error-${index}`}>
          <ErrorMessage>{errorMsg}</ErrorMessage>
        </div>
      ))}
    </>
  );
};

export default ETagCodeSchemeAtoms;

export enum EPseType {
  GPE = 'GPE',
  LSE = 'LSE',
  PSE = 'PSE',
}

export enum EEntityType {
  CA = 'CA',
  GCA = 'GCA',
  LCA = 'LCA',
  MO = 'MO',
  POD = 'POD',
  POR = 'POR',
  PSE = 'PSE',
  SC = 'SC',
  SourceSink = 'SourceSink',
  TP = 'TP',
}

import { FileDoneOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import ErrorMessage from 'components/atoms/ErrorMessage/ErrorMessage';
import IconButton from 'components/atoms/IconButton/IconButton';
import SuccessMessage from 'components/atoms/SuccessMessage/SuccessMessage';
import Modal from 'components/molecules/Modal/Modal';
import {
  checkoutReportSorter,
  getAttributeNamesFromTableConfiguration,
  getCheckoutDataSetFromData,
} from 'components/molecules/ToEntityReportCreateModal/helpers';
import ToEntityReportCreatePanel from 'components/molecules/ToEntityReportCreatePanel/ToEntityReportCreatePanel';
import ToEntityReportReview from 'components/molecules/ToEntityReportReview/ToEntityReportReview';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import useDisplayTimedMessage from 'hooks/useDisplayTimedMessage';
import { IOption } from 'interfaces/Component';
import {
  IETagCheckoutReport,
  IETagCheckoutReportCreateResponse,
  IETagCheckoutReportDataSet,
  IETagCheckoutReportsReponse,
} from 'interfaces/ETag';
import { ICustomFilter } from 'interfaces/Filter';
import { ITableConfiguration } from 'interfaces/Summary';
import React, { useEffect, useState } from 'react';
import {
  createCheckoutReport,
  getCheckoutReports,
} from 'services/agent/tags/reports';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TTableConfigurationOptions } from 'types/Summary';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import MenuItemActionButton from '../../atoms/MenuActionItem/MenuActionItem';
import { useDispatch, useSelector } from 'react-redux';
import { TRootState } from '../../../types/Redux';
import { EMenuActions, TMenuAction } from '../../../reduxes/Menu/actions';

const CreateIcon = styled(FileDoneOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const StyledToEntityReportCreatePanel = styled(ToEntityReportCreatePanel)`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
`;

const StyledReportReviewPanel = styled(ToEntityReportReview)`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  justify-content: right;
`;

const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

interface IToEntityReportCreateProps {
  customFilterOptions: IOption<ICustomFilter>[];
  encodedPermissionsId: string;
  isDisabled?: boolean;
  isMenuItem?: boolean;
  tableConfigurationOptions: TTableConfigurationOptions;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityReportModal = ({
  customFilterOptions,
  encodedPermissionsId,
  isDisabled,
  isMenuItem,
  tableConfigurationOptions,
  timeZone,
  toEntityId,
}: IToEntityReportCreateProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    ZonedDateTime.now(timeZone).startOf('month'),
  );
  const [stopDateTime, setStopDateTime] = useState<ZonedDateTime | null>(null);
  const [selectedFilter, setSelectedFilter] = useState<
    ICustomFilter | undefined
  >();
  const [selectedConfiguration, setSelectedTableConfiguration] = useState<
    ITableConfiguration | undefined
  >(
    tableConfigurationOptions.find(
      (config: IOption<ITableConfiguration>) =>
        config.label === 'All Available Columns',
    )?.value,
  );
  const [checkoutData, setCheckoutData] = useState<IETagCheckoutReport[]>([]);
  const [checkoutDataSet, setCheckoutDataSet] = useState<
    IETagCheckoutReportDataSet[]
  >([]);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const isCreateDisabled: boolean = !(
    startDateTime &&
    stopDateTime &&
    timeZone &&
    selectedConfiguration
  );
  const [createReportIsLoading, setCreateReportIsLoading] =
    useState<boolean>(false);

  const { displayTimedMessage, timedMessage, showTimedMessage } =
    useDisplayTimedMessage();

  const menuState = useSelector((state: TRootState) => state.menu);
  const dispatch = useDispatch();

  const setShowReportsMenuItemClicked = (
    showReports: boolean,
  ): TMenuAction => ({
    payload: showReports,
    type: EMenuActions.ShowReportsItemClicked,
  });

  useEffect(() => {
    if (menuState.showReports && !isVisible) {
      setIsVisible(true);
      dispatch(setShowReportsMenuItemClicked(false));
    }
  }, [dispatch, menuState, isVisible]);

  useEffect(() => {
    if (successMessage) {
      displayTimedMessage(
        <SuccessMessage>{successMessage}</SuccessMessage>,
        5000,
      );
    }
  }, [displayTimedMessage, successMessage]);

  useEffect(() => {
    if (errorMessage) {
      displayTimedMessage(<ErrorMessage>{errorMessage}</ErrorMessage>, 5000);
    }
  }, [displayTimedMessage, errorMessage]);

  const handleRefresh = async () => {
    try {
      setErrorMessage(null);
      const getCheckoutResponse: AxiosResponse<IETagCheckoutReportsReponse> =
        await getCheckoutReports(toEntityId);

      if (!isSuccessStatus(getCheckoutResponse.status)) {
        throw new Error(getCheckoutResponse.data.errorMessage!);
      }

      const checkoutData: IETagCheckoutReport[] =
        getCheckoutResponse.data.response;
      setCheckoutData(checkoutData.sort(checkoutReportSorter));
    } catch (error: any) {
      captureError(error);
    }
  };

  useEffect(() => {
    setCheckoutDataSet(
      getCheckoutDataSetFromData(checkoutData, customFilterOptions, timeZone),
    );
  }, [checkoutData, customFilterOptions, timeZone]);

  useAsyncEffect(async () => {
    handleRefresh();
  }, [isVisible]);

  const handleShow = () => {
    setIsVisible(true);
  };

  const handleHide = () => {
    setErrorMessage(null);
    setSuccessMessage(null);
    setIsVisible(false);
  };

  const handleCreate = async () => {
    if (startDateTime && stopDateTime && timeZone && selectedConfiguration) {
      setCreateReportIsLoading(true);
      try {
        const attributeNames = getAttributeNamesFromTableConfiguration(
          selectedConfiguration,
        );
        const createCheckoutResponse: AxiosResponse<IETagCheckoutReportCreateResponse> =
          await createCheckoutReport(
            toEntityId,
            startDateTime.isoFormat(),
            stopDateTime.isoFormat(),
            timeZone,
            selectedFilter?.filter_id ? [selectedFilter.filter_id] : [],
            attributeNames,
          );

        if (!isSuccessStatus(createCheckoutResponse.status)) {
          setErrorMessage(
            'An error occurred during report creation. Please try again later.',
          );
          throw new Error(createCheckoutResponse.data.errorMessage!);
        } else {
          setStartDateTime(ZonedDateTime.now(timeZone).startOf('month'));
          setStopDateTime(null);
          setSelectedFilter(undefined);
          setSelectedTableConfiguration(
            tableConfigurationOptions.find(
              (config: IOption<ITableConfiguration>) =>
                config.label === 'All Available Columns',
            )?.value,
          );
          setSuccessMessage(
            'Your report will be available shortly. Please check back later.',
          );
          handleRefresh();
        }
      } catch (error: any) {
        captureError(error);
      } finally {
        setCreateReportIsLoading(false);
      }
    }
  };

  const handleTableConfigurationChange = (
    view: ITableConfiguration | undefined,
  ) => {
    setSelectedTableConfiguration(view);
  };

  const handleStartDateTimeChange = (value: ZonedDateTime | null) => {
    setStartDateTime(value);
  };

  const handleStopDateTimeChange = (value: ZonedDateTime | null) => {
    setStopDateTime(value);
  };

  return (
    <>
      <Tooltip isDisabled={isVisible} title='Checkout Reports'>
        {isMenuItem ? (
          <MenuItemActionButton
            encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
            onClick={handleShow}
            type={'reports'}
          />
        ) : (
          <IconButton
            encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
            icon={<CreateIcon />}
            isDisabled={isDisabled}
            onClick={handleShow}
          />
        )}
      </Tooltip>
      <Modal
        footer={null}
        isVisible={isVisible}
        title={'Checkout Reports'}
        width={1048}
        onCancel={handleHide}
      >
        <FlexRow>
          <StyledToEntityReportCreatePanel
            createReport={handleCreate}
            customFilterOptions={customFilterOptions}
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'createReport'],
              toEntityId,
            )}
            isCreateDisabled={isCreateDisabled}
            isLoading={createReportIsLoading}
            onFilterChange={setSelectedFilter}
            onStartDateTimeChange={handleStartDateTimeChange}
            onStopDateTimeChange={handleStopDateTimeChange}
            onTableConfigurationChange={handleTableConfigurationChange}
            startDateTime={startDateTime}
            stopDateTime={stopDateTime}
            selectedFilter={selectedFilter}
            selectedTableConfiguration={selectedConfiguration}
            showTimedMessage={showTimedMessage}
            tableConfigurationOptions={tableConfigurationOptions}
            timedMessage={timedMessage}
            timeZone={timeZone}
          />
          <StyledReportReviewPanel
            data={checkoutDataSet}
            isUnconstrained={false}
            onRefresh={handleRefresh}
            timeZone={timeZone}
          />
        </FlexRow>
      </Modal>
    </>
  );
};

export default ToEntityReportModal;

import { IEditSummaryInformation } from 'components/organisms/SummaryInformationView/types';
import {
  EDIT_ETAG_TAG_ID_LABEL,
  EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL,
} from 'constants/Detail';
import { IETagTagId } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { IDetailState } from 'reduxes/Detail/types';
import { TStateTransform } from 'types/General';
import {
  copyDetailGenerationPhysicalSegment,
  copyDetailLoadPhysicalSegment,
  getEditInfoKey,
  getInitialContactInfo,
  getInitialETagTagId,
} from 'utils/detail';

export const editSummaryInformationToDetailState =
  (
    editSummaryInformation: IEditSummaryInformation,
  ): TStateTransform<IDetailState> =>
  (detailState: IDetailState): IDetailState => {
    const {
      contactInfos,
      description,
      eTagTagIds,
      groupName,
      notes,
      tagHeaderData,
    } = editSummaryInformation;
    const updatedDetailState: IDetailState = { ...detailState };

    if (contactInfos !== undefined) {
      const updatedContactInfo: IContactInfo =
        detailState.contact_info === null
          ? getInitialContactInfo(
              getEditInfoKey(EDIT_SUMMARY_INFORMATION_CONTACT_INFO_LABEL, 0, 0),
            )
          : { ...detailState.contact_info };
      const { contact, fax, phone } = contactInfos[0];

      if (contact !== undefined) {
        updatedContactInfo.contact = contact;
      }

      if (fax !== undefined) {
        updatedContactInfo.fax = fax;
      }

      if (phone !== undefined) {
        updatedContactInfo.phone = phone;
      }

      updatedDetailState.contact_info = updatedContactInfo;
    }

    if (description !== undefined) {
      updatedDetailState.description = description;
    }

    if (groupName !== undefined) {
      updatedDetailState.group_name = groupName;
    }

    if (notes !== undefined) {
      updatedDetailState.notes = notes;
    }

    if (eTagTagIds !== undefined) {
      const updatedTagId: IETagTagId =
        updatedDetailState.tag_id === null
          ? getInitialETagTagId(getEditInfoKey(EDIT_ETAG_TAG_ID_LABEL, 0, 0))
          : { ...updatedDetailState.tag_id };
      const { gca, lca, pse, tag_code } = eTagTagIds[0];

      if (gca !== undefined) {
        updatedTagId.gca = gca;

        // Update gca references
        if (updatedDetailState.generationPhysicalSegment !== null) {
          updatedDetailState.generationPhysicalSegment =
            copyDetailGenerationPhysicalSegment(
              updatedDetailState.generationPhysicalSegment,
            );

          updatedDetailState.generationPhysicalSegment!.gca = gca;

          // generation_source is dependent upon the gca and so must be reset to
          // ensure that the user selects a valid generation_source for the given
          // gca
          updatedDetailState.generationPhysicalSegment!.generation_source =
            null;
        }
      }

      if (lca !== undefined) {
        updatedTagId.lca = lca;

        // Update lca references
        if (updatedDetailState.loadPhysicalSegment !== null) {
          updatedDetailState.loadPhysicalSegment =
            copyDetailLoadPhysicalSegment(
              updatedDetailState.loadPhysicalSegment,
            );

          updatedDetailState.loadPhysicalSegment!.lca = lca;

          // load_sink is dependent upon the lca and so must be reset to ensure
          // that the user selects a valid load_sink for the given lca
          updatedDetailState.loadPhysicalSegment!.load_sink = null;
        }
      }

      if (pse !== undefined) {
        updatedTagId.pse = pse;
      }

      if (tag_code !== undefined) {
        updatedTagId.tag_code = tag_code;
      }

      updatedDetailState.tag_id = updatedTagId;
    }

    if (tagHeaderData !== undefined) {
      const { ccList, test_flag, transaction_type } = tagHeaderData[0];

      if (ccList !== undefined) {
        updatedDetailState.cc_list = ccList.length === 0 ? null : ccList;
      }

      if (test_flag !== undefined) {
        updatedDetailState.test_flag = test_flag;
      }

      if (transaction_type !== undefined) {
        updatedDetailState.transaction_type = transaction_type;
      }
    }

    return updatedDetailState;
  };

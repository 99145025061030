import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  TAG_CODE_SCHEME_SET_ENTITY_DEFAULT_ROUTE,
  TAG_CODE_SCHEME_TEST_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const retrieveDefaultETagCodeScheme = async (
  toEntityId: TToEntityId,
): Promise<AxiosResponse> => {
  return retrieveETagCodeSchemeTestResult(toEntityId, 'default');
};

export const retrieveETagCodeSchemeTestResult = async (
  toEntityId: TToEntityId,
  tagCodeScheme: string,
  testSequenceValue?: number,
  instant?: ZonedDateTime,
  timeZone?: string,
): Promise<AxiosResponse> => {
  const qparams: Record<string, string | number> = {};
  if (testSequenceValue !== undefined) {
    qparams['testSequenceValue'] = testSequenceValue;
  }
  if (instant !== undefined) {
    qparams['instant'] = instant!.isoFormat();
  }
  if (timeZone !== undefined) {
    qparams['timeZone'] = timeZone;
  }
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TAG_CODE_SCHEME_TEST_ROUTE(toEntityId, tagCodeScheme),
    { ...DEFAULT_OPTIONS, queryStringParameters: qparams },
  );
};

export const putETagCodeSchemeDefault = async (
  toEntityId: TToEntityId,
  tagCodeScheme: string,
  initialSequenceValue?: number,
): Promise<AxiosResponse> => {
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TAG_CODE_SCHEME_SET_ENTITY_DEFAULT_ROUTE(toEntityId),
    {
      ...DEFAULT_OPTIONS,
      body: {
        tagCodeScheme: tagCodeScheme,
        initialSequenceValue: initialSequenceValue,
      },
    },
  );
};

import { IETagTransmissionAllocation } from 'interfaces/ETag';

export enum EBlockType {
  Generation = 'generation',
  Transmission = 'transmission',
  Load = 'load',
}

export enum ENodePosition {
  Left = 'left',
  Right = 'right',
}

export interface ILabelledValue {
  label: string;
  value: string | null;
}

export interface ITransmissionMW {
  lossMW: number | null;
  podMW: number | null;
  totalMW: number | null;
}

export interface IPathDiagramBlockData {
  labelledValues: ILabelledValue[];
  leftNodeLabel?: string | null;
  lossAccountingMethodNames?: string[];
  mw: number | null;
  oasisInfos?: IETagTransmissionAllocation[];
  physical_segment_id: number;
  rightNodeLabel?: string | null;
  transmissionMW?: ITransmissionMW;
  type: EBlockType;
}

import { ELossMethodEntryType } from 'enums/ETag';
import { IEditETagTagIds } from 'interfaces/ETag';
import { IEditContracts } from 'interfaces/General';

export enum ELossMethodsExpandedColumn {
  Contracts = 'Contracts',
  ETagTagIds = 'ETagTagIds',
  None = 'None',
}

export interface IEditLossMethod {
  editContractNumbers?: IEditContracts;
  editTagIds?: IEditETagTagIds;
  loss_method_entry_type?: ELossMethodEntryType | null;
}

export interface IEditLossAccounting {
  editLossMethod?: IEditLossMethod;
  physical_segment_ref?: number | null;
  start?: string | null;
  stop?: string | null;
}

export interface IEditLossMethodsInformation {
  addAfterLossAccounting?: string;
  withPhysicalSegmentRef?: number;
  editLossAccountingMap?: Record<string, IEditLossAccounting>;
  removeLossAccounting?: string;
}

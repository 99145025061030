import { TEnergyProfileChangesMap } from 'components/organisms/DetailChangeRequest/types';
import {
  GENERATION_MARKET_LEVEL_KEY_REG_EXP,
  LOAD_MARKET_LEVEL_KEY_REG_EXP,
  PROFILE_PATH_RAMP_START_KEY,
  PROFILE_PATH_RAMP_STOP_KEY,
  PROFILE_PATH_START_KEY,
  PROFILE_PATH_STOP_KEY,
} from 'constants/Detail';
import { EProfileDataGridCellType } from 'enums/Detail';
import { EEnergyProfileType, ERequestType } from 'enums/ETag';
import { IProfileDataGridCell, IProfileTransmission } from 'interfaces/Detail';
import { IEntityInfo } from 'interfaces/Entity';
import {
  IETagCorrectionRequest,
  IETagEnergyProfileChange,
  IETagEnergyProfileDetail,
  IETagExceptionTransAllocProfileChange,
  IETagLossAccounting,
  IETagMarketSegment,
  IETagPathTransmissionAllocationProfile,
  IETagPhysicalSegmentsProfile,
  IETagProfileChangeRequest,
  IETagTagId,
  IETagTransmissionAllocation,
  IETagTransmissionPhysicalSegment,
  IETagTransmissionSegment,
} from 'interfaces/ETag';
import {
  IContactInfo,
  IContract,
  IEnergyProductInfo,
  IMiscInfo,
} from 'interfaces/General';
import { IPointInfo } from 'interfaces/Point';
import {
  IDetailGenerationPhysicalSegment,
  IDetailLoadPhysicalSegment,
  IDetailLossAccounting,
  IDetailLossMethod,
  IDetailMarketSegment,
  IDetailPhysicalSegment,
} from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import { TProfileDataGridRow } from 'types/Detail';
import { TETagTagPrimaryKey } from 'types/ETag';
import {
  getPhysicalSegmentRefForTransmissionPodkey,
  getProfileTransmissionForKey,
  transformDetailGenerationPhysicalSegment,
  transformDetailLoadPhysicalSegment,
  transformDetailLossAccountingsToETagLossAccountings,
  transformDetailMarketSegment,
  transformTransmissionPhysicalSegments,
} from 'utils/detail';
import { isEmptyValue } from 'utils/general';

const isContractEqual = (a: IContract | null, b: IContract | null): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return a!.contract === b!.contract && a!.key === b!.key;
};

const isContractsEqual = (
  a: IContract[] | null,
  b: IContract[] | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  } else if (a!.length !== b!.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aContract: IContract = a![i];
    const bContract: IContract = b![i];

    if (!isContractEqual(aContract, bContract)) {
      return false;
    }
  }

  return true;
};

const isMiscInfoEqual = (a: IMiscInfo | null, b: IMiscInfo | null): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return a!.key === b!.key && a!.token === b!.token && a!.value === b!.value;
};

const isMiscInfosEqual = (
  a: IMiscInfo[] | null,
  b: IMiscInfo[] | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  } else if (a!.length !== b!.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aMiscInfo: IMiscInfo = a![i];
    const bMiscInfo: IMiscInfo = b![i];

    if (!isMiscInfoEqual(aMiscInfo, bMiscInfo)) {
      return false;
    }
  }

  return true;
};

// We only check the properties which can change in a correction
const isDetailPhysicalSegmentEqual = (
  a: IDetailPhysicalSegment,
  b: IDetailPhysicalSegment,
): boolean =>
  isContractsEqual(a!.contracts, b!.contracts) &&
  isMiscInfosEqual(a!.misc_infos, b!.misc_infos);

const isEntityInfoEqual = (
  a: IEntityInfo | null,
  b: IEntityInfo | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return (
    a!.entity_code === b!.entity_code &&
    a!.entity_type === b!.entity_type &&
    a!.tagging_entity_id === b!.tagging_entity_id
  );
};

const isTagIdEqual = (a: IETagTagId, b: IETagTagId): boolean =>
  isEntityInfoEqual(a.gca, b.gca) &&
  a.key === b.key &&
  isEntityInfoEqual(a.lca, b.lca) &&
  isEntityInfoEqual(a.pse, b.pse) &&
  a.tag_code === b.tag_code &&
  a.tag_primary_key === b.tag_primary_key &&
  a.ui_tag_id === b.ui_tag_id;

const isTagIdsEqual = (
  a: IETagTagId[] | null,
  b: IETagTagId[] | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  } else if (a!.length !== b!.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aTagTagId: IETagTagId = a![i];
    const bTagTagId: IETagTagId = b![i];

    if (!isTagIdEqual(aTagTagId, bTagTagId)) {
      return false;
    }
  }

  return true;
};

const isDetailLossMethodEqual = (
  a: IDetailLossMethod | null,
  b: IDetailLossMethod | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return (
    isContractsEqual(a!.contractNumbers, b!.contractNumbers) &&
    a!.loss_method_entry_type === b!.loss_method_entry_type &&
    isTagIdsEqual(a!.tag_ids, b!.tag_ids)
  );
};

const isDetailLossAccountingEqual = (
  a: IDetailLossAccounting,
  b: IDetailLossAccounting,
): boolean =>
  a.key === b.key &&
  isDetailLossMethodEqual(a.lossMethod, b.lossMethod) &&
  a.physical_segment_ref === b.physical_segment_ref &&
  a.request_ref === b.request_ref &&
  a.start === b.start &&
  a.stop === b.stop;

// We only check the properties which can change in a correction
const isLossAccountingsEqual = (
  a: IDetailLossAccounting[],
  b: IDetailLossAccounting[],
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aDetailLossAccounting: IDetailLossAccounting = a![i];
    const bDetailLossAccounting: IDetailLossAccounting = b![i];

    if (
      !isDetailLossAccountingEqual(aDetailLossAccounting, bDetailLossAccounting)
    ) {
      return false;
    }
  }

  return true;
};

const isEnergyProductInfoEqual = (
  a: IEnergyProductInfo | null,
  b: IEnergyProductInfo | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return (
    a!.product_name === b!.product_name && a!.product_ref === b!.product_ref
  );
};

const isDetailMarketSegmentEqual = (
  a: IDetailMarketSegment,
  b: IDetailMarketSegment,
): boolean =>
  isEnergyProductInfoEqual(a.energy_product_ref, b.energy_product_ref) &&
  isContractsEqual(a.contracts, b.contracts) &&
  isMiscInfosEqual(a.misc_infos, b.misc_infos);

// We only check the properties which can change in a correction
const isMarketSegmentsEqual = (
  a: IDetailMarketSegment[],
  b: IDetailMarketSegment[],
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i: number = 0; i < a.length; i += 1) {
    const aDetailMarketSegment: IDetailMarketSegment = a[i];
    const bDetailMarketSegment: IDetailMarketSegment = b[i];

    if (
      !isDetailMarketSegmentEqual(aDetailMarketSegment, bDetailMarketSegment)
    ) {
      return false;
    }
  }

  return true;
};

// We only check the properties which can change in a correction
const isTransmissionAllocationEqual = (
  a: IETagTransmissionAllocation,
  b: IETagTransmissionAllocation,
): boolean =>
  a.contract_number === b.contract_number &&
  isMiscInfosEqual(a.misc_infos, b.misc_infos) &&
  a.nits_resource === b.nits_resource &&
  isEntityInfoEqual(a.trans_alloc_customer_code, b.trans_alloc_customer_code) &&
  isEnergyProductInfoEqual(a.trans_product_ref, b.trans_product_ref);

// We only check the properties which can change in a correction
const isTransmissionAllocationsEqual = (
  a: IETagTransmissionAllocation[],
  b: IETagTransmissionAllocation[],
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aTransmissionAllocation: IETagTransmissionAllocation = a![i];
    const bTransmissionAllocation: IETagTransmissionAllocation = b![i];

    if (
      !isTransmissionAllocationEqual(
        aTransmissionAllocation,
        bTransmissionAllocation,
      )
    ) {
      return false;
    }
  }

  return true;
};

const isPointInfoEqual = (
  a: IPointInfo | null,
  b: IPointInfo | null,
): boolean => {
  if (a === null && b !== null) {
    return false;
  } else if (a !== null && b === null) {
    return false;
  } else if (a === null && b === null) {
    return true;
  }

  return (
    a!.point_id === b!.point_id &&
    a!.point_name === b!.point_name &&
    a!.point_type === b!.point_type
  );
};

// We only check the properties which can change in a correction
const isTransmissionPhysicalSegmentEqual = (
  a: IETagTransmissionPhysicalSegment,
  b: IETagTransmissionPhysicalSegment,
): boolean =>
  isPointInfoEqual(a.pod, b.pod) &&
  isPointInfoEqual(a.por, b.por) &&
  isMiscInfosEqual(a.misc_infos, b.misc_infos);

// We only check the properties which can change in a correction
const isTransmissionPhysicalSegmentsEqual = (
  a: IETagTransmissionPhysicalSegment[],
  b: IETagTransmissionPhysicalSegment[],
): boolean => {
  if (a.length !== b.length) {
    return false;
  }

  for (let i: number = 0; i < a!.length; i += 1) {
    const aTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
      a![i];
    const bTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment =
      b![i];

    if (
      !isTransmissionPhysicalSegmentEqual(
        aTransmissionPhysicalSegment,
        bTransmissionPhysicalSegment,
      )
    ) {
      return false;
    }
  }

  return true;
};

export const getCorrectionRequest = (
  tagPrimaryKey: TETagTagPrimaryKey | undefined,
  tagId: IETagTagId | null,
  securityKey: string | undefined,
  marketSegments: IDetailMarketSegment[] | null,
  initialMarketSegments: IDetailMarketSegment[] | null | undefined,
  generationPhysicalSegment: IDetailGenerationPhysicalSegment | null,
  initialGenerationPhysicalSegment:
    | IDetailGenerationPhysicalSegment
    | null
    | undefined,
  transmissionPhysicalSegments: IETagTransmissionPhysicalSegment[] | null,
  initialTransmissionPhysicalSegments:
    | IETagTransmissionPhysicalSegment[]
    | null
    | undefined,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  initialLoadPhysicalSegment: IDetailLoadPhysicalSegment | null | undefined,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  initialTransmissionAllocations:
    | IETagTransmissionAllocation[]
    | null
    | undefined,
  lossAccountings: IDetailLossAccounting[],
  initialLossAccountings: IDetailLossAccounting[] | undefined,
  contactInfo: IContactInfo,
  notes: string,
  useUniqueProfiles: boolean,
  timeZone: TTimeZone,
): IETagCorrectionRequest | null => {
  if (
    tagPrimaryKey !== undefined &&
    tagId !== null &&
    securityKey !== undefined
  ) {
    const correctionRequest: IETagCorrectionRequest = {
      contact_info: contactInfo,
      tag_id: {
        ...tagId,
        security_key: securityKey,
      },
    };

    if (
      generationPhysicalSegment !== null &&
      !isEmptyValue(initialGenerationPhysicalSegment) &&
      !isDetailPhysicalSegmentEqual(
        generationPhysicalSegment,
        initialGenerationPhysicalSegment!,
      )
    ) {
      if (correctionRequest.tag_corrections === undefined) {
        correctionRequest.tag_corrections = {};
      }

      correctionRequest.tag_corrections.generation_physical_segment_correction =
        transformDetailGenerationPhysicalSegment(generationPhysicalSegment);
    }

    if (
      loadPhysicalSegment !== null &&
      !isEmptyValue(initialLoadPhysicalSegment) &&
      !isDetailPhysicalSegmentEqual(
        loadPhysicalSegment,
        initialLoadPhysicalSegment!,
      )
    ) {
      if (correctionRequest.tag_corrections === undefined) {
        correctionRequest.tag_corrections = {};
      }

      correctionRequest.tag_corrections.load_physical_segment_correction =
        transformDetailLoadPhysicalSegment(
          loadPhysicalSegment,
          useUniqueProfiles,
        );
    }

    if (
      lossAccountings !== null &&
      !isEmptyValue(initialLossAccountings) &&
      !isLossAccountingsEqual(lossAccountings, initialLossAccountings!)
    ) {
      const lossAccountingCorrections: IETagLossAccounting[] =
        transformDetailLossAccountingsToETagLossAccountings(
          lossAccountings.filter(
            (lossAccounting: IDetailLossAccounting): boolean =>
              initialLossAccountings!.find(
                (initialLossAccounting: IDetailLossAccounting): boolean =>
                  lossAccounting === initialLossAccounting,
              ) === undefined,
          ),
          true,
          timeZone,
        );

      if (lossAccountingCorrections.length > 0) {
        if (correctionRequest.tag_corrections === undefined) {
          correctionRequest.tag_corrections = {};
        }

        correctionRequest.tag_corrections.loss_accounting_corrections =
          lossAccountingCorrections;
      }
    }

    if (
      marketSegments !== null &&
      !isEmptyValue(initialMarketSegments) &&
      !isMarketSegmentsEqual(marketSegments, initialMarketSegments!)
    ) {
      const marketSegmentCorrections: IETagMarketSegment[] = marketSegments
        .filter(
          (marketSegment: IDetailMarketSegment): boolean =>
            initialMarketSegments!.find(
              (initialMarketSegment: IDetailMarketSegment): boolean =>
                marketSegment === initialMarketSegment,
            ) === undefined,
        )
        .map(transformDetailMarketSegment);

      if (marketSegmentCorrections.length > 0) {
        if (correctionRequest.tag_corrections === undefined) {
          correctionRequest.tag_corrections = {};
        }

        correctionRequest.tag_corrections.market_segment_corrections =
          marketSegmentCorrections;
      }
    }

    if (
      transmissionAllocations !== null &&
      !isEmptyValue(initialTransmissionAllocations) &&
      !isTransmissionAllocationsEqual(
        transmissionAllocations,
        initialTransmissionAllocations!,
      )
    ) {
      const transmissionAllocationCorrections: IETagTransmissionAllocation[] =
        transmissionAllocations.filter(
          (
            transmissionAllocationCorrection: IETagTransmissionAllocation,
          ): boolean =>
            initialTransmissionAllocations!.find(
              (
                initialTransmissionAllocationCorrection: IETagTransmissionAllocation,
              ): boolean =>
                transmissionAllocationCorrection ===
                initialTransmissionAllocationCorrection,
            ) === undefined,
        );

      if (transmissionAllocationCorrections.length > 0) {
        if (correctionRequest.tag_corrections === undefined) {
          correctionRequest.tag_corrections = {};
        }

        correctionRequest.tag_corrections.transmission_allocation_corrections =
          transmissionAllocationCorrections;
      }
    }

    if (
      transmissionPhysicalSegments !== null &&
      !isEmptyValue(initialTransmissionPhysicalSegments) &&
      !isTransmissionPhysicalSegmentsEqual(
        transmissionPhysicalSegments,
        initialTransmissionPhysicalSegments!,
      )
    ) {
      const transmissionPhysicalSegmentCorrections: IETagTransmissionPhysicalSegment[] =
        transformTransmissionPhysicalSegments(
          transmissionPhysicalSegments.filter(
            (
              transmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
            ): boolean =>
              initialTransmissionPhysicalSegments!.find(
                (
                  initialTransmissionPhysicalSegment: IETagTransmissionPhysicalSegment,
                ): boolean =>
                  transmissionPhysicalSegment ===
                  initialTransmissionPhysicalSegment,
              ) === undefined,
          ),
          false,
        );

      if (transmissionPhysicalSegmentCorrections.length > 0) {
        if (correctionRequest.tag_corrections === undefined) {
          correctionRequest.tag_corrections = {};
        }

        correctionRequest.tag_corrections.transmission_physical_segment_corrections =
          transmissionPhysicalSegmentCorrections;
      }
    }

    if (!isEmptyValue(notes)) {
      correctionRequest.notes = notes;
    }

    return correctionRequest.tag_corrections === undefined
      ? null
      : correctionRequest;
  }

  return null;
};

const updateEnergyProfileChangesMap = (
  profileRef: number,
  requestType: ERequestType,
  start: string | null,
  stop: string | null,
  rampStart: number | null,
  rampStop: number | null,
  mw: number | null,
  energyProfileChangesMap: TEnergyProfileChangesMap,
) => {
  if (energyProfileChangesMap[profileRef] === undefined) {
    energyProfileChangesMap[profileRef] = {
      profile_ref: profileRef,
      energy_profile_details: [],
    };
  }

  const energyProfileDetails: IETagEnergyProfileDetail[] =
    energyProfileChangesMap[profileRef].energy_profile_details;

  if (requestType === ERequestType.Reload) {
    if (!isEmptyValue(start) && !isEmptyValue(stop)) {
      energyProfileDetails.push({
        energy_profile_type: EEnergyProfileType.ReliabilityLimit,
        limit_clearing: true,
        mw: null,
        start,
        stop,
        start_ramp_dur: null,
        stop_ramp_dur: null,
      });
    }
  } else if (mw !== null || rampStart !== null || rampStop !== null) {
    energyProfileDetails.push({
      energy_profile_type:
        requestType === ERequestType.AtfAdjustment ||
        requestType === ERequestType.BtfAdjustment
          ? EEnergyProfileType.MarketLevel
          : requestType === ERequestType.Curtailment
          ? EEnergyProfileType.ReliabilityLimit
          : null,
      limit_clearing: false,
      mw,
      start,
      stop,
      start_ramp_dur: rampStart,
      stop_ramp_dur: rampStop,
    });
  }
};

const getAllUniqueEnergyProfileIds = (
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
): number[] => {
  const energyProfileIdsSet: Set<number> = new Set();

  if (physicalSegmentsProfiles !== null) {
    physicalSegmentsProfiles.forEach(
      (eTagPhysicalSegmentsProfile: IETagPhysicalSegmentsProfile) => {
        if (eTagPhysicalSegmentsProfile.physical_segments_profiles !== null) {
          if (
            eTagPhysicalSegmentsProfile.physical_segments_profiles
              .generation !== null &&
            eTagPhysicalSegmentsProfile.physical_segments_profiles.generation
              .profile !== null
          ) {
            energyProfileIdsSet.add(
              eTagPhysicalSegmentsProfile.physical_segments_profiles.generation
                .profile.profile_id,
            );
          }

          if (
            eTagPhysicalSegmentsProfile.physical_segments_profiles
              .transmission !== null &&
            eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission
              .transmission_segments !== null
          ) {
            eTagPhysicalSegmentsProfile.physical_segments_profiles.transmission.transmission_segments.forEach(
              (eTagTransmissionSegment: IETagTransmissionSegment) => {
                if (eTagTransmissionSegment.pod_energy_profile !== null) {
                  energyProfileIdsSet.add(
                    eTagTransmissionSegment.pod_energy_profile.profile_id,
                  );
                }

                if (eTagTransmissionSegment.por_energy_profile !== null) {
                  energyProfileIdsSet.add(
                    eTagTransmissionSegment.por_energy_profile.profile_id,
                  );
                }
              },
            );
          }

          if (
            eTagPhysicalSegmentsProfile.physical_segments_profiles.load !==
              null &&
            eTagPhysicalSegmentsProfile.physical_segments_profiles.load
              .profile !== null
          ) {
            energyProfileIdsSet.add(
              eTagPhysicalSegmentsProfile.physical_segments_profiles.load
                .profile.profile_id,
            );
          }
        }
      },
    );
  }

  return Array.from(energyProfileIdsSet);
};

export const getProfileChangeRequest = (
  tagPrimaryKey: TETagTagPrimaryKey | undefined,
  tagId: IETagTagId | null,
  securityKey: string | undefined,
  requestType: ERequestType | undefined,
  loadPhysicalSegment: IDetailLoadPhysicalSegment | null,
  transmissionAllocations: IETagTransmissionAllocation[] | null,
  initialTransmissionAllocations:
    | IETagTransmissionAllocation[]
    | null
    | undefined,
  lossAccountings: IDetailLossAccounting[],
  initialLossAccountings: IDetailLossAccounting[] | undefined,
  physicalSegmentsProfiles: IETagPhysicalSegmentsProfile[] | null,
  profileChanges: TProfileDataGridRow[],
  contactInfo: IContactInfo,
  notes: string,
  miscInfos: IMiscInfo[],
  useUniqueProfiles: boolean,
  timeZone: TTimeZone,
): IETagProfileChangeRequest | null => {
  if (
    tagPrimaryKey !== undefined &&
    tagId !== null &&
    securityKey !== undefined &&
    requestType !== undefined
  ) {
    const profileChangeRequest: IETagProfileChangeRequest = {
      contact_info: contactInfo,
      tag_id: {
        ...tagId,
        request_type: requestType,
        security_key: securityKey,
      },
    };

    if (profileChanges.length > 0) {
      const baseTransAllocProfileChangesMap: Record<
        string,
        IETagPathTransmissionAllocationProfile
      > = {};
      const exceptionTransAllocProfileChangesMap: Record<
        string,
        IETagExceptionTransAllocProfileChange
      > = {};
      const energyProfileChangesMap: TEnergyProfileChangesMap = {};
      const existingTransAllocIds: Record<number, true> = {};
      const newTransmissionAllocations: Record<
        string,
        IETagTransmissionAllocation
      > = {};
      const podValuesMap: Record<number, number | null> = {};

      if (isEmptyValue(initialTransmissionAllocations)) {
        throw new Error('Missing initialTransmissionAllocations');
      }

      if (transmissionAllocations !== null) {
        transmissionAllocations.forEach(
          (transmissionAllocation: IETagTransmissionAllocation) => {
            if (
              initialTransmissionAllocations!.find(
                (
                  initialTransmissionAllocation: IETagTransmissionAllocation,
                ): boolean =>
                  transmissionAllocation.trans_alloc_id ===
                  initialTransmissionAllocation.trans_alloc_id,
              ) === undefined
            ) {
              newTransmissionAllocations[
                transmissionAllocation.trans_alloc_id
              ] = transmissionAllocation;
            } else {
              existingTransAllocIds[transmissionAllocation.trans_alloc_id] =
                true;
            }
          },
        );
      }

      profileChanges.forEach((profileChange: TProfileDataGridRow) => {
        const startCell: IProfileDataGridCell | null | undefined =
          profileChange[PROFILE_PATH_START_KEY];

        if (startCell === undefined) {
          throw new Error(
            `Missing start cell from profileChange: ${JSON.stringify(
              profileChange,
            )}`,
          );
        }

        const start: string | null =
          startCell === null ? null : (startCell.value as string);

        const stopCell: IProfileDataGridCell | null | undefined =
          profileChange[PROFILE_PATH_STOP_KEY];

        if (stopCell === undefined) {
          throw new Error(
            `Missing stop cell from profileChange: ${JSON.stringify(
              profileChange,
            )}`,
          );
        }

        const stop: string | null =
          stopCell === null ? null : (stopCell.value as string);

        const rampStartCell: IProfileDataGridCell | null | undefined =
          profileChange[PROFILE_PATH_RAMP_START_KEY];

        if (rampStartCell === undefined) {
          throw new Error(
            `Missing rampStart cell from profileChange: ${JSON.stringify(
              profileChange,
            )}`,
          );
        }

        const rampStart: number | null =
          rampStartCell === null ? null : (rampStartCell.value as number);

        const rampStopCell: IProfileDataGridCell | null | undefined =
          profileChange[PROFILE_PATH_RAMP_STOP_KEY];

        if (rampStopCell === undefined) {
          throw new Error(
            `Missing rampStop cell from profileChange: ${JSON.stringify(
              profileChange,
            )}`,
          );
        }

        const rampStop: number | null =
          rampStopCell === null ? null : (rampStopCell.value as number);

        let generationMarketLevelMw: number | null = null;
        let loadMarketLevelMw: number | null = null;

        Object.keys(profileChange).forEach((key: string) => {
          const cell: IProfileDataGridCell | null | undefined =
            profileChange[key];

          if (cell === undefined) {
            throw new Error(
              `Invalid key: ${key} for profileChange: ${JSON.stringify(
                profileChange,
              )}`,
            );
          }

          const isCellEmpty: boolean =
            cell === null || cell.type === EProfileDataGridCellType.Empty;

          if (GENERATION_MARKET_LEVEL_KEY_REG_EXP.test(key)) {
            generationMarketLevelMw = isCellEmpty
              ? null
              : (cell!.value as number);
          } else if (LOAD_MARKET_LEVEL_KEY_REG_EXP.test(key)) {
            loadMarketLevelMw = isCellEmpty ? null : (cell!.value as number);
          } else if (!isCellEmpty) {
            const physicalSegmentRef: number | null | undefined =
              getPhysicalSegmentRefForTransmissionPodkey(key);

            if (physicalSegmentRef !== undefined) {
              if (physicalSegmentRef !== null) {
                podValuesMap[physicalSegmentRef] = isCellEmpty
                  ? null
                  : (cell!.value as number);
              }
            } else {
              const profileTransmission: IProfileTransmission | undefined =
                getProfileTransmissionForKey(key);

              if (profileTransmission !== undefined) {
                const { transAllocId } = profileTransmission;

                if (existingTransAllocIds[transAllocId] === true) {
                  let exceptionTransAllocProfileChange:
                    | IETagExceptionTransAllocProfileChange
                    | undefined =
                    exceptionTransAllocProfileChangesMap[transAllocId];

                  if (exceptionTransAllocProfileChange === undefined) {
                    exceptionTransAllocProfileChange = {
                      changed_trans_alloc_profile_details: [],
                      trans_alloc_id: transAllocId,
                    };

                    exceptionTransAllocProfileChangesMap[transAllocId] =
                      exceptionTransAllocProfileChange;
                  }

                  exceptionTransAllocProfileChange.changed_trans_alloc_profile_details.push(
                    {
                      mw: cell!.value as number,
                      start,
                      stop,
                    },
                  );
                } else {
                  const newTransmissionAllocation:
                    | IETagTransmissionAllocation
                    | undefined = newTransmissionAllocations[transAllocId];

                  if (newTransmissionAllocation === undefined) {
                    throw new Error(
                      `Invalid transAllocId: ${transAllocId} for newTransmissionAllocations: ${JSON.stringify(
                        newTransmissionAllocations,
                      )}`,
                    );
                  }

                  let baseTransAllocProfileChange:
                    | IETagPathTransmissionAllocationProfile
                    | undefined = baseTransAllocProfileChangesMap[transAllocId];

                  if (baseTransAllocProfileChange === undefined) {
                    baseTransAllocProfileChange = {
                      trans_alloc_profile_attributes: newTransmissionAllocation,
                      trans_alloc_profile_details: [],
                    };

                    baseTransAllocProfileChangesMap[transAllocId] =
                      baseTransAllocProfileChange;
                  }

                  baseTransAllocProfileChange.trans_alloc_profile_details?.push(
                    {
                      mw: cell!.value as number,
                      start,
                      stop,
                    },
                  );
                }
              }
            }
          }
        });

        if (useUniqueProfiles && requestType !== ERequestType.Reload) {
          updateEnergyProfileChangesMap(
            1,
            requestType,
            start,
            stop,
            rampStart,
            rampStop,
            generationMarketLevelMw,
            energyProfileChangesMap,
          );

          Object.keys(podValuesMap).forEach((key: string) => {
            const profileId: number = parseInt(key, 10);

            updateEnergyProfileChangesMap(
              profileId,
              requestType,
              start,
              stop,
              rampStart,
              rampStop,
              podValuesMap[profileId],
              energyProfileChangesMap,
            );
          });

          if (
            loadPhysicalSegment !== null &&
            loadPhysicalSegment.profile_ref !== null
          ) {
            updateEnergyProfileChangesMap(
              loadPhysicalSegment.profile_ref,
              requestType,
              start,
              stop,
              rampStart,
              rampStop,
              loadMarketLevelMw,
              energyProfileChangesMap,
            );
          }
        } else {
          // When not using unique profiles, but there already exists multiple
          // energy profiles, we need to update their values based on the
          // generation market level mw value
          const uniqueEnergyProfileIds: number[] = getAllUniqueEnergyProfileIds(
            physicalSegmentsProfiles,
          );

          uniqueEnergyProfileIds.forEach((profileId: number) => {
            updateEnergyProfileChangesMap(
              profileId,
              requestType,
              start,
              stop,
              rampStart,
              rampStop,
              generationMarketLevelMw,
              energyProfileChangesMap,
            );
          });
        }
      });

      const energyProfileChanges: IETagEnergyProfileChange[] = Object.values(
        energyProfileChangesMap,
      ).filter(
        (energyProfileChange: IETagEnergyProfileChange): boolean =>
          energyProfileChange.energy_profile_details.length > 0,
      );

      if (energyProfileChanges.length > 0) {
        if (profileChangeRequest.tag_profile_changes === undefined) {
          profileChangeRequest.tag_profile_changes = {};
        }

        profileChangeRequest.tag_profile_changes = {
          ...profileChangeRequest.tag_profile_changes,
          energy_profile_changes: energyProfileChanges,
        };
      }

      if (
        requestType === ERequestType.AtfAdjustment ||
        requestType === ERequestType.BtfAdjustment
      ) {
        const base_trans_alloc_profile_changes: IETagPathTransmissionAllocationProfile[] =
          Object.values(baseTransAllocProfileChangesMap);

        if (base_trans_alloc_profile_changes.length > 0) {
          if (profileChangeRequest.tag_profile_changes === undefined) {
            profileChangeRequest.tag_profile_changes = {};
          }

          profileChangeRequest.tag_profile_changes = {
            ...profileChangeRequest.tag_profile_changes,
            base_trans_alloc_profile_changes,
          };
        }

        const exception_trans_alloc_profile_changes: IETagExceptionTransAllocProfileChange[] =
          Object.values(exceptionTransAllocProfileChangesMap);

        if (exception_trans_alloc_profile_changes.length > 0) {
          if (profileChangeRequest.tag_profile_changes === undefined) {
            profileChangeRequest.tag_profile_changes = {};
          }

          profileChangeRequest.tag_profile_changes = {
            ...profileChangeRequest.tag_profile_changes,
            exception_trans_alloc_profile_changes,
          };
        }
      }
    }

    if (
      lossAccountings !== null &&
      !isEmptyValue(initialLossAccountings) &&
      !isLossAccountingsEqual(lossAccountings, initialLossAccountings!)
    ) {
      const lossAccountingCorrections: IETagLossAccounting[] =
        transformDetailLossAccountingsToETagLossAccountings(
          lossAccountings.filter(
            (lossAccounting: IDetailLossAccounting): boolean =>
              initialLossAccountings!.find(
                (initialLossAccounting: IDetailLossAccounting): boolean =>
                  lossAccounting === initialLossAccounting,
              ) === undefined,
          ),
          true,
          timeZone,
        );

      if (lossAccountingCorrections.length > 0) {
        if (profileChangeRequest.tag_profile_changes === undefined) {
          profileChangeRequest.tag_profile_changes = {};
        }

        profileChangeRequest.tag_profile_changes.loss_accounting_changes =
          lossAccountingCorrections;
      }
    }

    if (!isEmptyValue(notes)) {
      profileChangeRequest.notes = notes;
    }

    if (miscInfos.length > 0) {
      const filteredMiscInfos: IMiscInfo[] = miscInfos.filter(
        (miscInfo: IMiscInfo): boolean =>
          !(miscInfo.token === null && miscInfo.value === null),
      );

      if (filteredMiscInfos.length > 0) {
        profileChangeRequest.misc_infos = filteredMiscInfos;
      }
    }

    return profileChangeRequest.tag_profile_changes === undefined
      ? null
      : profileChangeRequest;
  }

  return null;
};

import { TToEntityId } from '../../types/ToEntity';
import { AxiosResponse } from 'axios';
import { API } from 'aws-amplify';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from '../constants';
import { NOTICES_ACKNOWLEDGE_ROUTE, NOTICES_ROUTE } from './constants';
import { TNoticeId } from 'types/Notices';
import { ZonedDateTime } from 'utils/zonedDateTime';
import { ENoticeSeverity } from 'enums/General';
import { EAlertAcknowledged } from 'enums/Alert';

export const retrieveNotices = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
  noticeSeverities: ENoticeSeverity[],
  acknowledgeStatus: EAlertAcknowledged,
  includeExpired: boolean,
): Promise<AxiosResponse> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    NOTICES_ROUTE(
      toEntityId,
      start,
      end,
      noticeSeverities,
      acknowledgeStatus,
      includeExpired,
    ),
    DEFAULT_OPTIONS,
  );
};

export const acknowledgeNotices = async (
  toEntityId: TToEntityId,
  noticesIds: TNoticeId[],
): Promise<AxiosResponse> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: {
      noticesIds,
    },
  };

  return await API.put(
    AGENT_SERVICE_API_NAME,
    NOTICES_ACKNOWLEDGE_ROUTE(toEntityId, noticesIds),
    options,
  );
};

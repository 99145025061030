import { IHeData } from 'interfaces/ETag';
import { TTimeZone } from 'types/DateTime';
import { TArraySorter } from 'types/Sort';
import { ZonedDateTime } from 'utils/zonedDateTime';

const emptySort = <T>(a: T, b: T): number => {
  if (a !== undefined && a !== null && b !== null && b !== undefined) {
    return 0;
  } else if (a === undefined || a === null) {
    return 1;
  } else {
    return -1;
  }
};

const allowEmpties =
  <T>(sorter: TArraySorter<T>) =>
  (
    nullSafe: TArraySorter<T | undefined | null>,
  ): TArraySorter<T | undefined | null> => {
    return (a: T | undefined | null, b: T | undefined | null): number => {
      if (a === undefined || a === null || b === undefined || b === null) {
        return nullSafe(a, b);
      } else {
        return sorter(a, b);
      }
    };
  };

const emptiesLast = <T>(
  sorter: TArraySorter<T>,
): TArraySorter<T | undefined | null> => {
  return allowEmpties(sorter)(emptySort);
};

const reverse = <T>(sorter: TArraySorter<T>): TArraySorter<T> => {
  return (a: T, b: T): number => -sorter(a, b); // or sorter(b, a)
};

const mapSorter =
  <T, S>(mapper: (t: T) => S) =>
  (sorter: TArraySorter<S>): TArraySorter<T> => {
    return (a: T, b: T): number => sorter(mapper(a), mapper(b));
  };

export const andThen =
  <T>(first: TArraySorter<T>) =>
  (second: TArraySorter<T>): TArraySorter<T> => {
    return (a: T, b: T): number => {
      const compare = first(a, b);
      if (compare === 0) {
        return second(a, b);
      } else {
        return compare;
      }
    };
  };

export const booleanSort = (a: boolean, b: boolean): number =>
  (a && b) || (!a && !b) ? 0 : a && !b ? -1 : 1;

export const booleanSortWithEmpties = (ascending: boolean) =>
  emptiesLast(ascending ? booleanSort : reverse(booleanSort));

export const stringSort = (a: string, b: string): number =>
  a < b ? -1 : a > b ? 1 : 0;

const emptyStringsLast = (a: string, b: string): number =>
  a === '' ? 1 : b === '' ? -1 : 0;

export const stringSortWithEmpties = (ascending: boolean) =>
  emptiesLast(
    andThen(emptyStringsLast)(ascending ? stringSort : reverse(stringSort)),
  );

export const numberSort = (a: number, b: number): number => a - b;

export const numberSortWithEmpties = (ascending: boolean) =>
  emptiesLast(ascending ? numberSort : reverse(numberSort));

export const hourEndingSortWithEmpties = (ascending: boolean) =>
  mapSorter((t: IHeData | undefined) => t?.energy?.mw)(
    emptiesLast(ascending ? numberSort : reverse(numberSort)),
  );

export const dateTimeStringSort = (
  timeZone: TTimeZone,
  dateOnly: boolean,
): TArraySorter<string> => {
  if (dateOnly) {
    return mapSorter((isoString: string): number =>
      ZonedDateTime.parseIso(isoString, timeZone).startOf('day').epochMillis(),
    )(numberSort);
  } else {
    return mapSorter((isoString: string): number =>
      ZonedDateTime.parseIso(isoString, timeZone).epochMillis(),
    )(numberSort);
  }
};

export const dateTimeStringSortWithEmpties =
  (timeZone: TTimeZone, dateOnly: boolean) => (ascending: boolean) =>
    emptiesLast(
      andThen(emptyStringsLast)(
        ascending
          ? dateTimeStringSort(timeZone, dateOnly)
          : reverse(dateTimeStringSort(timeZone, dateOnly)),
      ),
    );

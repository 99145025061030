import { AxiosResponse } from 'axios';
import { IOption } from 'interfaces/Component';
import {
  IToEntityUiConfiguration,
  IToEntityUiConfigurationResponse,
  IUserUiConfiguration,
  IUserUiConfigurationResponse,
} from 'interfaces/Config';
import { IColumnSort, ITableConfiguration } from 'interfaces/Summary';
import { TToEntityId } from 'types/ToEntity';
import { isSuccessStatus } from 'utils/general';

export const getToEntityUiConfigurationFor = (
  toEntityUiConfigurationResponse: AxiosResponse<IToEntityUiConfigurationResponse>,
  toEntityId: TToEntityId,
): IToEntityUiConfiguration => {
  const { status } = toEntityUiConfigurationResponse;

  if (!isSuccessStatus(status)) {
    throw new Error(
      `Retrieve ToEntity Ui Configuration for ${toEntityId} failed with status ${status}`,
    );
  }

  const { response } = toEntityUiConfigurationResponse.data;
  const toEntityUiConfiguration: IToEntityUiConfiguration = {
    ...response,
  };

  if (typeof toEntityUiConfiguration.profilesMaxHourRange === 'string') {
    toEntityUiConfiguration.profilesMaxHourRange = parseInt(
      toEntityUiConfiguration.profilesMaxHourRange,
      10,
    );
  }

  if (
    typeof toEntityUiConfiguration.summaryAttributesBatchLoadSize === 'string'
  ) {
    toEntityUiConfiguration.summaryAttributesBatchLoadSize = parseInt(
      toEntityUiConfiguration.summaryAttributesBatchLoadSize,
      10,
    );
  }

  if (
    typeof toEntityUiConfiguration.summaryProfilesBatchLoadSize === 'string'
  ) {
    toEntityUiConfiguration.summaryProfilesBatchLoadSize = parseInt(
      toEntityUiConfiguration.summaryProfilesBatchLoadSize,
      10,
    );
  }

  return toEntityUiConfiguration;
};

export const getUserUiConfigurationFor = (
  userUiConfigurationResponse: AxiosResponse<IUserUiConfigurationResponse>,
  toEntityId: TToEntityId,
): IUserUiConfiguration => {
  const { status } = userUiConfigurationResponse;

  if (!isSuccessStatus(status)) {
    throw new Error(
      `Retrieve User Ui Configuration for ${toEntityId} failed with status ${status}`,
    );
  }

  const { response } = userUiConfigurationResponse.data;
  const userUiConfiguration: IUserUiConfiguration = {
    ...response,
  };

  if (typeof userUiConfiguration.defaultAutoRefreshInSeconds === 'string') {
    userUiConfiguration.defaultAutoRefreshInSeconds = parseInt(
      userUiConfiguration.defaultAutoRefreshInSeconds,
      10,
    );
  }

  if (typeof userUiConfiguration.defaultTableConfiguration === 'string') {
    userUiConfiguration.defaultTableConfiguration = parseInt(
      userUiConfiguration.defaultTableConfiguration,
      10,
    );
  }

  userUiConfiguration.tableConfigurations.forEach(
    (tableConfigurationOption: IOption<ITableConfiguration>) => {
      const { id, defaultSort, userCreated } = tableConfigurationOption.value;

      if (defaultSort !== undefined) {
        const keys: string[] = Object.keys(defaultSort);

        keys.forEach((key: string) => {
          const columnSort: IColumnSort = defaultSort[key];

          if (typeof columnSort.sortOrder === 'string') {
            columnSort.sortOrder = parseInt(columnSort.sortOrder, 10);
          }
        });
      }

      if (typeof id === 'string') {
        tableConfigurationOption.value.id = parseInt(id, 10);
      }

      if (typeof userCreated === 'string') {
        tableConfigurationOption.value.userCreated =
          userCreated === 'true' ? true : false;
      }
    },
  );

  return userUiConfiguration;
};

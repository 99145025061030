import { EMenuActions, TMenuAction } from './actions';

interface IMenuState {
  showConfiguration: boolean;
  showReports: boolean;
  showQueryEtagAuthority: boolean;
}

const initialMenuState: IMenuState = {
  showConfiguration: false,
  showReports: false,
  showQueryEtagAuthority: false,
};

export const menuReducer = (
  state: IMenuState = initialMenuState,
  action: TMenuAction,
): IMenuState => {
  switch (action.type) {
    case EMenuActions.ShowConfigurationItemClicked: {
      return { ...state, showConfiguration: action.payload };
    }
    case EMenuActions.ShowReportsItemClicked: {
      return { ...state, showReports: action.payload };
    }
    case EMenuActions.ShowQueryEtagAuthorityItemClicked: {
      return { ...state, showQueryEtagAuthority: action.payload };
    }
    default:
      return state;
  }
};

import {
  ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE,
  ICON_BUTTON_SIZE_VALUE,
  STANDARD_SPACING,
  VIEW_PADDING_VALUE,
} from 'constants/styles';

const COLUMN_GAP_VALUE = 3;

export const COLUMN_GAP = `${COLUMN_GAP_VALUE}px`;

export const COLUMN_1 = '350px';
export const COLUMN_2 = '500px';
export const COLUMN_3 = '502px';
export const COLUMN_0 = `calc(100vw - ${VIEW_PADDING_VALUE}px - ${STANDARD_SPACING} - ${COLUMN_3} - ${COLUMN_GAP} - ${COLUMN_2} - ${COLUMN_GAP} - ${COLUMN_1} - ${COLUMN_GAP} - ${STANDARD_SPACING} - ${VIEW_PADDING_VALUE}px)`;

export const NARROW_COLUMN = `calc(50vw - ${VIEW_PADDING_VALUE}px - ${STANDARD_SPACING} - ${STANDARD_SPACING} - ${VIEW_PADDING_VALUE}px)`;

const ROW_GAP_VALUE = 3;

export const ROW_GAP = `${ROW_GAP_VALUE}px`;

export const ROW_0 = '201px';
export const ROW_1 = '144px';
export const ROW_2 = `calc((100vh - ${ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE}px - ${ICON_BUTTON_SIZE_VALUE}px - ${ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE}px - ${ROW_0} - ${ROW_GAP} - ${ROW_1} - ${ROW_GAP} - ${STANDARD_SPACING}) / 2)`;
export const ROW_3 = '190px';

export const ROW_0_EDIT = '233px';
export const ROW_1_EDIT = '144px';
export const ROW_2_EDIT = `calc((100vh - ${ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE}px - ${ICON_BUTTON_SIZE_VALUE}px - ${ETAG_MANAGER_ACTION_BAR_VERTICAL_PADDING_VALUE}px - ${ROW_0_EDIT} - ${ROW_GAP} - ${ROW_1_EDIT} - ${ROW_GAP} - ${STANDARD_SPACING}) / 2)`;
export const ROW_3_EDIT = '190px';

export const ETAG_MANAGER_CONFIGURATOR_MODAL_WIDTH_VALUE = 549;
export const ETAG_MANAGER_CONFIGURATOR_WITH_PROFILE_FORMAT_MODAL_WIDTH_VALUE = 869;

export const MAXIMUM_NUMBER_OF_LOAD_RETRIES = 5;

export const LOAD_RETRY_DELAY_IN_MILLISECONDS = 15000;

export const ETAG_MANAGER_NARROW_VIEW_THRESHOLD_PX = 1200;

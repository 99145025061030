import App from 'components/App/App';
import { APP_ELEMENT_ID } from 'constants/General';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById(APP_ELEMENT_ID),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as am4core from '@amcharts/amcharts4/core';
import {
  BulbFilled,
  BulbOutlined,
  HomeOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import '@aws-amplify/ui/dist/style.css';
import { LicenseManager } from 'ag-grid-enterprise';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios';
import Routes from 'components/App/routes';
import Link from 'components/atoms/Link/Link';
import NavigationActionIconContainer from 'components/atoms/NavigationActionIconContainer/NavigationActionIconContainer';
import NavigationActions from 'components/atoms/NavigationActions/NavigationActions';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToggleSwitch from 'components/atoms/ToggleSwitch/ToggleSwitch';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { EXTERNAL_LINKS_MAP, THEMES_INSERTION_POINT } from 'constants/General';
import {
  DEFAULT_THEME,
  NAVIGATION_ACTIONS_MARGIN_RIGHT,
  THEMES,
} from 'constants/styles';
import { EExternalLinks } from 'enums/General';
import { ETheme } from 'enums/Style';
import { IExternalLink } from 'interfaces/General';
import apiConfig from 'private/api-config.json';
import authConfig from 'private/auth-config.json';
import { useEffect, useMemo, useState } from 'react';
import {
  ThemeSwitcherProvider,
  useThemeSwitcher,
} from 'react-css-theme-switcher';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { store } from 'reduxes';
import { TThemeValue } from 'types/Style';
import useAsyncEffect from 'use-async-effect';
import { federatedSignIn } from 'utils/auth';
import { captureError } from 'utils/error';

am4core.addLicense('CH211112185');
LicenseManager.setLicenseKey(
  'CompanyName=Power Costs Inc,LicensedGroup=Power Costs,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=8,LicensedProductionInstancesCount=5,AssetReference=AG-036343,SupportServicesEnd=10_January_2024_[v2]_MTcwNDg0NDgwMDAwMA==4e66481e14077f09a87bf8c82dadd138',
);

// Prevent exceptions being thrown on error status responses
axios.defaults.validateStatus = () => true;

interface IEndPointConfiguration {
  name: string;
  endpoint: string;
}

Amplify.configure({
  API: {
    endpoints: apiConfig.endpoints.map(
      (endPointConfiguration: IEndPointConfiguration) => ({
        ...endPointConfiguration,
        custom_header: async () => {
          const cognitoUserSession: CognitoUserSession =
            await Auth.currentSession();
          return {
            Authorization: `Bearer ${cognitoUserSession
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      }),
    ),
  },
  Auth: authConfig,
});

const App = (): JSX.Element => {
  const { switcher, themes, currentTheme } = useThemeSwitcher();
  const [isLightMode, setIsLightMode] = useState<boolean>(
    DEFAULT_THEME === ETheme.Light,
  );
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
  const [userGuideExternalLink, setUserGuideExternalLink] = useState<
    IExternalLink | undefined
  >(EXTERNAL_LINKS_MAP[EExternalLinks.UserGuide]);

  const handleThemeChange = (isLightMode: boolean) => {
    try {
      localStorage.setItem('isLightMode', isLightMode ? 'true' : 'false');
    } catch (error: any) {
      captureError(error);
    }

    setIsLightMode(isLightMode);
  };

  useEffect(() => {
    try {
      const localStorageIsLightMode =
        localStorage.getItem('isLightMode') === 'true' ||
        !localStorage.getItem('isLightMode');
      setIsLightMode(localStorageIsLightMode);
    } catch (error: any) {
      captureError(error);
    }
  }, []);

  useAsyncEffect(async () => {
    try {
      await Auth.currentSession();
      setIsSignedIn(true);
    } catch (e) {
      setIsSignedIn(false);
    }
  }, []);

  useEffect(() => {
    if (isSignedIn === false) {
      const key = 'tenantIdp';
      const searchParams = new URLSearchParams(window.location.search);
      federatedSignIn(window.location.href, searchParams.get(key));
    }
  }, [isSignedIn]);

  useEffect(() => {
    switcher({
      theme: isLightMode ? themes[ETheme.Light] : themes[ETheme.Dark],
    });
  }, [switcher, themes, isLightMode]);

  const appActions = useMemo(() => {
    if (userGuideExternalLink === undefined) {
      throw new Error(`Missing userGuideExternalLink`);
    }

    return (
      <NavigationActions right={NAVIGATION_ACTIONS_MARGIN_RIGHT}>
        <SeparatedRowLayout>
          <Tooltip placement='bottomRight' title={userGuideExternalLink.label}>
            <Link
              external={true}
              target='new'
              to={userGuideExternalLink.location}
            >
              <NavigationActionIconContainer
                currentTheme={currentTheme as TThemeValue}
              >
                <ReadOutlined />
              </NavigationActionIconContainer>
            </Link>
          </Tooltip>
          <Tooltip placement='bottomRight' title='Landing Page'>
            <Link to='/'>
              <NavigationActionIconContainer
                currentTheme={currentTheme as TThemeValue}
              >
                <HomeOutlined />
              </NavigationActionIconContainer>
            </Link>
          </Tooltip>
          <Tooltip placement='bottomRight' title='Light/Dark Mode'>
            <ToggleSwitch
              checkedChildren={<BulbFilled />}
              isChecked={isLightMode}
              onChange={handleThemeChange}
              unCheckedChildren={<BulbOutlined />}
            />
          </Tooltip>
        </SeparatedRowLayout>
      </NavigationActions>
    );
  }, [currentTheme, isLightMode, userGuideExternalLink]);

  if (isSignedIn === undefined || !isSignedIn) {
    return <></>;
  } else {
    return (
      <div className='App'>
        <Router basename={process.env.REACT_APP_BASEDIR}>
          <Routes
            appActions={appActions}
            setIsLightMode={setIsLightMode}
            setUserGuideExternalLink={setUserGuideExternalLink}
          />
        </Router>
      </div>
    );
  }
};

const AppWrapper = (): JSX.Element => (
  <Provider store={store}>
    <ThemeSwitcherProvider
      defaultTheme={DEFAULT_THEME}
      insertionPoint={THEMES_INSERTION_POINT}
      themeMap={THEMES}
    >
      <App />
    </ThemeSwitcherProvider>
  </Provider>
);

export default AppWrapper;

import { EEntityType } from 'enums/Entity';
import { ERegistryType } from 'enums/General';

export const ENTITY_TYPE_TO_REGISTRY_TYPE_MAP = {
  [EEntityType.CA]: ERegistryType.BA,
  [EEntityType.GCA]: ERegistryType.BA,
  [EEntityType.LCA]: ERegistryType.BA,
  [EEntityType.MO]: ERegistryType.MO,
  [EEntityType.POD]: ERegistryType.PORPOD,
  [EEntityType.POR]: ERegistryType.PORPOD,
  [EEntityType.PSE]: ERegistryType.PSE,
  [EEntityType.SC]: ERegistryType.RC,
  [EEntityType.SourceSink]: ERegistryType.SourceSink,
  [EEntityType.TP]: ERegistryType.TSP,
};

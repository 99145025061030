import { EAlertMessageAttribute } from 'enums/Alert';
import { ESeverity } from 'enums/General';

export const ALERT_SEVERITY_MAP: Record<ESeverity, string> = {
  [ESeverity.Error]: 'ERROR',
  [ESeverity.Info]: 'INFO',
  [ESeverity.Warning]: 'WARNING',
};

export const ALERT_MESSAGE_ATTRIBUTE_MAP: Record<
  EAlertMessageAttribute,
  string
> = {
  [EAlertMessageAttribute.Notes]: 'Notes',
  [EAlertMessageAttribute.RequestType]: 'Request Type',
  [EAlertMessageAttribute.UiRequestor]: 'Requestor',
  [EAlertMessageAttribute.Start]: 'Start',
  [EAlertMessageAttribute.End]: 'End',
  [EAlertMessageAttribute.TagPrimaryKey]: 'Tag Primary Key',
  [EAlertMessageAttribute.UiTagId]: 'Tag ID',
  [EAlertMessageAttribute.TagId]: 'Tag ID (numbers)',
  [EAlertMessageAttribute.PreviousCompositeState]: 'Previous Composite State',
  [EAlertMessageAttribute.NewCompositeState]: 'New Composite State',
  [EAlertMessageAttribute.RequestNote]: 'Request Note',
  [EAlertMessageAttribute.TagNote]: 'Tag Note',
  [EAlertMessageAttribute.RequestId]: 'Request Id',
};

export const ALERT_MESSAGE_ORDERING: EAlertMessageAttribute[][] = [
  [EAlertMessageAttribute.UiTagId],
  [EAlertMessageAttribute.TagId],
  [EAlertMessageAttribute.RequestId],
  [EAlertMessageAttribute.UiRequestor, EAlertMessageAttribute.RequestType],
  [EAlertMessageAttribute.Start, EAlertMessageAttribute.End],
  [EAlertMessageAttribute.PreviousCompositeState],
  [EAlertMessageAttribute.NewCompositeState],
  [EAlertMessageAttribute.TagNote],
  [EAlertMessageAttribute.RequestNote],
  [EAlertMessageAttribute.Notes],
];

export const DEFAULT_ON_SCREEN_TIME_IN_SECONDS = 15;

export const ALERT_WIDTH_VALUE = 414;

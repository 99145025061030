import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import {
  ITransmissionAvailabilityReportResponse,
  ITransmissionServiceNetwork,
  ITransmissionServiceNetworkResponse,
} from 'interfaces/ETag';
import {
  TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_ALL_ROUTE,
  TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_TSN_ROUTE,
  TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_UNASSOCIATED_TSR_ROUTE,
  TRANSMISSION_CAPACITY_NETWORK_ROUTE,
} from 'services/agent/constants';
import { AGENT_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';
import { TToEntityId } from 'types/ToEntity';

export const retrieveAllTransmissionCapacityAvailability = async (
  toEntityId: TToEntityId,
  start: string,
  end: string,
): Promise<AxiosResponse<ITransmissionAvailabilityReportResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_ALL_ROUTE(toEntityId, start, end),
    DEFAULT_OPTIONS,
  );
};

export const retrieveUnassociatedTsrTransmissionCapacityAvailability = async (
  toEntityId: TToEntityId,
  start: string,
  end: string,
): Promise<AxiosResponse<ITransmissionAvailabilityReportResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_UNASSOCIATED_TSR_ROUTE(
      toEntityId,
      start,
      end,
    ),
    DEFAULT_OPTIONS,
  );
};

export const retrieveTsnTransmissionCapacityAvailability = async (
  toEntityId: TToEntityId,
  start: string,
  end: string,
): Promise<AxiosResponse<ITransmissionAvailabilityReportResponse>> => {
  return await API.get(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_CAPACITY_AVAILABILITY_REPORT_TSN_ROUTE(toEntityId, start, end),
    DEFAULT_OPTIONS,
  );
};

export const saveSingleTSN = async (
  toEntityId: TToEntityId,
  transmissionServiceNetwork: ITransmissionServiceNetwork,
): Promise<AxiosResponse<ITransmissionServiceNetworkResponse>> => {
  const options = {
    ...DEFAULT_OPTIONS,
    body: transmissionServiceNetwork,
  };
  return await API.put(
    AGENT_SERVICE_API_NAME,
    TRANSMISSION_CAPACITY_NETWORK_ROUTE(toEntityId),
    options,
  );
};

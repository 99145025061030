import { AxiosResponse } from 'axios';
import { IToEntityUiConfigurationResponse } from 'interfaces/Config';
import { TTimeZone, TTimeZoneOption } from 'types/DateTime';
import { isEmptyValue } from 'utils/general';

export const updateToEntityUiConfigurationResponse = (
  response: AxiosResponse<IToEntityUiConfigurationResponse>,
): AxiosResponse<IToEntityUiConfigurationResponse> => {
  let updatedResponse: AxiosResponse<IToEntityUiConfigurationResponse> =
    response;

  if (isEmptyValue(updatedResponse.data.errorMessage)) {
    if (updatedResponse.data.response.timeZoneOptions !== undefined) {
      updatedResponse = {
        ...updatedResponse,
        data: {
          ...updatedResponse.data,
          response: {
            ...updatedResponse.data.response,
            timeZones: updatedResponse.data.response.timeZoneOptions.map(
              (timeZoneOption: TTimeZoneOption): TTimeZone =>
                timeZoneOption.value,
            ),
          },
        },
      };

      delete updatedResponse.data.response.timeZoneOptions;
    }
  }

  return updatedResponse;
};

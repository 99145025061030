import {
  BACKGROUND_BLACK,
  BACKGROUND_BLACK_FLOAT,
  BACKGROUND_WHITE,
  CELL_BACKGROUND_COLOUR_DARK,
  CELL_BACKGROUND_COLOUR_LIGHT,
  CELL_BORDER_COLOUR_DARK,
  CELL_BORDER_COLOUR_LIGHT,
  CELL_BORDER_STYLE,
  COMPONENT_BACKGROUND_DARK,
  COMPONENT_BACKGROUND_LIGHT,
  ERROR_BORDER,
  HIGHLIGHT_BLUE,
  HOVER_BACKGROUND_DARK,
  HOVER_BACKGROUND_LIGHT,
  INPUT_BACKGROUND_DARK,
  INPUT_BACKGROUND_EXPAND_DARK,
  INPUT_BACKGROUND_EXPAND_LIGHT,
  INPUT_BACKGROUND_LIGHT,
  ROW_BACKGROUND_DARK,
  ROW_BACKGROUND_LIGHT,
  SEPARATOR_DARK,
  SEPARATOR_LIGHT,
  TEXT_DARK,
  TEXT_DISABLED_DARK,
  TEXT_DISABLED_LIGHT,
  TEXT_LIGHT,
  WARNING_BORDER,
} from 'constants/styles';
import { ESelectedCellType } from 'enums/Component';
import { ESeverity } from 'enums/General';
import { ETheme } from 'enums/Style';
import {
  IContainerSelectable,
  IExpandableRowProps,
  IHighlightSeverity,
  IThemedProps,
  IValueChanged,
} from 'interfaces/Component';
import { IIndexable } from 'interfaces/General';
import { ISummaryStyles } from 'interfaces/Summary';

const containsDanglingParen = (cssSnippet: string): boolean => {
  let numOpenUnmatched = 0;
  for (let i: number = 0; i < cssSnippet.length; i += 1) {
    if (cssSnippet[i] === '(') {
      numOpenUnmatched += 1;
    } else if (cssSnippet[i] === ')') {
      numOpenUnmatched -= 1;
    }
  }
  return numOpenUnmatched !== 0;
};

export const replaceDanglingParenWithUndefined = (
  cssSnippet: string | null | undefined,
): string | undefined => {
  if (
    cssSnippet === undefined ||
    cssSnippet === null ||
    containsDanglingParen(cssSnippet)
  ) {
    return undefined;
  }
  return cssSnippet;
};

/**
 * Turns object into css string. Any properties with dangling parentheses
 * (e.g. an incomplete value like 'rgb(100, ' ) not included, since these
 * will mess with the layout. In testing, it was found that a two column
 * layout switched to a one column layout when the user reached the open
 * parentheses in the rgb color description.
 * @param summaryStyles object with backgroundColor, color, border, etc.
 */
export const unpackSummaryStyles = (
  summaryStyles?: ISummaryStyles & IIndexable,
): string =>
  summaryStyles === undefined
    ? ''
    : Object.keys(summaryStyles)
        .map((key: string) => {
          const cleanedStyle = replaceDanglingParenWithUndefined(
            summaryStyles[key],
          );
          if (cleanedStyle === undefined || cleanedStyle.length === 0) {
            return '';
          } else {
            return `${key}: ${cleanedStyle};`;
          }
        })
        .join('');

export const textColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark ? TEXT_DARK : TEXT_LIGHT;

export const textDisabledColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark ? TEXT_DISABLED_DARK : TEXT_DISABLED_LIGHT;

export const inputBackground = (props: IThemedProps): string =>
  props.disabled === true
    ? ''
    : `background-color: ${
        props.currentTheme === ETheme.Dark
          ? INPUT_BACKGROUND_DARK
          : INPUT_BACKGROUND_LIGHT
      };`;

export const inputBackgroundExpand = (props: IThemedProps): string =>
  `background-color: ${
    props.currentTheme === ETheme.Dark
      ? INPUT_BACKGROUND_EXPAND_DARK
      : INPUT_BACKGROUND_EXPAND_LIGHT
  };`;

export const floatBackground = (props: IThemedProps): string =>
  `background-color: ${
    props.currentTheme === ETheme.Dark
      ? BACKGROUND_BLACK_FLOAT
      : BACKGROUND_WHITE
  };`;

export const backgroundColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark ? BACKGROUND_BLACK : BACKGROUND_WHITE;

export const componentBackgroundColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark
    ? COMPONENT_BACKGROUND_DARK
    : COMPONENT_BACKGROUND_LIGHT;

export const rowBackgroundColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark
    ? ROW_BACKGROUND_DARK
    : ROW_BACKGROUND_LIGHT;

export const separator = (props: IThemedProps): string =>
  `border-bottom: 1px solid ${
    props.currentTheme === ETheme.Dark ? SEPARATOR_DARK : SEPARATOR_LIGHT
  };`;

export const hoverBackground = (props: IThemedProps): string =>
  `background-color: ${
    props.currentTheme === ETheme.Dark
      ? HOVER_BACKGROUND_DARK
      : HOVER_BACKGROUND_LIGHT
  };`;

export const rowBackground = (props: IThemedProps): string =>
  `background-color: ${rowBackgroundColour(props)};`;

export const alternatingTableRowBackground = (props: IThemedProps): string => `
  .ant-table {
    &-tbody {
      > tr {
        :nth-child(even) {
          ${rowBackground(props)}
        }
      }
    }
  }
`;

export const componentBackground = (props: IThemedProps): string =>
  `background-color: ${componentBackgroundColour(props)};`;

export const hideExpandIconColumn = (props: IExpandableRowProps): string =>
  props.hideExpandIconColumn === true
    ? `
    .ant-table-expand-icon-col {
      width: 1px;
    }

    .ant-table-row-expand-icon-cell {
      padding: 0 !important;
    }
  `
    : '';

export const inputValueChanged = (props: IValueChanged): string =>
  props.valueChanged ? `color: ${HIGHLIGHT_BLUE};` : '';

export const buttonHighlightSeverity = (props: IHighlightSeverity): string => {
  switch (props.highlightSeverity) {
    case ESeverity.Error:
      return ERROR_BORDER;
    case ESeverity.Warning:
      return WARNING_BORDER;
    default:
      return '';
  }
};

export const getHighlightedStyle = (props: IContainerSelectable) => `
  ${
    props.highlightColour === undefined
      ? ''
      : `
          ::after {
            ${CELL_BORDER_STYLE}

            border: solid 2px ${props.highlightColour};
          }
        `
  }
`;

export const getCellSelectedBackgroundColour = (
  props: IThemedProps,
): string => `
  background-color: ${
    props.currentTheme === ETheme.Dark
      ? CELL_BACKGROUND_COLOUR_DARK
      : CELL_BACKGROUND_COLOUR_LIGHT
  };
`;

export const getCellBorderColour = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Dark
    ? CELL_BORDER_COLOUR_DARK
    : CELL_BORDER_COLOUR_LIGHT;

const getPrimaryBorder = (props: IContainerSelectable) => `
  ::after {
    ${CELL_BORDER_STYLE}

    ${
      props.hasFocus
        ? `border: solid 2px ${getCellBorderColour(props)}`
        : `border: dashed 2px ${getCellBorderColour(props)}`
    }
  }
`;

export const getSelectedStyle = (props: IContainerSelectable) => `
  ${
    props.selectedCellType === ESelectedCellType.Primary
      ? getPrimaryBorder(props)
      : props.selectedCellType === ESelectedCellType.Selected
      ? getCellSelectedBackgroundColour(props)
      : ''
  }
`;

export const colourForTheme = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Light ? TEXT_LIGHT : TEXT_DARK;

export const colourDisabledForTheme = (props: IThemedProps): string =>
  props.currentTheme === ETheme.Light
    ? TEXT_DISABLED_LIGHT
    : TEXT_DISABLED_DARK;

import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { EBulletShape } from 'components/organisms/ProfileGraphView/types';
import styled from 'styled-components';

const LINE_Z_INDEX_VALUE = 1;
const BULLET_Z_INDEX_VALUE = 2;

const ImageLayout = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;

  > div:first-child {
    left: 0;
  }

  > div:last-child {
    right: 0;
  }
`;

interface IShapeProps {
  colour: string;
  shape: EBulletShape;
}

const Line = styled.div<IShapeProps>`
  border-top: ${(props) =>
      props.shape === EBulletShape.Diamond ? 'dashed' : 'solid'}
    3px ${(props) => props.colour};
  height: 3px;
  width: 37px;
  z-index: ${LINE_Z_INDEX_VALUE};
`;

const Bullet = styled.div<IShapeProps>`
  background-color: ${(props) => props.colour};
  height: 7px;
  position: absolute;
  width: 7px;
  z-index: ${BULLET_Z_INDEX_VALUE};

  ${(props) =>
    props.shape === EBulletShape.Circle ? 'border-radius: 50%;' : ''}
  ${(props) =>
    props.shape === EBulletShape.Diamond ? 'transform: rotate(45deg);' : ''}
`;

interface ILegendKeyProps extends IShapeProps {
  label: string;
}

const LegendKey = (props: ILegendKeyProps): JSX.Element => {
  const { colour, label, shape } = props;

  return (
    <SeparatedRowLayout>
      <ImageLayout>
        <Bullet colour={colour} shape={shape} />
        <Line colour={colour} shape={shape} />
        <Bullet colour={colour} shape={shape} />
      </ImageLayout>
      <div>{label}</div>
    </SeparatedRowLayout>
  );
};

export default LegendKey;

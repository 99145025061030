import { ECompositeState } from 'enums/ETag';
import { ERetreiveState } from 'enums/General';
import {
  IETagData,
  IETagIdentifier,
  IETagSummaryAttributeDataSet,
  IETagSummaryAttributeRecord,
  IETagSummaryProfile,
  IETagSummaryProfileDataSet,
  IETagSummaryProfilesRecord,
} from 'interfaces/ETag';
import { IToEntityRecord } from 'interfaces/ToEntity';
import { TTimeZone } from 'types/DateTime';
import { TETagDraftId, TETagRecordKey, TETagTagPrimaryKey } from 'types/ETag';
import { eTagSummaryAttributeToDataSet } from 'utils/eTag';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const transformRetrievedETagSummaryProfiles = (
  retrievedETagSummaryProfiles: IETagSummaryProfile[],
  eTagSummaryProfiles: IETagSummaryProfile[],
  timeZone: TTimeZone,
): IETagSummaryProfileDataSet[] => {
  let transformedETagSummaryProfiles: IETagSummaryProfileDataSet[] = [];

  if (eTagSummaryProfiles.length > 0) {
    eTagSummaryProfiles.forEach((summaryProfile: IETagSummaryProfile) => {
      const foundSummaryProfile: IETagSummaryProfile | undefined =
        retrievedETagSummaryProfiles.find(
          (retrievedSummaryProfile: IETagSummaryProfile) =>
            ZonedDateTime.parseIso(
              retrievedSummaryProfile.day,
              timeZone,
            ).isSame(
              ZonedDateTime.parseIso(summaryProfile.day, timeZone),
              'day',
            ),
        );

      if (foundSummaryProfile === undefined) {
        transformedETagSummaryProfiles.push({
          ...summaryProfile,
          summaryProfileRemoved: true,
        });
      } else {
        transformedETagSummaryProfiles.push(foundSummaryProfile);
      }
    });

    retrievedETagSummaryProfiles.forEach(
      (retrievedSummaryProfile: IETagSummaryProfile) => {
        if (
          eTagSummaryProfiles.find((summaryProfile: IETagSummaryProfile) =>
            ZonedDateTime.parseIso(summaryProfile.day, timeZone).isSame(
              ZonedDateTime.parseIso(retrievedSummaryProfile.day, timeZone),
              'day',
            ),
          ) === undefined
        ) {
          transformedETagSummaryProfiles.push(retrievedSummaryProfile);
        }
      },
    );
  } else {
    transformedETagSummaryProfiles = retrievedETagSummaryProfiles;
  }

  // Keep Summary Profiles sorted by day
  transformedETagSummaryProfiles.sort(
    (a: IETagSummaryProfile, b: IETagSummaryProfile): number =>
      a.day < b.day ? -1 : a.day > b.day ? 1 : 0,
  );

  return transformedETagSummaryProfiles;
};

export const createOrUpdateSummaryAttributeRecord = (
  state: IToEntityRecord,
  recordKey: TETagRecordKey,
  updatedSummaryAttributeRecord: IETagSummaryAttributeRecord,
  timeZone: TTimeZone,
): IToEntityRecord => {
  let summaryAttributeRecord: IETagSummaryAttributeRecord =
    state.eTagsSummaryAttributeMap[recordKey];

  if (summaryAttributeRecord === undefined) {
    summaryAttributeRecord = {
      eTagSummaryAttributeDataSet: undefined,
      eTagSummaryAttributeError: null,
      eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  let newSummaryAttributeRecord: IETagSummaryAttributeRecord;

  if (updatedSummaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
    if (summaryAttributeRecord.eTagSummaryAttributeDataSet === undefined) {
      newSummaryAttributeRecord = summaryAttributeRecord;
    } else {
      newSummaryAttributeRecord = {
        ...summaryAttributeRecord,
        eTagSummaryAttributeDataSet: {
          ...summaryAttributeRecord.eTagSummaryAttributeDataSet,
          summaryAttributeRemoved: true,
        },
      };
    }
  } else {
    newSummaryAttributeRecord = {
      ...summaryAttributeRecord,
      ...updatedSummaryAttributeRecord,
    };
  }

  const newState: IToEntityRecord = {
    ...state,
    eTagsSummaryAttributeMap: {
      ...state.eTagsSummaryAttributeMap,
      [recordKey]: newSummaryAttributeRecord,
    },
  };

  // Check to see if an ETag summary attribute has a pre-existing draft
  // ETag and therefore should be removed.
  if (
    newSummaryAttributeRecord.eTagSummaryAttributeDataSet !== undefined &&
    !newSummaryAttributeRecord.eTagSummaryAttributeDataSet
      ?.summaryAttributeRemoved
  ) {
    if (
      newSummaryAttributeRecord.eTagSummaryAttributeDataSet.composite_state ===
      ECompositeState.Draft
    ) {
      newState.eTagsDraftTagPrimaryKeys = cleanUpETagsDraftTagPrimaryKeys(
        recordKey,
        newState.eTagsDraftTagPrimaryKeys,
      );
      newState.eTagsDraftTagPrimaryKeys[
        newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
      ] = recordKey;
    } else {
      const foundRecordKey: TETagRecordKey | undefined =
        newState.eTagsDraftTagPrimaryKeys[
          newSummaryAttributeRecord.eTagSummaryAttributeDataSet.tag_primary_key
        ];

      if (foundRecordKey !== undefined) {
        newState.eTagsDraftTagPrimaryKeys = {};

        // Clear all old tagPrimaryKeys associated with the foundRecordKey
        Object.keys(state.eTagsDraftTagPrimaryKeys).forEach(
          (tagPrimaryKey: string) => {
            const recordKey: TETagRecordKey =
              state.eTagsDraftTagPrimaryKeys[tagPrimaryKey];

            if (recordKey !== foundRecordKey) {
              newState.eTagsDraftTagPrimaryKeys[tagPrimaryKey] = recordKey;
            }
          },
        );

        const eTagSummaryAttributeDataSet:
          | IETagSummaryAttributeDataSet
          | undefined =
          newState.eTagsSummaryAttributeMap[foundRecordKey] &&
          newState.eTagsSummaryAttributeMap[foundRecordKey]
            .eTagSummaryAttributeDataSet;

        if (
          eTagSummaryAttributeDataSet !== undefined &&
          newState.eTagsSummaryAttributeMap[foundRecordKey] !== undefined
        ) {
          newState.eTagsSummaryAttributeMap[
            foundRecordKey
          ].eTagSummaryAttributeDataSet = {
            ...eTagSummaryAttributeDataSet,
            summaryAttributeRemoved: true,
          };
        }
      }
    }
  }

  return newState;
};

export const createOrUpdateETagData = (
  state: IToEntityRecord,
  eTagData: IETagData,
  timeZone: TTimeZone,
): IToEntityRecord => {
  const { recordKey, summaryAttribute, summaryProfiles } = eTagData;

  const newState: IToEntityRecord = createOrUpdateSummaryAttributeRecord(
    state,
    recordKey,
    {
      eTagSummaryAttributeDataSet:
        eTagSummaryAttributeToDataSet(summaryAttribute),
      eTagSummaryAttributeError: null,
      eTagSummaryAttributeRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryAttributeLastRequestedAt: ZonedDateTime.now(timeZone),
    },
    timeZone,
  );

  let currentSummaryProfilesRecord: IETagSummaryProfilesRecord | undefined =
    state.eTagsSummaryProfilesMap[recordKey];

  if (currentSummaryProfilesRecord === undefined) {
    currentSummaryProfilesRecord = {
      eTagSummaryProfiles: [],
      eTagSummaryProfilesError: null,
      eTagSummaryProfilesRetrieving: ERetreiveState.RetrievingCompleted,
      eTagSummaryProfilesLastRequestedAt: ZonedDateTime.now(timeZone),
    };
  }

  return {
    ...newState,
    eTagsSummaryProfilesMap: {
      ...state.eTagsSummaryProfilesMap,
      [recordKey]: {
        ...currentSummaryProfilesRecord,
        eTagSummaryProfiles: transformRetrievedETagSummaryProfiles(
          summaryProfiles,
          currentSummaryProfilesRecord.eTagSummaryProfiles,
          timeZone,
        ),
      },
    },
  };
};

export const cleanUpETagsDraftTagPrimaryKeys = (
  draftId: TETagDraftId,
  eTagsDraftTagPrimaryKeys: Record<TETagTagPrimaryKey, TETagRecordKey>,
): Record<TETagTagPrimaryKey, TETagRecordKey> => {
  const updatedETagsDraftTagPrimaryKeys: Record<
    TETagTagPrimaryKey,
    TETagRecordKey
  > = {
    ...eTagsDraftTagPrimaryKeys,
  };
  for (let key in updatedETagsDraftTagPrimaryKeys) {
    if (updatedETagsDraftTagPrimaryKeys[key] === draftId) {
      delete updatedETagsDraftTagPrimaryKeys[key];
    }
  }
  return updatedETagsDraftTagPrimaryKeys;
};

export const eTagIdentifiersToIdList = (
  eTagIdentifiers: IETagIdentifier[],
): string[] =>
  eTagIdentifiers.map((eTagIdentifier: IETagIdentifier): string =>
    eTagIdentifier.draft_id === null
      ? eTagIdentifier.tag_primary_key
      : `d:${eTagIdentifier.draft_id}`,
  );

// It's important to know that if there is a draft_id then the tag_primary_key
// is not a valid tag_primary_key since we don't have enough information to
// correctly determine its value
export const idToETagIdentifier = (id: string): IETagIdentifier => ({
  draft_id: id.startsWith('d:') ? id.substring(2) : null,
  tag_primary_key: id,
});

import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { ERequestStatus } from 'enums/ETag';
import { EServiceType } from 'enums/General';
import {
  IQueryAvailabilityResponse,
  IQueryCorrectionResponse,
  IQueryRequestIdsResponse,
  IQueryRequestResponse,
  IQueryResolutionResponse,
  IQueryStatusResponse,
  IQuerySummaryResponse,
  IQueryTagResponse,
} from 'interfaces/Query';
import { DEFAULT_OPTIONS, QUERY_SERVICE_API_NAME } from 'services/constants';
import {
  QUERY_AVAILABILITY_ROUTE,
  QUERY_CORRECTION_ROUTE,
  QUERY_MISSING_TAGS_ROUTE,
  QUERY_REQUEST_IDS_ROUTE,
  QUERY_REQUEST_ROUTE,
  QUERY_RESOLUTION_ROUTE,
  QUERY_STATUS_ROUTE,
  QUERY_SUMMARY_ROUTE,
  QUERY_TAG_ROUTE,
} from 'services/query/constants';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const queryMissingETags = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
): Promise<AxiosResponse> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_MISSING_TAGS_ROUTE(toEntityId, start, end),
    DEFAULT_OPTIONS,
  );
};

export const queryAvailability = async (
  toEntityId: TToEntityId,
  queryEntity: string,
  serviceType?: EServiceType,
): Promise<AxiosResponse<IQueryAvailabilityResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_AVAILABILITY_ROUTE(toEntityId, queryEntity, serviceType),
    DEFAULT_OPTIONS,
  );
};

export const queryCorrection = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  correctionId: number,
): Promise<AxiosResponse<IQueryCorrectionResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_CORRECTION_ROUTE(
      toEntityId,
      tagPrimaryKey,
      queryEntity,
      correctionId,
    ),
    DEFAULT_OPTIONS,
  );
};

export const queryRequestIds = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestStatus: ERequestStatus,
): Promise<AxiosResponse<IQueryRequestIdsResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_REQUEST_IDS_ROUTE(
      toEntityId,
      tagPrimaryKey,
      queryEntity,
      requestStatus,
    ),
    DEFAULT_OPTIONS,
  );
};

export const queryRequest = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): Promise<AxiosResponse<IQueryRequestResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_REQUEST_ROUTE(toEntityId, tagPrimaryKey, queryEntity, requestId),
    DEFAULT_OPTIONS,
  );
};

export const queryResolution = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): Promise<AxiosResponse<IQueryResolutionResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_RESOLUTION_ROUTE(toEntityId, tagPrimaryKey, queryEntity, requestId),
    DEFAULT_OPTIONS,
  );
};

export const queryStatus = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): Promise<AxiosResponse<IQueryStatusResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_STATUS_ROUTE(toEntityId, tagPrimaryKey, queryEntity, requestId),
    DEFAULT_OPTIONS,
  );
};

export const querySummaries = async (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
): Promise<AxiosResponse<IQuerySummaryResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_SUMMARY_ROUTE(toEntityId, start, end),
    DEFAULT_OPTIONS,
  );
};

export const queryTag = async (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
): Promise<AxiosResponse<IQueryTagResponse>> => {
  return await API.get(
    QUERY_SERVICE_API_NAME,
    QUERY_TAG_ROUTE(toEntityId, tagPrimaryKey, queryEntity),
    DEFAULT_OPTIONS,
  );
};

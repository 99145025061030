import withAlertsQueue, {
  IwithAlertsQueueProps,
} from 'components/hocs/withAlertsQueue/withAlertsQueue';
import withFloatOver from 'components/hocs/withFloatOver/withFloatOver';
import ToEntitySelector from 'components/molecules/ToEntitySelector/ToEntitySelector';
import { IPageContentProps } from 'components/organisms/Page/Page';
import ToEntityMonitor from 'components/organisms/ToEntityMonitor/ToEntityMonitor';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING_VALUE } from 'constants/styles';
import AnimationProvider from 'contexts/Animation/Animation';
import ETagFilteringProvider from 'contexts/ETagFiltering/ETagFiltering';
import ETagSortingProvider from 'contexts/ETagSorting/ETagSorting';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { encodeIds } from 'utils/general';
import { AxiosResponse } from 'axios';
import { IETagConfigResponse } from '../../../interfaces/ETag';
import { retrieveToEntityConfig } from '../../../services/configclient/config';
import useAsyncEffect from 'use-async-effect';
import { IToEntityLossesConfig } from '../../../reduxes/User/types';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}
`;

const Actions = styled.div`
  padding: 8px 4px 0 4px;
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface ISummaryPageContentProps
  extends IPageContentProps<undefined>,
    IwithAlertsQueueProps {}

const SummaryPageContent = (props: ISummaryPageContentProps): JSX.Element => {
  const { addAlert, closeAllAlerts, userInfo } = props;
  const { toEntities, toEntityUserStates } = userInfo;
  const [selectedToEntities, setSelectedToEntities] = useState<IToEntity[]>([]);
  const [toEntityLossesConfig, setToEntityLossesConfig] = useState<
    IToEntityLossesConfig[]
  >([]);

  useAsyncEffect(async () => {
    const toEntityLossesConfig: IToEntityLossesConfig[] = [];
    for (const entity of toEntities) {
      const retrieveToEntityConfigResponse: AxiosResponse<IETagConfigResponse> =
        await retrieveToEntityConfig(entity.to_entity);
      const eTagConfigResponse: IETagConfigResponse =
        retrieveToEntityConfigResponse.data;
      toEntityLossesConfig.push({
        toEntity: entity.to_entity,
        showLosses: eTagConfigResponse.response.show_losses || false,
      });
    }
    setToEntityLossesConfig(toEntityLossesConfig);
  }, [toEntities]);

  useEffect(() => {
    setSelectedToEntities(toEntities);
  }, [toEntities]);

  const hasMultipleToEntities: boolean = toEntities.length > 1;
  const toEntityMonitorHeight: string = `calc((100vh - ${
    2 * STANDARD_SPACING_VALUE
  }px) / ${Math.max(selectedToEntities.length, 1)})`;

  return (
    <Layout>
      {hasMultipleToEntities ? (
        <Actions>
          <ToEntitySelector
            onChange={setSelectedToEntities}
            selectedToEntities={selectedToEntities}
            toEntities={toEntities}
          />
        </Actions>
      ) : null}
      {selectedToEntities.map(
        (toEntity: IToEntity): JSX.Element => (
          <ToEntity key={toEntity.to_entity}>
            <AnimationProvider>
              <ETagFilteringProvider>
                <ETagSortingProvider>
                  <ToEntityMonitor
                    closeAllAlerts={closeAllAlerts}
                    encodedPermissionsId={encodeIds(
                      ['toEntityMonitor'],
                      toEntity.to_entity,
                    )}
                    isEmbeddedTitle={hasMultipleToEntities}
                    maxHeight={toEntityMonitorHeight}
                    onAlert={addAlert}
                    toEntity={toEntity}
                    toEntityUserState={toEntityUserStates[toEntity.to_entity]}
                    toEntityLossesConfig={toEntityLossesConfig}
                  />
                </ETagSortingProvider>
              </ETagFilteringProvider>
            </AnimationProvider>
          </ToEntity>
        ),
      )}
    </Layout>
  );
};

export default withFloatOver(withAlertsQueue(SummaryPageContent));

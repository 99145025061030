import { Modal as AntDesignModal } from 'antd';
import { ModalFuncProps } from 'antd/es/modal';
import { useRef, useState } from 'react';

interface IModalHandle {
  destroy: () => void;
}

export enum EModalType {
  INFO,
  ERROR,
  SUCCESS,
}

/**
 * AntDesign has a useModal hook, but
 * it seems to allow more than one modal to be overlayed.
 * This hook ensures there is at most one modal.
 * If you call 'show' many times, only the latest modal
 * shown will survive. The rest will be destroyed.
 */
export const useModal = () => {
  const [modalType, setModalType] = useState<EModalType | undefined>();
  const modalHandleRef = useRef<IModalHandle>();
  const show = (modalTypeToShow: EModalType, modalProps: ModalFuncProps) => {
    if (modalType !== modalTypeToShow) {
      destroy();
      setModalType(modalTypeToShow);
      switch (modalTypeToShow) {
        case EModalType.INFO:
          modalHandleRef.current = AntDesignModal.info(modalProps);
          break;
        case EModalType.SUCCESS:
          modalHandleRef.current = AntDesignModal.success(modalProps);
          break;
        case EModalType.ERROR:
          modalHandleRef.current = AntDesignModal.error(modalProps);
          break;
      }
    }
  };
  const destroy = () => {
    if (modalHandleRef.current !== undefined) {
      setModalType(undefined);
      modalHandleRef.current.destroy();
    }
  };

  return {
    destroy,
    show,
  };
};

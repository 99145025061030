import HelpUI from './HelpUI';
import { encodeIds } from '../../../utils/general';
import { useState } from 'react';
import { TToEntityId } from '../../../types/ToEntity';
import useAsyncEffect from 'use-async-effect';

const ToEntityHelpUI = (props: any): JSX.Element => {
  const [toEntityId, setToEntityId] = useState<TToEntityId>('');
  const encodedPermissionsId = encodeIds(['alertUI'], toEntityId);
  const { toEntity } = props;

  useAsyncEffect(async () => {
    const { toEntityId } = props;
    if (toEntityId) {
      setToEntityId(toEntityId);
    }
  }, [props]);

  return (
    <HelpUI encodedPermissionsId={encodedPermissionsId} toEntity={toEntity} />
  );
};

export default ToEntityHelpUI;

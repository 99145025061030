import { IRoute } from 'components/App/types';
import AlertRulesPage from 'components/pages/AlertRulesPage/AlertRulesPage';
import ApprovalConfigsPage from 'components/pages/ApprovalConfigsPage/ApprovalConfigsPage';
import DetailPage from 'components/pages/DetailPage/DetailPage';
import DetailPrintPage from 'components/pages/DetailPrintPage/DetailPrintPage';
import SummaryOptionsPage from 'components/pages/SummaryOptionsPage/SummaryOptionsPage';
import SummaryPage from 'components/pages/SummaryPage/SummaryPage';
import TemplatesPage from 'components/pages/TemplatesPage/TemplatesPage';
import TransmissionCapacityAvailabilityPage from 'components/pages/TransmissionCapacityAvailabilityPage/TransmissionCapacityAvailabilityPage';

import {
  ALERT_RULES_ROUTE,
  ALERT_ROUTE,
  APPROVAL_CONFIGS_ROUTE,
  DETAIL_PRINT_ROUTE,
  DETAIL_ROUTE,
  SUMMARY_OPTIONS_ROUTE,
  SUMMARY_ROUTE,
  TEMPLATES_ROUTE,
  TRANSMISSION_CAPACITY_AVAILABILITY_ROUTE,
  HELP_ROUTE,
} from 'constants/routes';
import AlertPage from '../pages/AlertPage/AlertPage';
import HelpTutorialsPage from '../pages/HelpTutorialsPage/HelpTutorialsPage';

export const ROUTES: IRoute[] = [
  {
    component: SummaryPage,
    exact: true,
    name: 'Summary',
    path: SUMMARY_ROUTE,
  },
  {
    component: DetailPage,
    exact: true,
    name: 'Detail',
    path: DETAIL_ROUTE,
  },
  {
    component: DetailPrintPage,
    exact: true,
    name: 'Detail Print',
    path: DETAIL_PRINT_ROUTE,
  },
  {
    component: SummaryOptionsPage,
    exact: true,
    name: 'Summary Options',
    path: SUMMARY_OPTIONS_ROUTE,
  },
  {
    component: AlertRulesPage,
    exact: true,
    name: 'Alert Rules',
    path: ALERT_RULES_ROUTE,
  },
  {
    component: TemplatesPage,
    exact: true,
    name: 'Templates',
    path: TEMPLATES_ROUTE,
  },
  {
    component: ApprovalConfigsPage,
    exact: true,
    name: 'Approval Configuration',
    path: APPROVAL_CONFIGS_ROUTE,
  },
  {
    component: TransmissionCapacityAvailabilityPage,
    exact: true,
    name: 'Transmission Availability',
    path: TRANSMISSION_CAPACITY_AVAILABILITY_ROUTE,
  },
  {
    component: AlertPage,
    exact: true,
    name: 'Alert',
    path: ALERT_ROUTE,
  },
  {
    component: HelpTutorialsPage,
    exact: true,
    name: 'Tutorials',
    path: HELP_ROUTE,
  },
];

export enum ECompositeState {
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  Denied = 'DENIED',
  Draft = 'DRAFT',
  Expired = 'EXPIRED',
  Implemented = 'IMPLEMENTED',
  Pending = 'PENDING',
  Terminated = 'TERMINATED',
  Withdrawn = 'WITHDRAWN',
}

export enum ERequestStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Expired = 'EXPIRED',
  Pending = 'PENDING',
  Withdrawn = 'WITHDRAWN',
}

export enum ELinkageStatus {
  Unlinked = 'Unlinked',
  PartiallyLinked = 'PartiallyLinked',
  FullyLinked = 'FullyLinked',
  OverLinked = 'OverLinked',
}

export enum ERequestType {
  AtfAdjustment = 'ATF_ADJUSTMENT',
  BtfAdjustment = 'BTF_ADJUSTMENT',
  Correction = 'CORRECTION',
  CurrentLevel = 'CURRENT_LEVEL',
  CurrentPendingLevel = 'CURRENT_PENDING_LEVEL',
  Curtailment = 'CURTAILMENT',
  Extension = 'EXTENSION',
  NewTag = 'NEW_TAG',
  None = 'NONE',
  Reload = 'RELOAD',
  Termination = 'TERMINATION',
}

export enum ETransactionType {
  Capacity = 'CAPACITY',
  Dynamic = 'DYNAMIC',
  Emergency = 'EMERGENCY',
  LossSupply = 'LOSSSUPPLY',
  Normal = 'NORMAL',
  PseudoTie = 'PSEUDOTIE',
  Recallable = 'RECALLABLE',
}

export enum ETimeClassification {
  ATF = 'ATF',
  Late = 'Late',
  OnTime = 'OnTime',
}

export enum EProfileSegment {
  Gen = 'gen',
  Load = 'load',
  Pod = 'pod',
}

export enum EColumnType {
  Boolean = 'boolean',
  Date = 'date',
  DateTime = 'datetime',
  DetailLink = 'detailLink',
  Enumeration = 'enumeration',
  HourEndings = 'hourEndings',
  Indicator = 'indicator',
  Integer = 'integer',
  String = 'string',
  Group = 'group',
}

export enum EColumnPosition {
  Left = 'left',
  Right = 'right',
  Unfixed = 'unfixed',
}

export enum EProfileType {
  Energy = 'Energy',
  Transmission = 'TransAlloc',
}

export enum EEnergyProfileType {
  MarketLevel = 'MARKETLEVEL',
  ReliabilityLimit = 'RELIABILITYLIMIT',
}

export enum EMessageType {
  CompositeStateChange = 'CompositeStateChange',
  DeleteDraft = 'DeleteDraft',
  DistributeCorrection = 'DistributeCorrection',
  DistributeNewTag = 'DistributeNewTag',
  DistributePotentialTLRProfileChange = 'DistributePotentialTLRProfileChange',
  DistributeProfileChange = 'DistributeProfileChange',
  DistributeResolution = 'DistributeResolution',
  DistributeStatus = 'DistributeStatus',
  DistributeTerminateTag = 'DistributeTerminateTag',
  DraftResolved = 'DraftResolved',
  PutDraft = 'PutDraft',
}

export enum ELossMethodEntryType {
  External = 'External',
  Financial = 'Financial',
  InKind = 'InKind',
  Internal = 'Internal',
}

export enum EEditMode {
  Adjustment = 'adjustment',
  Correction = 'correction',
}

export enum EDistributedTagItem {
  AllTransmissionAllocations = 'allTransmissionAllocations',
  LossAccountings = 'lossAccountings',
  MarketSegment = 'marketSegment',
  PhysicalSegment = 'physicalSegment',
  PhysicalSegmentsProfiles = 'physicalSegmentsProfiles',
  Summary = 'summary',
  TransactionStatuses = 'transactionStatuses',
  TransmissionAllocations = 'transmissionAllocations',
}

export enum ESearchType {
  ByTagCode = 'byTagCode',
  ByTagId = 'byTagId',
}

export enum EMarketInfoMarket {
  MISO = 'MISO',
  SPP = 'SPP',
}

export enum EMarketInfoTransactionType {
  Dispatchable = 'DISPATCHABLE',
  Fixed = 'FIXED',
  UpToTuc = 'UPTOTUC',
}

export enum EMarketInfoMarketType {
  Both = 'BOTH',
  DayAhead = 'DAYAHEAD',
  RealTime = 'REALTIME',
}

export enum EDraftScheduleStatus {
  Submitted = 'SUBMITTED',
  Failed = 'FAIL',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
}

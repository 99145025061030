import { Card as AntDesignCard } from 'antd';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import Separator from 'components/atoms/Separator/Separator';
import RedoButton from 'components/molecules/RedoButton/RedoButton';
import UndoButton from 'components/molecules/UndoButton/UndoButton';
import CustomFilter from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/CustomFilter/CustomFilter';
import { ICustomFilterEditHistory } from 'components/organisms/ToEntityCustomFilterConfigurator/CustomFilterEditor/types';
import { STANDARD_SPACING_VALUE } from 'constants/styles';
import { ICustomFilter } from 'interfaces/Filter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import { generateCustomFilter } from 'utils/filter';

const Container = styled.div``;

const Editor = styled.div`
  height: 50vh;
  overflow: auto;
  padding: ${STANDARD_SPACING_VALUE}px ${STANDARD_SPACING_VALUE / 2}px;
`;

interface ICustomFilterEditorProps {
  customFilter?: ICustomFilter;
  onCustomFilterChange?: (customFilter: ICustomFilter) => void;
}

const CustomFilterEditor = ({
  customFilter,
  onCustomFilterChange,
}: ICustomFilterEditorProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const [customFilterEditHistory, setCustomFilterEditHistory] =
    useState<ICustomFilterEditHistory>({
      future: [],
      past: [],
      present:
        customFilter === undefined ? generateCustomFilter() : customFilter,
    });

  const resetCustomFilterEditHistory = useCallback(
    (customFilter: ICustomFilter) => {
      setCustomFilterEditHistory(
        (): ICustomFilterEditHistory => ({
          future: [],
          past: [],
          present: customFilter,
        }),
      );
    },
    [],
  );

  useEffect(() => {
    resetCustomFilterEditHistory(
      customFilter === undefined ? generateCustomFilter() : customFilter,
    );
  }, [customFilter, resetCustomFilterEditHistory]);

  const handleUndo = useCallback(() => {
    setCustomFilterEditHistory(
      (
        previousCustomFilterEditHistory: ICustomFilterEditHistory,
      ): ICustomFilterEditHistory => {
        if (previousCustomFilterEditHistory.past.length > 0) {
          const updatedPast: ICustomFilter[] = [
            ...previousCustomFilterEditHistory.past,
          ];
          const updatedFuture: ICustomFilter[] =
            previousCustomFilterEditHistory.future.concat(
              previousCustomFilterEditHistory.present,
            );
          const updatedPresent: ICustomFilter = updatedPast.pop()!;

          return {
            future: updatedFuture,
            past: updatedPast,
            present: updatedPresent,
          };
        }

        return previousCustomFilterEditHistory;
      },
    );
  }, []);

  const handleRedo = useCallback(() => {
    setCustomFilterEditHistory(
      (
        previousCustomFilterEditHistory: ICustomFilterEditHistory,
      ): ICustomFilterEditHistory => {
        if (previousCustomFilterEditHistory.future.length > 0) {
          const updatedPast: ICustomFilter[] =
            previousCustomFilterEditHistory.past.concat(
              previousCustomFilterEditHistory.present,
            );
          const updatedFuture: ICustomFilter[] = [
            ...previousCustomFilterEditHistory.future,
          ];
          const updatedPresent: ICustomFilter = updatedFuture.pop()!;

          return {
            future: updatedFuture,
            past: updatedPast,
            present: updatedPresent,
          };
        }

        return previousCustomFilterEditHistory;
      },
    );
  }, []);

  const handleCustomFilterChange = useCallback(
    (customFilter: ICustomFilter) => {
      setCustomFilterEditHistory(
        (
          previousCustomFilterEditHistory: ICustomFilterEditHistory,
        ): ICustomFilterEditHistory => ({
          future: [],
          past: [
            ...previousCustomFilterEditHistory.past,
            previousCustomFilterEditHistory.present,
          ],
          present: customFilter,
        }),
      );

      onCustomFilterChange?.(customFilter);
    },
    [onCustomFilterChange],
  );

  const handleCustomFilterRemove = useCallback(() => {
    const newCustomFilter: ICustomFilter = generateCustomFilter();

    if (customFilter !== undefined) {
      newCustomFilter.filter_id = customFilter.filter_id;
      newCustomFilter.filter_name = customFilter.filter_name;
    }

    handleCustomFilterChange(newCustomFilter);
  }, [customFilter, handleCustomFilterChange]);

  const undoDisabled: boolean = useMemo(
    (): boolean => customFilterEditHistory.past.length < 1,
    [customFilterEditHistory.past.length],
  );

  const redoDisabled: boolean = useMemo(
    (): boolean => customFilterEditHistory.future.length < 1,
    [customFilterEditHistory.future.length],
  );

  return (
    <AntDesignCard>
      <Container>
        <SeparatedRowLayout>
          <UndoButton isDisabled={undoDisabled} onClick={handleUndo} />
          <RedoButton isDisabled={redoDisabled} onClick={handleRedo} />
        </SeparatedRowLayout>
        <Separator currentTheme={currentTheme!} />
        <Editor>
          {customFilter === undefined ? null : (
            <CustomFilter
              customFilter={customFilterEditHistory.present}
              onChange={handleCustomFilterChange}
              onRemove={handleCustomFilterRemove}
            />
          )}
        </Editor>
      </Container>
    </AntDesignCard>
  );
};

export default CustomFilterEditor;

import {
  AutoComplete as AntDesignAutoComplete,
  Input as AntDesignInput,
} from 'antd';
import { AxiosResponse } from 'axios';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import Input from 'components/atoms/Input/Input';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import ToEntityETagPopover from 'components/molecules/ToEntityETagPopover/ToEntityETagPopover';
import { ERROR_MESSAGE_MAX_WIDTH } from 'components/molecules/ToEntityETagTemplateCreate/constants';
import { ITemplateGroupValue } from 'components/molecules/ToEntityETagTemplateCreate/types';
import { TEMPLATE_GROUP_NAME_REGEX } from 'constants/ETag';
import { NOT_FOUND_STATUS_CODE } from 'constants/misc';
import { ECompositeState } from 'enums/ETag';
import { EActionState } from 'enums/General';
import { EPageMode } from 'enums/Page';
import { IOption } from 'interfaces/Component';
import {
  IETagExtendedIdentifier,
  IETagTemplateCreateResponse,
  IETagTemplateGroup,
  IETagTemplateGroupsResponse,
} from 'interfaces/ETag';
import { ChangeEvent, useMemo, useState } from 'react';
import {
  createTemplateFromDistributed,
  createTemplateFromDraft,
  retrieveTemplateGroups,
} from 'services/agent/templates';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TErrorMessage } from 'types/Error';
import { TToEntityId } from 'types/ToEntity';
import useAsyncEffect from 'use-async-effect';
import { sortByOptionLabel } from 'utils/component';
import { detailPageLocationString, filterTemplateGroups } from 'utils/detail';
import { captureError } from 'utils/error';
import { isEmptyValue, isSuccessStatus } from 'utils/general';
import {
  retrieveETagMarketInfos,
  updateETagMarketInfos,
} from '../../../services/agent/marketInfos';

const { TextArea: AntDesignTextArea } = AntDesignInput;

const NameLabel = styled.span`
  max-width: 92px;
  width: 100%;
`;

const GroupNameLabel = styled.span`
  max-width: 94px;
  width: 100%;
`;

const DescriptionLabel = styled.span`
  max-width: 124px;
  width: 100%;
`;

// Specialize the Select component
const TemplateGroupAutoComplete = styled((props) => (
  <AntDesignAutoComplete {...props} />
))`
  width: 204px;
`;

interface IToEntityETagTemplateCreatePopoverProps {
  eTagExtendedIdentifier: IETagExtendedIdentifier;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const ToEntityETagTemplateCreatePopover = (
  props: IToEntityETagTemplateCreatePopoverProps,
): JSX.Element => {
  const { eTagExtendedIdentifier, timeZone, toEntityId } = props;
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [name, setName] = useState<string>('');
  const [groupName, setGroupName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [includeEnergyProfiles, setIncludeEnergyProfiles] =
    useState<boolean>(false);
  const [includeTransmissionProfiles, setIncludeTransmissionProfiles] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<TErrorMessage>(null);

  const [templateGroupOptions, setTemplateGroupOptions] = useState<
    ITemplateGroupValue[]
  >([]);
  const [currentTemplateGroupValue, setCurrentTemplateGroupValue] =
    useState<ITemplateGroupValue>({ value: '' });

  useAsyncEffect(async () => {
    try {
      const groupsResponse: AxiosResponse = await retrieveTemplateGroups(
        toEntityId,
      );

      const eTagTemplateGroupsResponse: IETagTemplateGroupsResponse =
        groupsResponse.data;

      if (!isSuccessStatus(groupsResponse.status)) {
        throw new Error(eTagTemplateGroupsResponse.errorMessage!);
      }

      const incomingTemplateGroups: IETagTemplateGroup[] =
        eTagTemplateGroupsResponse.response;

      const incomingTemplateGroupOptions: ITemplateGroupValue[] =
        incomingTemplateGroups.map(
          (option: IETagTemplateGroup): ITemplateGroupValue => ({
            value: option.group_name,
          }),
        );

      setTemplateGroupOptions(incomingTemplateGroupOptions);
    } catch (error: any) {
      captureError(error, 'Failed to load list of template groups');
    }
  }, [toEntityId]);

  const handleTemplateNameInput = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleTemplateGroupNameInput = (value: string) => {
    // Only empty string, letters, number, _ and - are allowed!
    // remove spaces from value in input box
    const valueWithoutSpaces: string = value.replace(' ', '');
    setCurrentTemplateGroupValue({ value: valueWithoutSpaces });
    if (
      valueWithoutSpaces.match(TEMPLATE_GROUP_NAME_REGEX) !== null ||
      valueWithoutSpaces === ''
    ) {
      setGroupName(valueWithoutSpaces);
    }
  };

  const handleTemplateDescriptionInput = (
    event: ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setDescription(event.target.value);
  };

  const handleCreate = async () => {
    // Add the option to the dropdown if it isn't there already.
    // Covers the case where the user creates two templates within one opening of the dropdown
    if (
      templateGroupOptions.find(
        (element) => element.value === currentTemplateGroupValue.value,
      ) === undefined
    ) {
      setTemplateGroupOptions(
        templateGroupOptions.concat(currentTemplateGroupValue),
      );
    }

    try {
      setActionState(EActionState.Actioning);

      setErrorMessage(null);

      let response: AxiosResponse<IETagTemplateCreateResponse> | undefined =
        undefined;

      if (eTagExtendedIdentifier.composite_state === ECompositeState.Draft) {
        if (eTagExtendedIdentifier.draft_id === null) {
          throw new Error('Cannot create a template with a null draft_id');
        }

        response = await createTemplateFromDraft(
          toEntityId,
          eTagExtendedIdentifier.draft_id,
          name,
          groupName,
          description,
        );
      } else {
        if (eTagExtendedIdentifier.tag_primary_key === undefined) {
          throw new Error(
            'Cannot create a template with a undefined tag_primary_key',
          );
        }

        response = await createTemplateFromDistributed(
          toEntityId,
          eTagExtendedIdentifier.tag_primary_key,
          name,
          includeEnergyProfiles,
          includeTransmissionProfiles,
          groupName,
          description,
        );
      }

      const eTagTemplateCreateResponse: IETagTemplateCreateResponse =
        response.data;

      if (!isSuccessStatus(response.status)) {
        throw new Error(
          eTagTemplateCreateResponse.errorMessage === null
            ? 'Unknown error'
            : eTagTemplateCreateResponse.errorMessage,
        );
      }

      setActionState(EActionState.Succeeded);

      if (eTagTemplateCreateResponse.response.templateId !== null) {
        // Create a new Market Info record for the template
        if (eTagExtendedIdentifier && eTagExtendedIdentifier.tag_primary_key) {
          const marketInfo = await retrieveETagMarketInfos(
            toEntityId,
            eTagExtendedIdentifier?.draft_id,
            eTagExtendedIdentifier?.tag_primary_key,
            eTagTemplateCreateResponse.response.templateId,
          );
          if (
            marketInfo &&
            marketInfo.data &&
            marketInfo.data.response &&
            marketInfo.status !== NOT_FOUND_STATUS_CODE
          ) {
            await updateETagMarketInfos(
              toEntityId,
              eTagExtendedIdentifier?.draft_id,
              eTagTemplateCreateResponse.response.templateId,
              marketInfo.data.response,
            );
          }
        }
        const href: string = detailPageLocationString({
          defaultTimeZone: timeZone,
          templateId: eTagTemplateCreateResponse.response.templateId,
          mode: EPageMode.Edit,
          toEntity: toEntityId,
        });
        window.open(`${process.env.PUBLIC_URL}${href}`, '_blank');
      } else {
        throw new Error(
          'No templateId in response, cannot open draft edit page',
        );
      }
    } catch (error: any) {
      captureError(error);

      if (error instanceof Error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          'An error occurred during template creation. Please try again later.',
        );
      }

      setActionState(EActionState.Failed);
    }
  };

  const sortedTemplateGroupOptions: IOption<string | null>[] = useMemo(
    () =>
      templateGroupOptions
        .map((option: ITemplateGroupValue) => ({
          label: option.value,
          value: option.value,
        }))
        .sort(sortByOptionLabel),
    [templateGroupOptions],
  );

  return (
    <ToEntityETagPopover
      actionState={actionState}
      errorMessage={errorMessage}
      errorMessageMaxWidth={ERROR_MESSAGE_MAX_WIDTH}
      isPrimaryActionDisabled={isEmptyValue(name)}
      onPrimaryAction={handleCreate}
      primaryActionLabel='Save as'
      timeZone={timeZone}
      title={`Save e-Tag ${eTagExtendedIdentifier?.ui_tag_id} as template`}
    >
      <SeparatedRowLayout>
        <NameLabel>Template Name:</NameLabel>
        <Input
          onChange={handleTemplateNameInput}
          placeholder='Template Name'
          value={name}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <GroupNameLabel>
          Template Group:
          <br />
          (optional)
        </GroupNameLabel>
        <TemplateGroupAutoComplete
          filterOption={filterTemplateGroups}
          options={sortedTemplateGroupOptions}
          placeholder={'Select Group Filter'}
          onChange={handleTemplateGroupNameInput}
          value={groupName}
        />
      </SeparatedRowLayout>
      <SeparatedRowLayout>
        <DescriptionLabel>
          Template Description:
          <br />
          (optional)
        </DescriptionLabel>
        <AntDesignTextArea
          onChange={handleTemplateDescriptionInput}
          placeholder='Template Description'
          rows={3}
          value={description}
        />
      </SeparatedRowLayout>
      {eTagExtendedIdentifier.composite_state ===
      ECompositeState.Draft ? null : (
        <>
          <Checkbox
            checked={includeEnergyProfiles}
            label='Include Energy Profiles'
            onChange={setIncludeEnergyProfiles}
          />
          <Checkbox
            checked={includeTransmissionProfiles}
            label='Include Transmission Profiles'
            onChange={setIncludeTransmissionProfiles}
          />
        </>
      )}
    </ToEntityETagPopover>
  );
};

export default ToEntityETagTemplateCreatePopover;

import DataIndicator from 'components/atoms/DataIndicator/DataIndicator';
import {
  ICON_BUTTON_SIZE_VALUE,
  VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH,
  VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE,
  VIEW_DATA_TABLE_COLUMN_LOSS_METHOD_WIDTH,
  VIEW_DATA_TABLE_COLUMN_LOSS_TAG_IDS_WIDTH,
  VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
} from 'constants/styles';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { ELossMethodEntryType, ERequestType } from 'enums/ETag';
import usePrevious from 'hooks/usePrevious';
import {
  ELossMethodsExpandedColumn,
  IEditLossMethodsInformation,
} from 'hooks/useTransmissionEditColumns/LossMethodsEdit/types';
import { IOption } from 'interfaces/Component';
import {
  IDateTimePickerColumnConfig,
  IIconButtonColumnConfig,
  ISelectColumnConfig,
} from 'interfaces/View';
import { ReactNode, useCallback, useMemo } from 'react';
import { IDetailLossAccounting } from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import { TDisabledDateHandler } from 'types/General';
import {
  getEditInfoKey,
  getInitialLossAccountingKey,
  getSplitEditInfoKey,
  stopDisabledDate,
} from 'utils/detail';
import { isEmptyValue, simpleEqualityChecker } from 'utils/general';
import { toFormattedDateTimeString, toFormattedUtcString } from 'utils/time';
import {
  getColumnDateTimePickerRender,
  getColumnIconButton,
  getColumnRender,
  getColumnSelectRender,
  getPidSelectNoHighlightRender,
  getPidSelectRender,
} from 'utils/views';
import { ZonedDateTime } from 'utils/zonedDateTime';
import moment from 'moment/moment';
import { EViewMode } from 'enums/View';
import { INITIAL_RECORD_ID } from '../../../constants/Detail';

const LOSS_METHOD_ENTRY_TYPE_OPTIONS = Object.values(ELossMethodEntryType).map(
  (value: ELossMethodEntryType) => ({ label: value as string, value }),
);

export const getColumnLossMethodsIdColumnConfig = () => ({
  dataIndex: 'key',
  render: (
    value: unknown,
    record: IDetailLossAccounting,
    index: number,
  ): JSX.Element => {
    return <div>{index + 1}</div>;
  },
  title: 'ID',
  width: VIEW_DATA_TABLE_COLUMN_ID_COLUMN_WIDTH,
});

const useLossMethodsEditColumns = (
  getInitialDetailLossAccountingForKey: (
    key: string,
  ) => IDetailLossAccounting | undefined,
  getStartDisabledDate: (
    record: IDetailLossAccounting,
  ) => TDisabledDateHandler | undefined,
  initialLossAccountings: IDetailLossAccounting[],
  initialLossAccountingsIds: number[],
  initialLossAccountingsKeys: string[],
  isDetailDeleted: boolean,
  isDetailUpdating: boolean,
  isUnconstrained: boolean,
  lossAccountings: IDetailLossAccounting[],
  onChange: (editLossMethodsInformation: IEditLossMethodsInformation) => void,
  onClick: (
    lossMethodsExpandedColumn: ELossMethodsExpandedColumn,
    record: IDetailLossAccounting,
  ) => void,
  pidOptions: IOption<number>[],
  previousIsDetailDeleted: boolean | undefined,
  previousIsDetailUpdating: boolean | undefined,
  selectedRequestType: ERequestType | undefined,
  timeZone: TTimeZone,
  viewMode: EViewMode,
) => {
  const shouldAllowEdit = (
    viewMode: EViewMode,
    record: IDetailLossAccounting,
    initialLossAccountingsKeys: string[],
  ): boolean => {
    const { label, editIndex } = getSplitEditInfoKey(record.key);
    const key = getEditInfoKey(
      label,
      record.physical_segment_ref || INITIAL_RECORD_ID,
      editIndex,
    );

    return (
      (viewMode !== EViewMode.EditETagAdjustment &&
        viewMode !== EViewMode.EditETagAdjustmentWithATF) ||
      ((viewMode === EViewMode.EditETagAdjustment ||
        viewMode === EViewMode.EditETagAdjustmentWithATF) &&
        !initialLossAccountingsKeys.includes(key))
    );
  };

  const dateTimePickerRender =
    getColumnDateTimePickerRender<IDetailLossAccounting>('100%');

  const lossMethodsDateTimePickerRender =
    (
      dateTimePickerColumnConfig: IDateTimePickerColumnConfig<IDetailLossAccounting>,
      viewMode: EViewMode,
      initialLossAccountingsIds: number[],
      isUnconstrained: boolean,
    ) =>
    (
      value: unknown,
      record: IDetailLossAccounting,
      index: number,
    ): JSX.Element => {
      const formattedDateValue =
        typeof value === 'string'
          ? toFormattedDateTimeString(value, timeZone)
          : value;
      return viewMode === EViewMode.EditETagCorrection ||
        !shouldAllowEdit(viewMode, record, initialLossAccountingsKeys)
        ? getColumnRender(isUnconstrained)(formattedDateValue)
        : dateTimePickerRender(dateTimePickerColumnConfig)(value, record);
    };

  const lossMethodsPidNoHighlightSelectRender =
    getPidSelectNoHighlightRender<IDetailLossAccounting>();
  const lossMethodsPidSelectRender =
    getPidSelectRender<IDetailLossAccounting>();
  const lossMethodsPidRender =
    (
      selectColumnConfig: ISelectColumnConfig<number, IDetailLossAccounting>,
      viewMode: EViewMode,
      initialLossAccountingsIds: number[],
      isUnconstrained: boolean,
    ) =>
    (
      value: unknown,
      record: IDetailLossAccounting,
      index: number,
    ): JSX.Element =>
      viewMode === EViewMode.EditETagCorrection ||
      !shouldAllowEdit(viewMode, record, initialLossAccountingsKeys)
        ? getColumnRender(isUnconstrained)(value)
        : record.key ===
          getInitialLossAccountingKey(record.physical_segment_ref || undefined)
        ? lossMethodsPidNoHighlightSelectRender(selectColumnConfig)(
            value,
            record,
            index,
          )
        : lossMethodsPidSelectRender(selectColumnConfig)(value, record, index);
  const getLossMethodEntryTypeSelectRender = getColumnSelectRender<
    ELossMethodEntryType | null,
    IDetailLossAccounting
  >('100%');
  const lossMethodEntryTypeSelectRender =
    (
      selectColumnConfig: ISelectColumnConfig<
        ELossMethodEntryType | null,
        IDetailLossAccounting
      >,
      viewMode: EViewMode,
      initialLossAccountingsIds: number[],
      isUnconstrained: boolean,
    ) =>
    (
      value: unknown,
      record: IDetailLossAccounting,
      index: number,
    ): JSX.Element =>
      viewMode === EViewMode.EditETagCorrection ||
      !shouldAllowEdit(viewMode, record, initialLossAccountingsKeys)
        ? getColumnRender(isUnconstrained)(value)
        : getLossMethodEntryTypeSelectRender(selectColumnConfig)(
            value,
            record,
            index,
          );

  const getLossMethodTagIdsIconButtonRender =
    getColumnIconButton<IDetailLossAccounting>('100%');

  const lossMethodTagIdsIconButtonRender =
    (
      iconButtonConfig: IIconButtonColumnConfig<IDetailLossAccounting>,
      viewMode: EViewMode,
      initialLossAccountingsIds: number[],
      isUnconstrained: boolean,
    ) =>
    (
      value: unknown,
      record: IDetailLossAccounting,
      index: number,
    ): JSX.Element =>
      viewMode === EViewMode.EditETagCorrection ||
      !shouldAllowEdit(viewMode, record, initialLossAccountingsKeys)
        ? getColumnRender(isUnconstrained)(value)
        : getLossMethodTagIdsIconButtonRender(iconButtonConfig)(value, record);

  const getLossMethodContractsIconButtonRender =
    getColumnIconButton<IDetailLossAccounting>(
      `${
        VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH_VALUE -
        ICON_BUTTON_SIZE_VALUE
      }px`,
    );

  const lossMethodContractsIconButtonRender =
    (
      iconButtonConfig: IIconButtonColumnConfig<IDetailLossAccounting>,
      viewMode: EViewMode,
      initialLossAccountingsIds: number[],
      isUnconstrained: boolean,
    ) =>
    (
      value: unknown,
      record: IDetailLossAccounting,
      index: number,
    ): JSX.Element =>
      viewMode === EViewMode.EditETagCorrection ||
      !shouldAllowEdit(viewMode, record, initialLossAccountingsKeys)
        ? getColumnRender(isUnconstrained)(value)
        : getLossMethodContractsIconButtonRender(iconButtonConfig)(
            value,
            record,
          );

  const previousLossAccountings: IDetailLossAccounting[] | undefined =
    usePrevious(lossAccountings);
  const previousPidOptions: IOption<number>[] | undefined =
    usePrevious(pidOptions);
  const previousSelectedRequestType: ERequestType | undefined =
    usePrevious(selectedRequestType);
  const previousTimeZone: TTimeZone | undefined = usePrevious(timeZone);

  const getPidOptions = useCallback(
    async (_: IDetailLossAccounting, index: number) => {
      let highestPIDBefore: number = Number.MIN_SAFE_INTEGER;
      let lowestPIDAfter: number = Number.MAX_SAFE_INTEGER;

      for (let i: number = 0; i < index; i += 1) {
        const detailLossAccounting: IDetailLossAccounting = lossAccountings[i];

        if (
          detailLossAccounting.physical_segment_ref !== null &&
          detailLossAccounting.physical_segment_ref > highestPIDBefore
        ) {
          highestPIDBefore = detailLossAccounting.physical_segment_ref;
        }
      }

      for (let i: number = index + 1; i < lossAccountings.length; i += 1) {
        const detailLossAccounting: IDetailLossAccounting = lossAccountings[i];

        if (
          detailLossAccounting.physical_segment_ref !== null &&
          detailLossAccounting.physical_segment_ref < lowestPIDAfter
        ) {
          lowestPIDAfter = detailLossAccounting.physical_segment_ref;
        }
      }

      return pidOptions;
    },
    [lossAccountings, pidOptions],
  );

  const getInitialLossMethodsPidSelectValue = useCallback(
    (record: IDetailLossAccounting): number | null => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        initialLossAccountings.find(
          (detailLossAccounting: IDetailLossAccounting): boolean =>
            detailLossAccounting.key === record.key,
        );

      return initialDetailLossAccounting === undefined
        ? null
        : initialDetailLossAccounting.physical_segment_ref;
    },
    [initialLossAccountings],
  );

  const pidSelectOnChange = useCallback(
    (value: number | undefined, record: IDetailLossAccounting) => {
      onChange({
        editLossAccountingMap: {
          [record.key]: {
            physical_segment_ref: value === undefined ? null : value,
          },
        },
      });
    },
    [onChange],
  );

  const getInitialLossMethodsStartDateTimePickerValue = useCallback(
    (record: IDetailLossAccounting): string | null => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(record.key);

      return initialDetailLossAccounting === undefined
        ? null
        : initialDetailLossAccounting.start;
    },
    [getInitialDetailLossAccountingForKey],
  );

  const startDateTimePickerOnChange = useCallback(
    (value: ZonedDateTime | null, record: IDetailLossAccounting) => {
      onChange({
        editLossAccountingMap: {
          [record.key]: {
            start: value === null ? null : toFormattedUtcString(value),
          },
        },
        withPhysicalSegmentRef:
          record.physical_segment_ref === null ||
          record.physical_segment_ref === undefined
            ? undefined
            : record.physical_segment_ref,
      });
    },
    [onChange],
  );

  const getInitialLossMethodsStopDateTimePickerValue = useCallback(
    (record: IDetailLossAccounting): string | null => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(record.key);

      return initialDetailLossAccounting === undefined
        ? null
        : initialDetailLossAccounting.stop;
    },
    [getInitialDetailLossAccountingForKey],
  );

  const getStopDisabledDate = useCallback(
    (record: IDetailLossAccounting): TDisabledDateHandler =>
      (dateTime: ZonedDateTime | null, isSelected?: boolean): boolean =>
        stopDisabledDate(record.start, dateTime, isSelected),
    [],
  );

  const stopDateTimePickerOnChange = useCallback(
    (value: ZonedDateTime | null, record: IDetailLossAccounting) => {
      onChange({
        editLossAccountingMap: {
          [record.key]: {
            stop: value === null ? null : toFormattedUtcString(value),
          },
        },
        withPhysicalSegmentRef:
          record.physical_segment_ref === null ||
          record.physical_segment_ref === undefined
            ? undefined
            : record.physical_segment_ref,
      });
    },
    [onChange],
  );

  const getLossMethodEntryTypeOptions = useCallback(
    async () => LOSS_METHOD_ENTRY_TYPE_OPTIONS,
    [],
  );

  const getInitialLossMethodsEntryTypeSelectValue = useCallback(
    (record: IDetailLossAccounting): ELossMethodEntryType | null => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(record.key);

      if (
        initialDetailLossAccounting === undefined ||
        initialDetailLossAccounting.lossMethod === null ||
        initialDetailLossAccounting.lossMethod.loss_method_entry_type === null
      ) {
        return null;
      }

      return initialDetailLossAccounting.lossMethod.loss_method_entry_type;
    },
    [getInitialDetailLossAccountingForKey],
  );

  const lossMethodEntryTypeSelectOnChange = useCallback(
    (
      value: ELossMethodEntryType | null | undefined,
      record: IDetailLossAccounting,
    ) => {
      onChange({
        editLossAccountingMap: {
          [record.key]: {
            editLossMethod: {
              loss_method_entry_type: value === undefined ? null : value,
            },
          },
        },
        withPhysicalSegmentRef:
          record.physical_segment_ref === null ||
          record.physical_segment_ref === undefined
            ? undefined
            : record.physical_segment_ref,
      });
    },
    [onChange],
  );

  const getTagIdsIcon = useCallback(
    (value: number | string, record: IDetailLossAccounting): ReactNode => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(record.key);
      const valueChanged: boolean | undefined =
        initialDetailLossAccounting === undefined ||
        initialDetailLossAccounting.lossMethod === null ||
        record.lossMethod === null
          ? undefined
          : (initialDetailLossAccounting.lossMethod.tag_ids !== null &&
              record.lossMethod.tag_ids === null) ||
            (initialDetailLossAccounting.lossMethod.tag_ids === null &&
              record.lossMethod.tag_ids !== null &&
              record.lossMethod.tag_ids.length > 0) ||
            (initialDetailLossAccounting.lossMethod.tag_ids !== null &&
              record.lossMethod.tag_ids !== null &&
              initialDetailLossAccounting.lossMethod.tag_ids.length !==
                record.lossMethod.tag_ids.length);

      return (
        <DataIndicator
          value={
            record.lossMethod === null || record.lossMethod.tag_ids === null
              ? 0
              : record.lossMethod.tag_ids.length
          }
          valueChanged={valueChanged}
        />
      );
    },
    [getInitialDetailLossAccountingForKey],
  );

  const tagIdsIconButtonOnClick = useCallback(
    (record: IDetailLossAccounting) => {
      onClick(ELossMethodsExpandedColumn.ETagTagIds, record);
    },
    [onClick],
  );

  const tagIdsIconButtonGetIsDisabled = useCallback(
    (record: IDetailLossAccounting): boolean =>
      record.lossMethod === null ||
      record.lossMethod.loss_method_entry_type ===
        ELossMethodEntryType.Financial ||
      record.lossMethod.loss_method_entry_type === ELossMethodEntryType.InKind,
    [],
  );

  const getContractsIcon = useCallback(
    (value: number | string, record: IDetailLossAccounting): ReactNode => {
      const initialDetailLossAccounting: IDetailLossAccounting | undefined =
        getInitialDetailLossAccountingForKey(record.key);
      const valueChanged: boolean | undefined =
        initialDetailLossAccounting === undefined ||
        initialDetailLossAccounting.lossMethod === null ||
        record.lossMethod === null
          ? undefined
          : (initialDetailLossAccounting.lossMethod.contractNumbers !== null &&
              record.lossMethod.contractNumbers === null) ||
            (initialDetailLossAccounting.lossMethod.contractNumbers === null &&
              record.lossMethod.contractNumbers !== null &&
              record.lossMethod.contractNumbers.length > 0) ||
            (initialDetailLossAccounting.lossMethod.contractNumbers !== null &&
              record.lossMethod.contractNumbers !== null &&
              initialDetailLossAccounting.lossMethod.contractNumbers.length !==
                record.lossMethod.contractNumbers.length);

      return (
        <DataIndicator
          value={
            record.lossMethod === null ||
            record.lossMethod.contractNumbers === null
              ? 0
              : record.lossMethod.contractNumbers.length
          }
          valueChanged={valueChanged}
        />
      );
    },
    [getInitialDetailLossAccountingForKey],
  );

  const contractsIconButtonOnClick = useCallback(
    (record: IDetailLossAccounting) => {
      onClick(ELossMethodsExpandedColumn.Contracts, record);
    },
    [onClick],
  );

  const contractsIconButtonGetIsDisabled = useCallback(
    (record: IDetailLossAccounting): boolean =>
      record.lossMethod === null ||
      !(
        record.lossMethod.loss_method_entry_type ===
          ELossMethodEntryType.Internal &&
        (record.lossMethod.tag_ids === null ||
          record.lossMethod.tag_ids.length === 0)
      ),
    [],
  );

  return useMemo(
    () => {
      const hasTimeZoneChanged: boolean = timeZone !== previousTimeZone;
      const hasSelectedRequestTypeChanged: boolean =
        selectedRequestType !== previousSelectedRequestType;
      const isDetailDeletedChanged: boolean =
        isDetailDeleted !== previousIsDetailDeleted;
      const isDetailUpdatingChanged: boolean =
        isDetailUpdating !== previousIsDetailUpdating;
      const lossAccountingsChanged: boolean =
        lossAccountings !== previousLossAccountings;
      const pidOptionsChanged: boolean = pidOptions !== previousPidOptions;

      return [
        getColumnLossMethodsIdColumnConfig(),
        {
          dataIndex: 'physical_segment_ref',
          render: lossMethodsPidRender(
            {
              allowClear: true,
              equalityChecker: simpleEqualityChecker,
              getInitialValue: getInitialLossMethodsPidSelectValue,
              getOptions: getPidOptions,
              isDisabled: isDetailDeleted || isDetailUpdating,
              onChange: pidSelectOnChange,
              valueToUid: (value: number | null | undefined): string =>
                isEmptyValue(value) ? '' : value!.toString(),
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            lossAccountingsChanged ||
            pidOptionsChanged ||
            record.physical_segment_ref !== previousRecord.physical_segment_ref,
          title: 'PID',
          width: VIEW_DATA_TABLE_COLUMN_PID_SELECT_COLUMN_WIDTH,
        },
        {
          dataIndex: 'start',
          render: lossMethodsDateTimePickerRender(
            {
              allowClear: true,
              format: DATE_TIME_FORMAT,
              getDisabledDate: getStartDisabledDate,
              getInitialValue: getInitialLossMethodsStartDateTimePickerValue,
              isDisabled: isDetailDeleted || isDetailUpdating,
              onChange: startDateTimePickerOnChange,
              // We set this like so the time picker would default to 00:00 when changing dates
              showTime: {
                defaultValue: ZonedDateTime.fromMoment(
                  moment('00:00', 'HH:mm'),
                  'UTC',
                )
                  .withHour(0)
                  .withMinute(0),
                format: TIME_FORMAT,
              },
              timeZone,
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            hasTimeZoneChanged ||
            hasSelectedRequestTypeChanged ||
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            // We include a check on both start and stop in order to allow the
            // disabledDate function to be updated
            record.start !== previousRecord.start ||
            record.stop !== previousRecord.stop,
          title: 'Start',
          width: VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH,
        },
        {
          dataIndex: 'stop',
          render: lossMethodsDateTimePickerRender(
            {
              allowClear: true,
              format: DATE_TIME_FORMAT,
              getDisabledDate: getStopDisabledDate,
              getInitialValue: getInitialLossMethodsStopDateTimePickerValue,
              isDisabled: isDetailDeleted || isDetailUpdating,
              onChange: stopDateTimePickerOnChange,
              // We set this like so the time picker would default to 00:00 when changing dates
              showTime: {
                defaultValue: ZonedDateTime.fromMoment(
                  moment('00:00', 'HH:mm'),
                  'UTC',
                )
                  .withHour(0)
                  .withMinute(0),
                format: TIME_FORMAT,
              },
              timeZone,
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            hasTimeZoneChanged ||
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            // We include a check on both start and stop in order to allow the
            // disabledDate function to be updated
            record.start !== previousRecord.start ||
            record.stop !== previousRecord.stop,
          title: 'Stop',
          width: VIEW_DATA_TABLE_COLUMN_DATE_TIME_PICKER_WIDTH,
        },
        {
          dataIndex: ['lossMethod', 'loss_method_entry_type'],
          render: lossMethodEntryTypeSelectRender(
            {
              allowClear: true,
              equalityChecker: simpleEqualityChecker,
              getInitialValue: getInitialLossMethodsEntryTypeSelectValue,
              getOptions: getLossMethodEntryTypeOptions,
              isDisabled: isDetailDeleted || isDetailUpdating,
              onChange: lossMethodEntryTypeSelectOnChange,
              valueToUid: (
                value: ELossMethodEntryType | null | undefined,
              ): string => (isEmptyValue(value) ? '' : (value as string)),
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            (record.lossMethod !== null &&
              record.lossMethod.loss_method_entry_type !== null &&
              previousRecord.lossMethod === null) ||
            (record.lossMethod !== null &&
              previousRecord.lossMethod !== null &&
              record.lossMethod.loss_method_entry_type !==
                previousRecord.lossMethod.loss_method_entry_type),
          title: 'Loss Method',
          width: VIEW_DATA_TABLE_COLUMN_LOSS_METHOD_WIDTH,
        },
        {
          dataIndex: ['lossMethod', 'tag_ids'],
          render: lossMethodTagIdsIconButtonRender(
            {
              getIcon: getTagIdsIcon,
              getIsDisabled: tagIdsIconButtonGetIsDisabled,
              onClick: tagIdsIconButtonOnClick,
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            (record.lossMethod !== null &&
              record.lossMethod.loss_method_entry_type !== null &&
              previousRecord.lossMethod === null) ||
            (record.lossMethod !== null &&
              previousRecord.lossMethod !== null &&
              (record.lossMethod.loss_method_entry_type !==
                previousRecord.lossMethod.loss_method_entry_type ||
                record.lossMethod.tag_ids !==
                  previousRecord.lossMethod.tag_ids)),
          title: 'Loss Tag IDs',
          width: VIEW_DATA_TABLE_COLUMN_LOSS_TAG_IDS_WIDTH,
        },
        {
          dataIndex: ['lossMethod', 'contractNumbers'],
          render: lossMethodContractsIconButtonRender(
            {
              getIcon: getContractsIcon,
              getIsDisabled: contractsIconButtonGetIsDisabled,
              onClick: contractsIconButtonOnClick,
            },
            viewMode,
            initialLossAccountingsIds,
            isUnconstrained,
          ),
          shouldCellUpdate: (
            record: IDetailLossAccounting,
            previousRecord: IDetailLossAccounting,
          ): boolean =>
            isDetailDeletedChanged ||
            isDetailUpdatingChanged ||
            (record.lossMethod !== null &&
              record.lossMethod.loss_method_entry_type !== null &&
              previousRecord.lossMethod === null) ||
            (record.lossMethod !== null &&
              previousRecord.lossMethod !== null &&
              (record.lossMethod.loss_method_entry_type !==
                previousRecord.lossMethod.loss_method_entry_type ||
                record.lossMethod.contractNumbers !==
                  previousRecord.lossMethod.contractNumbers)),
          title: 'Loss Contract Numbers',
          width: VIEW_DATA_TABLE_COLUMN_LOSS_CONTRACT_NUMBERS_WIDTH,
        },
      ];
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      contractsIconButtonGetIsDisabled,
      contractsIconButtonOnClick,
      getContractsIcon,
      getInitialLossMethodsEntryTypeSelectValue,
      getInitialLossMethodsPidSelectValue,
      getInitialLossMethodsStartDateTimePickerValue,
      getInitialLossMethodsStopDateTimePickerValue,
      getLossMethodEntryTypeOptions,
      getPidOptions,
      getStartDisabledDate,
      getStopDisabledDate,
      getTagIdsIcon,
      isDetailDeleted,
      isDetailUpdating,
      lossAccountings,
      lossMethodEntryTypeSelectOnChange,
      pidSelectOnChange,
      pidOptions,
      previousIsDetailDeleted,
      previousIsDetailUpdating,
      previousLossAccountings,
      previousPidOptions,
      previousSelectedRequestType,
      previousTimeZone,
      selectedRequestType,
      startDateTimePickerOnChange,
      stopDateTimePickerOnChange,
      tagIdsIconButtonGetIsDisabled,
      tagIdsIconButtonOnClick,
      timeZone,
    ],
  );
};

export default useLossMethodsEditColumns;

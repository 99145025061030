import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import { IPageContentProps } from 'components/organisms/Page/Page';
import ETagCodeSchemeForm from 'components/organisms/ToEntitySummaryUiConfiguration/ETagCodeSchemeForm';
import {
  saveButtonExecutePermissions,
  TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID,
} from 'components/organisms/ToEntitySummaryUiConfiguration/helpers';
import {
  ConfigSection,
  ConfigSectionTitle,
} from 'components/organisms/ToEntitySummaryUiConfiguration/styledComponents';
import ToEntitySummaryUiConfiguration from 'components/organisms/ToEntitySummaryUiConfiguration/ToEntitySummaryUiConfiguration';
import UserToEntitySelection from 'components/organisms/UserToEntitySelection/UserToEntitySelection';
import { PAGE_LAYOUT_STYLES, STANDARD_SPACING } from 'constants/styles';
import { IToEntity } from 'interfaces/ToEntity';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { encodeIds } from 'utils/general';
import { getToEntityUserState } from 'utils/user';
import { ZonedDateTime } from 'utils/zonedDateTime';
import TopBarMenu from '../../organisms/TopBarMenu/TopBarMenu';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  padding: ${STANDARD_SPACING};

  > :not(:first-child) {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntitySelectionBar = styled(SeparatedRowLayout)`
  flex-shrink: 0;
  height: 40px;
`;

interface ISummaryOptionsPageContentProps
  extends IPageContentProps<undefined> {}

const SummaryOptionsPageContent = (
  props: ISummaryOptionsPageContentProps,
): JSX.Element => {
  const { userInfo } = props;
  const { selectedToEntity, toEntities, toEntityUserStates } = userInfo;
  const timeZone: TTimeZone =
    getToEntityUserState(selectedToEntity, toEntityUserStates)
      ?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();
  const [
    currentlySelectedToEntitySaveable,
    setCurrentlySelectedToEntitySaveable,
  ] = useState<boolean>(false);
  const [saveableToEntities, setSaveableToEntities] = useState<IToEntity[]>([]);

  useEffect(() => {
    const toEntityCanPressSave: (toEntity: IToEntity) => boolean =
      saveButtonExecutePermissions(toEntities);

    setSaveableToEntities(toEntities.filter(toEntityCanPressSave));
  }, [toEntities]);

  useEffect(() => {
    if (selectedToEntity !== undefined) {
      setCurrentlySelectedToEntitySaveable(
        saveButtonExecutePermissions(toEntities)(selectedToEntity),
      );
    }
  }, [toEntities, selectedToEntity]);

  return (
    <Layout>
      <SeparatedRowLayout>
        <TopBarMenu />
        <ToEntitySelectionBar>
          <UserToEntitySelection />
        </ToEntitySelectionBar>
      </SeparatedRowLayout>
      {selectedToEntity === undefined ? null : (
        <ToEntitySummaryUiConfiguration
          encodedPermissionsId={encodeIds(
            [TO_ENTITY_SUMMARY_UI_CONFIG_PERMISSIONS_ID],
            selectedToEntity.to_entity,
          )}
          saveable={currentlySelectedToEntitySaveable}
          saveableToEntities={saveableToEntities}
          toEntity={selectedToEntity}
        >
          <ConfigSection>
            <ConfigSectionTitle>Default Tag Code Scheme</ConfigSectionTitle>
            <ETagCodeSchemeForm
              encodedPermissionsId={encodeIds(
                ['defaultTagCodeScheme'],
                selectedToEntity.to_entity,
              )}
              timeZone={timeZone}
              toEntity={selectedToEntity}
            />
          </ConfigSection>
        </ToEntitySummaryUiConfiguration>
      )}
    </Layout>
  );
};

export default SummaryOptionsPageContent;

import { PlusCircleOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagCreatePanel from 'components/molecules/ToEntityETagCreatePanel/ToEntityETagCreatePanel';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import { IETagExtendedIdentifier, IETagTemplate } from 'interfaces/ETag';
import { IContactInfo } from 'interfaces/General';
import { useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';

const CreateIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagCreateProps {
  contactInfo?: IContactInfo;
  encodedPermissionsId: string;
  eTagExtendedIdentifier?: IETagExtendedIdentifier;
  isDisabled?: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  template?: IETagTemplate;
}

const ToEntityETagCreate = ({
  contactInfo,
  encodedPermissionsId,
  eTagExtendedIdentifier,
  isDisabled,
  template,
  timeZone,
  toEntityId,
}: IToEntityETagCreateProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Tooltip isDisabled={isVisible} title='Create Tag'>
      <AntDesignPopover
        content={
          <ToEntityETagCreatePanel
            contactInfo={contactInfo}
            eTagExtendedIdentifier={eTagExtendedIdentifier}
            template={template}
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement='bottom'
        trigger='click'
        visible={isVisible}
      >
        <IconButton
          encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
          icon={<CreateIcon />}
          isDisabled={isDisabled}
        />
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagCreate;

import { PCI_MODULE_KEY } from 'components/services/ETagWebSocket/constants';
import { IAlert } from 'interfaces/Alert';
import {
  IETagCheckoutReportNotification,
  IETagNotification,
} from 'interfaces/ETag';

export enum EETagPciModule {
  ETag = 'etag',
}

export enum EETagMessageSchema {
  AlertViewModel = 'AlertViewModel',
  CompositeStateChangeNotification = 'CompositeStateChangeNotification',
  DraftNotification = 'DraftNotification',
  SimpleTagNotification = 'SimpleTagNotification',
  RevisionFileNotification = 'RevisionFileNotification',
  ScheduledRequestNewTagNotification = 'ScheduledRequestNewTagNotification',
  SummaryCheckoutReportNotification = 'SummaryCheckoutReportNotification',
}

export interface IETagMessage {
  message_schema: EETagMessageSchema;
  [PCI_MODULE_KEY]: string;
}

export type TETagMessage = IETagMessage &
  (IAlert | IETagNotification | IETagCheckoutReportNotification);

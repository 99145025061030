import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;

  > :not(:first-child) {
    margin-top: 8px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;
export const ConfigSection = styled.div`
  width: 100%;

  > :not(:first-child) {
    margin-top: 3px;
  }

  > :last-child {
    margin-bottom: 0;
  }
`;
export const ConfigSectionTitle = styled.div`
  font-weight: bold;
  margin-top: 6px;
  margin-top: 3px;
  font-size: 220%;
`;
export const StyledLabel = styled.div`
  font-weight: bold;
`;

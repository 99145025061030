import { useState } from 'react';

export interface IuseArraySorters<T> {
  arraySorters: T[];
  addArraySorter: (arraySorter: T) => void;
  clearArraySorters: () => void;
  removeArraySorter: (arraySorter: T) => void;
  replaceArraySorter: (currentArraySorter: T, newArraySorter: T) => void;
  setDefaultArraySorter: (arraySorter: T, sortOrder: number) => void;
}

const useArraySorters = <T>(): IuseArraySorters<T> => {
  const [arraySorters, setArraySorters] = useState<T[]>([]);

  const addArraySorter = (arraySorter: T) => {
    setArraySorters((previousArraySorters: T[]): T[] =>
      previousArraySorters.concat([arraySorter]),
    );
  };

  const clearArraySorters = () => {
    setArraySorters(() => []);
  };

  const removeArraySorter = (arraySorter: T) => {
    setArraySorters((previousArraySorters: T[]): T[] =>
      previousArraySorters.filter((item: T) => item !== arraySorter),
    );
  };

  const replaceArraySorter = (currentArraySorter: T, newArraySorter: T) => {
    setArraySorters((previousArraySorters: T[]): T[] =>
      previousArraySorters.map((item: T) =>
        item === currentArraySorter ? newArraySorter : item,
      ),
    );
  };

  const setDefaultArraySorter = (arraySorter: T, sortOrder: number) => {
    setArraySorters((previousArraySorters: T[]): T[] => {
      const newArraySorters: T[] = [...previousArraySorters];
      newArraySorters[sortOrder] = arraySorter;
      return newArraySorters;
    });
  };

  return {
    arraySorters,
    addArraySorter,
    clearArraySorters,
    removeArraySorter,
    replaceArraySorter,
    setDefaultArraySorter,
  };
};

export default useArraySorters;

import { TIME_FORMAT } from 'constants/time';
import { ERegistryType } from 'enums/General';
import { EQueryType } from 'enums/Query';
import { IOption } from 'interfaces/Component';

export const TO_ENTITY_QUERY_ETAG_AUTHORITY_MODAL_WIDTH_VALUE = 543;

export const SHOW_TIME_FORMAT = {
  format: TIME_FORMAT,
};

export const BASE_QUERY_TYPE_OPTIONS: IOption<EQueryType>[] = [
  {
    label: 'Query Summaries',
    value: EQueryType.Summary,
  },
  {
    label: 'Query Availability',
    value: EQueryType.Availability,
  },
];

export const ALL_QUERY_TYPE_OPTIONS: IOption<EQueryType>[] =
  BASE_QUERY_TYPE_OPTIONS.concat([
    {
      label: 'Query Tag',
      value: EQueryType.Tag,
    },
    {
      label: 'Query Request',
      value: EQueryType.Request,
    },
    {
      label: 'Query Status',
      value: EQueryType.Status,
    },
    {
      label: 'Query Resolution',
      value: EQueryType.Resolution,
    },
    {
      label: 'Query Request Ids',
      value: EQueryType.RequestIds,
    },
    {
      label: 'Query Correction',
      value: EQueryType.Correction,
    },
  ]);

export const REGISTRY_TYPE_OPTIONS: IOption<ERegistryType>[] = [
  {
    label: 'BA',
    value: ERegistryType.BA,
  },
  {
    label: 'MO',
    value: ERegistryType.MO,
  },
  {
    label: 'PSE',
    value: ERegistryType.PSE,
  },
  {
    label: 'TP',
    value: ERegistryType.TSP,
  },
];

export const LOAD_ENTITY_NAMES_ERROR_MESSAGE =
  'Unable to load entity names. Please try again later.';

export const QUERY_AVAILABILITY_ERROR_MESSAGE =
  'Query availability failed. Please try again later.';

export const QUERY_CORRECTION_ERROR_MESSAGE =
  'Query correction failed. Please try again later.';

export const QUERY_REQUEST_ERROR_MESSAGE =
  'Query request failed. Please try again later.';

export const QUERY_REQUEST_IDS_ERROR_MESSAGE =
  'Query request ids failed. Please try again later.';

export const QUERY_RESOLUTION_ERROR_MESSAGE =
  'Query resolution failed. Please try again later.';

export const QUERY_STATUS_ERROR_MESSAGE =
  'Query status failed. Please try again later.';

export const QUERY_SUMMARIES_ERROR_MESSAGE =
  'Query summaries failed. Please try again later.';

export const QUERY_TAG_ERROR_MESSAGE =
  'Query tag failed. Please try again later.';

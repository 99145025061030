import TextAligned from 'components/atoms/TextAligned/TextAligned';
import { summaryThemedStylesDifferent } from 'components/organisms/Cell/helpers';
import ProfileLoadIndicator from 'components/organisms/ProfileLoadIndicator/ProfileLoadIndicator';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import { CELL_CONTENT_HEIGHT, CELL_CONTENT_PADDING } from 'constants/styles';
import { DATE_FORMAT } from 'constants/time';
import { AnimationContext } from 'contexts/Animation/Animation';
import { IHeData } from 'interfaces/ETag';
import { ISummaryThemedStyles } from 'interfaces/Summary';
import { Component, ReactNode } from 'react';
import styled, { keyframes, Keyframes } from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TETagRecordKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { isEmptyValue } from 'utils/general';
import { toFormattedDateTimeString } from 'utils/time';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface IAnimatedProps {
  animation?: Keyframes;
  duration: number;
}

const Animated = styled.div<IAnimatedProps>`
  animation-name: ${(props) => props.animation};
  animation-duration: ${(props) => props.duration}ms;
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.19, 1.55, 0.47, 1.79);
`;

const bounce = keyframes`
  0% { transform: scale(1) }
  30% { transform: scale(0.8)}
  80% { transform: scale(1.1)}
  100% { transform: scale(1) }
`;

const hop = keyframes`
  0% { transform: translateY(0) }
  30% { transform: translateY(5px)}
  80% { transform: translateY(-10px)}
  100% { transform: translateY(0) }
`;

interface ICellProps<T> {
  eTagRecordKey: TETagRecordKey;
  isProfileData?: true;
  minWidth: number;
  summaryThemedStyles: ISummaryThemedStyles;
  timeZone?: TTimeZone;
  toEntityId: TToEntityId;
  value: T;
}

interface ICellState {
  animation?: Keyframes;
  animationDurationMilliseconds: number;
}

class Cell<T> extends Component<ICellProps<T>, ICellState> {
  static contextType = AnimationContext;

  keyframes?: Keyframes;
  state = { animation: undefined, animationDurationMilliseconds: 2000 };
  timeoutId?: number;

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  componentDidUpdate(prevProps: ICellProps<T>) {
    const { shouldAnimate } = this.context;
    const { summaryThemedStyles, value } = this.props;
    const { animationDurationMilliseconds } = this.state;

    if (shouldAnimate) {
      if (
        summaryThemedStylesDifferent(
          prevProps.summaryThemedStyles,
          summaryThemedStyles,
        ) ||
        this.valuesDifferent(prevProps.value, value)
      ) {
        this.setState({ animation: this.keyframes });
        this.timeoutId = window.setTimeout(() => {
          this.setState({ animation: undefined });
        }, animationDurationMilliseconds);
      }
    }
  }

  render() {
    const { eTagRecordKey, isProfileData, toEntityId, value } = this.props;
    const { animation, animationDurationMilliseconds } = this.state;

    return (
      <Animated animation={animation} duration={animationDurationMilliseconds}>
        <ProfileLoadIndicator
          eTagRecordKey={eTagRecordKey}
          isProfileData={isProfileData}
          toEntityId={toEntityId}
        >
          {this.renderValue(value)}
        </ProfileLoadIndicator>
      </Animated>
    );
  }

  renderValue = (_value: T): ReactNode => {
    return null;
  };

  valuesDifferent = (a: T, b: T): boolean => a !== b;
}

// Typed Cells

export class BooleanCell extends Cell<boolean | null> {
  keyframes = bounce;

  renderValue = (value: boolean | null): ReactNode => {
    const { minWidth, summaryThemedStyles } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='center'
      >
        {value === null
          ? ''
          : value === true
          ? BOOLEAN_TRUE_LABEL
          : BOOLEAN_FALSE_LABEL}
      </TextAligned>
    );
  };
}

export class DateCell extends Cell<string | null> {
  keyframes = bounce;

  renderValue = (value: string | null): ReactNode => {
    const { minWidth, summaryThemedStyles, timeZone } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='center'
      >
        {value === undefined || value === null
          ? ''
          : ZonedDateTime.parseIso(value, timeZone!).format(DATE_FORMAT)}
      </TextAligned>
    );
  };
}

export class DateTimeCell extends Cell<string | null> {
  keyframes = bounce;

  renderValue = (value: string | null): ReactNode => {
    const { minWidth, summaryThemedStyles, timeZone } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='center'
      >
        {value === undefined || value === null
          ? ''
          : toFormattedDateTimeString(value, timeZone!)}
      </TextAligned>
    );
  };
}

export class HourEndingCell extends Cell<IHeData> {
  keyframes = hop;

  renderValue = (value: IHeData): ReactNode => {
    const { minWidth, summaryThemedStyles } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='right'
      >
        {value?.energy?.mw}
      </TextAligned>
    );
  };

  valuesDifferent = (a: IHeData, b: IHeData) => {
    if (a === undefined && b !== undefined) {
      return true;
    } else if (a !== undefined && b === undefined) {
      return true;
    } else if (a !== undefined && b !== undefined) {
      if (isEmptyValue(a.energy) && !isEmptyValue(b.energy)) {
        return true;
      } else if (!isEmptyValue(a.energy) && isEmptyValue(b.energy)) {
        return true;
      } else if (!isEmptyValue(a.energy) && !isEmptyValue(b.energy)) {
        return a.energy!.mw !== b.energy!.mw;
      }
    }
    return false;
  };
}

export class IntegerCell extends Cell<number | null> {
  keyframes = hop;

  renderValue = (value: number | null): ReactNode => {
    const { minWidth, summaryThemedStyles } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='right'
      >
        {value}
      </TextAligned>
    );
  };
}

export class StringCell extends Cell<string | null> {
  keyframes = bounce;

  renderValue = (value: string | null): ReactNode => {
    const { minWidth, summaryThemedStyles } = this.props;
    return (
      <TextAligned
        height={CELL_CONTENT_HEIGHT}
        minWidth={minWidth}
        padding={CELL_CONTENT_PADDING}
        summaryThemedStyles={summaryThemedStyles}
        textAlign='center'
      >
        {value}
      </TextAligned>
    );
  };
}

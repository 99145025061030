import { NO_OP_HANDLER } from 'constants/General';
import useArrayFilters, { IuseArrayFilters } from 'hooks/useArrayFilters';
import { Context, createContext, ReactNode } from 'react';
import { TETagDataSetFilter } from 'types/ETag';

export interface IETagFilteringContext
  extends IuseArrayFilters<TETagDataSetFilter> {}

export const ETagFilteringContext: Context<IETagFilteringContext> =
  createContext<IETagFilteringContext>({
    arrayFilters: {},
    clearArrayFilters: NO_OP_HANDLER,
    setFilter: NO_OP_HANDLER,
    unSetFilter: NO_OP_HANDLER,
  });

interface IETagFilteringProviderProps {
  children: ReactNode;
}

const ETagFilteringProvider = (props: IETagFilteringProviderProps) => {
  const { children } = props;
  const { arrayFilters, clearArrayFilters, setFilter, unSetFilter } =
    useArrayFilters<TETagDataSetFilter>();

  return (
    <ETagFilteringContext.Provider
      value={{ arrayFilters, clearArrayFilters, setFilter, unSetFilter }}
    >
      {children}
    </ETagFilteringContext.Provider>
  );
};

export default ETagFilteringProvider;

import MarketInfoPrices from 'components/organisms/MarketInformation/MarketInfoPrices/MarketInfoPrices';
import { ETAG_BOOLEAN_OPTIONS } from 'constants/ETag';
import { BOOLEAN_FALSE_LABEL, BOOLEAN_TRUE_LABEL } from 'constants/misc';
import {
  EMarketInfoMarket,
  EMarketInfoMarketType,
  EMarketInfoTransactionType,
} from 'enums/ETag';
import {
  IEditMarketInfo,
  IEditMisoMarketData,
  IEditSppMarketData,
} from 'interfaces/Detail';
import {
  IETagMarketInfo,
  IETagMarketInfoPrice,
  IETagMisoMarketData,
  IETagSppMarketData,
} from 'interfaces/ETag';
import { IViewDataTableColumn } from 'interfaces/View';
import { booleanOrNullToUid, simpleEqualityChecker } from 'utils/general';
import { getColumnRender, getColumnSelectRender } from 'utils/views';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const marketTypeToDisplayString = (
  marketType: EMarketInfoMarketType,
): string => {
  switch (marketType) {
    case EMarketInfoMarketType.Both: {
      return 'Both';
    }
    case EMarketInfoMarketType.DayAhead: {
      return 'Day Ahead';
    }
    case EMarketInfoMarketType.RealTime: {
      return 'Real-time';
    }
    default: {
      throw new Error(`Invalid marketType: ${marketType}`);
    }
  }
};

export const transactionTypeToDisplayString = (
  transactionType: EMarketInfoTransactionType,
): string => {
  switch (transactionType) {
    case EMarketInfoTransactionType.Dispatchable: {
      return 'Dispatchable';
    }
    case EMarketInfoTransactionType.Fixed: {
      return 'Fixed';
    }
    case EMarketInfoTransactionType.UpToTuc: {
      return 'Up to TUC';
    }
    default: {
      throw new Error(`Invalid transactionType: ${transactionType}`);
    }
  }
};

const getMisoMarketTypeOptions = async () => [
  {
    label: marketTypeToDisplayString(EMarketInfoMarketType.Both),
    value: EMarketInfoMarketType.Both,
  },
  {
    label: marketTypeToDisplayString(EMarketInfoMarketType.RealTime),
    value: EMarketInfoMarketType.RealTime,
  },
];

const getSppMarketTypeOptions = async () => [
  {
    label: marketTypeToDisplayString(EMarketInfoMarketType.DayAhead),
    value: EMarketInfoMarketType.DayAhead,
  },
  {
    label: marketTypeToDisplayString(EMarketInfoMarketType.RealTime),
    value: EMarketInfoMarketType.RealTime,
  },
];

const getTransactionTypeOptions = async () => [
  {
    label: transactionTypeToDisplayString(
      EMarketInfoTransactionType.Dispatchable,
    ),
    value: EMarketInfoTransactionType.Dispatchable,
  },
  {
    label: transactionTypeToDisplayString(EMarketInfoTransactionType.Fixed),
    value: EMarketInfoTransactionType.Fixed,
  },
  {
    label: transactionTypeToDisplayString(EMarketInfoTransactionType.UpToTuc),
    value: EMarketInfoTransactionType.UpToTuc,
  },
];

const getBooleanOptions = async () => ETAG_BOOLEAN_OPTIONS;

const marketTypeToUid = (value: EMarketInfoMarketType): string =>
  value as string;

const transactionTypeToUid = (value: EMarketInfoTransactionType): string =>
  value as string;

const createFinScheduleToDisplayString = (createFinSchedule: boolean): string =>
  createFinSchedule === true ? BOOLEAN_TRUE_LABEL : BOOLEAN_FALSE_LABEL;

const getMisoMarketTypeSelectRender = getColumnSelectRender<
  EMarketInfoMarketType,
  IETagMisoMarketData
>('100%');

const getMisoTransactionTypeSelectRender = getColumnSelectRender<
  EMarketInfoTransactionType,
  IETagMisoMarketData
>('100%');

const getFinScheduleSelectRender = getColumnSelectRender<
  boolean | null,
  IETagMisoMarketData
>('100%');

const getSppMarketTypeSelectRender = getColumnSelectRender<
  EMarketInfoMarketType,
  IETagSppMarketData
>('100%');

const getSppTransactionTypeSelectRender = getColumnSelectRender<
  EMarketInfoTransactionType,
  IETagSppMarketData
>('100%');

const getColumnPricesRender =
  (
    market: EMarketInfoMarket,
    marketDate: ZonedDateTime,
    isEditable: boolean,
    onChange: (marketInfoPrices: IETagMarketInfoPrice[]) => void,
    initialMarketInfos?: IETagMarketInfo[],
    isDisabled?: boolean,
  ) =>
  (value: unknown): JSX.Element =>
    (
      <MarketInfoPrices
        initialMarketInfos={initialMarketInfos}
        isDisabled={isDisabled}
        isEditable={isEditable}
        market={market}
        marketDate={marketDate}
        marketInfoPrices={value as IETagMarketInfoPrice[]}
        onChange={onChange}
      />
    );

const getMisoMarketColumns = (
  marketDate: ZonedDateTime,
  isEditable: boolean,
  isUnconstrained: boolean,
  onChange: (editMisoMarketData: IEditMisoMarketData) => void,
  initialMarketInfos?: IETagMarketInfo[],
  isDisabled?: boolean,
): IViewDataTableColumn<IETagMisoMarketData>[] => {
  const getInitialMarketInfo = (): IETagMarketInfo | undefined =>
    initialMarketInfos?.find(
      (marketInfo: IETagMarketInfo): boolean =>
        marketInfo.market_info_market === EMarketInfoMarket.MISO,
    );

  const getInitialMisoMarketType = ():
    | EMarketInfoMarketType
    | null
    | undefined => {
    const initialMarketInfo: IETagMarketInfo | undefined =
      getInitialMarketInfo();

    return initialMarketInfo === undefined
      ? null
      : (initialMarketInfo.data as IETagMisoMarketData).miso_market_type;
  };

  const getInitialMisoTransactionType = ():
    | EMarketInfoTransactionType
    | null
    | undefined => {
    const initialMarketInfo: IETagMarketInfo | undefined =
      getInitialMarketInfo();

    return initialMarketInfo === undefined
      ? null
      : (initialMarketInfo.data as IETagMisoMarketData).miso_transaction_type;
  };

  const getInitialMisoFinSchedule = (): boolean | null | undefined => {
    const initialMarketInfo: IETagMarketInfo | undefined =
      getInitialMarketInfo();

    return initialMarketInfo === undefined
      ? null
      : (initialMarketInfo.data as IETagMisoMarketData)
          .miso_create_fin_schedule;
  };

  const handlePricesChange = (marketInfoPrices: IETagMarketInfoPrice[]) => {
    onChange({ miso_price_list: marketInfoPrices });
  };

  return [
    {
      dataIndex: 'miso_market_type',
      render: isEditable
        ? getMisoMarketTypeSelectRender({
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialMisoMarketType,
            getOptions: getMisoMarketTypeOptions,
            isDisabled,
            onChange: (value: EMarketInfoMarketType | null | undefined) => {
              onChange({
                miso_market_type: value === undefined ? null : value,
              });
            },
            placeholder: 'Select Market Type',
            valueToUid: marketTypeToUid,
          })
        : getColumnRender(isUnconstrained, marketTypeToDisplayString),
      title: 'Market Type',
      width: '25%',
    },
    {
      dataIndex: 'miso_transaction_type',
      render: isEditable
        ? getMisoTransactionTypeSelectRender({
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialMisoTransactionType,
            getOptions: getTransactionTypeOptions,
            isDisabled,
            onChange: (
              value: EMarketInfoTransactionType | null | undefined,
            ) => {
              onChange({
                miso_transaction_type: value === undefined ? null : value,
              });
            },
            placeholder: 'Select Transaction Type',
            valueToUid: transactionTypeToUid,
          })
        : getColumnRender(isUnconstrained, transactionTypeToDisplayString),
      title: 'Transaction Type',
    },
    {
      dataIndex: 'miso_create_fin_schedule',
      render: isEditable
        ? getFinScheduleSelectRender({
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialMisoFinSchedule,
            getOptions: getBooleanOptions,
            isDisabled,
            onChange: (value: boolean | null | undefined) => {
              onChange({
                miso_create_fin_schedule: value === undefined ? null : value,
              });
            },
            placeholder: 'Select Fin Schedule',
            valueToUid: booleanOrNullToUid,
          })
        : getColumnRender(isUnconstrained, createFinScheduleToDisplayString),
      title: 'FIN Schedule',
      width: '87px',
    },
    {
      dataIndex: 'miso_price_list',
      render: getColumnPricesRender(
        EMarketInfoMarket.MISO,
        marketDate,
        isEditable,
        handlePricesChange,
        initialMarketInfos,
        isDisabled,
      ),
      title: 'Price Info',
      width: '67px',
    },
  ];
};

const getSppMarketColumns = (
  marketDate: ZonedDateTime,
  isEditable: boolean,
  isUnconstrained: boolean,
  onChange: (editSppMarketData: IEditSppMarketData) => void,
  initialMarketInfos?: IETagMarketInfo[],
  isDisabled?: boolean,
): IViewDataTableColumn<IETagSppMarketData>[] => {
  const getInitialMarketInfo = (): IETagMarketInfo | null | undefined =>
    initialMarketInfos === undefined
      ? null
      : initialMarketInfos.find(
          (marketInfo: IETagMarketInfo): boolean =>
            marketInfo.market_info_market === EMarketInfoMarket.SPP,
        );

  const getInitialSppMarketType = ():
    | EMarketInfoMarketType
    | null
    | undefined => {
    const initialMarketInfo: IETagMarketInfo | null | undefined =
      getInitialMarketInfo();

    return initialMarketInfo === null
      ? undefined
      : initialMarketInfo === undefined
      ? null
      : (initialMarketInfo.data as IETagSppMarketData).spp_market_type;
  };

  const getInitialSppTransactionType = ():
    | EMarketInfoTransactionType
    | null
    | undefined => {
    const initialMarketInfo: IETagMarketInfo | null | undefined =
      getInitialMarketInfo();

    return initialMarketInfo === null
      ? undefined
      : initialMarketInfo === undefined
      ? null
      : (initialMarketInfo.data as IETagSppMarketData).spp_transaction_type;
  };

  const handlePricesChange = (marketInfoPrices: IETagMarketInfoPrice[]) => {
    onChange({ spp_price_list: marketInfoPrices });
  };

  return [
    {
      dataIndex: 'spp_market_type',
      render: isEditable
        ? getSppMarketTypeSelectRender({
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialSppMarketType,
            getOptions: getSppMarketTypeOptions,
            isDisabled,
            onChange: (value: EMarketInfoMarketType | null | undefined) => {
              onChange({ spp_market_type: value === undefined ? null : value });
            },
            placeholder: 'Select Market Type',
            valueToUid: marketTypeToUid,
          })
        : getColumnRender(isUnconstrained, marketTypeToDisplayString),
      title: 'Market Type',
      width: '25%',
    },
    {
      dataIndex: 'spp_transaction_type',
      render: isEditable
        ? getSppTransactionTypeSelectRender({
            allowClear: true,
            equalityChecker: simpleEqualityChecker,
            getInitialValue: getInitialSppTransactionType,
            getOptions: getTransactionTypeOptions,
            isDisabled,
            onChange: (
              value: EMarketInfoTransactionType | null | undefined,
            ) => {
              onChange({
                spp_transaction_type: value === undefined ? null : value,
              });
            },
            placeholder: 'Select Transaction Type',
            valueToUid: transactionTypeToUid,
          })
        : getColumnRender(isUnconstrained, transactionTypeToDisplayString),
      title: 'Transaction Type',
    },
    {
      dataIndex: 'spp_price_list',
      render: getColumnPricesRender(
        EMarketInfoMarket.SPP,
        marketDate,
        isEditable,
        handlePricesChange,
        initialMarketInfos,
        isDisabled,
      ),
      title: 'Price Info',
      width: '67px',
    },
  ];
};

export const getColumnsForMarket = (
  market: EMarketInfoMarket | undefined,
  marketDate: ZonedDateTime,
  isEditable: boolean,
  isUnconstrained: boolean,
  onChange: (editMarketInfo: IEditMarketInfo) => void,
  initialMarketInfos?: IETagMarketInfo[],
  isDisabled?: boolean,
):
  | IViewDataTableColumn<IETagMisoMarketData>[]
  | IViewDataTableColumn<IETagSppMarketData>[] => {
  if (market === EMarketInfoMarket.MISO) {
    return getMisoMarketColumns(
      marketDate,
      isEditable,
      isUnconstrained,
      (editMisoMarketData: IEditMisoMarketData) =>
        onChange({
          data: editMisoMarketData,
          market: EMarketInfoMarket.MISO,
        }),
      initialMarketInfos,
      isDisabled,
    );
  } else if (market === EMarketInfoMarket.SPP) {
    return getSppMarketColumns(
      marketDate,
      isEditable,
      isUnconstrained,
      (editSppMarketData: IEditSppMarketData) =>
        onChange({
          data: editSppMarketData,
          market: EMarketInfoMarket.SPP,
        }),
      initialMarketInfos,
      isDisabled,
    );
  }

  return [];
};

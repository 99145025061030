import { ERegistryType } from 'enums/General';
import { EPointTypeName } from 'enums/Point';
import { TToEntityId } from 'types/ToEntity';

export const REGISTRY_ENTITIES_ROUTE = (toEntityId: TToEntityId): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/registry/entities`;

export const REGISTRY_ENTITIES_BY_TYPE_ROUTE = (
  toEntityId: TToEntityId,
  registryType: ERegistryType,
  taggingEntityIds?: number[],
): string =>
  `/toEntities/${encodeURIComponent(toEntityId)}/registry/entities/${
    ERegistryType[registryType]
  }${
    taggingEntityIds === undefined
      ? ''
      : `?tagging_entity_ids=${taggingEntityIds.join(',')}`
  }`;

export const REGISTRY_POINTS_ROUTE = (
  toEntityId: TToEntityId,
  pointTypeName: EPointTypeName,
  baTaggingEntityId?: number,
  pseTaggingEntityId?: number,
  tspTaggingEntityId?: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/registry/points/?pointTypeNames=${encodeURIComponent(pointTypeName)}${
    baTaggingEntityId === undefined
      ? ''
      : `&baTaggingEntityId=${baTaggingEntityId}`
  }${
    pseTaggingEntityId === undefined
      ? ''
      : `&pseTaggingEntityId=${pseTaggingEntityId}`
  }${
    tspTaggingEntityId === undefined
      ? ''
      : `&tspTaggingEntityId=${tspTaggingEntityId}`
  }`;

import { EEntityType } from 'enums/Entity';
import { ERegistryType } from 'enums/General';
import { IOption } from 'interfaces/Component';
import {
  IEntityIdentifierInfo,
  IEntityInfo,
  IRegistryEntity,
} from 'interfaces/Entity';
import { IToEntity } from 'interfaces/ToEntity';
import { TToEntityId } from 'types/ToEntity';

export const isWritablePseEntity = (toEntity: IToEntity): boolean =>
  // PCI Support Super User is a writable toEntity
  (toEntity.to_entity_type_role_abbreviation.startsWith('pcisupport-super') &&
    toEntity.entity_type === EEntityType.PSE) ||
  (toEntity.to_entity_type_role_abbreviation.startsWith('pse') &&
    toEntity.to_entity_type_role_abbreviation.includes('write'));

/**
 * Parse "PSECode-123" into { entity_type: "PSE", tagging_entity_id: 123 }.
 * @param toEntityId example is "PSECode-123". Must have this general format.
 */
export const parseToEntityId = (
  toEntityId: TToEntityId,
): IEntityIdentifierInfo => {
  // We expect to_entity to have the format {entity_type}Code-${tagging_entity_id}
  const splits: string[] = toEntityId.split('-');
  const entityType: EEntityType | undefined = Object.values(EEntityType).find(
    (entityType: EEntityType): boolean => splits[0].startsWith(entityType),
  );
  // to handle negative toEntityIds, check for --, if it's there, place '-' before the number
  const taggingEntityId: number = toEntityId.includes('--')
    ? parseInt('-' + splits[splits.length - 1], 10)
    : parseInt(splits[splits.length - 1], 10);

  if (entityType === undefined) {
    throw new Error(`Invalid entity_type for to_entity: ${toEntityId}`);
  }

  if (Number.isNaN(taggingEntityId)) {
    throw new Error(`Invalid tagging_entity_id for to_entity: ${toEntityId}`);
  }

  return {
    entity_type: entityType,
    tagging_entity_id: taggingEntityId,
  };
};

/**
 * Inverse of parseToEntityId.
 * @param entityIdentifierInfo e.g. {entity_type: 'PSE', tagging_entity_id: 123} will yield 'PSECode-123'.
 */
export const toToEntityId = (
  entityIdentifierInfo: IEntityIdentifierInfo,
): string => {
  return `${entityIdentifierInfo.entity_type}Code-${entityIdentifierInfo.tagging_entity_id}`;
};

export const toEntityToEntityInfo = (toEntity: IToEntity): IEntityInfo => {
  const { to_entity, entity_code } = toEntity;
  const entityIdentifierInfo = parseToEntityId(to_entity);
  return {
    ...entityIdentifierInfo,
    entity_code: entity_code,
  };
};

export const registryTypeToEntityType = (
  registryType: ERegistryType,
): EEntityType => {
  switch (registryType) {
    case ERegistryType.BA: {
      // Could also be EEntity.GCA or EEntity.LCA
      return EEntityType.CA;
    }
    case ERegistryType.MO: {
      return EEntityType.MO;
    }
    case ERegistryType.PORPOD: {
      // Could also be EEntity.POD
      return EEntityType.POR;
    }
    case ERegistryType.PSE: {
      return EEntityType.PSE;
    }
    case ERegistryType.RC: {
      return EEntityType.SC;
    }
    case ERegistryType.SourceSink: {
      return EEntityType.SourceSink;
    }
    case ERegistryType.TSP: {
      return EEntityType.TP;
    }
    default:
      throw new Error(`Invalid registry type for entity: ${registryType}`);
  }
};

export const typeIgnoredEntityInfoToUid = (value: IEntityInfo | null): string =>
  value === null ? '' : value.tagging_entity_id.toString();

export const entityInfoToUid = (value: IEntityInfo | null): string =>
  value === null ? '' : `${value.tagging_entity_id}-${value.entity_type}`;

export const entityInfoToString = (value: IEntityInfo | null): string =>
  value === null ? '' : value.entity_code;

export const registryEntityToEntityInfoOption =
  (useRegistryType: boolean, useRegistryTypeInLabel: boolean) =>
  (registryEntity: IRegistryEntity): IOption<IEntityInfo> => {
    const { Code, TaggingEntityID } = registryEntity;

    if (Code === undefined) {
      throw new Error('Missing Code for registry entity');
    }

    if (TaggingEntityID === undefined) {
      throw new Error('Missing TaggingEntityID for registry entity');
    }

    const tagging_entity_id: number = parseInt(TaggingEntityID);

    if (Number.isNaN(tagging_entity_id)) {
      throw new Error(
        `Invalid TaggingEntityID: ${TaggingEntityID} for registry entity`,
      );
    }

    const registryType: string | undefined = registryEntity['Registry Type'];

    if (registryType === undefined) {
      throw new Error(
        `Missing Registry Type for registry entity: ${registryEntity}`,
      );
    }

    const entity_type: EEntityType = useRegistryType
      ? registryTypeToEntityType(registryType as ERegistryType)
      : EEntityType.PSE;

    return {
      label: useRegistryTypeInLabel ? `${Code}-${entity_type[0]}` : Code,
      value: {
        entity_code: Code,
        entity_type,
        tagging_entity_id,
      },
    };
  };

const entityInfoEqual =
  (ignoreEntityType: boolean) =>
  (a: IEntityInfo | null, b: IEntityInfo | null): boolean => {
    if (a === null) {
      return b === null;
    }

    return (
      b !== null &&
      a.entity_code === b.entity_code &&
      (ignoreEntityType ||
        (!ignoreEntityType && a.entity_type === b.entity_type)) &&
      a.tagging_entity_id === b.tagging_entity_id
    );
  };

export const caEntityInfoEqual = entityInfoEqual(true);

export const tpEntityInfoEqual = entityInfoEqual(false);

export const moEntityInfoEqual = entityInfoEqual(false);

export const pseEntityInfoEqual = entityInfoEqual(false);

export const getEntityInfoLabel = (entityInfo: IEntityInfo): string =>
  `${entityInfo.entity_code}-${entityInfo.entity_type[0].toUpperCase()}`;

import { FileSearchOutlined } from '@ant-design/icons';
import { Popover as AntDesignPopover } from 'antd';
import IconButton from 'components/atoms/IconButton/IconButton';
import ToEntityETagSearchPanel from 'components/molecules/ToEntityETagSearchPanel/ToEntityETagSearchPanel';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import { BUTTON_ICON_DIMENSIONS } from 'constants/styles';
import React, { useState } from 'react';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { encodeIds } from 'utils/general';
import { TooltipPlacement } from 'antd/lib/tooltip';
import MenuActionItem from '../../atoms/MenuActionItem/MenuActionItem';

const SearchIcon = styled(FileSearchOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

interface IToEntityETagSearchProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  isMenuItem?: boolean;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
  placement?: TooltipPlacement;
}

const ToEntityETagSearch = ({
  encodedPermissionsId,
  isDisabled,
  isMenuItem,
  timeZone,
  toEntityId,
  placement,
}: IToEntityETagSearchProps): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  return (
    <Tooltip isDisabled={isVisible} title='Search Tag'>
      <AntDesignPopover
        content={
          <ToEntityETagSearchPanel
            timeZone={timeZone}
            toEntityId={toEntityId}
          />
        }
        destroyTooltipOnHide={true}
        onVisibleChange={setIsVisible}
        placement={placement || 'bottom'}
        trigger='click'
        visible={isVisible}
      >
        {isMenuItem ? (
          <MenuActionItem
            encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
            type={'search'}
          />
        ) : (
          <IconButton
            encodedPermissionsId={encodeIds([encodedPermissionsId], toEntityId)}
            icon={<SearchIcon />}
            isDisabled={isDisabled}
          />
        )}
      </AntDesignPopover>
    </Tooltip>
  );
};

export default ToEntityETagSearch;

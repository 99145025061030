import {
  GENERATION_BLOCK_WIDTH_VALUE,
  LOAD_BLOCK_WIDTH_VALUE,
  TRANSMISSION_BLOCK_WIDTH_VALUE,
} from 'components/organisms/PathDiagramView/constants';
import PathDiagramBlock from 'components/organisms/PathDiagramView/PathDiagramBlock';
import {
  EBlockType,
  IPathDiagramBlockData,
} from 'components/organisms/PathDiagramView/types';
import { STANDARD_SPACING } from 'constants/styles';
import { IThemedProps } from 'interfaces/Component';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';

const SEPARATOR_WIDTH_VALUE = 1;

const Layout = styled.div`
  > div:not(:last-child) {
    margin-bottom: ${STANDARD_SPACING};
  }
`;

const PlotTime = styled.div``;

interface IBlocksProps extends IThemedProps {
  shouldCenter: boolean;
}

const Blocks = styled.div<IBlocksProps>`
  display: flex;
  flex-direction: row;

  ${(props) => (props.shouldCenter ? 'justify-content: center;' : '')}

  > div:not(:last-child) {
    border-right: solid ${SEPARATOR_WIDTH_VALUE}px grey;
  }
`;

interface IProps {
  blocksData: IPathDiagramBlockData[];
  nodeSize: number;
  plotTime: string | undefined;
}

const PathDiagram = (props: IProps): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const { blocksData, nodeSize, plotTime } = props;
  const layoutRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const [centerBlocks, setCenterBlocks] = useState<boolean | undefined>(
    undefined,
  );

  useEffect(() => {
    if (layoutRef.current) {
      let blocksWidth: number = 0;

      blocksData.forEach((pathDiagramBlockData: IPathDiagramBlockData) => {
        if (pathDiagramBlockData.type === EBlockType.Generation) {
          blocksWidth += GENERATION_BLOCK_WIDTH_VALUE + SEPARATOR_WIDTH_VALUE;
        } else if (pathDiagramBlockData.type === EBlockType.Transmission) {
          blocksWidth += TRANSMISSION_BLOCK_WIDTH_VALUE + SEPARATOR_WIDTH_VALUE;
        } else if (pathDiagramBlockData.type === EBlockType.Load) {
          blocksWidth += LOAD_BLOCK_WIDTH_VALUE;
        }
      });

      setCenterBlocks(blocksWidth < layoutRef.current.clientWidth);
    }
  }, [blocksData, layoutRef]);

  return (
    <Layout ref={layoutRef}>
      {centerBlocks === undefined || plotTime === undefined ? null : (
        <>
          <PlotTime>Plot Time: {plotTime}</PlotTime>
          <Blocks currentTheme={currentTheme!} shouldCenter={centerBlocks}>
            {blocksData.map(
              (pathDiagramBlockData: IPathDiagramBlockData): JSX.Element => (
                <PathDiagramBlock
                  data={pathDiagramBlockData}
                  key={pathDiagramBlockData.physical_segment_id}
                  nodeSize={nodeSize}
                />
              ),
            )}
          </Blocks>
        </>
      )}
    </Layout>
  );
};

export default PathDiagram;

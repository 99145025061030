import { ERequestStatus } from 'enums/ETag';
import { EServiceType } from 'enums/General';
import { TETagTagPrimaryKey } from 'types/ETag';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

export const QUERY_MISSING_TAGS_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/missingTags?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}`;

export const QUERY_AVAILABILITY_ROUTE = (
  toEntityId: TToEntityId,
  queryEntity: string,
  serviceType?: EServiceType,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/availability?queryEntity=${encodeURIComponent(queryEntity)}${
    serviceType === undefined
      ? ''
      : `&serviceType=${encodeURIComponent(serviceType)}`
  }`;

export const QUERY_CORRECTION_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  correctionId: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/correction?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(
    queryEntity,
  )}&correctionId=${correctionId}`;

export const QUERY_REQUEST_IDS_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestStatus: ERequestStatus,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/requestIDs?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(
    queryEntity,
  )}&requestStatus=${requestStatus}`;

export const QUERY_REQUEST_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/request?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(queryEntity)}&requestId=${requestId}`;

export const QUERY_RESOLUTION_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/resolution?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(queryEntity)}&requestId=${requestId}`;

export const QUERY_STATUS_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
  requestId: number,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/status?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(queryEntity)}&requestId=${requestId}`;

export const QUERY_SUMMARY_ROUTE = (
  toEntityId: TToEntityId,
  start: ZonedDateTime,
  end: ZonedDateTime,
): string =>
  `/toEntities/${encodeURIComponent(
    toEntityId,
  )}/query/summary?start=${encodeURIComponent(
    start.isoFormat(),
  )}&end=${encodeURIComponent(end.isoFormat())}`;

export const QUERY_TAG_ROUTE = (
  toEntityId: TToEntityId,
  tagPrimaryKey: TETagTagPrimaryKey,
  queryEntity: string,
): string =>
  `/toEntities/${toEntityId}/query/tag?tagPrimaryKey=${encodeURIComponent(
    tagPrimaryKey,
  )}&queryEntity=${encodeURIComponent(queryEntity)}`;

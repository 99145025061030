import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import { undoableDetailReducer } from 'reduxes/Detail/reducers';
import { summaryReducer } from 'reduxes/Summary/reducers';
import { userReducer } from 'reduxes/User/reducers';
import { menuReducer } from './Menu/reducers';

const enhancers = [applyMiddleware(thunk)];

export const rootReducer = combineReducers({
  detail: undoableDetailReducer,
  summary: summaryReducer,
  user: userReducer,
  menu: menuReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(...enhancers),
);

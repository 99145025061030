import { IUserUiConfiguration } from 'interfaces/Config';
import { IContactInfo } from 'interfaces/General';
import { IToEntity } from 'interfaces/ToEntity';
import { IToEntityUserState, TToEntityUserStates } from 'reduxes/User/types';
import { TTimeZone } from 'types/DateTime';
import { TRootState } from 'types/Redux';
import { TToEntityId } from 'types/ToEntity';
import { ZonedDateTime } from 'utils/zonedDateTime';

interface IToEntityPartial {
  to_entity?: string;
}
export const getToEntityUserState = (
  toEntityPartial: IToEntityPartial | undefined | null,
  toEntityUserStates: TToEntityUserStates,
): IToEntityUserState | undefined => {
  if (
    toEntityPartial === undefined ||
    toEntityPartial === null ||
    toEntityPartial.to_entity === undefined
  ) {
    return undefined;
  } else {
    return toEntityUserStates[toEntityPartial.to_entity];
  }
};

export const getUserContactInfo = (
  state: TRootState,
): IContactInfo | undefined => {
  const userState: TToEntityUserStates = state.user.toEntityUserStates;
  const keys: string[] = Object.keys(userState);

  let contactInfo: IContactInfo | undefined;

  keys.forEach((key) => {
    if (!contactInfo) {
      contactInfo = userState[key]!.userUiConfig?.contactInfo;
    }
  });

  return contactInfo;
};

/**
 * Similar to getToEntityUserState, but takes state: TRootState
 * and directly uses state.detail.present.to_entity to select the user state.
 */
export const getDetailToEntityUserState = (
  state: TRootState,
): IToEntityUserState | undefined =>
  getToEntityUserState(
    {
      to_entity:
        state.detail.present.toEntity === null
          ? undefined
          : state.detail.present.toEntity.to_entity,
    },
    state.user.toEntityUserStates,
  );

export const getDetailToEntityUserSelectedTimeZone = (
  state: TRootState,
): TTimeZone =>
  getDetailToEntityUserState(state)?.selectedTimeZone ??
  ZonedDateTime.defaultTimeZone();

export const retrieveToEntityTimeZone =
  (toEntity: IToEntity | undefined | null) =>
  (state: TRootState): TTimeZone =>
    getToEntityUserState(toEntity, state.user.toEntityUserStates)
      ?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();

export const retrieveToEntityIdTimeZone =
  (toEntityId: TToEntityId | undefined) =>
  (state: TRootState): TTimeZone =>
    getToEntityUserState(
      { to_entity: toEntityId ?? state.user.selectedToEntity?.to_entity },
      state.user.toEntityUserStates,
    )?.selectedTimeZone ?? ZonedDateTime.defaultTimeZone();

/**
 * Will only copy defined properties
 */
export const copyUserUiConfiguration = (
  userUiConfiguration: IUserUiConfiguration,
): IUserUiConfiguration => {
  const {
    contactInfo,
    defaultAutoRefreshInSeconds,
    defaultDateRange,
    defaultDisplayView,
    defaultFilter,
    defaultProfileFormat,
    defaultTableConfiguration,
    tableConfigurations,
  } = userUiConfiguration;
  const copiedUserUiConfiguation: IUserUiConfiguration = {
    defaultDateRange,
    tableConfigurations,
  };

  if (contactInfo !== undefined) {
    copiedUserUiConfiguation.contactInfo = contactInfo;
  }

  if (defaultAutoRefreshInSeconds !== undefined) {
    copiedUserUiConfiguation.defaultAutoRefreshInSeconds =
      defaultAutoRefreshInSeconds;
  }

  if (defaultDisplayView !== undefined) {
    copiedUserUiConfiguation.defaultDisplayView = defaultDisplayView;
  }

  if (defaultFilter !== undefined) {
    copiedUserUiConfiguation.defaultFilter = defaultFilter;
  }

  if (defaultTableConfiguration !== undefined) {
    copiedUserUiConfiguation.defaultTableConfiguration =
      defaultTableConfiguration;
  }

  if (defaultProfileFormat !== undefined) {
    copiedUserUiConfiguation.defaultProfileFormat = defaultProfileFormat;
  }

  return copiedUserUiConfiguation;
};

import {
  EDIT_LOSS_ACCOUNTING_LABEL,
  EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
  EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
  INITIAL_RECORD_ID,
} from 'constants/Detail';
import {
  IEditLossAccounting,
  IEditLossMethodsInformation,
} from 'hooks/useTransmissionEditColumns/LossMethodsEdit/types';
import { IETagTagId } from 'interfaces/ETag';
import { IContract } from 'interfaces/General';
import {
  IDetailLossAccounting,
  IDetailLossMethod,
  IDetailState,
} from 'reduxes/Detail/types';
import { TTimeZone } from 'types/DateTime';
import { TDetailValidations } from 'types/Detail';
import { TStateTransform } from 'types/General';
import {
  copyDetailLossAccounting,
  copyDetailLossMethod,
  getEditInfoKey,
  getInitialContract,
  getInitialETagTagId,
  getInitialLossAccounting,
  getInitialLossAccountingKey,
  getInitialLossMethod,
  getSplitEditInfoKey,
  isValidLossAccounting,
} from 'utils/detail';
import { copyETagTagId } from 'utils/eTag';
import { isEmptyValue } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';

const updateValidations = (
  validations: TDetailValidations,
  previousLossAccountings: IDetailLossAccounting[],
  lossAccountings: IDetailLossAccounting[],
): TDetailValidations => {
  const updatedValidations: TDetailValidations = { ...validations };

  previousLossAccountings.forEach((lossAccounting: IDetailLossAccounting) => {
    delete updatedValidations[lossAccounting.key];
  });

  lossAccountings.forEach((lossAccounting: IDetailLossAccounting) => {
    updatedValidations[lossAccounting.key] =
      isValidLossAccounting(lossAccounting);
  });

  return updatedValidations;
};

export const editLossMethodsInformationToDetailState = (
  editLossMethodsInformation: IEditLossMethodsInformation,
  timeZone: TTimeZone,
): TStateTransform<IDetailState> => {
  return (detailState: IDetailState): IDetailState => {
    const updatedDetailState: IDetailState = { ...detailState };
    const updatedLossAccountings: IDetailLossAccounting[] = [
      ...updatedDetailState.lossAccountings,
    ];
    let {
      addAfterLossAccounting,
      editLossAccountingMap,
      removeLossAccounting,
      withPhysicalSegmentRef,
    } = editLossMethodsInformation;

    if (addAfterLossAccounting !== undefined) {
      const { label, editIndex, primaryId } = getSplitEditInfoKey(
        addAfterLossAccounting,
      );
      if (editIndex === 0 && updatedLossAccountings.length > 0) {
        const currentLossAccounting = updatedLossAccountings.findIndex(
          (i) => i.key === addAfterLossAccounting,
        );
        const previousLossAccounting =
          updatedLossAccountings[currentLossAccounting - 1];
        if (previousLossAccounting) {
          const previousLossAccountingSplitEditInfoKey = getSplitEditInfoKey(
            previousLossAccounting.key,
          );
          addAfterLossAccounting = getEditInfoKey(
            label,
            primaryId,
            previousLossAccountingSplitEditInfoKey.editIndex + 1,
          );
          updatedLossAccountings[currentLossAccounting].key =
            addAfterLossAccounting;
        }
      }

      let highestIndex: number = -1;
      highestIndex = updatedLossAccountings.findIndex(
        (i) => i.key === addAfterLossAccounting,
      );

      const filteredUpdatedLossAccountings = withPhysicalSegmentRef
        ? updatedLossAccountings.filter(
            (i) => i.physical_segment_ref === withPhysicalSegmentRef,
          )
        : updatedLossAccountings;

      const splitEditInfoKey = getSplitEditInfoKey(addAfterLossAccounting);
      if (
        splitEditInfoKey.editIndex === 0 &&
        filteredUpdatedLossAccountings.length === 0
      ) {
        let firstLossAccounting: IDetailLossAccounting =
          getInitialLossAccounting(
            getEditInfoKey(
              EDIT_LOSS_ACCOUNTING_LABEL,
              withPhysicalSegmentRef || INITIAL_RECORD_ID,
              splitEditInfoKey.editIndex,
            ),
          );

        firstLossAccounting.physical_segment_ref =
          withPhysicalSegmentRef || null;

        updatedLossAccountings.splice(
          splitEditInfoKey.editIndex,
          0,
          firstLossAccounting,
        );
      }
      let newLossAccounting: IDetailLossAccounting = getInitialLossAccounting(
        getEditInfoKey(
          EDIT_LOSS_ACCOUNTING_LABEL,
          withPhysicalSegmentRef || INITIAL_RECORD_ID,
          withPhysicalSegmentRef
            ? splitEditInfoKey.editIndex + 1
            : highestIndex + 1,
        ),
      );

      newLossAccounting.physical_segment_ref = withPhysicalSegmentRef || null;

      if (withPhysicalSegmentRef && splitEditInfoKey.editIndex === 0) {
        updatedLossAccountings.splice(
          splitEditInfoKey.editIndex + 1,
          0,
          newLossAccounting,
        );
      } else {
        updatedLossAccountings.push(newLossAccounting);
      }

      updatedLossAccountings.forEach(
        (lossAccounting: IDetailLossAccounting, index: number) => {
          const { editIndex } = getSplitEditInfoKey(lossAccounting.key);

          if (editIndex === undefined) {
            throw new Error(
              `Invalid lossAccounting.key: ${lossAccounting.key}`,
            );
          }

          if (editIndex > splitEditInfoKey.editIndex) {
            updatedLossAccountings[index] =
              copyDetailLossAccounting(lossAccounting);
          }

          if (
            withPhysicalSegmentRef &&
            updatedLossAccountings[index].physical_segment_ref ===
              withPhysicalSegmentRef
          ) {
            updatedLossAccountings[index].key = getEditInfoKey(
              EDIT_LOSS_ACCOUNTING_LABEL,
              withPhysicalSegmentRef || INITIAL_RECORD_ID,
              index,
            );
          }

          if (!withPhysicalSegmentRef && editIndex < index) {
            updatedLossAccountings[index].key = getEditInfoKey(
              EDIT_LOSS_ACCOUNTING_LABEL,
              INITIAL_RECORD_ID,
              index,
            );
          }
        },
      );

      updatedDetailState.lossAccountings = withPhysicalSegmentRef
        ? updatedLossAccountings.sort((a, b) => a.key.localeCompare(b.key))
        : updatedLossAccountings;
    }

    if (editLossAccountingMap !== undefined) {
      for (let lossAccountingKey in editLossAccountingMap) {
        const editLossAccounting: IEditLossAccounting =
          editLossAccountingMap[lossAccountingKey];
        const adjustedLossAccountingKey: string =
          lossAccountingKey === getInitialLossAccountingKey()
            ? getEditInfoKey(EDIT_LOSS_ACCOUNTING_LABEL, INITIAL_RECORD_ID, 1)
            : lossAccountingKey;
        const lossAccountingIndex: number = updatedLossAccountings.findIndex(
          (detailLossAccounting: IDetailLossAccounting): boolean =>
            detailLossAccounting.key === adjustedLossAccountingKey,
        );
        const updatedLossAccounting: IDetailLossAccounting =
          lossAccountingIndex === -1
            ? getInitialLossAccounting(
                adjustedLossAccountingKey,
                withPhysicalSegmentRef,
              )
            : copyDetailLossAccounting(
                updatedLossAccountings[lossAccountingIndex],
              );
        const { editLossMethod, physical_segment_ref, start, stop } =
          editLossAccounting;

        if (editLossMethod !== undefined) {
          const updatedLossMethod: IDetailLossMethod =
            updatedLossAccounting.lossMethod === null
              ? getInitialLossMethod()
              : copyDetailLossMethod(updatedLossAccounting.lossMethod)!;
          const { editContractNumbers, editTagIds, loss_method_entry_type } =
            editLossMethod;

          if (editContractNumbers !== undefined) {
            const { addAfterContract, editContractMap, removeContract } =
              editContractNumbers;

            if (updatedLossMethod.contractNumbers === null) {
              updatedLossMethod.contractNumbers = [];
            } else {
              updatedLossMethod.contractNumbers = [
                ...updatedLossMethod.contractNumbers,
              ];
            }

            if (addAfterContract !== undefined) {
              let foundIndex: number = -1;
              let highestIndex: number = -1;

              updatedLossMethod.contractNumbers.forEach(
                (contract: IContract, index: number) => {
                  const { editIndex } = getSplitEditInfoKey(contract.key);

                  if (editIndex === undefined) {
                    throw new Error(`Invalid contract.key: ${contract.key}`);
                  }

                  if (editIndex > highestIndex) {
                    highestIndex = editIndex;
                  }

                  if (contract.key === addAfterContract) {
                    foundIndex = index;
                  }
                },
              );

              if (foundIndex !== -1) {
                const newEditInfoKey: string = getEditInfoKey(
                  adjustedLossAccountingKey,
                  EDIT_LOSS_METHODS_CONTRACTS_PRIMARY_KEY,
                  highestIndex + 1,
                );

                updatedLossMethod.contractNumbers.splice(
                  foundIndex + 1,
                  0,
                  getInitialContract(newEditInfoKey),
                );

                updatedDetailState.focusKey = newEditInfoKey;
              }
            }

            if (editContractMap !== undefined) {
              for (let contractKey in editContractMap) {
                let contractIndex: number =
                  updatedLossMethod.contractNumbers.findIndex(
                    (contract: IContract): boolean =>
                      contract.key === contractKey,
                  );
                let updatedContract: IContract;

                if (contractIndex === -1) {
                  updatedContract = getInitialContract(contractKey);
                  contractIndex = updatedLossMethod.contractNumbers.length;
                } else {
                  updatedContract =
                    updatedLossMethod.contractNumbers[contractIndex];
                }

                updatedLossMethod.contractNumbers[contractIndex] = {
                  ...updatedContract,
                  ...editContractMap[contractKey],
                };
              }

              updatedDetailState.focusKey = null;
            }

            if (removeContract !== undefined) {
              const foundIndex: number =
                updatedLossMethod.contractNumbers.findIndex(
                  (contract: IContract): boolean =>
                    contract.key === removeContract,
                );

              if (foundIndex !== -1) {
                updatedLossMethod.contractNumbers.splice(foundIndex, 1);
              }

              if (updatedLossMethod.contractNumbers.length === 0) {
                updatedLossMethod.contractNumbers = null;
              }
            }
          }

          if (editTagIds !== undefined) {
            const { addAfterETagTagId, editETagTagIdMap, removeETagTagId } =
              editTagIds;

            if (updatedLossMethod.tag_ids === null) {
              updatedLossMethod.tag_ids = [];
            } else {
              updatedLossMethod.tag_ids = [...updatedLossMethod.tag_ids];
            }

            if (addAfterETagTagId !== undefined) {
              let foundIndex: number = -1;
              let highestIndex: number = -1;

              updatedLossMethod.tag_ids.forEach(
                (eTagTagId: IETagTagId, index: number) => {
                  const { editIndex } = getSplitEditInfoKey(eTagTagId.key);

                  if (editIndex === undefined) {
                    throw new Error(`Invalid eTagTagId.key: ${eTagTagId.key}`);
                  }

                  if (editIndex > highestIndex) {
                    highestIndex = editIndex;
                  }

                  if (eTagTagId.key === addAfterETagTagId) {
                    foundIndex = index;
                  }
                },
              );

              if (foundIndex !== -1) {
                const newEditInfoKey: string = getEditInfoKey(
                  adjustedLossAccountingKey,
                  EDIT_LOSS_METHODS_ETAG_TAG_IDS_PRIMARY_KEY,
                  highestIndex + 1,
                );

                updatedLossMethod.tag_ids.splice(
                  foundIndex + 1,
                  0,
                  getInitialETagTagId(newEditInfoKey),
                );
              }
            }

            if (editETagTagIdMap !== undefined) {
              for (let eTagTagIdKey in editETagTagIdMap) {
                let eTagTagIdIndex: number =
                  updatedLossMethod.tag_ids.findIndex(
                    (eTagTagId: IETagTagId): boolean =>
                      eTagTagId.key === eTagTagIdKey,
                  );
                let updatedETagTagId: IETagTagId;

                if (eTagTagIdIndex === -1) {
                  updatedETagTagId = getInitialETagTagId(eTagTagIdKey);
                  eTagTagIdIndex = updatedLossMethod.tag_ids.length;
                } else {
                  updatedETagTagId = updatedLossMethod.tag_ids[eTagTagIdIndex];
                }

                updatedLossMethod.tag_ids[eTagTagIdIndex] = {
                  ...copyETagTagId(updatedETagTagId),
                  ...editETagTagIdMap[eTagTagIdKey],
                };
              }
            }

            if (removeETagTagId !== undefined) {
              const foundIndex: number = updatedLossMethod.tag_ids.findIndex(
                (eTagTagId: IETagTagId): boolean =>
                  eTagTagId.key === removeETagTagId,
              );

              if (foundIndex !== -1) {
                updatedLossMethod.tag_ids.splice(foundIndex, 1);
              }
            }
          }

          if (loss_method_entry_type !== undefined) {
            updatedLossMethod.loss_method_entry_type = loss_method_entry_type;
          }

          updatedLossAccounting.lossMethod = updatedLossMethod;
        }

        if (physical_segment_ref !== undefined) {
          updatedLossAccounting.physical_segment_ref = physical_segment_ref;
        }

        if (start !== undefined) {
          updatedLossAccounting.start = start;

          if (
            !isEmptyValue(updatedLossAccounting.start) &&
            !isEmptyValue(updatedLossAccounting.stop)
          ) {
            const startDateTime: ZonedDateTime = ZonedDateTime.parseIso(
              updatedLossAccounting.start!,
              timeZone,
            );
            const stopDateTime: ZonedDateTime = ZonedDateTime.parseIso(
              updatedLossAccounting.stop!,
              timeZone,
            );

            if (startDateTime.isSameOrAfter(stopDateTime)) {
              updatedLossAccounting.stop = null;
            }
          }
        }

        if (stop !== undefined) {
          updatedLossAccounting.stop = stop;
        }

        updatedLossAccountings[
          lossAccountingIndex === -1
            ? updatedLossAccountings.length
            : lossAccountingIndex
        ] = updatedLossAccounting;
      }

      updatedDetailState.lossAccountings = updatedLossAccountings;
    }

    if (removeLossAccounting !== undefined) {
      const foundIndex: number = updatedLossAccountings.findIndex(
        (lossAccounting: IDetailLossAccounting): boolean =>
          lossAccounting.key === removeLossAccounting,
      );

      if (foundIndex !== -1) {
        updatedLossAccountings.splice(foundIndex, 1);

        updatedDetailState.lossAccountings = updatedLossAccountings;

        updatedDetailState.validations = updateValidations(
          updatedDetailState.validations,
          detailState.lossAccountings,
          updatedLossAccountings,
        );
      }
    }

    return updatedDetailState;
  };
};

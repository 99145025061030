import { PlusCircleOutlined } from '@ant-design/icons';
import { AxiosResponse } from 'axios';
import IconButton from 'components/atoms/IconButton/IconButton';
import Input, { IInputProps } from 'components/atoms/Input/Input';
import InputNumber from 'components/atoms/InputNumber/InputNumber';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import EditorFooter from 'components/molecules/EditorFooter/EditorFooter';
import Modal from 'components/molecules/Modal/Modal';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import {
  BUTTON_ICON_DIMENSIONS,
  COLUMN_LAYOUT_SHARED_STYLES,
} from 'constants/styles';
import { DATE_TIME_FORMAT, TIME_FORMAT } from 'constants/time';
import { EActionState } from 'enums/General';
import usePermissions from 'hooks/usePermissions';
import {
  ITransmissionServiceNetwork,
  ITransmissionServiceNetworkData,
  ITransmissionServiceNetworkResponse,
} from 'interfaces/ETag';
import {
  ChangeEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { saveSingleTSN } from 'services/agent/transmissionAvailability';
import styled from 'styled-components';
import { TTimeZone } from 'types/DateTime';
import { TToEntityId } from 'types/ToEntity';
import { captureError } from 'utils/error';
import { encodeIds, isSuccessStatus } from 'utils/general';
import { ZonedDateTime } from 'utils/zonedDateTime';
import DateTimePicker from '../DateTimePicker/DateTimePicker';

export const WIDTH = 400;
export const HEIGHT = 980;
const LABEL_WIDTH_VALUE = 120;
const INPUT_ITEM_STYLE = `
  flex-grow: 100;
  width: 100px;
`;

const AddIcon = styled(PlusCircleOutlined)`
  ${BUTTON_ICON_DIMENSIONS}
`;

const Wrapper = styled.div`
  ${COLUMN_LAYOUT_SHARED_STYLES}
`;

interface IStyledLabelProps {
  width?: string;
}

const StyledLabel = styled.div<IStyledLabelProps>`
  font-weight: bold;
  ${(props) =>
    `width: ${
      props.width === undefined ? `${LABEL_WIDTH_VALUE}px` : props.width
    }`};
  padding: 3px;
`;

const StyledInput = styled((props: IInputProps) => Input(props))`
  ${INPUT_ITEM_STYLE};
`;

const StyledInputNumber = styled(InputNumber)`
  ${INPUT_ITEM_STYLE};
`;

const StyledDateTimePicker = styled(DateTimePicker)`
  ${INPUT_ITEM_STYLE};
  > .ant-picker {
    width: 100%;
  }
`;

export interface IAddTSNProps {
  encodedPermissionsId: string;
  isDisabled?: boolean;
  onApply: () => void;
  timeZone: TTimeZone;
  toEntityId: TToEntityId;
}

const AddTSN = (props: IAddTSNProps): JSX.Element => {
  const { encodedPermissionsId, isDisabled, onApply, timeZone, toEntityId } =
    props;
  const buttonRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const permissions = usePermissions(encodedPermissionsId);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [actionState, setActionState] = useState<EActionState>(
    EActionState.NoAction,
  );
  const [TSNSaveErrorMessage, setTSNSaveErrorMessage] = useState<string | null>(
    null,
  );
  const [capacityGranted, setCapacityGranted] = useState<number | null>(null);

  const [startDateTime, setStartDateTime] = useState<ZonedDateTime | null>(
    ZonedDateTime.now(timeZone).withHour(0).withMinute(0),
  );
  const [stopDateTime, setStopDateTime] = useState<ZonedDateTime | null>(
    ZonedDateTime.now(timeZone).add(1, 'day').withHour(0).withMinute(0),
  );

  const disabledStartDates = (dateTime: ZonedDateTime | null): boolean => {
    if (stopDateTime && dateTime) {
      return dateTime?.isAfter(stopDateTime);
    }
    return false;
  };

  const disabledStopDates = (dateTime: ZonedDateTime | null): boolean => {
    if (startDateTime && dateTime) {
      return dateTime?.isBefore(startDateTime);
    }
    return false;
  };

  const initialTSN: ITransmissionServiceNetwork = {
    assignment_ref: null,
    data: [],
  };

  const [transmissionServiceNetwork, setTransmissionServiceNetwork] =
    useState<ITransmissionServiceNetwork>(initialTSN);

  useEffect(() => {
    if (!showModal) {
      setActionState(EActionState.NoAction);
      setTSNSaveErrorMessage(null);
    }
  }, [showModal]);

  const isSaveEnabled = useMemo(() => {
    return (
      transmissionServiceNetwork.assignment_ref &&
      transmissionServiceNetwork.data.length > 0 &&
      startDateTime &&
      stopDateTime
    );
  }, [
    startDateTime,
    stopDateTime,
    transmissionServiceNetwork.assignment_ref,
    transmissionServiceNetwork.data,
  ]);

  useEffect(() => {
    if (capacityGranted && startDateTime && stopDateTime) {
      const newTSNData: ITransmissionServiceNetworkData = {
        capacity_granted: capacityGranted,
        start: startDateTime.withTimeZone('UTC', false).toIsoString(),
        stop: stopDateTime.withTimeZone('UTC', false).toIsoString(),
      };
      setTransmissionServiceNetwork((prevTSN) => ({
        ...prevTSN,
        data: [newTSNData],
      }));
    } else {
      setTransmissionServiceNetwork((prevTSN) => ({
        ...prevTSN,
        data: [],
      }));
    }
  }, [capacityGranted, startDateTime, stopDateTime]);

  const handleSaveTSN = async () => {
    setActionState(EActionState.Actioning);
    try {
      const response: AxiosResponse<ITransmissionServiceNetworkResponse> =
        await saveSingleTSN(toEntityId, transmissionServiceNetwork);
      const tsnSaveResponse: ITransmissionServiceNetworkResponse =
        response.data;
      if (response.status === 400) {
        setActionState(EActionState.Failed);
        setTSNSaveErrorMessage(String(tsnSaveResponse.errorMessage));
      } else if (!isSuccessStatus(response.status)) {
        throw new Error(tsnSaveResponse.errorMessage!);
      } else {
        // Reset modal values to default state
        setActionState(EActionState.Succeeded);
        setTSNSaveErrorMessage(null);
        onApply();
        setShowModal(false);
        setStartDateTime(ZonedDateTime.now(timeZone).withHour(0).withMinute(0));
        setStopDateTime(
          ZonedDateTime.now(timeZone).withHour(0).withMinute(0).add(1, 'day'),
        );
        setCapacityGranted(null);
        setTransmissionServiceNetwork(initialTSN);
      }
    } catch (error: any) {
      captureError(
        error,
        `Failed saving TSN: ${JSON.stringify(transmissionServiceNetwork)}`,
      );

      setActionState(EActionState.Failed);

      setTSNSaveErrorMessage('Failed to save TSN. Please try again later.');
    }
  };

  const handleCapacityChanged = (capacityGranted: number | undefined) => {
    if (capacityGranted === undefined || capacityGranted < 0) {
      setCapacityGranted(null);
    } else {
      setCapacityGranted(capacityGranted);
    }
  };

  const handleStartChanged = (startDateTime: ZonedDateTime | null) => {
    setStartDateTime(startDateTime);
  };

  const handleStopChanged = (stopDateTime: ZonedDateTime | null) => {
    setStopDateTime(stopDateTime);
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  const arefCharacterFilter = (e: React.KeyboardEvent) => {
    const specialCharRegex = new RegExp('[a-zA-Z0-9-]');
    const pressedKey = e.key;
    if (!specialCharRegex.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  const handleClick = () => {
    setShowModal(true);
  };

  const handleArefNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        assignment_ref: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handleTransmissionProviderChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        transmission_provider: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handleSourceChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        source_name: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handleSinkChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        sink_name: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handlePodChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        pod_name: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handlePorChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTransmissionServiceNetwork({
        ...transmissionServiceNetwork,
        por_name: e.currentTarget.value,
      });
    },
    [transmissionServiceNetwork],
  );

  const handleNercPriorityChange = useCallback(
    (value: unknown) => {
      typeof value === 'number' &&
        setTransmissionServiceNetwork({
          ...transmissionServiceNetwork,
          nerc_curtailment_priority: value,
        });
    },
    [transmissionServiceNetwork],
  );

  return (
    <>
      <Tooltip title={'Add TSN'}>
        <IconButton
          buttonRef={buttonRef}
          icon={<AddIcon />}
          isDisabled={isDisabled}
          onClick={handleClick}
        />
      </Tooltip>
      <Modal
        footer={
          <EditorFooter
            confirmActionState={actionState}
            confirmLabel='Save'
            encodedPermissionsId={encodeIds(
              [encodedPermissionsId, 'footer'],
              toEntityId,
            )}
            errorMessage={TSNSaveErrorMessage}
            isConfirmDisabled={!isSaveEnabled}
            onCancel={handleCancel}
            onConfirm={handleSaveTSN}
          />
        }
        isVisible={showModal}
        onCancel={handleCancel}
        title={`Add Transmission Service Network`}
        width={WIDTH}
      >
        <Wrapper>
          <SeparatedRowLayout>
            <StyledLabel>AREF Name:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onKeyDown={arefCharacterFilter}
              onChange={handleArefNameChange}
              value={transmissionServiceNetwork.assignment_ref ?? undefined}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Capacity Granted:</StyledLabel>
            <StyledInputNumber
              hideArrows={true}
              isDisabled={!permissions.isExecutable}
              onChange={handleCapacityChanged}
              value={
                transmissionServiceNetwork.data[0]?.capacity_granted ??
                undefined
              }
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Start Date:</StyledLabel>
            <StyledDateTimePicker
              allowClear={true}
              onChange={handleStartChanged}
              selectHourOnly={false}
              format={DATE_TIME_FORMAT}
              placeholder='Profile Start'
              disabledDate={disabledStartDates}
              showTime={{
                format: TIME_FORMAT,
                defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
              }}
              timeZone={timeZone}
              value={startDateTime}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Stop Date:</StyledLabel>
            <StyledDateTimePicker
              allowClear={true}
              onChange={handleStopChanged}
              defaultPickerValue={
                startDateTime?.add(1, 'day') ??
                ZonedDateTime.now(timeZone).startOf('day')
              }
              selectHourOnly={false}
              format={DATE_TIME_FORMAT}
              placeholder='Profile Stop'
              disabledDate={disabledStopDates}
              showTime={{
                format: TIME_FORMAT,
                defaultValue: ZonedDateTime.now(timeZone).startOf('day'),
              }}
              timeZone={timeZone}
              value={stopDateTime}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Transmission Provider:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onChange={handleTransmissionProviderChange}
              value={
                transmissionServiceNetwork.transmission_provider ?? undefined
              }
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Source:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onChange={handleSourceChange}
              value={transmissionServiceNetwork.source_name ?? undefined}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>Sink:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onChange={handleSinkChange}
              value={transmissionServiceNetwork.sink_name ?? undefined}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>POD:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onChange={handlePodChange}
              value={transmissionServiceNetwork.pod_name ?? undefined}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>POR:</StyledLabel>
            <StyledInput
              isDisabled={!permissions.isExecutable}
              onChange={handlePorChange}
              value={transmissionServiceNetwork.por_name ?? undefined}
            />
          </SeparatedRowLayout>
          <SeparatedRowLayout>
            <StyledLabel>NERC Priority:</StyledLabel>
            <StyledInputNumber
              hideArrows={true}
              isDisabled={!permissions.isExecutable}
              min={0}
              onChange={handleNercPriorityChange}
              value={
                transmissionServiceNetwork.nerc_curtailment_priority ??
                undefined
              }
            />
          </SeparatedRowLayout>
        </Wrapper>
      </Modal>
    </>
  );
};

export default AddTSN;

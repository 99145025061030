import { MutableRefObject, useRef } from 'react';

const useInitialData = <T>(
  data: T | undefined,
  hasChanged: (previousData: T | undefined) => boolean,
): T | undefined => {
  const previousData: MutableRefObject<T | undefined> = useRef<T | undefined>(
    undefined,
  );

  if (hasChanged(previousData.current)) {
    previousData.current = data;
  }

  return previousData.current;
};

export default useInitialData;

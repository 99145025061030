import { DATE_RANGE_LABEL_MAP } from 'constants/Summary';
import { EDefaultDateRange } from 'enums/Summary';
import { IOption } from 'interfaces/Component';

export const DEFAULT_DATE_RANGE_OPTIONS: IOption<EDefaultDateRange>[] = [
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Today],
    value: EDefaultDateRange.Today,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Tomorrow],
    value: EDefaultDateRange.Tomorrow,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.Yesterday],
    value: EDefaultDateRange.Yesterday,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayTomorrow],
    value: EDefaultDateRange.TodayTomorrow,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.YesterdayToday],
    value: EDefaultDateRange.YesterdayToday,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus2Days],
    value: EDefaultDateRange.TodayPlus2Days,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus3Days],
    value: EDefaultDateRange.TodayPlus3Days,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.TodayPlus4Days],
    value: EDefaultDateRange.TodayPlus4Days,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.LastWeek],
    value: EDefaultDateRange.LastWeek,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.ThisWeek],
    value: EDefaultDateRange.ThisWeek,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.NextWeek],
    value: EDefaultDateRange.NextWeek,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.LastMonth],
    value: EDefaultDateRange.LastMonth,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.ThisMonth],
    value: EDefaultDateRange.ThisMonth,
  },
  {
    label: DATE_RANGE_LABEL_MAP[EDefaultDateRange.NextMonth],
    value: EDefaultDateRange.NextMonth,
  },
];

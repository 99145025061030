import {
  FileSearchOutlined,
  FileSyncOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import React, { MouseEvent } from 'react';
import usePermissions from '../../../hooks/usePermissions';
import styled from 'styled-components';

interface IMenuActionItemProps {
  encodedPermissionsId: string | undefined;
  onClick?: () => void;
  stopPropagation?: boolean;
  type: string;
}

const StyledMenuItem = styled.div`
  .anticon {
    padding-right: 5px;
  }
`;

const MenuActionItem = (props: IMenuActionItemProps): JSX.Element | null => {
  const { encodedPermissionsId, onClick, stopPropagation, type } = props;

  const permissions = usePermissions(encodedPermissionsId);
  let icon;
  let text = '';

  switch (type) {
    case 'reports':
      icon = <FileSyncOutlined />;
      text = 'Reports';
      break;
    case 'search':
      icon = <FileSearchOutlined />;
      text = 'Search Tag';
      break;
    case 'query':
      icon = <SearchOutlined />;
      text = 'Query E-tag Authority';
      break;
  }

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    if (stopPropagation) {
      event.stopPropagation();
    }

    if (onClick !== undefined) {
      onClick();
    }
  };

  if (permissions.isDisplayable) {
    return (
      <StyledMenuItem onClick={handleClick}>
        {icon}
        <span>{text}</span>
      </StyledMenuItem>
    );
  }

  return null;
};

export default MenuActionItem;

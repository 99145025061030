import { ERegistryType } from 'enums/General';
import { EPointType } from 'enums/Point';
import { IOption } from 'interfaces/Component';
import { IPointInfo, IRegistryPoint } from 'interfaces/Point';

export const registryTypeToPointType = (
  registryType: ERegistryType,
): EPointType => {
  switch (registryType) {
    case ERegistryType.PORPOD: {
      return EPointType.PORPOD;
    }
    case ERegistryType.SourceSink: {
      return EPointType.SourceSink;
    }
    default:
      throw new Error(`Invalid registry type for point: ${registryType}`);
  }
};

export const registryPointToPointInfo = (
  registryPoint: IRegistryPoint,
): IPointInfo => ({
  point_id: parseInt(registryPoint.TaggingPointID, 10),
  point_name: registryPoint.Name,
  point_type: registryTypeToPointType(registryPoint['Registry Type']),
});

export const pointInfoToString = (value: IPointInfo | null): string =>
  value === null ? '' : value.point_name;

export const pointInfoToUid = (value: IPointInfo | null): string =>
  value === null ? '' : value.point_id.toString();

export const registryPointToPointInfoOption = (
  registryPoint: IRegistryPoint,
): IOption<IPointInfo> => ({
  label: registryPoint.Name,
  value: registryPointToPointInfo(registryPoint),
});

export const pointInfoEqual = (
  a: IPointInfo | null,
  b: IPointInfo | null,
): boolean => {
  if (a === null) {
    return b === null;
  }

  return (
    b !== null &&
    a.point_id === b.point_id &&
    a.point_name === b.point_name &&
    (a.point_type.includes(b.point_type) ||
      b.point_type.includes(a.point_type) ||
      a.point_type === b.point_type)
  );
};

import { urlSafeEncode } from '@aws-amplify/core';
import Base64 from 'crypto-js/enc-base64';
import sha256 from 'crypto-js/sha256';
import authConfig from 'private/auth-config.json';

const setState = (state: string) => {
  window.sessionStorage.setItem('oauth_state', state);
};

const setPKCE = (private_key: string) => {
  window.sessionStorage.setItem('ouath_pkce_key', private_key);
};

/**
 * Stolen from a aws-amplify Auth.federatedSignIn, and
 * simplified to take the authConfig imported from our json file.
 * Also simplified with provider always being COGNITO.
 * Enhanced to take an idpIdentifier. If this identifier is given,
 * user will be redirected to their idp login page with as few
 * steps as we can manage. If it is not given, the standard
 * cognito hosted ui login page will show, which asks the user
 * for their email, such as 'user1@foobar.com'. Then 'foobar.com'
 * is parsed as the idpIdentifier and the user is directed to their
 * idp login page.
 * @param customState appended to the state query parameter in the auth flow
 * @param idpIdentifier optional, used to skip page where user has to enter email
 */
export const federatedSignIn = (
  customState?: string,
  idpIdentifier?: string | null,
) => {
  let {
    oauth: { domain, redirectSignIn, responseType, scope },
    userPoolWebClientId,
  } = authConfig;
  const provider: string = 'COGNITO';
  const generatedState = generateState(32);

  const state = customState
    ? `${generatedState}-${urlSafeEncode(customState)}`
    : generatedState;

  setState(state);

  const pkce_key = generateRandom(128);
  setPKCE(pkce_key);

  const code_challenge = generateChallenge(pkce_key);
  const code_challenge_method = 'S256';

  const scopesString = scope.join(' ');
  const qparams: Record<string, string> = {};
  if (idpIdentifier !== undefined && idpIdentifier !== null) {
    qparams.idp_identifier = idpIdentifier;
  } else {
    qparams.identity_provider = provider;
  }

  Object.assign(qparams, {
    redirect_uri: redirectSignIn,
    response_type: responseType,
    client_id: userPoolWebClientId,
    state,
    scope: scopesString,
    ...(responseType === 'code' ? { code_challenge } : {}),
    ...(responseType === 'code' ? { code_challenge_method } : {}),
  });
  const queryString = Object.entries(qparams)
    .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
    .join('&');

  window.open(loginUrl(domain, queryString, idpIdentifier), '_self');
};

const loginUrl = (
  domain: string,
  queryString: string,
  idpIdentifier: string | undefined | null,
): string => {
  if (idpIdentifier === undefined || idpIdentifier == null) {
    return `https://${domain}/oauth2/authorize?${queryString}`;
  } else {
    return `https://${domain}/authorize?${queryString}`;
  }
};

const generateState = (length: number) => {
  let result = '';
  let i = length;
  const chars =
    '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

  for (; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))];
  }

  return result;
};

const generateChallenge = (code: string) => {
  return base64URL(sha256(code));
};

const base64URL = (wordArray: CryptoJS.lib.WordArray) => {
  return wordArray
    .toString(Base64)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_');
};

const generateRandom = (size: number) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~';
  const buffer = new Uint8Array(size);
  if (typeof window !== 'undefined' && !!window.crypto) {
    window.crypto.getRandomValues(buffer);
  } else {
    for (let i: number = 0; i < size; i += 1) {
      buffer[i] = (Math.random() * CHARSET.length) | 0;
    }
  }
  return bufferToString(buffer);
};

const bufferToString = (buffer: Uint8Array) => {
  const CHARSET =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = [];
  for (let i: number = 0; i < buffer.byteLength; i += 1) {
    const index = buffer[i] % CHARSET.length;
    state.push(CHARSET[index]);
  }
  return state.join('');
};

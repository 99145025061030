import { API } from 'aws-amplify';
import { AxiosResponse } from 'axios';
import { IUserInfoResponse } from 'interfaces/ToEntity';
import { USER_INFO_ROUTE } from 'services/auth/constants';
import { AUTH_SERVICE_API_NAME, DEFAULT_OPTIONS } from 'services/constants';

export const retrieveUserInfo = async (): Promise<
  AxiosResponse<IUserInfoResponse>
> => {
  return await API.get(
    AUTH_SERVICE_API_NAME,
    USER_INFO_ROUTE(),
    DEFAULT_OPTIONS,
  );
};

import { Select as AntDesignSelect } from 'antd';
import Tooltip from 'components/molecules/Tooltip/Tooltip';
import SeparatedRowLayout from 'components/atoms/SeparatedRowLayout/SeparatedRowLayout';
import {
  EXPANDED_INPUT_HEIGHT_VALUE,
  EXPANSION_TRANSITION_IN_SECONDS_VALUE,
  FLOAT_BOX_SHADOW,
  INPUT_HEIGHT_VALUE,
} from 'constants/styles';
import { IExpandableProps, IOption, IValueChanged } from 'interfaces/Component';
import React, {
  MutableRefObject,
  ReactNode,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useThemeSwitcher } from 'react-css-theme-switcher';
import styled from 'styled-components';
import {
  clickOnAppAfterDelay,
  hasModifierKey,
  isEmptyValue,
  isModifierKey,
} from 'utils/general';
import {
  inputBackground,
  inputBackgroundExpand,
  inputValueChanged,
} from 'utils/styles';

const { Option: AntDesignOption } = AntDesignSelect;

const LIST_HEIGHT_VALUE = 256;
const LIST_ITEM_HEIGHT_VALUE = 28;

interface IWrapperProps extends IExpandableProps, IValueChanged {
  showSelectionArrowOnly?: boolean;
}

const Wrapper = styled.div<IWrapperProps>`
  height: ${INPUT_HEIGHT_VALUE}px;

  .ant-select-selection-item .option-text {
    ${(props) => (props.hideSelectedOptionText ? 'display: none' : '')}
  }

  .ant-select {
    width: 100%;
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector,
  .ant-select-focused .ant-select-selector {
    border-width: 1px !important;
  }

  .ant-select-selector {
    ${(props) => inputBackground(props)}
  }

  ${(props) =>
    props.shouldExpand
      ? `
      .ant-select-selector {
        ${inputBackgroundExpand(props)}
        flex-wrap: nowrap;
        overflow: hidden;
        transition: ${EXPANSION_TRANSITION_IN_SECONDS_VALUE}s;
        transition-timing-function: ease;
        width: 100%;
      }

      .ant-select-focused .ant-select-selector {
        box-shadow: ${FLOAT_BOX_SHADOW};
        height: ${EXPANDED_INPUT_HEIGHT_VALUE}px;
        overflow: auto;
        width: 200%;
      }
    `
      : `
      .ant-select-selector {
        flex-wrap: nowrap;
        overflow: hidden;
        width: 100%;
      }
    `}

  .ant-select-selection-overflow {
    flex-wrap: unset;
  }

  .ant-select-selection-item {
    ${(props) => inputValueChanged(props)}

    ${(props) => (props.showSelectionArrowOnly ? 'display: none;' : '')}
  }
`;

export interface ISelectFilterOption {
  label?: ReactNode;
  value?: ReactText;
}

export interface ISelectConfig<T> {
  allowClear?: boolean;
  allowMultiple?: true;
  equalityChecker?: (a: T, b: T) => boolean;
  equalityCheckerMultiple?: (a: T[], b: T[]) => boolean;
  dropdownMatchSelectWidth?: boolean | number;
  filter?: (input: string, option?: ISelectFilterOption) => boolean;
  hideSelectedOptionText?: boolean;
  initialValue?: T | null;
  initialValues?: T[] | null;
  isDisabled?: boolean;
  isLoading?: boolean;
  listHeight?: number;
  listItemHeight?: number;
  maxTagCount?: number | 'responsive';
  options: IOption<T>[];
  placeholder?: string;
  shouldExpand?: boolean;
  showOptionTooltip?: boolean;
  showSearch?: boolean;
  showSelectionArrowOnly?: boolean;
  value?: T;
  values?: T[];
  valueToUid: (value: T) => string;
}

export interface ISelectProps<T> extends ISelectConfig<T> {
  className?: string;
  dropdownClassName?: string;
  dropdownRender?: (menu: JSX.Element) => JSX.Element;
  getPopupContainer?: (selectDomNode: HTMLElement) => HTMLElement;
  onChange?: (value: T | undefined) => void;
  onChangeMultiple?: (values: T[]) => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  open?: boolean;
}

const Select = <T extends any>(props: ISelectProps<T>): JSX.Element => {
  const { currentTheme } = useThemeSwitcher();
  const {
    allowClear,
    allowMultiple,
    className,
    dropdownClassName,
    dropdownMatchSelectWidth,
    dropdownRender,
    equalityChecker,
    equalityCheckerMultiple,
    filter,
    getPopupContainer,
    hideSelectedOptionText,
    initialValue,
    initialValues,
    isDisabled,
    isLoading,
    listHeight,
    listItemHeight,
    maxTagCount,
    onChange,
    onChangeMultiple,
    onDropdownVisibleChange,
    open,
    options,
    placeholder,
    shouldExpand,
    showOptionTooltip,
    showSearch,
    showSelectionArrowOnly,
    value,
    values,
    valueToUid,
  } = props;
  const wrapperRef =
    useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const selectInputRef = useRef<HTMLInputElement>();
  const requestAnimationFrameIdRef = useRef<number | null>(null);

  useEffect(() => {
    if (wrapperRef.current !== undefined) {
      const searchInputElements: HTMLCollectionOf<Element> =
        wrapperRef.current.getElementsByClassName(
          'ant-select-selection-search-input',
        );

      if (searchInputElements.length === 1) {
        selectInputRef.current = searchInputElements[0] as HTMLInputElement;
      } else {
        throw new Error('Invalid number of search input elements found');
      }
    }

    return () => {
      if (requestAnimationFrameIdRef.current !== null) {
        window.cancelAnimationFrame(requestAnimationFrameIdRef.current);
      }
    };
  }, []);

  // Ant Design Select Component uses a span to show the currently selected
  // item. This span includes the title attribute which is set to the string
  // representation of the selected value's label property. The title attribute
  // causes modern browsers to show a tooltip when in hover state. We thus have
  // to remove this attribute every time the value changes in order to hide
  // this tooltip.
  useEffect(() => {
    if (wrapperRef.current !== undefined && !isEmptyValue(value)) {
      // Should only be one selection item element if we have a value
      const spanElement: HTMLSpanElement =
        wrapperRef.current.getElementsByClassName(
          'ant-select-selection-item',
        )[0] as HTMLSpanElement;

      spanElement.removeAttribute('title');
    }
  }, [options, value, wrapperRef]);

  const valueChanged: boolean | undefined = useMemo(() => {
    if (allowMultiple === true) {
      if (initialValues !== undefined) {
        if (equalityCheckerMultiple === undefined) {
          throw new Error(
            'Missing dependent prop: equalityCheckerMulitple due to inclusion of prop: initialValues',
          );
        }

        if (values !== undefined) {
          return initialValues === null
            ? true
            : !equalityCheckerMultiple(initialValues, values);
        }
      }
    } else {
      if (initialValue !== undefined) {
        if (equalityChecker === undefined) {
          throw new Error(
            'Missing dependent prop: equalityChecker due to inclusion of prop: initialValue',
          );
        }

        if (value !== undefined) {
          return initialValue === null
            ? true
            : !equalityChecker(initialValue, value);
        }
      }
    }

    return false;
  }, [
    allowMultiple,
    equalityChecker,
    equalityCheckerMultiple,
    initialValue,
    initialValues,
    value,
    values,
  ]);

  // When we have a search input on the Select, we ensure that it receives
  // focus whenever the select value changes because the logic for focus/blur
  // can get out of sync and the Select can become focus stuck.
  const handleFocus = useCallback(() => {
    if (selectInputRef.current !== undefined) {
      requestAnimationFrameIdRef.current = window.requestAnimationFrame(() => {
        if (selectInputRef.current !== undefined) {
          selectInputRef.current.focus();
        }

        requestAnimationFrameIdRef.current = null;
      });
    }
  }, []);

  const handleChange = (uid: string | string[] | undefined) => {
    if (allowMultiple) {
      const selected: T[] = [];
      if (uid !== undefined) {
        (uid as string[]).forEach((item: string) => {
          const foundOption: IOption<T> | undefined = options.find(
            (option: IOption<T>): boolean => valueToUid(option.value) === item,
          );
          if (foundOption !== undefined) {
            selected.push(foundOption.value);
          }
        });
      }

      if (onChangeMultiple !== undefined) {
        onChangeMultiple(selected);
      }
    } else {
      if (onChange !== undefined) {
        if (uid === undefined) {
          onChange(undefined);
        }

        const foundOption: IOption<T> | undefined = options.find(
          (option: IOption<T>): boolean => valueToUid(option.value) === uid,
        );
        if (foundOption !== undefined) {
          onChange(foundOption.value);
        }
      }
    }

    handleFocus();
  };

  const adjustedValue = useMemo(() => {
    if (allowMultiple) {
      if (values === null) {
        throw new Error('Invalid Select prop: values');
      }

      if (values !== undefined) {
        return values
          .map((value: T) => valueToUid(value))
          .filter((uid: string | undefined) => uid !== undefined) as string[];
      }
    } else if (value !== undefined) {
      return valueToUid(value);
    }

    return undefined;
  }, [allowMultiple, value, values, valueToUid]);

  const handleKeyDown = useCallback((keyboardEvent: React.KeyboardEvent) => {
    // We don't want modifier keys causing the select menu to stay open, so
    // we trigger a click outside of the Select component to force it to
    // close
    if (hasModifierKey(keyboardEvent.nativeEvent, false)) {
      clickOnAppAfterDelay(3);
    }
  }, []);

  const handleKeyDownCapture = useCallback(
    (keyboardEvent: React.KeyboardEvent) => {
      // If we have just a modifier key on its own, then we stop it from
      // triggering the select to open.
      if (isModifierKey(keyboardEvent.nativeEvent)) {
        keyboardEvent.preventDefault();
        keyboardEvent.stopPropagation();
      }
    },
    [],
  );

  return (
    <Wrapper
      className={className}
      currentTheme={currentTheme!}
      hideSelectedOptionText={hideSelectedOptionText}
      onKeyDown={showSearch ? handleKeyDown : undefined}
      onKeyDownCapture={showSearch ? handleKeyDownCapture : undefined}
      ref={wrapperRef}
      shouldExpand={shouldExpand}
      showSelectionArrowOnly={showSelectionArrowOnly}
      valueChanged={valueChanged}
    >
      <AntDesignSelect
        allowClear={allowClear}
        disabled={isDisabled}
        dropdownClassName={dropdownClassName}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        dropdownRender={dropdownRender}
        filterOption={filter}
        getPopupContainer={getPopupContainer}
        listHeight={listHeight === undefined ? LIST_HEIGHT_VALUE : listHeight}
        listItemHeight={
          listItemHeight === undefined ? LIST_ITEM_HEIGHT_VALUE : listItemHeight
        }
        loading={isLoading}
        maxTagCount={maxTagCount}
        mode={allowMultiple ? 'multiple' : undefined}
        onChange={handleChange}
        onClear={handleFocus}
        onDropdownVisibleChange={onDropdownVisibleChange}
        open={open}
        placeholder={placeholder}
        showSearch={showSearch}
        value={adjustedValue}
        virtual={true}
      >
        {options.map((option: IOption<T>): JSX.Element => {
          const uid: string = valueToUid(option.value);
          const optionLabelBlock = (
            <SeparatedRowLayout>
              {option.icon}
              <div className='option-text'>{option.label}</div>
            </SeparatedRowLayout>
          );

          return (
            <AntDesignOption
              disabled={option.isDisabled}
              key={uid}
              label={option.label}
              title=''
              value={uid}
            >
              {showOptionTooltip ? (
                <Tooltip title={option.label} placement='right'>
                  {option.icon === undefined ? option.label : optionLabelBlock}
                </Tooltip>
              ) : option.icon === undefined ? (
                option.label
              ) : (
                optionLabelBlock
              )}
            </AntDesignOption>
          );
        })}
      </AntDesignSelect>
    </Wrapper>
  );
};

export default Select;

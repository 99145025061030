import styled from 'styled-components';
import {
  PAGE_LAYOUT_STYLES,
  STANDARD_SPACING,
} from '../../../constants/styles';
import { IPageContentProps } from '../../organisms/Page/Page';
import ToEntityHelpUI from '../../molecules/ToEntityHelpUI/ToEntityHelpUI';

const Layout = styled.div`
  ${PAGE_LAYOUT_STYLES}

  > :first-child {
    margin-top: ${STANDARD_SPACING};
  }

  > :last-child {
    margin-bottom: 0;
  }
`;

const ToEntity = styled.div`
  height: 100%;
`;

interface IAlertPageContentProps extends IPageContentProps<undefined> {}

const HelpTutorialsPageContent = (
  props: IAlertPageContentProps,
): JSX.Element => {
  const { userInfo } = props;

  const toEntityId =
    (userInfo.toEntities &&
      userInfo.toEntities[0] &&
      userInfo.toEntities[0].tagging_entity_id) ||
    undefined;

  const toEntity = userInfo.toEntities[0] || undefined;

  return (
    <Layout>
      <ToEntity>
        <ToEntityHelpUI
          toEntityId={toEntityId}
          userInfo={userInfo}
          toEntity={toEntity}
        />
      </ToEntity>
    </Layout>
  );
};

export default HelpTutorialsPageContent;
